import React from 'react';
import RatioGrid from './RatioGrid';
import { dealOverview } from './report';
import Section from './Section';

const DealOverview = React.memo(props => {
  return <Section title="Deal and Asset Overview" subtitle="(what is selling and how deals are getting done)" bg="gray.1">
    <RatioGrid
      ratio1={dealOverview[0]}
      ratio2={dealOverview[1]}
      ratio3={dealOverview[2]}
      ratio4={dealOverview[3]}
      ratio5={dealOverview[4]}
      ratio6={dealOverview[5]}
    />
  </Section>
});

DealOverview.propTypes = {};

DealOverview.defaultProps = {};

DealOverview.displayName = 'DealOverview';

export default DealOverview;