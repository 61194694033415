import useBreakpoints from '@paperstac/ui/lib/hooks/useBreakpoints';
import React from 'react';
import ClosingsSectionDesktop from './ClosingsSectionDesktop';
import ClosingsSectionMobile from './ClosingsSectionMobile';

export const SECTION_TITLE = 'How Online Closings Work';
export const TIMELINE = 'TIMELINE';
export const TODOS = 'TODOS';
export const ESCROW = 'ESCROW';
export const TIMELINE_TITLE = 'Transaction Timeline';
export const TIMELINE_LEAD_TEXT = 'We store every single event and interaction onto a single transaction timeline.';
export const TODOS_TITLE = 'Dynamic To-Do List';
export const TODOS_LEAD_TEXT = 'We guide you through each step of the closing by providing a dynamic To-Do list.';
export const ESCROW_TITLE = 'Escrow & Audit';
export const ESCROW_LEAD_TEXT = 'We have integrated Escrow and Collateral Audit into our closing process to create the safest way to transact mortgage notes.';

const ClosingsSection = React.memo(props => {
  const [currentTab, setCurrentTab] = React.useState(TIMELINE);
  const [accordionState, setAccordionState] = React.useReducer((oldState, newState) => ({ ...oldState, ...newState }), {});
  const { isMobile, isTabletOrLarger } = useBreakpoints();

  return <>
    {isMobile && <ClosingsSectionMobile accordionState={accordionState} setAccordionState={setAccordionState} />}
    {isTabletOrLarger && <ClosingsSectionDesktop currentTab={currentTab} setCurrentTab={setCurrentTab} />}
  </>
});

ClosingsSection.propTypes = {};

ClosingsSection.defaultProps = {};

ClosingsSection.displayName = 'ClosingsSection';

export default ClosingsSection;
