import toTitle from './toTitle';

export const getStreetAddress = (addressable = {}, prefix = null) => {
  const streetAddress1Prop = prefix ? `${prefix}StreetAddress1` : 'streetAddress1';
  const streetAddress2Prop = prefix ? `${prefix}StreetAddress2` : 'streetAddress2';
  return addressable[streetAddress2Prop]
    ? `${toTitle(addressable[streetAddress1Prop])}, ${toTitle(addressable[streetAddress2Prop])}`
    : toTitle(addressable[streetAddress1Prop]);
};

export const getCityStateZip = (addressable = {}, prefix = null) => {
  const cityProp = prefix ? `${prefix}City` : 'city';
  const stateProp = prefix ? `${prefix}State` : 'state';
  const zipProp = prefix ? `${prefix}Zip` : 'zip';
  return `${toTitle(addressable[cityProp])}, ${addressable[stateProp].toUpperCase()} ${addressable[zipProp]}`;
};

export const getFullAddress = (addressable = {}, prefix = null) => {
  return `${getStreetAddress(addressable, prefix)}, ${getCityStateZip(addressable, prefix)}`;
};

export const getAddressMeta = (addressable, prefix) => {
  const streetAddressProp = prefix ? `${prefix}StreetAddress` : 'streetAddress';
  const cityStateZipProp = prefix ? `${prefix}CityStateZip` : 'cityStateZip';
  const fullAddressProp = prefix ? `${prefix}FullAddress` : 'fullAddress';
  return {
    [streetAddressProp]: getStreetAddress(addressable, prefix),
    [cityStateZipProp]: getCityStateZip(addressable, prefix),
    [fullAddressProp]: getFullAddress(addressable, prefix)
  };
};
