import { firestore } from '../services/firebaseClient';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const SET_CAMPAIGNS = 'SET_CAMPAIGNS';

const transformCollection = querySnapshot => {
  return querySnapshot.docs.map(transformFirestoreTimestamps);
};

export const setCampaigns = payload => {
  return { type: SET_CAMPAIGNS, payload };
};

export const subscribeCampaigns = () => {
  return dispatch => {
    return firestore.collection('campaigns').onSnapshot(querySnapshot => {
      dispatch(setCampaigns(transformCollection(querySnapshot)));
    });
  };
};
