import useBreakpoints from '@paperstac/ui/lib/hooks/useBreakpoints';
import Box from '@paperstac/ui/lib/Box';
import Container from '@paperstac/ui/lib/Container';
import Flex from '@paperstac/ui/lib/Flex';
import Heading from '@paperstac/ui/lib/Heading';
import CheckmarkCircleIcon from '@paperstac/ui/lib/icons/CheckmarkCircleIcon';
import Spinner from '@paperstac/ui/lib/Spinner';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';
import styled from 'styled-components';
import { maxWidth, order } from 'styled-system';
import {
  ESCROW,
  ESCROW_LEAD_TEXT,
  SECTION_TITLE,
  TIMELINE,
  TIMELINE_LEAD_TEXT,
  TODOS,
  TODOS_LEAD_TEXT
} from './ClosingsSection';
import ClosingsSectionTabs from './ClosingsSectionTabs';

const TimelineMedia = React.lazy(() => import('./TimelineMedia'));
const TodosMedia = React.lazy(() => import('./TodosMedia'));
const EscrowMedia = React.lazy(() => import('./EscrowMedia'));

const ClosingsSectionDesktop = React.memo(({ currentTab, setCurrentTab }) => {


  return <Box>
    <Heading fontSize={[null, 32, 32, 48]} mt={[null, 3, 40]} mb={[null, 16 + 38, 16 + 54, 32 + 65]} textAlign="center">{SECTION_TITLE}</Heading>
    <Box bg="darkBlue">
      <ClosingsSectionTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
      {currentTab === TIMELINE && <Panel
        leadText={TIMELINE_LEAD_TEXT}
        Media={TimelineMedia}
        bullets={[{
          title: 'Digital Audit Trail',
          body: 'The transaction timeline gives you a complete digital audit trail of your entire deal from opening negotiations to final disbursements.'
        }, {
          title: 'Communication That Works',
          body: 'All communication is consolidated and stored on your transaction timeline.  We\'ll even record your phone calls and transcribe them to keep your digital audit trail intact.'
        }, {
          title: 'Perfect For Team Accounts',
          body: 'The transaction timeline makes it easy for any team member to jump into a transaction and instantly know what is going on saving everyone time and money.'
        }]}
      />}
      {currentTab === TODOS && <Panel
        leadText={TODOS_LEAD_TEXT}
        Media={TodosMedia}
        bullets={[{
          title: 'The Easiest Closing Ever',
          body: 'To-Do\'s remove the guesswork out of what\'s next. Simply click on each To-Do item as they become enabled until the deal is complete.'
        }, {
          title: 'Consistency and Safety',
          body: 'Our To-Do\'s guide you down the right path making sure that everything is done in the correct order preventing you and your money from being exposed.'
        }, {
          title: 'Perfect For Team Accounts',
          body: 'A team member can jump into a transaction, view the To-Do\'s and immediately see the actionable items that need completion.'
        }]}
      />}
      {currentTab === ESCROW && <Panel
        leadText={ESCROW_LEAD_TEXT}
        Media={EscrowMedia}
        bullets={[{
          title: 'Step 1',
          body: 'Buyer wires funds to escrow'
        }, {
          title: 'Step 2',
          body: 'Seller ships collateral via FedEx to auditor for review'
        }, {
          title: 'Step 3',
          body: 'Buyer approves audit report and seller executes assignments'
        }, {
          title: 'Step 4',
          body: 'Escrow disburses funds and auditor ships collateral file via FedEx simultaneously'
        }]}
      />}
    </Box>
  </Box>
});

const MediaWrapper = styled(Box)`
  ${maxWidth}
`;

const Content = styled(Box)`
  ${order}
`;

const Panel = ({ leadText, bullets, Media }) => {
  const { isTablet, isWideTabletOrLarger, isWideDesktop } = useBreakpoints();
  return <Container pt={[null, 2]} pb={[null, 5]}>
    {isTablet && <LeadText>{leadText}</LeadText>}
    <Flex flexDirection={[null, 'column', 'row']} alignItems={[null, null, 'center']}>
      <Content order={[null, 1, 0]} mr={[null, 0, 5, 6, 7]} flex={[null, null, 60]}>
        {isWideTabletOrLarger && <LeadText>{leadText}</LeadText>}
        {bullets.map(({ title, body }, index) =>
          <Bullet key={index} title={title} body={body} iconSize={isWideDesktop ? 30 : 24} />)}
      </Content>
      <MediaWrapper maxWidth={[null, 500, '100%', '100%']} flex={[null, null, 40]} mr="auto" ml="auto" mb={[null, 2, null]}>
        <React.Suspense fallback={<Flex py={5} justifyContent="center"><Spinner /></Flex>}>
          <Media />
        </React.Suspense>
      </MediaWrapper>
    </Flex>
  </Container>
};

const LeadText = ({ children }) => <Heading
  fontSize={[null, 24, 24, 24, 32]}
  mb={[null, 40]}
  color="white"
  textAlign={[null, 'center', 'left']}
  children={children}
/>;

const Bullet = ({ title, body, iconSize }) => <Flex alignItems="flex-start" mb={[null, 3, 3, 4, 5]}>
  <Box flex={[null, '0 0 40px', '0 0 40px', '0 0 40px', '0 0 46px']}>
    <CheckmarkCircleIcon color="primary" width={iconSize} height={iconSize} mr={[null, 16]} />
  </Box>
  <Box>
    <Heading fontSize={[null, 20, 20, 20, 24]} mb={[null, 2]} mt={[null, '2px']} color="white" children={title} />
    <Text fontSize={[null, 14, 14, 16, 20]} color="gray.2" children={body} />
  </Box>
</Flex>;

ClosingsSectionDesktop.propTypes = {};

ClosingsSectionDesktop.defaultProps = {};

ClosingsSectionDesktop.displayName = 'ClosingsSectionDesktop';

export default ClosingsSectionDesktop;
