import { sortCollection } from '@paperstac/helpers/lib/arrayHelpers';
import AlgoliaChecklistRefinement from '@paperstac/ui/lib/AlgoliaChecklistRefinement';
import AlgoliaHasListRefinement from '@paperstac/ui/lib/AlgoliaHasListRefinement';
import React from 'react';
import CollapsibleFilterContainer from './CollapsibleFilterContainer';

const PropertyTypeFilter = React.memo(() => <AlgoliaHasListRefinement attribute="propertyTypes">
  <CollapsibleFilterContainer title="Property Type">
    <AlgoliaChecklistRefinement attribute="propertyTypes" transformItems={(items) => sortCollection(items, 'label')} />
  </CollapsibleFilterContainer>
</AlgoliaHasListRefinement>);

PropertyTypeFilter.propTypes = {};

PropertyTypeFilter.defaultProps = {};

PropertyTypeFilter.displayName = 'PropertyTypeFilter';

export default PropertyTypeFilter;
