import { firestore } from '../services/firebaseClient';
import { destroy, post } from '../services/api';
import { reset, SubmissionError } from 'redux-form';
import { TeamInviteFormName } from '../components/TeamInviteForm';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const DELETE_TEAM_INVITE_REQUEST = 'DELETE_TEAM_INVITE_REQUEST';
export const DELETE_TEAM_INVITE_SUCCESS = 'DELETE_TEAM_INVITE_SUCCESS';
export const DELETE_TEAM_INVITE_FAILURE = 'DELETE_TEAM_INVITE_FAILURE';
export const FETCH_TEAM_INVITES_REQUEST = 'FETCH_TEAM_INVITES_REQUEST';
export const FETCH_TEAM_INVITES_SUCCESS = 'FETCH_TEAM_INVITES_SUCCESS';
export const FETCH_TEAM_INVITES_FAILURE = 'FETCH_TEAM_INVITES_FAILURE';
export const FETCH_TEAM_THAT_INVITED_REQUEST = 'FETCH_TEAM_THAT_INVITED_REQUEST';
export const FETCH_TEAM_THAT_INVITED_SUCCESS = 'FETCH_TEAM_THAT_INVITED_SUCCESS';
export const FETCH_TEAM_THAT_INVITED_FAILURE = 'FETCH_TEAM_THAT_INVITED_FAILURE';
export const SEND_TEAM_INVITE_REQUEST = 'SEND_TEAM_INVITE_REQUEST';
export const SEND_TEAM_INVITE_SUCCESS = 'SEND_TEAM_INVITE_SUCCESS';
export const SEND_TEAM_INVITE_FAILURE = 'SEND_TEAM_INVITE_FAILURE';
export const SET_TEAM_INVITES = 'SET_TEAM_INVITES';
export const SET_TEAM_THAT_INVITED = 'SET_TEAM_THAT_INVITED';
export const SUBSCRIBE_TEAM_INVITES = 'SUBSCRIBE_TEAM_INVITES';
export const UNSUBSCRIBE_TEAM_INVITES = 'UNSUBSCRIBE_TEAM_INVITES';

let unsubscribe = null;

const transformQuerySnapshot = querySnapshot => {
  return querySnapshot.docs.map(transformFirestoreTimestamps);
};

export const setTeamInvites = payload => {
  return { type: SET_TEAM_INVITES, payload };
};

export const sendTeamInviteRequest = (accountId, email) => {
  return dispatch => {
    const payload = { email };
    dispatch({ type: SEND_TEAM_INVITE_REQUEST, payload });
    return post(`accounts/${accountId}/invites`, payload)
      .then(() => {
        dispatch({ type: SEND_TEAM_INVITE_SUCCESS });
        dispatch(reset(TeamInviteFormName));
      })
      .catch(error => {
        dispatch({ type: SEND_TEAM_INVITE_FAILURE, message: error.message });
        throw new SubmissionError({ _error: error.message });
      });
  };
};

export const deleteTeamInviteRequest = (accountId, inviteId) => {
  return dispatch => {
    dispatch({ type: DELETE_TEAM_INVITE_REQUEST });
    return destroy(`accounts/${accountId}/invites/${inviteId}`)
      .then(() => {
        dispatch({ type: DELETE_TEAM_INVITE_SUCCESS });
      })
      .catch(error => {
        dispatch({ type: DELETE_TEAM_INVITE_FAILURE, message: error.message });
        throw new Error(error.message);
      });
  };
};

export const fetchTeamInvitesRequest = accountId => {
  return dispatch => {
    dispatch({ type: FETCH_TEAM_INVITES_REQUEST });
    return firestore
      .collection(`accounts/${accountId}/invites`)
      .orderBy('lastSendDate')
      .get()
      .then(querySnapshot => {
        dispatch({ type: FETCH_TEAM_INVITES_SUCCESS });
        dispatch(setTeamInvites(transformQuerySnapshot(querySnapshot)));
      })
      .catch(error => {
        dispatch({ type: FETCH_TEAM_INVITES_FAILURE, message: error.message });
      });
  };
};

export const subscribeTeamInvites = accountId => {
  return dispatch => {
    if (!unsubscribe) {
      dispatch({ type: SUBSCRIBE_TEAM_INVITES, accountId });
      unsubscribe = firestore
        .collection(`accounts/${accountId}/invites`)
        .orderBy('lastSendDate')
        .onSnapshot(querySnapshot => {
          dispatch(setTeamInvites(transformQuerySnapshot(querySnapshot)));
        });
    }
  };
};

export const unsubscribeTeamInvites = () => {
  return dispatch => {
    if (unsubscribe) {
      unsubscribe();
      unsubscribe = null;
      dispatch({ type: UNSUBSCRIBE_TEAM_INVITES });
    }
  };
};

export const setTeamThatInvited = payload => {
  return { type: SET_TEAM_THAT_INVITED, payload };
};

export const fetchTeamThatInvitedRequest = accountId => {
  return dispatch => {
    dispatch({ type: FETCH_TEAM_THAT_INVITED_REQUEST });
    return firestore
      .doc(`accounts/${accountId}/profiles/${accountId}`)
      .get()
      .then(doc => {
        dispatch({ type: FETCH_TEAM_THAT_INVITED_SUCCESS });
        dispatch(setTeamThatInvited(doc.data()));
      })
      .catch(error => {
        dispatch({ type: FETCH_TEAM_THAT_INVITED_FAILURE, message: error.message });
      });
  };
};
