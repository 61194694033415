import Box from '@paperstac/ui/lib/Box';
import Drawer from '@paperstac/ui/lib/Drawer';
import DrawerHeader from '@paperstac/ui/lib/DrawerHeader';
import Overlay from '@paperstac/ui/lib/Overlay';
import React from 'react';
import AccountsSection from './AccountsSection';
import LoginDetailsSection from './LoginDetailsSection';
import VerifiedSection from './VerifiedSection';
import useBoolean from '@paperstac/hooks/lib/useBoolean';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import VerifyIdentityOverlay from '../VerifyIdentityOverlay/VerifyIdentityOverlay';

const ManageIdentityOverlay = React.memo(({ onClose, ...props }) => {
  const { identity } = React.useContext(IdentityContext);
  const [isVerifying, startVerification, endVerification] = useBoolean(false);

  if (!identity.isVerified && isVerifying) return <VerifyIdentityOverlay onClose={endVerification} />;

  return (
    <>
      <Overlay onClose={onClose}>
        <Drawer>
          <DrawerHeader text="Manage Identity" onClose={onClose} />
          <Box sx={{ overflowY: 'auto', flex: '1 1 auto' }}>
            <VerifiedSection startVerification={startVerification} />
            <LoginDetailsSection />
            <AccountsSection />
          </Box>
        </Drawer>
      </Overlay>
    </>
  );
});

ManageIdentityOverlay.propTypes = {};

ManageIdentityOverlay.defaultProps = {};

ManageIdentityOverlay.displayName = 'ManageIdentityOverlay';

export default ManageIdentityOverlay;
