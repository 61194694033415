import { DELETE_NOTE_FILE, UPDATE_NOTE_FILE } from '@paperstac/common/lib/serverDispatchActionTypes';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import { VISIBILITY_PRIVATE, VISIBILITY_PUBLIC } from '@paperstac/constants';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import ErrorText from '@paperstac/ui/lib/ErrorText';
import Flex from '@paperstac/ui/lib/Flex';
import InputFormGroup from '@paperstac/ui/lib/form/InputFormGroup';
import RadioGroup from '@paperstac/ui/lib/form/RadioGroup';
import FileExtIcon from '@paperstac/ui/lib/icons/FileExtIcon';
import Link from '@paperstac/ui/lib/Link';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import { Form, Formik } from 'formik';
import get from 'lodash/get';
import React from 'react';
import EditButton from './EditButton';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';

const NoteScreenFileItem = React.memo(({ file, ...props }) => {
  const { note, stacked } = React.useContext(ManageListingOverlayContext);
  const [isEditing, setEditing] = React.useState(false);
  const [isDeleting, setDeleting] = React.useState(false);
  const showEdit = React.useCallback(() => setEditing(true), []);
  const hideEdit = React.useCallback(() => setEditing(false), []);

  const onDelete = React.useCallback(() => setDeleting(true), []);

  React.useEffect(() => {
    if (!isDeleting) return;
    serverDispatch({
      action: DELETE_NOTE_FILE,
      payload: { noteId: note.id, accountId: note.accountId, fileId: file.id }
    }).catch(error => {
      alert(error.message);
      setDeleting(false);
    });
  }, [isDeleting, file.id, note.accountId, note.id]);

  if (isDeleting) return null;

  if (isEditing) return <Box {...props} py={4}>
    <Formik
      initialValues={{
        noteId: note.id,
        accountId: note.accountId,
        fileId: file.id,
        name: file.name,
        visibility: file.visibility,
      }}
      onSubmit={async (payload, { setSubmitting, setStatus }) => {
        setSubmitting(true);
        serverDispatch({ action: UPDATE_NOTE_FILE, payload })
          .then(hideEdit)
          .catch(error => {
            setStatus({ errorMessage: error.message });
            setSubmitting(false);
          });
      }}
      children={formik => <Form>
        <InputFormGroup name="name" />
        <Box mb={3}>
          <RadioGroup name="visibility" value={formik.values.visibility} items={[
            { label: 'Public', value: VISIBILITY_PUBLIC },
            { label: 'Private', value: VISIBILITY_PRIVATE }
          ]} />
        </Box>
        {get(formik, 'status.errorMessage') && <ErrorText mb={3}>{formik.status.errorMessage}</ErrorText>}
        <Button type="submit" variant="primary" busy={formik.isSubmitting} mr={2}>Update File</Button>
        <Button variant="default" onClick={hideEdit}>Cancel</Button>
        <Box mt={3}><LinkButton onClick={onDelete} sx={{ color: 'red', fontSize: 1 }}>Delete File</LinkButton></Box>
      </Form>} />
  </Box>

  return <Flex alignItems="center" {...props}>
    <Box flex={'1 1 0'} mr={stacked ? 3 : 5}>
      <Link href={file.fileUrl} target="_blank">
        <FileExtIcon filePath={file.filePath} /> {file.name}
      </Link>
      {file.visibility === VISIBILITY_PUBLIC && <Box color="green" fontSize={1} mt={1}>Public</Box>}
      {file.visibility === VISIBILITY_PRIVATE && <Box color="red" fontSize={1} mt={1}>Private</Box>}
    </Box>
    <Box><EditButton onClick={showEdit} /></Box>
  </Flex>;
});

NoteScreenFileItem.propTypes = {};

NoteScreenFileItem.defaultProps = {};

NoteScreenFileItem.displayName = 'NoteScreenFileItem';

export default NoteScreenFileItem;
