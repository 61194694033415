import { getOrdinal } from '@paperstac/helpers/lib/numberHelpers';
import { LISTING_NEGOTIATION_TYPE } from '@paperstac/firestore-collections/lib/listings';
import getPath from '@paperstac/routes/lib/getPath';
import { FOR_SALE_SINGLE } from '@paperstac/routes/lib/webRoutes';
import Box from '@paperstac/ui/lib/Box';
import Carousel from '@paperstac/ui/lib/Carousel';
import Flex from '@paperstac/ui/lib/Flex';
import Heading from '@paperstac/ui/lib/Heading';
import CheckmarkCircleIcon from '@paperstac/ui/lib/icons/CheckmarkCircleIcon';
import XCircleIcon from '@paperstac/ui/lib/icons/XCircleIcon';
import Link from '@paperstac/ui/lib/Link';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';
import { Configure } from 'react-instantsearch-dom';
import styled from 'styled-components';
import { border } from 'styled-system';
import AlgoliaResults from '../../AlgoliaResults';
import AlgoliaSearch from '../../AlgoliaSearch';

const NUMBER_OF_LISTINGS = 10;

const formatCurrency = cents => {
  if (typeof cents === 'undefined' || isNaN(cents)) return null;
  const dollars = Math.round(cents / 100);
  return `$${dollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

const formatLienPosition = lienPositions => {
  const areSame = lienPositions.reduce((areSame, lienPosition) => {
    if (!areSame) return false;
    return lienPosition === lienPositions[0];
  }, true);
  return areSame ? getOrdinal(lienPositions[0]) : 'Mixed'
};

const formatPerformance = performance => {
  const areSame = performance.reduce((areSame, perf) => {
    if (!areSame) return false;
    return perf === performance[0];
  }, true);
  return areSame ? performance[0] : 'Non-Performing'
};

const TrendingSection = React.memo(props => (
  <Box py={[40, 40, 40, 64]}>
    <Heading fontSize={[20, 32, 32, 32, 48]} mb={[32, 40]} textAlign="center">Currently Trending Listings</Heading>
    <AlgoliaSearch indexName="listings" syncUrl={false}>
      <Configure hitsPerPage={NUMBER_OF_LISTINGS} />
      <AlgoliaResults>
        {listings => (
          <Carousel arrows={false} autoplay={true} adaptiveHeight={true} variableWidth={true} swipeToSlide={true} centerMode={true}>
            {listings.map(listing => <Listing key={listing.id} {...listing} />)}
          </Carousel>
        )}
      </AlgoliaResults>
    </AlgoliaSearch>
  </Box>
));

const ListingHeader = styled(Box)`
  ${border}
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const ListingBody = styled(Box)`
  ${border}
  border-top: none;
  border-bottom: none;
`;

const ListingMetric = ({ label, value, ...props }) => <Box {...props}>
  <Text fontSize={12}>{label}</Text>
  <Text fontSize={16} fontWeight={700}>{value}</Text>
</Box>;

const ListingButton = props => <Link {...props} sx={{
  display: 'block',
  color: 'white',
  bg: 'secondary',
  textAlign: 'center',
  textDecoration: 'none',
  padding: 2,
  fontWeight: 'bold',
  borderBottomLeftRadius: 'corner',
  borderBottomRightRadius: 'corner',
  '&:hover,&:focus': {
    color: 'white',
    bg: 'secondaryDarken'
  }
}} />;

const Listing = ({ id, listPrice, negotiationType, noteMetaAgg: { interestRatePercent, lienPosition, performance, propertyValue, upb } }) => {
  const perf = formatPerformance(performance);

  return <Box width={280} mx={3}>
    <ListingHeader border="default" bg="gray.1" p={16}>
      <Text fontSize={12} textAlign="center">For Sale</Text>
      <Text fontSize={24} fontWeight={700} lineHeight={1.2} textAlign="center">{negotiationType === LISTING_NEGOTIATION_TYPE.MAKE_OFFER ? 'Make an Offer' : formatCurrency(listPrice)}</Text>
    </ListingHeader>
    <ListingBody border="default" bg="white" p={24}>
      <Flex mb={24}>
        <ListingMetric label="Principal Balance" value={formatCurrency(upb)} width={150} />
        <ListingMetric label="Interest Rate" value={typeof interestRatePercent === 'undefined' ? 'N/A' : `${interestRatePercent}%`} />
      </Flex>
      <Flex mb={24}>
        <ListingMetric label="Property Value" value={formatCurrency(propertyValue)} width={150} />
        <ListingMetric label="Lien Position" value={formatLienPosition(lienPosition)} />
      </Flex>
      <Flex alignItems="center" justifyContent="center" py={3}>
        {perf === 'Performing' && <CheckmarkCircleIcon size={24} color="green" mr={2} />}
        {perf === 'Non-Performing' && <XCircleIcon size={24} color="red" mr={2} />}
        <Text fontSize={20} fontWeight={700}>{perf}</Text>
      </Flex>
    </ListingBody>
    <ListingButton to={getPath(FOR_SALE_SINGLE, id)}>View Details</ListingButton>
  </Box>;
};

TrendingSection.propTypes = {};

TrendingSection.defaultProps = {};

TrendingSection.displayName = 'TrendingSection';

export default TrendingSection;
