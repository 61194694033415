import sortBy from 'lodash/sortBy';
import { SubmissionError } from 'redux-form';
import { updateEmail, updatePassword } from '../services/auth';
import { firestore } from '../services/firebaseClient';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const SUBSCRIBE_CURRENT_ACCOUNT = 'SUBSCRIBE_CURRENT_ACCOUNT';
export const SET_CURRENT_ACCOUNT = 'SET_CURRENT_ACCOUNT';
export const SET_CURRENT_ACCOUNT_FEE_OVERRIDES = 'SET_CURRENT_ACCOUNT_FEE_OVERRIDES';
export const SET_CURRENT_ACCOUNT_ID = 'SET_CURRENT_ACCOUNT_ID';
export const SWITCH_CURRENT_ACCOUNT = 'SWITCH_CURRENT_ACCOUNT';
export const UNSUBSCRIBE_CURRENT_ACCOUNT = 'UNSUBSCRIBE_CURRENT_ACCOUNT';
export const UPDATE_CURRENT_ACCOUNT_REQUEST = 'UPDATE_CURRENT_ACCOUNT_REQUEST';
export const UPDATE_CURRENT_ACCOUNT_SUCCESS = 'UPDATE_CURRENT_ACCOUNT_SUCCESS';
export const UPDATE_CURRENT_ACCOUNT_FAILURE = 'UPDATE_CURRENT_ACCOUNT_FAILURE';
export const UPDATE_CURRENT_ACCOUNT_AVATAR_REQUEST = 'UPDATE_CURRENT_ACCOUNT_AVATAR_REQUEST';
export const UPDATE_CURRENT_ACCOUNT_AVATAR_FAILURE = 'UPDATE_CURRENT_ACCOUNT_AVATAR_FAILURE';
export const UPDATE_CURRENT_ACCOUNT_EMAIL_REQUEST = 'UPDATE_CURRENT_ACCOUNT_EMAIL_REQUEST';
export const UPDATE_CURRENT_ACCOUNT_EMAIL_SUCCESS = 'UPDATE_CURRENT_ACCOUNT_EMAIL_SUCCESS';
export const UPDATE_CURRENT_ACCOUNT_EMAIL_FAILURE = 'UPDATE_CURRENT_ACCOUNT_EMAIL_FAILURE';
export const UPDATE_CURRENT_ACCOUNT_PASSWORD_REQUEST = 'UPDATE_CURRENT_ACCOUNT_PASSWORD_REQUEST';
export const UPDATE_CURRENT_ACCOUNT_PASSWORD_SUCCESS = 'UPDATE_CURRENT_ACCOUNT_PASSWORD_SUCCESS';
export const UPDATE_CURRENT_ACCOUNT_PASSWORD_FAILURE = 'UPDATE_CURRENT_ACCOUNT_PASSWORD_FAILURE';

let unsubscribe = null;

export const transformAccount = account => {
  if (!account) {
    return null;
  }
  return account.isTeam
    ? { ...account, members: sortBy(Object.values(account.members), 'displayName') }
    : { ...account, teams: sortBy(Object.values(account.teams), 'displayName') };
};

export const setCurrentAccount = payload => {
  return { type: SET_CURRENT_ACCOUNT, payload };
};

export const setCurrentAccountFeeOverrides = payload => {
  return { type: SET_CURRENT_ACCOUNT_FEE_OVERRIDES, payload };
};

export const setCurrentAccountId = payload => {
  return { type: SET_CURRENT_ACCOUNT_ID, payload };
};

export const updateCurrentAccountRequest = (accountId, payload) => {
  return dispatch => {
    dispatch({ type: UPDATE_CURRENT_ACCOUNT_REQUEST });
    return firestore
      .doc(`accounts/${accountId}`)
      .update(payload)
      .then(() => dispatch({ type: UPDATE_CURRENT_ACCOUNT_SUCCESS }))
      .catch(error => {
        dispatch({ type: UPDATE_CURRENT_ACCOUNT_FAILURE, message: error.message });
        throw new SubmissionError({ _error: error.message });
      });
  };
};

export const updateCurrentAccountEmailRequest = (accountId, email) => {
  return async dispatch => {
    try {
      dispatch({ type: UPDATE_CURRENT_ACCOUNT_EMAIL_REQUEST });
      await updateEmail(email);
      await firestore.doc(`accounts/${accountId}`).update({ email });
      return dispatch({ type: UPDATE_CURRENT_ACCOUNT_EMAIL_SUCCESS });
    } catch (error) {
      dispatch({ type: UPDATE_CURRENT_ACCOUNT_EMAIL_FAILURE, message: error.message });
      throw new SubmissionError({ _error: error.message });
    }
  };
};

export const updateCurrentAccountPasswordRequest = password => {
  return async dispatch => {
    try {
      dispatch({ type: UPDATE_CURRENT_ACCOUNT_PASSWORD_REQUEST });
      await updatePassword(password);
      return dispatch({ type: UPDATE_CURRENT_ACCOUNT_PASSWORD_SUCCESS });
    } catch (error) {
      dispatch({ type: UPDATE_CURRENT_ACCOUNT_PASSWORD_FAILURE, message: error.message });
      throw new SubmissionError({ _error: error.message });
    }
  };
};

export const updateCurrentAccountAvatarRequest = () => {
  return { type: UPDATE_CURRENT_ACCOUNT_AVATAR_REQUEST };
};

export const updateCurrentAccountAvatarFailure = () => {
  return { type: UPDATE_CURRENT_ACCOUNT_AVATAR_FAILURE };
};

export const subscribeCurrentAccount = accountId => {
  return dispatch => {
    if (!unsubscribe) {
      dispatch({ type: SUBSCRIBE_CURRENT_ACCOUNT });
      unsubscribe = firestore.doc(`accounts/${accountId}`).onSnapshot(docSnap => {
        dispatch(setCurrentAccount(transformAccount(transformFirestoreTimestamps(docSnap))));
      });
    }
  };
};

export const unsubscribeCurrentAccount = () => {
  return dispatch => {
    if (unsubscribe) {
      unsubscribe();
      unsubscribe = null;
      dispatch({ type: UNSUBSCRIBE_CURRENT_ACCOUNT });
    }
  };
};

export const switchCurrentAccount = currentAccountId => {
  return dispatch => {
    dispatch({ type: SWITCH_CURRENT_ACCOUNT, payload: { currentAccountId } });
    dispatch(unsubscribeCurrentAccount());
    dispatch(subscribeCurrentAccount(currentAccountId));
  };
};

export const subscribeCurrentAccountFeeOverrides = accountId => {
  return dispatch => {
    return firestore
      .collection(`feeOverrides`)
      .where('accountId', '==', accountId)
      .onSnapshot(querySnapshot => {
        dispatch(setCurrentAccountFeeOverrides(querySnapshot.docs.map(transformFirestoreTimestamps)));
      });
  };
};
