import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import accountSwitcher from './accountSwitcherReducer';
import activeListings, * as activeListingsReducer from './activeListingsReducer';
import auditOrders, * as auditOrdersReducer from './auditOrdersReducer';
import bankAccounts from './bankAccountsReducer';
import blacklists, * as blacklistsReducer from './blacklistsReducer';
import campaigns, * as campaignsReducer from './campaignsReducer';
import columnSettings, * as columnSettingsReducer from './columnSettingsReducer';
import communities, * as communitiesReducer from './communitiesReducer';
import communityInvites, * as communityInvitesReducer from './communityInvitesReducer';
import creditCards from './creditCardsReducer';
import currentAccountFeeOverrides, * as currentAccountFeeOverridesReducer from './currentAccountFeeOverridesReducer';
import currentAccountId from './currentAccountIdReducer';
import currentAccount from './currentAccountReducer';
import currentUser from './currentUserReducer';
import device, * as deviceReducer from './deviceReducer';
import escrowAccounts, * as escrowAccountsReducer from './escrowAccountsReducer';
import escrowOrders, * as escrowOrdersReducer from './escrowOrdersReducer';
import eventModerationQueue, * as eventModerationQueueReducer from './eventModerationQueueReducer';
import flaggedEvents, * as flaggedEventsReducer from './flaggedEventsReducer';
import imports, * as importsReducer from './importsReducer';
import listings, * as listingsReducer from './listingsReducer';
import noteFiles, * as noteFilesReducer from './noteFilesReducer';
import noteImports, * as noteImportsReducer from './noteImportsReducer';
import notePhotos, * as notePhotosReducer from './notePhotosReducer';
import notes, * as notesReducer from './notesReducer';
import overlay, * as overlayReducer from './overlayReducer';
import restorePoints, * as restorePointsReducer from './restorePointsReducer';
import savedSearches, * as savedSearchesReducer from './savedSearchesReducer';
import sellerOnboarding from './sellerOnboardingReducer';
import servicerProfiles from './servicerProfilesReducer';
import serviceWorker, * as serviceWorkerReducer from './serviceWorkerReducer';
import shippingAddresses from './shippingAddressesReducer';
import starredListings, * as starredListingsReducer from './starredListingsReducer';
import teamInvites, * as teamInvitesReducer from './teamInvitesReducer';
import toaster from './toasterReducer';
import transactionDocuments, * as transactionDocumentsReducer from './transactionDocumentsReducer';
import transactionEvents, * as transactionEventsReducer from './transactionEventsReducer';
import transactionFees, * as transactionFeesReducer from './transactionFeesReducer';
import transactions, * as transactionsReducer from './transactionsReducer';
import vestingProfiles from './vestingProfilesReducer';

const rootReducer = combineReducers({
  accountSwitcher,
  activeListings,
  auditOrders,
  bankAccounts,
  blacklists,
  campaigns,
  columnSettings,
  communities,
  communityInvites,
  creditCards,
  currentAccount,
  currentAccountId,
  currentAccountFeeOverrides,
  currentUser,
  device,
  escrowAccounts,
  escrowOrders,
  eventModerationQueue,
  flaggedEvents,
  form,
  imports,
  listings,
  notes,
  noteFiles,
  noteImports,
  notePhotos,
  overlay,
  restorePoints,
  savedSearches,
  sellerOnboarding,
  servicerProfiles,
  starredListings,
  teamInvites,
  toaster,
  transactions,
  transactionDocuments,
  transactionEvents,
  transactionFees,
  serviceWorker,
  shippingAddresses,
  vestingProfiles
});

export default rootReducer;

export const columnSettingsLastUpdated = (state, key) => {
  return columnSettingsReducer.columnSettingsLastUpdated(state.columnSettings, key);
};

export const getActiveListing = (state, listingId) => {
  return activeListingsReducer.getActiveListing(state.activeListings, listingId);
};

export const getAuditOrders = state => {
  return auditOrdersReducer.getAuditOrders(state.auditOrders);
};

export const getAuditOrder = (state, auditOrderId) => {
  return auditOrdersReducer.getAuditOrder(state.auditOrders, auditOrderId);
};

export const auditOrdersLastUpdated = state => {
  return auditOrdersReducer.auditOrdersLastUpdated(state.auditOrders);
};

export const getCampaigns = state => {
  return campaignsReducer.getCampaigns(state.campaigns);
};

export const getMyBlacklist = state => {
  return blacklistsReducer.getMyBlacklist(state.blacklists);
};

export const getAllBlacklistAccountIds = state => {
  return blacklistsReducer.getAllBlacklistAccountIds(state.blacklists);
};

export const getBankAccounts = state => {
  return state.bankAccounts;
};

export const getCreditCards = state => {
  return state.creditCards;
};

export const getColumnSettings = (state, key) => {
  return columnSettingsReducer.getColumnSettings(state.columnSettings, key);
};

export const getCommunity = (state, communityId) => {
  return communitiesReducer.getCommunity(state.communities, communityId);
};

export const getMyCommunities = state => {
  return communitiesReducer.getMyCommunities(state.communities);
};

export const getSellingCommunities = state => {
  return communitiesReducer.getSellingCommunities(state.communities);
};

export const getCommunityInvites = (state, communityId) => {
  return communityInvitesReducer.getCommunityInvites(state.communityInvites, communityId);
};

export const getCommunityInvitesLastUpdated = state => {
  return communityInvitesReducer.getCommunityInvitesLastUpdated(state.communityInvites);
};

export const getCurrentAccountFeeOverrides = state => {
  return currentAccountFeeOverridesReducer.getCurrentAccountFeeOverrides(state.currentAccountFeeOverrides);
};

export const getIsTouchDevice = state => {
  return deviceReducer.getIsTouchDevice(state.device);
};

export const getCurrentImportRows = state => {
  return importsReducer.getCurrentImportRows(state.imports);
};

export const getEventModerationQueue = state => {
  return eventModerationQueueReducer.getEventModerationQueue(state.eventModerationQueue);
};

export const getFlaggedEvents = state => {
  return flaggedEventsReducer.getFlaggedEvents(state.flaggedEvents);
};

export const getEscrowAccount = state => {
  return escrowAccountsReducer.getEscrowAccount(state.escrowAccounts);
};

export const getEscrowAccountTransactions = state => {
  return escrowAccountsReducer.getEscrowAccountTransactions(state.escrowAccounts);
};

export const getEscrowOrders = state => {
  return escrowOrdersReducer.getEscrowOrders(state.escrowOrders);
};

export const getEscrowOrder = (state, escrowOrderId) => {
  return escrowOrdersReducer.getEscrowOrder(state.escrowOrders, escrowOrderId);
};

export const escrowOrdersLastUpdated = state => {
  return escrowOrdersReducer.escrowOrdersLastUpdated(state.escrowOrders);
};

export const getImports = state => {
  return importsReducer.getImports(state.imports);
};

export const getImportRowDetails = state => {
  return importsReducer.getImportRowDetails(state.imports);
};

export const getListing = (state, listingId) => {
  return listingsReducer.getListing(state.listings, listingId);
};

export const getListings = state => {
  return listingsReducer.getListings(state.listings);
};

export const listingsLastUpdated = state => {
  return listingsReducer.listingsLastUpdated(state.listings);
};

export const getManageNotesColumnSettings = state => {
  return columnSettingsReducer.getManageNotesColumnSettings(state.columnSettings);
};

export const getViewNotesColumnSettings = state => {
  return columnSettingsReducer.getViewNotesColumnSettings(state.columnSettings);
};

export const getNotes = state => {
  return notesReducer.getNotes(state.notes);
};

export const getNoteFiles = (state, noteId) => {
  return noteFilesReducer.getNoteFiles(state.noteFiles, noteId);
};

export const getNoteImports = (state, noteId) => {
  return noteImportsReducer.getNoteImports(state.noteImports, noteId);
};

export const getNotePhotos = (state, noteId) => {
  return notePhotosReducer.getNotePhotos(state.notePhotos, noteId);
};

export const getNoteImportDetail = (state, noteId, importId) => {
  return noteImportsReducer.getNoteImportDetail(state.noteImports, noteId, importId);
};

export const getUnlistedNotes = state => {
  return notesReducer.getUnlistedNotes(state.notes);
};

export const getPoolUnlistedNotes = state => {
  return notesReducer.getPoolUnlistedNotes(state.notes);
};

export const getRestorePoints = state => {
  return restorePointsReducer.getRestorePoints(state.restorePoints);
};

export const getSavedSearches = state => {
  return savedSearchesReducer.getSavedSearches(state.savedSearches);
};

export const getShippingAddresses = state => {
  return state.shippingAddresses;
};

export const getStarredListings = state => {
  return starredListingsReducer.getStarredListings(state.starredListings);
};

export const getActiveStarredListings = state => {
  return starredListingsReducer.getActiveStarredListings(state.starredListings);
};

export const getInactiveStarredListings = state => {
  return starredListingsReducer.getInactiveStarredListings(state.starredListings);
};

export const getSellerOnboarding = state => {
  return state.sellerOnboarding;
};

export const getTeamInvites = state => {
  return teamInvitesReducer.getTeamInvites(state.teamInvites);
};

export const getTeamThatInvited = state => {
  return teamInvitesReducer.getTeamThatInvited(state.teamInvites);
};

export const getTransaction = (state, transactionId) => {
  return transactionsReducer.getTransaction(state.transactions, transactionId);
};

export const getTransactionOthers = (state, transactionId) => {
  return transactionsReducer.getTransactionOthers(state.transactions, transactionId);
};

export const getTransactions = state => {
  return transactionsReducer.getTransactions(state.transactions);
};

export const getActiveSellerTransactions = state => {
  return transactionsReducer.getActiveSellerTransactions(state.transactions);
};

export const transactionsLastUpdated = state => {
  return transactionsReducer.transactionsLastUpdated(state.transactions);
};

export const getClosingTransactions = state => {
  return transactionsReducer.getClosingTransactions(state.transactions);
};

export const closingTransactionsLastUpdated = state => {
  return transactionsReducer.closingTransactionsLastUpdated(state.transactions);
};

export const getTransactionEvents = (state, transactionId) => {
  return transactionEventsReducer.getTransactionEvents(state.transactionEvents, transactionId);
};

export const getTransactionDocuments = (state, transactionId) => {
  return transactionDocumentsReducer.getTransactionDocuments(state.transactionDocuments, transactionId);
};

export const getTransactionDocument = (state, transactionId, documentId) => {
  return transactionDocumentsReducer.getTransactionDocument(state.transactionDocuments, transactionId, documentId);
};

export const getTransactionFees = (state, transactionId) => {
  return transactionFeesReducer.getTransactionFees(state.transactionFees, transactionId);
};

export const getBuyerTransactionForListing = (state, buyerId, listingId) => {
  return transactionsReducer.getBuyerTransactionForListing(state.transactions, buyerId, listingId);
};

export const getTransactionPageAction = state => {
  return transactionsReducer.getTransactionPageAction(state.transactions);
};

export const getVestingProfiles = state => {
  return state.vestingProfiles;
};

export const getServicerProfiles = state => {
  return state.servicerProfiles;
};

export const notesLastUpdated = state => {
  return notesReducer.notesLastUpdated(state.notes);
};

export const manageNotesColumnSettingsLastUpdated = state => {
  return columnSettingsReducer.manageNotesColumnSettingsLastUpdated(state.columnSettings);
};

export const isShowingNoteFilesOverlay = state => {
  return overlayReducer.isShowingNoteFilesOverlay(state.overlay);
};

export const isShowingNoteHistoryOverlay = state => {
  return overlayReducer.isShowingNoteHistoryOverlay(state.overlay);
};

export const isShowingNoteListingOverlay = state => {
  return overlayReducer.isShowingNoteListingOverlay(state.overlay);
};

export const isShowingNotePoolListingOverlay = state => {
  return overlayReducer.isShowingNotePoolListingOverlay(state.overlay);
};

export const isShowingNotePhotosOverlay = state => {
  return overlayReducer.isShowingNotePhotosOverlay(state.overlay);
};

export const viewNotesColumnSettingsLastUpdated = state => {
  return columnSettingsReducer.viewNotesColumnSettingsLastUpdated(state.columnSettings);
};

export const serviceWorkerNeedsRefresh = state => {
  return serviceWorkerReducer.serviceWorkerNeedsRefresh(state.serviceWorker);
};
