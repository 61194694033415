import { END_SESSION } from '../actions/authActions';
import { UNSUBSCRIBE_CURRENT_ACCOUNT } from '../actions/currentAccountActions';
import {
  SET_CURRENT_IMPORT_ROWS,
  SET_IMPORT_ROW_DETAIL,
  SET_IMPORTS,
  UNSUBSCRIBE_CURRENT_IMPORT_ROWS
} from '../actions/importActions';

const DEFAULT_STATE = { imports: [], currentRows: [], rowDetails: {} };

export default function importsReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case SET_IMPORTS:
      return { ...state, imports: payload };
    case SET_CURRENT_IMPORT_ROWS:
      return { ...state, currentRows: payload.rows };
    case SET_IMPORT_ROW_DETAIL:
      return { ...state, rowDetails: { ...state.rowDetails, [payload.rowId]: payload.data } };
    case UNSUBSCRIBE_CURRENT_IMPORT_ROWS:
      return { ...state, currentRows: [], rowDetails: {} };
    default:
      return state;
  }
}

export const getImports = state => {
  return state.imports;
};

export const getCurrentImportRows = state => {
  return state.currentRows || [];
};

export const getImportRowDetails = state => {
  return state.rowDetails || {};
};
