import React from 'react';
import { SPACER } from '../../../theme';
import BlogPostAuthorBrettBurky from '../../BlogPostAuthorBrettBurky';
import BlogPostLayout from '../../BlogPostLayout';
import CloudinaryImage from '../../CloudinaryImage';
import BlogMediaBox from '../../BlogMediaBox';

export const metadata = {
  postTitle: "It's Here - Meet the Improved Paperstac",
  metaTitle: 'Buying and Selling Mortgage Notes Just Got a Lot Easier',
  metaDescription: "The process of buying and selling mortgage notes has been brought into modern times. Paperstac's relaunch is here to improve the transaction process.",
  slug: 'its-here-meet-the-improved-paperstac',
  publishDate: '2018-10-16 10:00',
  featureImageUrl: 'https://res.cloudinary.com/paperstac/image/upload/v1539622867/website-assets/its-here-meet-then-new-paperstac-feature.png',
  socialImageUrl: 'https://res.cloudinary.com/paperstac/image/upload/v1539622604/website-assets/its-here-meet-then-new-paperstac-social.png',
  tags: [],
};

const TitleComponent = () => (
  <h1 style={{ marginTop: 0, marginBottom: SPACER(2) }}>
    <CloudinaryImage
      url="https://res.cloudinary.com/paperstac/image/upload/v1539626542/its-here-meet-then-new-paperstac-hero_idhfy6.png"
      alt={metadata.postTitle}
    />
  </h1>
);

const ItsHereMeetTheImprovedPaperstac = () => (
  <BlogPostLayout metadata={metadata} AuthorComponent={BlogPostAuthorBrettBurky} TitleComponent={TitleComponent}>
    <p>
      <strong>Exciting News</strong> - the official version of Paperstac has launched! The new Paperstac platform is based on all of the great feedback we received during our year-long pilot program, plus a bunch of new features that we've been working on secretly with our private partners. This is just the beginning. We have an active roadmap and will be rolling out new features every month!
    </p>

    <h2>Introducing Pool Transactions</h2>

    <p>Paperstac now supports pool transactions <em>(deals involving more than a single asset)</em>. Better yet, we've made those pool transactions super flexible. At any time a buyer or seller can add or remove assets from a deal.
    </p>

    <p>Being able to purchase pools of notes is a great feature, but the efficiency and speed of our process is what makes it truly amazing. Each note in your pool multiplies the workload savings you would normally encounter when doing a similar deal offline. A transaction that would take weeks or months can now be accomplished in a couple days.</p>

    <h2>What Are To-Do's?</h2>
    <BlogMediaBox>
      <CloudinaryImage url="https://res.cloudinary.com/paperstac/image/upload/v1539633512/website-assets/its-here-meet-then-new-paperstac-todos.png"/>
    </BlogMediaBox>
    <p>We know that there are a lot of moving steps in note transactions. Critical steps can easily be missed and can ruin a deal. With our To-Do's system, we make sure nothing gets overlooked. You can't move from one step to the next without completing the task you have in front of you. This is a great benefit for investors new to the business of mortgage notes. We eliminate the guesswork and fear of purchasing and/or selling assets.</p>

    <h2>Introducing the Note Importer</h2>

    <p>The biggest lesson we learned during our pilot program was that sellers having to input all of the data-points for multiple mortgage notes was error-prone and not very fun. We also learned that keeping the data up-to-date on all of those notes was basically impossible. With the new Paperstac system we've handled all of this for the seller.</p>

    <p>After the seller completes a quick one-time onboarding process, we provide a solution where the seller's servicer(s) can import the seller's portfolio straight into the Paperstac system. This keeps all of the seller's notes up-to-date and accurate. When a seller is ready to list an asset, they can choose a note from their portfolio, give it a price and easily list it for sale.</p>

    <h2>Track It All From the Timeline</h2>

    <BlogMediaBox>
      <CloudinaryImage
        url="https://res.cloudinary.com/paperstac/image/upload/v1539634210/website-assets/its-here-meet-then-new-paperstac-timeline.png"
        width={600}
      />
    </BlogMediaBox>

    <p>Being able get an overview of your transaction was a stand-out feature in our pilot launch of Paperstac. However we've made it more streamlined and concise. From the timeline view, you see everything that is going on with your transaction. Every event has a timestamp so you know exactly when it happened. So whether you are sharing files securely, signing documents or tracking a collateral audit shipment - the timeline is the place where it all happens.</p>

    <h2>Just the Beginning</h2>

    <p>We've got a full roadmap of features that we'll be rolling out over the next few weeks and beyond. We're excited to hear your feedback and see what we can do to make Paperstac even better.</p>
  </BlogPostLayout>
);

export default ItsHereMeetTheImprovedPaperstac;