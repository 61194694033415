import { END_SESSION } from '../actions/authActions';
import { UNSUBSCRIBE_CURRENT_ACCOUNT } from '../actions/currentAccountActions';
import { SET_CURRENT_IMPORT_ROWS } from '../actions/importActions';
import { SET_NOTE_IMPORT, SET_NOTE_IMPORTS } from '../actions/noteImportActions';

const DEFAULT_STATE = { noteImports: {}, noteImportDetails: {} };

export default function importsReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case SET_NOTE_IMPORTS:
      return { ...state, noteImports: { ...state.noteImports, [payload.noteId]: payload.items } };
    case SET_CURRENT_IMPORT_ROWS:
      return { ...state, currentImports: payload.imports };
    case SET_NOTE_IMPORT:
      return {
        ...state,
        noteImportDetails: { ...state.noteImportDetails, [`${payload.noteId}/${payload.importId}`]: payload.data }
      };
    default:
      return state;
  }
}

export const getNoteImports = (state, noteId) => {
  return state.noteImports[noteId];
};

export const getNoteImportDetail = (state, noteId, importId) => {
  return state.noteImportDetails[`${noteId}/${importId}`];
};
