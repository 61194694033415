import React from 'react';
import DescriptionScreen from './DescriptionScreen';
import { ManageListingOverlayContext, SCREENS } from './ManageListingOverlayProvider';
import NoteScreen from './NoteScreen';
import OverviewScreen from './OverviewScreen';
import PriceScreen from './PriceScreen';
import SelectNotesScreen from './SelectNotesScreen';
import StatusScreen from './StatusScreen';

const ManageListingOverlay = React.memo(() => {
  const { screen } = React.useContext(ManageListingOverlayContext);

  if (screen === SCREENS.DESCRIPTION) return <DescriptionScreen />;
  if (screen === SCREENS.NOTE) return <NoteScreen />;
  if (screen === SCREENS.PRICE) return <PriceScreen />;
  if (screen === SCREENS.SELECT_NOTES) return <SelectNotesScreen />;
  if (screen === SCREENS.STATUS) return <StatusScreen />;

  return <OverviewScreen />;

});

ManageListingOverlay.propTypes = {};

ManageListingOverlay.defaultProps = {};

ManageListingOverlay.displayName = 'ManageListingOverlay';

export default ManageListingOverlay;
