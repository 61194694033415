import { firestore } from '../services/firebaseClient';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const SET_NOTES = 'SET_NOTES';
export const SET_NOTES_SORT = 'SET_NOTES_SORT';
export const UNSUBSCRIBE_NOTES = 'UNSUBSCRIBE_NOTES';

const unsubscribes = {};

const transformCollection = querySnapshot => {
  return querySnapshot.docs.map(transformDoc).filter(note => note.streetAddress);
};

const transformDoc = docSnap => {
  return docSnap.exists ? { sellerProvidedCustomData: [], ...transformFirestoreTimestamps(docSnap) } : null;
};

export const setNotes = payload => {
  return { type: SET_NOTES, payload };
};

export const setNotesSort = (sortBy, sortDirection) => {
  return { type: SET_NOTES_SORT, payload: { sortBy, sortDirection } };
};

export const subscribeAccountNotes = (accountId, sortBy = 'id', sortDirection = 'desc') => {
  return dispatch => {
    if (unsubscribes.notes) {
      unsubscribes.notes();
    }
    unsubscribes.notes = firestore
      .collection(`accounts/${accountId}/notes`)
      .orderBy(sortBy, sortDirection)
      .onSnapshot(querySnapshot => {
        dispatch(setNotes(transformCollection(querySnapshot)));
      });
  };
};

export const unsubscribeAccountNotes = () => {
  return dispatch => {
    if (unsubscribes.notes) {
      unsubscribes.notes();
      dispatch({ type: UNSUBSCRIBE_NOTES });
      unsubscribes.notes = null;
    }
  };
};

export const updateNoteRequest = (accountId, noteId, payload) => {
  return dispatch => {
    return firestore
      .doc(`accounts/${accountId}/notes/${noteId}`)
      .update(payload)
      .catch(console.error);
  };
};

export const createNoteRequest = (accountId, noteId, payload) => {
  return dispatch => {
    return firestore
      .doc(`accounts/${accountId}/notes/${noteId}`)
      .set(payload)
      .catch(console.error);
  };
};

export const deleteNoteRequest = payload => {
  return dispatch => {
    return firestore.doc(`accounts/${payload.accountId}/notes/${payload.id}`).delete();
  };
};
