import AlgoliaRadioRefinement from '@paperstac/ui/lib/AlgoliaRadioRefinement';
import React from 'react';
import FilterContainer from './FilterContainer';

const PerformanceFilter = React.memo(props => <FilterContainer title="Performance">
  <AlgoliaRadioRefinement
    attribute="noteMetaAgg.performance"
    itemsOverride={[
      { label: 'Performing', value: 'Performing' },
      { label: 'Non-Performing', value: 'Non-Performing' },
    ]}
  />
</FilterContainer>);

PerformanceFilter.propTypes = {};

PerformanceFilter.defaultProps = {};

PerformanceFilter.displayName = 'PerformanceFilter';

export default PerformanceFilter;