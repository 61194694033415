import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import useFirestoreSubscribe from '@paperstac/common/lib/hooks/useFirestoreSubscribe';
import { DELETE_NOTE_ADJUSTMENT, UPDATE_NOTE } from '@paperstac/common/lib/serverDispatchActionTypes';
import { normalizeDate } from '@paperstac/helpers/lib/dateHelpers';
import dateid from '@paperstac/common/lib/services/dateid';
import { centsToUsdString } from '@paperstac/helpers/lib/numberHelpers';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import storage from '@paperstac/firebase/lib/storage';
import { noteAdjustmentsByAccountAndNoteIdRef } from '@paperstac/firestore-collections/lib/noteAdjustments';
import Box from '@paperstac/ui/lib/Box';
import DescriptionGridCell from '@paperstac/ui/lib/DescriptionGridCell';
import DescriptionGridTerm from '@paperstac/ui/lib/DescriptionGridTerm';
import Flex from '@paperstac/ui/lib/Flex';
import ExternalLinkIcon from '@paperstac/ui/lib/icons/ExternalLinkIcon';
import Link from '@paperstac/ui/lib/Link';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import RelativeDate from '@paperstac/ui/lib/RelativeDate';
import Spinner from '@paperstac/ui/lib/Spinner';
import Text from '@paperstac/ui/lib/Text';
import format from "date-fns/format";
import React from 'react';
import getFileExtension from '../../../utils/getFileExtension';
import EditButton from './EditButton';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';
import { NoteScreenContext } from './NoteScreen';

const NoteScreenViewPaymentHistory = React.memo(props => {
  const { uid } = React.useContext(IdentityContext);
  const { currentAccountId } = React.useContext(AccountsContext);
  const { note, stacked } = React.useContext(ManageListingOverlayContext);
  const [adjustments] = useFirestoreSubscribe(noteAdjustmentsByAccountAndNoteIdRef(currentAccountId, note.id).orderBy('transactionDate', 'desc'), [currentAccountId, note.id]);
  const { showAddPaymentHistory, showEditAdjustment } = React.useContext(NoteScreenContext);
  const [isUploading, setUploading] = React.useState(false);
  const [isDeleting, setDeleting] = React.useState(false);

  const handleAddFiles = React.useCallback(async files => {
    try {
      if (isUploading) return;
      setUploading(true);
      const [file] = files;
      const fileExtension = getFileExtension(file.name);
      const userStoragePath = `user-uploads/${uid}/${currentAccountId}`;
      const filePath = `${userStoragePath}/notes/${note.id}/payment-history-${dateid()}.${fileExtension}`;
      serverDispatch({
        action: UPDATE_NOTE,
        payload: {
          accountId: note.accountId,
          id: note.id,
          paymentHistoryFilePath: await storage.child(filePath)
            .put(file, { cacheControl: 'public,max-age=31536000' })
            .then(() => filePath)
        }
      }).then(() => setUploading(false))
    } catch (error) {
      setUploading(false);
      alert(error.message);
    }
  }, [currentAccountId, isUploading, note.accountId, note.id, uid]);

  const deleteAdjustment = React.useCallback(async adjustmentId => {
    serverDispatch({
      action: DELETE_NOTE_ADJUSTMENT,
      payload: { id: adjustmentId }
    }).catch(err => alert(err.message));
  }, []);

  const handleDeleteFile = React.useCallback(async () => {
    if (isDeleting) return;
    setDeleting(true);
    serverDispatch({
      action: UPDATE_NOTE,
      payload: {
        accountId: note.accountId,
        id: note.id,
        paymentHistoryFilePath: null
      }
    })
      .catch(err => {
        alert(err.message);
        setDeleting(false);
      });
  }, [isDeleting, note.accountId, note.id]);

  React.useEffect(() => {
    if (!!note.paymentHistoryFileUrl) setDeleting(false);
  }, [note.paymentHistoryFileUrl]);

  return <DescriptionGridCell>
    <DescriptionGridTerm mb={3}>
      Payment History (<LinkButton onClick={showAddPaymentHistory} sx={{ textTransform: 'none' }}>
      <Text variant="bold">+</Text> Add Payment History
    </LinkButton>)
    </DescriptionGridTerm>
    {!!note.paymentHistoryFileUrl && !isDeleting && <Flex alignItems="center" mt={3}>
      <Box flex={'1 1 0'} mr={stacked ? 3 : 5}>
        <Box mb={1}>
          <Link href={note.paymentHistoryFileUrl} target="_blank">
            Payment History File <ExternalLinkIcon />
          </Link>
        </Box>
        <Box mb={1}>
          <Text variant="italic" fontSize={1}>Added <RelativeDate date={note.paymentHistoryFileDate} /></Text>
        </Box>
        <LinkButton fontSize={1} color="red" onClick={handleDeleteFile}>Delete File</LinkButton>
      </Box>
      <Box>
        {isUploading && <EditButton>
          <Box sx={{ position: 'relative', top: '2px' }}><Spinner size="tiny" /></Box>
        </EditButton>}
        {!isUploading && <EditButton fileInput={true} onFiles={handleAddFiles} />}
      </Box>
    </Flex>}
    {adjustments && adjustments.map(adjustment => <Flex key={adjustment.id} alignItems="center" mt={3}>
      <Box flex={'1 1 0'} mr={stacked ? 3 : 5}>
        <Flex justifyContent="space-between" fontSize={stacked ? 1 : 2}>
          <Box>Adjustment Description:</Box>
          <Box fontWeight="bold">{adjustment.description}</Box>
        </Flex>
        <Flex justifyContent="space-between" mt={1} fontSize={stacked ? 1 : 2}>
          <Box>Transaction Date:</Box>
          <Box fontWeight="bold">{format(normalizeDate(adjustment.transactionDate), 'MMM D, YYYY')}</Box>
        </Flex>
        <Flex justifyContent="space-between" mt={1} fontSize={stacked ? 1 : 2}>
          <Box>Amount:</Box>
          <Box fontWeight="bold">{centsToUsdString(adjustment.transactionAmount)}</Box>
        </Flex>
        <LinkButton onClick={() => deleteAdjustment(adjustment.id)} color="red" fontSize={1}>Delete Adjustment</LinkButton>
      </Box>
      <Box>
        <EditButton onClick={() => showEditAdjustment(adjustment)} />
      </Box>
    </Flex>)}
  </DescriptionGridCell>;
});

NoteScreenViewPaymentHistory.propTypes = {};

NoteScreenViewPaymentHistory.defaultProps = {};

NoteScreenViewPaymentHistory.displayName = 'NoteScreenViewPaymentHistory';

export default NoteScreenViewPaymentHistory;
