import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import Heading from '@paperstac/ui/lib/Heading';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';
import ViewContainer from './ViewContainer';
import ViewTitle from './ViewTitle';

const IntroView = React.memo(({ onContinue, onCancel }) => <ViewContainer>
  <ViewTitle>Merge Paperstac Identities</ViewTitle>

  <Box mb={5}>This wizard will allow you to merge (2) Paperstac Identities <Text sx={{
    display: 'inline',
    fontStyle: 'italic'
  }}>(logins)</Text> into one.</Box>

  <Heading as="h2" fontSize={2} mb={2}>What You'll Need</Heading>

  <Box mb={5}>You will need the login <Text sx={{
    display: 'inline',
    fontStyle: 'italic'
  }}>(email/password)</Text> for <Text sx={{
    display: 'inline',
    fontWeight: 'bold'
  }}>both</Text> Paperstac Identities.</Box>

  <Box mb={3}><Button variant="primary" block onClick={onContinue}>Continue</Button></Box>
  <Box><Button onClick={onCancel} variant="default" block>Cancel</Button></Box>
</ViewContainer>);

IntroView.propTypes = {};

IntroView.defaultProps = {};

IntroView.displayName = 'IntroView';

export default IntroView;
