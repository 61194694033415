import { TRANSACTION_EVENT_TYPE } from '@paperstac/firestore-collections/lib/transactionEvents';
import getPath from '@paperstac/routes/lib/getPath';
import { MY_FEED_ITEM } from '@paperstac/routes/lib/webRoutes';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import Drawer from '@paperstac/ui/lib/Drawer';
import DrawerHeader from '@paperstac/ui/lib/DrawerHeader';
import Heading from '@paperstac/ui/lib/Heading';
import Link from '@paperstac/ui/lib/Link';
import Overlay from '@paperstac/ui/lib/Overlay';
import Text from '@paperstac/ui/lib/Text';
import get from 'lodash/get';
import React from 'react';
import { object, string } from 'yup';
import NegotiateNotePriceMultiView from '../../common/MakeOfferOverlay/NegotiateNotePriceMultiView';
import NegotiateNotePriceSingleView from '../../common/MakeOfferOverlay/NegotiateNotePriceSingleView';
import { ListingPageContext } from './ListingPageProvider';
import useFirestoreSubscribe from '@paperstac/hooks/lib/useFirestoreSubscribe';
import { settingsRef } from '@paperstac/firestore-collections/lib/settings';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import VerifyIdentityOverlay from '../../common/VerifyIdentityOverlay/VerifyIdentityOverlay';

export const validator = () =>
  object().shape({
    message: string().required(),
  });

const MakeOfferOverlay = React.memo(() => {
  const { identity } = React.useContext(IdentityContext);
  const { createTransaction, hideOffer, listing, transactionId } = React.useContext(ListingPageContext);
  const [settings] = useFirestoreSubscribe(settingsRef());
  const [updatedNotePrices, setUpdatedNotePrices] = React.useState(listing.notePrices);
  const [noteIdToEdit, setNoteIdToEdit] = React.useState(null);
  const sellerName = get(listing, 'seller.displayName', 'Paperstac Seller');
  const feedItemUrl = React.useMemo(() => (transactionId ? getPath(MY_FEED_ITEM, transactionId) : ''), [transactionId]);
  const isPool = React.useMemo(() => listing.notes.length > 1, [listing.notes]);
  const noteToEdit = React.useMemo(
    () => listing.notes.find(({ id }) => id === noteIdToEdit),
    [listing.notes, noteIdToEdit]
  );

  const beginEdit = React.useCallback((noteId) => setNoteIdToEdit(noteId), []);
  const stopEdit = React.useCallback(() => setNoteIdToEdit(null), []);

  const handleNotePriceChange = React.useCallback(
    (change) => {
      setUpdatedNotePrices({ ...updatedNotePrices, ...change });
      stopEdit();
      return Promise.resolve();
    },
    [stopEdit, updatedNotePrices]
  );

  const handleSubmit = React.useCallback(
    (notePrices) =>
      createTransaction({
        type: TRANSACTION_EVENT_TYPE.MAKE_OFFER,
        notePrices,
      }),
    [createTransaction]
  );

  if (!settings) return null;

  if (settings.requireVerificationToCreateTransaction && !identity.isVerified)
    return <VerifyIdentityOverlay onClose={hideOffer} />;

  return (
    <>
      <Overlay onClose={hideOffer}>
        <Drawer width={600}>
          <DrawerHeader text="Make an Offer" onClose={hideOffer} />
          <Box sx={{ p: 4, overflowY: 'auto', flex: '1 1 auto' }}>
            {!feedItemUrl && isPool && !noteToEdit && (
              <NegotiateNotePriceMultiView
                notePrices={listing.notePrices}
                notes={listing.notes}
                onCancel={hideOffer}
                onEdit={beginEdit}
                onSubmit={handleSubmit}
                updatedNotePrices={updatedNotePrices}
              />
            )}

            {!feedItemUrl && isPool && !!noteToEdit && (
              <NegotiateNotePriceSingleView
                differentPriceActionText="Adjust Price"
                notePrice={listing.notePrices[noteIdToEdit] || ''}
                note={noteToEdit}
                onCancel={stopEdit}
                onSubmit={handleNotePriceChange}
                samePriceActionText="Keep Current Price"
                updatedNotePrice={updatedNotePrices[noteIdToEdit] || ''}
              />
            )}

            {!feedItemUrl && !isPool && (
              <NegotiateNotePriceSingleView
                notePrice={listing.notePrices[listing.notes[0].id] || ''}
                note={listing.notes[0]}
                onCancel={hideOffer}
                onSubmit={handleSubmit}
                updatedNotePrice={listing.notePrices[listing.notes[0].id] || ''}
              />
            )}

            {!!feedItemUrl && (
              <Box>
                <Heading mb={4}>Offer Made Successfully</Heading>
                <Text mb={4} fontSize={1}>
                  Paperstac has successfully delivered your offer. We have also created a{' '}
                  <Link to={feedItemUrl}>transaction</Link> between you and <Text variant="bold">{sellerName}</Text>. In
                  that transaction, you can discuss this listing, further negotiate pricing and even make a purchase
                  with our online note closings.
                </Text>
                <Button as={Link} to={feedItemUrl} variant="primary" mr={2}>
                  View Transaction
                </Button>
                <Button variant="default" onClick={hideOffer}>
                  Close
                </Button>
              </Box>
            )}
          </Box>
        </Drawer>
      </Overlay>
    </>
  );
});

MakeOfferOverlay.propTypes = {};

MakeOfferOverlay.defaultProps = {};

MakeOfferOverlay.displayName = 'MakeOfferOverlay';

export default MakeOfferOverlay;
