var PERSISTENCE_KEY = 'PaperstacPersistenceType';
export var PERSISTENCE_TYPES = {
  SESSION: 'SESSION',
  LOCAL: 'LOCAL'
};
export var getStateStorageAdapter = function getStateStorageAdapter() {
  try {
    return getPersistenceType() === PERSISTENCE_TYPES.LOCAL ? localStorage : sessionStorage;
  } catch (e) {
    return sessionStorage;
  }
};
export var setPersistenceType = function setPersistenceType() {
  var storageType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : PERSISTENCE_TYPES.SESSION;

  try {
    localStorage.setItem(PERSISTENCE_KEY, storageType);
  } catch (e) {// Do nothing
  }
};
export var getPersistenceType = function getPersistenceType() {
  try {
    return localStorage.getItem(PERSISTENCE_KEY) || PERSISTENCE_TYPES.SESSION;
  } catch (e) {
    return PERSISTENCE_TYPES.SESSION;
  }
};