import { logout } from '../services/auth';
import { addToast } from './toasterActions';

export const END_SESSION = 'END_SESSION';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const START_SESSION = 'START_SESSION';

export const logoutRequest = () => {
  return dispatch => {
    dispatch({ type: LOGOUT_REQUEST });
    try {
      return logout()
        .then(() => {
          dispatch({ type: LOGOUT_SUCCESS });
        });
    } catch (error) {
      dispatch(addToast(error.message, 30000, 'error'));
    }
  };
};
