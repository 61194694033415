import React from 'react';
import BaseFilterContainer from './BaseFilterContainer';
import FilterTitle from './FilterTitle';

const FilterContainer = React.memo(({ children, title, ...props }) => <BaseFilterContainer {...props}>
  <FilterTitle>{title}</FilterTitle>
  {children}
</BaseFilterContainer>);

FilterContainer.propTypes = {};

FilterContainer.defaultProps = {};

FilterContainer.displayName = 'FilterContainer';

export default FilterContainer;