import { firestore } from '../services/firebaseClient';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const SET_TRANSACTION_DOCUMENTS = 'SET_TRANSACTION_DOCUMENTS';

const unsubscribes = {};

export const setTransactionDocuments = payload => {
  return { type: SET_TRANSACTION_DOCUMENTS, payload };
};

export const subscribeTransactionDocuments = transactionId => {
  return dispatch => {
    if (!unsubscribes[transactionId]) {
      unsubscribes[transactionId] = firestore
        .collection(`transactions/${transactionId}/documents`)
        .onSnapshot(querySnapshot => {
          dispatch(
            setTransactionDocuments({
              transactionId,
              documents: querySnapshot.docs.map(transformFirestoreTimestamps)
            })
          );
        });
    }
  };
};

export const unsubscribeTransactionDocuments = transactionId => {
  return dispatch => {
    if (unsubscribes[transactionId]) {
      unsubscribes[transactionId]();
      delete unsubscribes[transactionId];
    }
  };
};

export const createTransactionDocumentSignature = payload => {
  return dispatch => {
    return firestore
      .doc(`transactions/${payload.transactionId}/documents/${payload.documentId}/signatures/${payload.id}`)
      .set(payload);
  };
};