import { sortCollection } from '@paperstac/helpers/lib/arrayHelpers';
import AlgoliaChecklistRefinement from '@paperstac/ui/lib/AlgoliaChecklistRefinement';
import AlgoliaHasListRefinement from '@paperstac/ui/lib/AlgoliaHasListRefinement';
import React from 'react';
import CollapsibleFilterContainer from './CollapsibleFilterContainer';

const LegalStatusFilter = React.memo(props => <AlgoliaHasListRefinement attribute="noteMetaAgg.legalStatus">
  <CollapsibleFilterContainer title="Legal Status">
    <AlgoliaChecklistRefinement
      attribute="noteMetaAgg.legalStatus"
      transformItems={(items) => sortCollection(items, 'label')}
    />
  </CollapsibleFilterContainer>
</AlgoliaHasListRefinement>);

LegalStatusFilter.propTypes = {};

LegalStatusFilter.defaultProps = {};

LegalStatusFilter.displayName = 'LegalStatusFilter';

export default LegalStatusFilter;
