import { noteLabel, noteValueFormatted } from '@paperstac/common/lib/services/notesHelper';
import { firestore } from '../services/firebaseClient';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const SET_IMPORTS = 'SET_IMPORTS';
export const SUBSCRIBE_IMPORTS = 'SUBSCRIBE_IMPORTS';
export const UNSUBSCRIBE_IMPORTS = 'UNSUBSCRIBE_IMPORTS';
export const FETCH_IMPORT_ROW_REQUEST = 'FETCH_IMPORT_ROW_REQUEST';
export const FETCH_IMPORT_ROW_SUCCESS = 'FETCH_IMPORT_ROW_SUCCESS';
export const FETCH_IMPORT_ROW_FAILURE = 'FETCH_IMPORT_ROW_FAILURE';
export const SET_IMPORT_ROW_DETAIL = 'SET_IMPORT_ROW_DETAIL';
export const SET_CURRENT_IMPORT_ROWS = 'SET_CURRENT_IMPORT_ROWS';
export const SUBSCRIBE_CURRENT_IMPORT_ROWS = 'SUBSCRIBE_CURRENT_IMPORT_ROWS';
export const UNSUBSCRIBE_CURRENT_IMPORT_ROWS = 'UNSUBSCRIBE_CURRENT_IMPORT_ROWS';

const unsubscribes = {};

const transformImports = querySnapshot => {
  return querySnapshot.docs.map(transformFirestoreTimestamps);
};

const transformImportRows = querySnapshot => {
  return querySnapshot.docs.map(docSnap => {
    const row = transformFirestoreTimestamps(docSnap);
    delete row.rawTape;
    delete row.diff;
    return row;
  });
};

const transformImportRow = doc => {
  const row = { ...doc.data() };
  const noteChanges = [];
  row.diff.forEach(diff => {
    switch (diff.kind) {
      case 'N':
        noteChanges.push({
          label: noteLabel(diff.path[0]),
          addition: noteValueFormatted(diff.path[0], diff.rhs),
          deletion: null
        });
        break;
      case 'E':
        noteChanges.push({
          label: noteLabel(diff.path[0]),
          addition: noteValueFormatted(diff.path[0], diff.rhs),
          deletion: noteValueFormatted(diff.path[0], diff.lhs)
        });
        break;
      case 'D':
        noteChanges.push({
          label: noteLabel(diff.path[0]),
          addition: null,
          deletion: noteValueFormatted(diff.path[0], diff.lhs)
        });
        break;
      default:
      // Do nothing
    }
  });
  delete row.diff;
  return { ...row, noteChanges };
};

export const setImports = payload => {
  return { type: SET_IMPORTS, payload };
};

export const setCurrentImportRows = (importId, rows) => {
  return { type: SET_CURRENT_IMPORT_ROWS, payload: { importId, rows } };
};

export const setImportRow = (rowId, data) => {
  return { type: SET_IMPORT_ROW_DETAIL, payload: { rowId, data } };
};

export const subscribeImports = accountId => {
  return dispatch => {
    if (!unsubscribes.imports) {
      dispatch({ type: SUBSCRIBE_IMPORTS, accountId });
      unsubscribes.imports = firestore
        .collection('imports')
        .where('accountId', '==', accountId)
        .orderBy('id', 'desc')
        .onSnapshot(querySnapshot => {
          dispatch(setImports(transformImports(querySnapshot)));
        });
    }
  };
};

export const unsubscribeImports = () => {
  return dispatch => {
    if (unsubscribes.imports) {
      unsubscribes.imports();
      unsubscribes.imports = null;
      dispatch({ type: UNSUBSCRIBE_IMPORTS });
    }
  };
};

export const fetchImportRowRequest = (importId, rowId) => {
  return dispatch => {
    dispatch({ type: FETCH_IMPORT_ROW_REQUEST });
    return firestore
      .doc(`imports/${importId}/rows/${rowId}`)
      .get()
      .then(docRef => {
        dispatch({ type: FETCH_IMPORT_ROW_SUCCESS });
        dispatch(setImportRow(rowId, transformImportRow(docRef)));
      })
      .catch(error => {
        dispatch({ type: FETCH_IMPORT_ROW_FAILURE, message: error.message });
      });
  };
};

export const subscribeCurrentImportRows = (accountId, importId) => {
  return dispatch => {
    if (!unsubscribes[`import${importId}`]) {
      dispatch({ type: SUBSCRIBE_CURRENT_IMPORT_ROWS, importId });
      unsubscribes[`import${importId}`] = firestore
        .collection(`imports/${importId}/rows`)
        .where('accountId', '==', accountId)
        .orderBy('rowNumber')
        .onSnapshot(querySnapshot => {
          dispatch(setCurrentImportRows(importId, transformImportRows(querySnapshot)));
        });
    }
  };
};

export const unsubscribeCurrentImportRows = importId => {
  return dispatch => {
    if (unsubscribes[`import${importId}`]) {
      unsubscribes[`import${importId}`]();
      unsubscribes[`import${importId}`] = null;
      dispatch({ type: UNSUBSCRIBE_CURRENT_IMPORT_ROWS });
    }
  };
};
