import { LISTING_DURATION_DAYS } from '@paperstac/constants';
import firestore from '@paperstac/firebase/lib/firestore';
import * as Yup from 'yup';
export var listingsRef = function listingsRef() {
  return firestore.collection('listings');
};
export var listingByIdRef = function listingByIdRef(listingId) {
  return listingId ? listingsRef().doc(listingId) : null;
};
export var listingsByIdRef = function listingsByIdRef(listingIds) {
  return listingsRef().where('id', 'in', listingIds);
};
export var listingsByAccountIdRef = function listingsByAccountIdRef(accountId) {
  return listingsRef().where('accountId', '==', accountId);
};
export var activeListingsRef = function activeListingsRef() {
  return listingsRef().where('isActive', '==', true);
};
export var activeListingsByAccountIdRef = function activeListingsByAccountIdRef(accountId) {
  return activeListingsRef().where('accountId', '==', accountId);
};
export var listingsByPopularityRef = function listingsByPopularityRef() {
  return activeListingsRef().orderBy('popularity', 'desc');
};
export var listingsByPendingNoteRef = function listingsByPendingNoteRef(pendingNote) {
  return listingsRef().where('pendingNotes', 'array-contains', pendingNote);
};
export var listingsByNoteIdRef = function listingsByNoteIdRef(noteId) {
  return listingsRef().where('noteIds', 'array-contains', noteId);
};
export var expiredListingsQuery = function expiredListingsQuery() {
  var expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() - LISTING_DURATION_DAYS);
  return listingsRef().where('listedDate', '<', expirationDate);
};
export var LISTING_NEGOTIATION_TYPE = {
  BEST_OFFER: 'BEST_OFFER',
  FIRM: 'FIRM',
  MAKE_OFFER: 'MAKE_OFFER'
};
export var LISTING_NEGOTIATION_TYPE_LABEL = {
  BEST_OFFER: 'Flexible',
  FIRM: 'Firm',
  MAKE_OFFER: 'Make an Offer'
};
export var createListingValidator = function createListingValidator() {
  return Yup.object().shape({
    accountId: Yup.string().required(),
    isPool: Yup.boolean().required(),
    notes: Yup.array().when('isPool', {
      is: true,
      then: Yup.array().min(2, 'Must have at least 2 notes in the pool').of(Yup.object().shape({
        id: Yup.string().required()
      })),
      otherwise: Yup.array().required().max(1).of(Yup.object().shape({
        city: Yup.string().required(),
        country: Yup.string().required(),
        lienPosition: Yup.string().required(),
        state: Yup.string().required(),
        streetAddress1: Yup.string().required(),
        streetAddress2: Yup.string(),
        zip: Yup.string().required()
      }))
    })
  });
};
export var deleteListingValidator = function deleteListingValidator() {
  return Yup.object().shape({
    listingId: Yup.string().required()
  });
};