import { FOR_SALE_ARCHIVE } from '@paperstac/routes/lib/webRoutes';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import Text from '@paperstac/ui/lib/Text';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Section from './Section';

const FetchError = React.memo(props => <Section>
  <Box>
    <Text fontSize={3} fontWeight="bold" mb={5}>{props.message}</Text>
    <Button as={Link} to={FOR_SALE_ARCHIVE} variant="primary">View Active Listings</Button>
  </Box>
</Section>);

FetchError.propTypes = {};

FetchError.defaultProps = {
  message: PropTypes.string.isRequired
};

FetchError.displayName = 'FetchError';

export default FetchError;
