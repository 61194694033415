import Flex from '@paperstac/ui/lib/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import Stat from './Stat';

const StatGrid = React.memo(({ currency, stat1, stat2, stat3, stat4 }) => {
  return <Flex flexDirection={['column', 'column', 'column', 'row']}>
    <Flex justifyContent="space-around" mb={[0, 64, 64, 0]} flex="1" flexWrap="wrap">
      <Stat currency={currency} {...stat1} />
      <Stat currency={currency} {...stat2} />
    </Flex>
    <Flex justifyContent="space-around" flex="1" flexWrap="wrap">
      <Stat currency={currency} {...stat3} />
      <Stat currency={currency} {...stat4} />
    </Flex>
  </Flex>
});

StatGrid.propTypes = {
  stat1: PropTypes.object.isRequired,
  stat2: PropTypes.object.isRequired,
  stat3: PropTypes.object.isRequired,
  stat4: PropTypes.object.isRequired,
};

StatGrid.defaultProps = {};

StatGrid.displayName = 'StatGrid';

export default StatGrid;
