import SortCollectionProvider from '@paperstac/common/lib/components/SortCollectionProvider';
import { normalizeDate } from '@paperstac/helpers/lib/dateHelpers';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

const PaymentHistoryContext = React.createContext();
const { Provider } = PaymentHistoryContext;

const PaymentHistoryProvider = React.memo(({ adjustments, ...props }) => {
  const results = React.useMemo(() => adjustments ? transformResults(adjustments) : [], [adjustments]);

  const providerValue = React.useMemo(() => ({
    resultCount: results.length || 0,
  }), [results]);

  return <SortCollectionProvider collection={results} sortBy="transactionDate" sortDirection="DESC" sortLabel="Recent">
    <Provider value={providerValue} {...props} />
  </SortCollectionProvider>
});

const transformResults = (results) => {
  return results.map(adjustment => ({
    transactionDate: adjustment.transactionDate ? normalizeDate(adjustment.transactionDate) : '',
    description: get(adjustment, 'description', ''),
    transactionAmount: get(adjustment, 'transactionAmount', ''),
    dueDate: adjustment.dueDate ? normalizeDate(adjustment.dueDate) : '',
    principalAmount: get(adjustment, 'principalAmount', ''),
    principalBalance: get(adjustment, 'principalBalance', ''),
    arrearageForbearancePrincipal: get(adjustment, 'arrearageForbearancePrincipal', ''),
    interestAmount: get(adjustment, 'interestAmount', ''),
    unappliedAmount: get(adjustment, 'unappliedAmount', ''),
    escrowAmount: get(adjustment, 'escrowAmount', ''),
    escrowBalance: get(adjustment, 'escrowBalance', ''),
    lateChargeAmount: get(adjustment, 'lateChargeAmount', ''),
    lateChargeBalance: get(adjustment, 'lateChargeBalance', ''),
  }));
};

PaymentHistoryProvider.propTypes = {
  adjustments: PropTypes.any.isRequired,
};

PaymentHistoryProvider.defaultProps = {};

PaymentHistoryProvider.displayName = 'PaymentHistoryProvider';

export default PaymentHistoryProvider;

export { PaymentHistoryContext };
