import { differenceInMonths } from "date-fns";
export var purchasePriceToYield = function purchasePriceToYield(purchasePrice, paymentsRemaining, principalAndInterestPayment, upb) {
  var interestOnly = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  var future = interestOnly ? upb * -1 : 0;
  var monthlyRate = ExcelRate(paymentsRemaining, principalAndInterestPayment * -1, purchasePrice, future, 0);
  var yieldPercent = +(monthlyRate * 12 * 100).toFixed(2);

  if (!interestOnly && yieldPercent < 0) {
    return purchasePriceToYield(purchasePrice, paymentsRemaining, principalAndInterestPayment, upb, true);
  }

  return yieldPercent < 5 || yieldPercent > 30 ? null : yieldPercent;
};
export var purchasePriceToItv = function purchasePriceToItv(purchasePrice, propertyValue) {
  return Math.round(purchasePrice / propertyValue * 100);
};
export var purchasePriceToItb = function purchasePriceToItb(purchasePrice, upb) {
  return Math.round(purchasePrice / upb * 100);
};
export var yieldToPurchasePrice = function yieldToPurchasePrice(yieldPercent, paymentsRemaining, principalAndInterestPayment) {
  var monthlyRate = yieldPercent / 12 / 100;
  var usd = ExcelPv(monthlyRate, paymentsRemaining, principalAndInterestPayment * -1, 0, 0);
  return Math.round(usd * 100);
};
export var yieldToItb = function yieldToItb(yieldPercent, paymentsRemaining, principalAndInterestPayment, upb) {
  return purchasePriceToItb(yieldToPurchasePrice(yieldPercent, paymentsRemaining, principalAndInterestPayment), upb);
};
export var yieldToItv = function yieldToItv(yieldPercent, paymentsRemaining, principalAndInterestPayment, propertyValue) {
  return purchasePriceToItv(yieldToPurchasePrice(yieldPercent, paymentsRemaining, principalAndInterestPayment), propertyValue);
};
export var itbToPurchasePrice = function itbToPurchasePrice(itbPercent, upb) {
  var itb = itbPercent / 100;
  return Math.round(itb * upb);
};
export var itbToYield = function itbToYield(itbPercent, upb, paymentsRemaining, principalAndInterestPayment) {
  return purchasePriceToYield(itbToPurchasePrice(itbPercent, upb), paymentsRemaining, principalAndInterestPayment, upb);
};
export var itbToItv = function itbToItv(itbPercent, upb, propertyValue) {
  return purchasePriceToItv(itbToPurchasePrice(itbPercent, upb), propertyValue);
};
export var itvToPurchasePrice = function itvToPurchasePrice(itvPercent, propertyValue) {
  var itv = itvPercent / 100;
  return Math.round(itv * propertyValue);
};
export var itvToYield = function itvToYield(itvPercent, propertyValue, paymentsRemaining, principalAndInterestPayment, upb) {
  return purchasePriceToYield(itvToPurchasePrice(itvPercent, propertyValue), paymentsRemaining, principalAndInterestPayment, upb);
};
export var itvToItb = function itvToItb(itvPercent, propertyValue, upb) {
  return purchasePriceToItb(itvToPurchasePrice(itvPercent, propertyValue), upb);
};
export var loanToValuePercent = function loanToValuePercent(upb, propertyValue) {
  return upb && propertyValue ? Math.round(upb / propertyValue * 100) : null;
};
export var combinedLoanToValuePercent = function combinedLoanToValuePercent(upb, seniorUpb, propertyValue) {
  return upb && seniorUpb && propertyValue ? Math.round((upb + seniorUpb) / propertyValue * 100) : null;
};
export var investmentToValuePercent = function investmentToValuePercent(investment, propertyValue) {
  var decimalSpaces = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  return investment && propertyValue ? round(investment / propertyValue * 100, decimalSpaces) : null;
};
export var investmentToBalancePercent = function investmentToBalancePercent(investment, upb) {
  var decimalSpaces = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  return investment && upb ? round(investment / upb * 100, decimalSpaces) : null;
};
export var round = function round(value) {
  var decimalSpaces = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var multiplier = Math.pow(10, decimalSpaces);
  return Math.round(value * multiplier) / multiplier;
};
export var paymentsRemainingByMaturity = function paymentsRemainingByMaturity(nextPaymentDate, maturityDate) {
  nextPaymentDate = nextPaymentDate.toDate ? nextPaymentDate.toDate() : nextPaymentDate;
  maturityDate = maturityDate.toDate ? maturityDate.toDate() : maturityDate;
  var diff = differenceInMonths(maturityDate, nextPaymentDate);
  return diff < 0 ? 0 : diff;
};
/**
 * This function returns the number of periods for an investment or a loan based on periodic constant payments and a constant interest rate.
 *
 * @param {number} annualInterestRatePercent Annual interest rate
 * @param {number} principalAndInterestPayment Periodical amount to be paid
 * @param {number} upb Present value of the investment
 * @param {number} paymentsPerYear Per period. (Example: If a loan is to be repaid in monthly instalments, this will be 12. For quarterly repayments, this parameter will be 4, 6 for half-yearly and 1 for yearly repayments)
 * @param {number} balanceUponMaturity Future Value or a cash balance that you want to attain after the last payment is made. This is an optional parameter. If omitted, 0 is assumed.
 * @param {number} decimalSpaces Number of decimal spaces to round result to
 * @returns {number}
 */

export var paymentsRemainingByBalance = function paymentsRemainingByBalance(annualInterestRatePercent, principalAndInterestPayment, upb) {
  var paymentsPerYear = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 12;
  var balanceUponMaturity = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
  var decimalSpaces = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 2;
  if (!paymentsPerYear || !principalAndInterestPayment || !upb) return 0;
  var interestRate = annualInterestRatePercent / (paymentsPerYear * 100);
  var paymentsRemaining = interestRate === 0 ? -(balanceUponMaturity - upb) / principalAndInterestPayment : Math.log((-balanceUponMaturity * interestRate + principalAndInterestPayment) / (principalAndInterestPayment + interestRate * upb * -1)) / Math.log(1 + interestRate);
  return round(paymentsRemaining, decimalSpaces);
}; // https://gist.github.com/kucukharf/677d8f21660efaa33f72

var ExcelRate = function ExcelRate(periods, payment, present, future, type, guess) {
  guess = guess === undefined ? 0.01 : guess;
  future = future === undefined ? 0 : future;
  type = type === undefined ? 0 : type; // Set maximum epsilon for end of iteration

  var epsMax = 1e-10; // Set maximum number of iterations

  var iterMax = 10; // Implement Newton's method

  var y,
      y0,
      y1,
      x0,
      x1 = 0,
      f = 0,
      i = 0;
  var rate = guess;

  if (Math.abs(rate) < epsMax) {
    y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
  } else {
    f = Math.exp(periods * Math.log(1 + rate));
    y = present * f + payment * (1 / rate + type) * (f - 1) + future;
  }

  y0 = present + payment * periods + future;
  y1 = present * f + payment * (1 / rate + type) * (f - 1) + future;
  i = x0 = 0;
  x1 = rate;

  while (Math.abs(y0 - y1) > epsMax && i < iterMax) {
    rate = (y1 * x0 - y0 * x1) / (y1 - y0);
    x0 = x1;
    x1 = rate;

    if (Math.abs(rate) < epsMax) {
      y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
    } else {
      f = Math.exp(periods * Math.log(1 + rate));
      y = present * f + payment * (1 / rate + type) * (f - 1) + future;
    }

    y0 = y1;
    y1 = y;
    ++i;
  }

  return rate;
}; // https://gist.github.com/ghalimi/4638848


var ExcelPv = function ExcelPv(rate, periods, payment, future, _type) {
  // Initialize type
  var type = typeof _type === 'undefined' ? 0 : _type; // Evaluate rate and periods

  rate = eval(rate);
  periods = eval(periods); // Return present value

  if (rate === 0) {
    return -payment * periods - future;
  } else {
    return ((1 - Math.pow(1 + rate, periods)) / rate * payment * (1 + rate * type) - future) / Math.pow(1 + rate, periods);
  }
};