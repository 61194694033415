export default (function (imageSrc) {
  return new Promise(function (resolve) {
    var img = new Image();

    img.onload = function () {
      return resolve();
    };

    img.onerror = function () {
      return resolve();
    };

    img.src = imageSrc;
    setTimeout(resolve, 1500);
  });
});