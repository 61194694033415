import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import { showPanel } from '@paperstac/common/lib/services/intercomMessenger';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import Heading from '@paperstac/ui/lib/Heading';
import Link from '@paperstac/ui/lib/Link';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';

const FrozenIdentityScreen = React.memo(({ children }) => {
  const { claims, identity } = React.useContext(IdentityContext);
  const isGuest = React.useMemo(() => !identity, [identity]);
  const isNotFrozen = React.useMemo(() => !!identity && !identity.isFrozen, [identity]);
  const isAdmin = React.useMemo(() => !!claims && claims.admin, [claims]);

  if (isGuest || isNotFrozen || isAdmin) return children;

  return (
    <Box
      sx={{
        my: [0, 5],
        mx: 'auto',
        py: 5,
        px: [3, 5],
        maxWidth: ['100%', 450],
        bg: 'gray.1',
        border: ['none', 'default'],
        borderRadius: ['none', 'center'],
        textAlign: 'center',
      }}
    >
      <Heading fontSize={3} mb={5}>
        Please Contact Paperstac Support
      </Heading>
      <Box mb={5}>
        We need to speak with you regarding your <Text variant="bold">{identity.email}</Text> Paperstac Identity.
      </Box>
      <Box mb={1}>
        <Text variant="bold">Support Phone</Text>: 407-930-9749
      </Box>
      <Box mb={3}>
        <Text variant="bold">Support Email</Text>:{' '}
        <Link href="mailto:support@paperstac.com">support@paperstac.com</Link>
      </Box>
      <Button onClick={showPanel} variant="primary">
        Start Chat
      </Button>
    </Box>
  );
});

FrozenIdentityScreen.propTypes = {};

FrozenIdentityScreen.defaultProps = {};

FrozenIdentityScreen.displayName = 'FrozenIdentityScreen';

export default FrozenIdentityScreen;
