function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
export var normalizeDate = function normalizeDate(dateOrFirestoreTimestamp) {
  if (!dateOrFirestoreTimestamp) return dateOrFirestoreTimestamp;
  if (typeof dateOrFirestoreTimestamp === 'string') return isoToDate(dateOrFirestoreTimestamp);
  if (_typeof(dateOrFirestoreTimestamp) !== 'object') return dateOrFirestoreTimestamp;
  if (dateOrFirestoreTimestamp.toDate) return dateOrFirestoreTimestamp.toDate();
  if (dateOrFirestoreTimestamp.seconds) return new Date(dateOrFirestoreTimestamp.seconds * 1000);
  if (dateOrFirestoreTimestamp._seconds) return new Date(dateOrFirestoreTimestamp._seconds * 1000);
  return dateOrFirestoreTimestamp;
};
export var isoToDate = function isoToDate(iso) {
  if (typeof iso !== 'string' || !/^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])$/.test(iso)) return iso;

  var _iso$split = iso.split('-'),
      _iso$split2 = _slicedToArray(_iso$split, 3),
      year = _iso$split2[0],
      month = _iso$split2[1],
      day = _iso$split2[2];

  return new Date(+year, +month - 1, +day);
};
export var dateToIso = function dateToIso(dateOrFirestoreTimestamp) {
  if (typeof dateOrFirestoreTimestamp === 'string') return dateOrFirestoreTimestamp.split('T')[0];
  if (!dateOrFirestoreTimestamp || _typeof(dateOrFirestoreTimestamp) !== 'object') return dateOrFirestoreTimestamp;
  return normalizeDate(dateOrFirestoreTimestamp).toISOString().split('T')[0];
};
export var dateToTime = function dateToTime(dateOrFirestoreTimestamp) {
  if (!dateOrFirestoreTimestamp || _typeof(dateOrFirestoreTimestamp) !== 'object') return dateOrFirestoreTimestamp;
  return normalizeDate(dateOrFirestoreTimestamp).getTime();
};
export var dateToUnix = function dateToUnix(dateOrFirestoreTimestamp) {
  var time = dateToTime(dateOrFirestoreTimestamp);
  return Math.round(time / 1000);
};
export var unixToDate = function unixToDate(unixTimestamp) {
  return new Date(unixTimestamp * 1000);
};
export var relativeDate = function relativeDate(dateOrFirestoreTimestamp) {
  return distanceInWordsToNow(normalizeDate(dateOrFirestoreTimestamp), {
    includeSeconds: true,
    addSuffix: true
  });
};
export var isFirstMinuteOfHour = function isFirstMinuteOfHour(d) {
  return normalizeDate(d).getMinutes() === 0;
};
export var get24HourEST = function get24HourEST(d) {
  var string = normalizeDate(d).toLocaleString('en-US', {
    timeZone: 'America/New_York',
    hour12: false
  });
  return +string.split(':').shift().split(' ').pop();
};
export var isFirstHourOfDayEST = function isFirstHourOfDayEST(d) {
  return [0, 24].includes(get24HourEST(d));
};
export var isFirstMinuteOfDayEST = function isFirstMinuteOfDayEST(d) {
  return isFirstMinuteOfHour(d) && isFirstHourOfDayEST(d);
};
export var secondsToTimeString = function secondsToTimeString(seconds) {
  return new Date(1000 * seconds).toISOString().substr(11, 8);
};
export var secondsToShortTimeString = function secondsToShortTimeString(seconds) {
  var timeString = secondsToTimeString(seconds);
  var timeParts = timeString.split(':');
  if (timeParts[0] !== '00') return "".concat(+timeParts[0], ":").concat(timeParts[1], ":").concat(timeParts[2]);
  if (timeParts[1] !== '00') return "".concat(+timeParts[1], ":").concat(timeParts[2]);
  return ":".concat(timeParts[2]);
};