import * as FullStory from '@fullstory/browser';
import { FIREBASE_PROJECT_ID, FULLSTORY_ORG_ID } from '@paperstac/env';
import React from 'react';
var fullStorySupported = ['paperstac-dev', 'paperstac-prod'].includes(FIREBASE_PROJECT_ID);

if (fullStorySupported) {
  FullStory.init({
    orgId: FULLSTORY_ORG_ID
  });
}

var noop = function noop() {};

var mock = {
  anonymize: noop,
  consent: noop,
  event: noop,
  getCurrentSessionURL: noop,
  identify: noop,
  log: noop,
  restart: noop,
  setUserVars: noop,
  shutdown: noop
};
export default (function () {
  return fullStorySupported ? FullStory : mock;
});