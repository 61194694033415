import * as routes from '@paperstac/routes/lib/webRoutes';
import loadable from 'loadable-components';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import blogPosts from './components/blogPosts';
import FrozenPage from './components/FrozenPage';
import ForSaleArchivePage from './components/pages/ForSalePage/ForSalePage';
import HomePage from './components/pages/HomePage/HomePage';
import RefreshWebsiteOverlay from './components/RefreshWebsiteOverlay';
import ScrollToTop from './components/ScrollToTop';
import withTracker from './components/withTracker';
import { BLOG_POSTS_SINGLE, getPath } from './routes';
import Sentry, { history } from './services/sentry';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';

const options = { ErrorComponent: RefreshWebsiteOverlay };

const SentryRoute = Sentry.withSentryRouting(Route);

const Async = {
  AboutUsPage: loadable(() => import('./components/pages/AboutUsPage/AboutUsPage'), options),
  AcceptTeamInvitePage: loadable(() => import('./components/AcceptTeamInvitePage'), options),
  AccountStatementsPage: loadable(
    () => import('./components/pages/AccountStatementsPage/AccountStatementsPage'),
    options
  ),
  ActionPage: loadable(() => import('./components/pages/ActionPage/ActionPage'), options),
  AdminCampaignsPage: loadable(() => import('./components/AdminCampaignsPage'), options),
  AdminAccountsPage: loadable(() => import('./components/pages/AdminAccountsPage/AdminAccountsPage'), options),
  AdminDatabasePage: loadable(() => import('./components/pages/AdminDatabasePage/AdminDatabasePage'), options),
  AdminEscrowOrdersPage: loadable(
    () => import('./components/pages/AdminEscrowOrdersPage/AdminEscrowOrdersPage'),
    options
  ),
  AdminIdentitiesPage: loadable(() => import('./components/pages/AdminIdentitiesPage/AdminIdentitiesPage'), options),
  AdminJobsPage: loadable(() => import('./components/pages/AdminJobsPage/AdminJobsPage'), options),
  AdminModerateEventsPage: loadable(() => import('./components/AdminModerateEventsPage'), options),
  AdminRestorePointsPage: loadable(() => import('./components/AdminRestorePointsPage'), options),
  AdminSettingsPage: loadable(() => import('./components/pages/AdminSettingsPage/AdminSettingsPage'), options),
  AdminShortUrlsPage: loadable(() => import('./components/AdminShortUrlsPage/AdminShortUrlsPage'), options),
  AdminSensitivityReviewPage: loadable(() => import('./components/AdminSensitivityReviewPage'), options),
  AdminReportsPage: loadable(() => import('./components/pages/AdminReportsPage/AdminReportsPage'), options),
  AdminTasksPage: loadable(() => import('./components/pages/AdminTasksPage/AdminTasksPage'), options),
  AdminTransactionsPage: loadable(
    () => import('./components/pages/AdminTransactionsPage/AdminTransactionsPage'),
    options
  ),
  AuthActionsPage: loadable(() => import('./components/pages/AuthActionsPage/AuthActionsPage'), options),
  AuditOrdersArchivePage: loadable(() => import('./components/AuditOrdersArchivePage'), options),
  AuditOrdersSinglePage: loadable(() => import('./components/AuditOrdersSinglePage'), options),
  BankAccountsPage: loadable(() => import('./components/BankAccountsPage'), options),
  BlockedAccountsPage: loadable(() => import('./components/pages/BlockedAccountsPage/BlockedAccountsPage'), options),
  BlogPage: loadable(() => import('./components/pages/BlogPage/BlogPage'), options),
  BlogTagPage: loadable(() => import('./components/pages/BlogPage/BlogTagPage'), options),
  MyCommunitiesArchivePage: loadable(() => import('./components/MyCommunitiesArchivePage'), options),
  EscrowAccountBalancePage: loadable(() => import('./components/EscrowAccountBalancePage'), options),
  EscrowOrdersArchivePage: loadable(() => import('./components/EscrowOrdersArchivePage'), options),
  EscrowOrdersSinglePage: loadable(() => import('./components/EscrowOrdersSinglePage'), options),
  ForgotPasswordPage: loadable(() => import('./components/pages/ForgotPasswordPage/ForgotPasswordPage'), options),
  ForSaleSinglePage: loadable(() => import('./components/pages/ListingPage/ListingPage'), options),
  FrozenPage: loadable(() => import('./components/FrozenPage'), options),
  ImportDetailPage: loadable(() => import('./components/ImportDetailPage'), options),
  InvestPage: loadable(() => import('./components/pages/InvestPage/InvestPage'), options),
  InvestInPaperstacPage: loadable(
    () => import('./components/pages/InvestInPaperstacPage/InvestInPaperstacPage'),
    options
  ),
  LoginPage: loadable(() => import('./components/pages/LoginPage/LoginPage'), options),
  ManageAccountPage: loadable(() => import('./components/pages/ManageAccountPage/ManageAccountPage'), options),
  ManageCommunityPage: loadable(() => import('./components/ManageCommunityPage'), options),
  ManageCommunityInvitesPage: loadable(() => import('./components/ManageCommunityInvitesPage'), options),
  MyFeedPage: loadable(() => import('./components/pages/MyFeedPage/MyFeedPage'), options),
  MyImportsPage: loadable(() => import('./components/MyImportsPage'), options),
  MyListingsPage: loadable(() => import('./components/pages/MyListingsPage/MyListingsPage'), options),
  MyNotesPage: loadable(() => import('./components/MyNotesPage'), options),
  NotFoundPage: loadable(() => import('./components/pages/NotFoundPage/NotFoundPage'), options),
  PaymentMethodsPage: loadable(() => import('./components/pages/PaymentMethodsPage/PaymentMethodsPage'), options),
  PasswordResetPage: loadable(() => import('./components/pages/PasswordResetPage/PasswordResetPage'), options),
  PrivacyPolicyPage: loadable(() => import('./components/PrivacyPolicyPage'), options),
  RegisterPage: loadable(() => import('./components/pages/RegisterPage/RegisterPage'), options),
  SdiraIntegrationsPage: loadable(
    () => import('./components/pages/SdiraIntegrationsPage/SdiraIntegrationsPage'),
    options
  ),
  SellerOnboardingPage: loadable(() => import('./components/SellerOnboardingPage'), options),
  ServicerProfilesPage: loadable(() => import('./components/pages/ServicerProfilesPage/ServicerProfilesPage'), options),
  ShippingAddressesPage: loadable(
    () => import('./components/pages/ShippingAddressesPage/ShippingAddressesPage'),
    options
  ),
  SignaturePadPage: loadable(() => import('./components/pages/SignaturePadPage/SignaturePadPage'), options),
  TeamsPage: loadable(() => import('./components/TeamsPage'), options),
  TermsPage: loadable(() => import('./components/TermsPage'), options),
  TransactionsArchivePage: loadable(() => import('./components/pages/TransactionsPage/TransactionsPage'), options),
  TransactionsSinglePage: loadable(() => import('./components/pages/TransactionPage/TransactionPage'), options),
  UserActionsPage: loadable(() => import('./components/UserActionsPage'), options),
  VestingProfilesPage: loadable(() => import('./components/pages/VestingProfilesPage/VestingProfilesPage'), options),
};

const PaperstacRoutes = React.memo((props) => {
  const { identity } = React.useContext(IdentityContext);
  React.useEffect(() => {
    !!identity
      ? Sentry.setUser({ id: identity.id, email: identity.email })
      : Sentry.configureScope((scope) => scope.setUser(null));
  }, [identity]);

  return (
    <Router history={history}>
      <ScrollToTop>
        <Switch>
          <SentryRoute exact path={routes.ABOUT} component={withTracker(Async.AboutUsPage)} />
          <SentryRoute exact path={routes.ACCEPT_TEAM_INVITE} component={withTracker(Async.AcceptTeamInvitePage)} />
          <SentryRoute exact path={routes.ACCOUNT_STATEMENTS} component={withTracker(Async.AccountStatementsPage)} />
          <SentryRoute exact path={routes.ACTION} component={withTracker(Async.ActionPage)} />
          <SentryRoute exact path={routes.ADMIN_CAMPAIGNS} component={withTracker(Async.AdminCampaignsPage)} />
          <SentryRoute exact path={routes.ADMIN} component={withTracker(Async.AdminSettingsPage)} />
          <SentryRoute exact path={routes.ADMIN_IDENTITIES} component={withTracker(Async.AdminIdentitiesPage)} />
          <SentryRoute exact path={routes.ADMIN_ACCOUNTS} component={withTracker(Async.AdminAccountsPage)} />
          <SentryRoute exact path={routes.ADMIN_DATABASE} component={withTracker(Async.AdminDatabasePage)} />
          <SentryRoute exact path={routes.ADMIN_ESCROW_ORDERS} component={withTracker(Async.AdminEscrowOrdersPage)} />
          <SentryRoute exact path={routes.ADMIN_JOBS} component={withTracker(Async.AdminJobsPage)} />
          <SentryRoute
            exact
            path={routes.ADMIN_MODERATE_EVENTS}
            component={withTracker(Async.AdminModerateEventsPage)}
          />
          <SentryRoute exact path={routes.ADMIN_REPORTS} component={withTracker(Async.AdminReportsPage)} />
          <SentryRoute exact path={routes.ADMIN_RESTORE_POINTS} component={withTracker(Async.AdminRestorePointsPage)} />
          <SentryRoute
            exact
            path={routes.ADMIN_SENSITIVITY_REVIEW}
            component={withTracker(Async.AdminSensitivityReviewPage)}
          />
          <SentryRoute exact path={routes.ADMIN_SETTINGS} component={withTracker(Async.AdminSettingsPage)} />
          <SentryRoute exact path={routes.ADMIN_SHORT_URLS} component={withTracker(Async.AdminShortUrlsPage)} />
          <SentryRoute exact path={routes.ADMIN_TASKS} component={withTracker(Async.AdminTasksPage)} />
          <SentryRoute exact path={routes.ADMIN_TRANSACTIONS} component={withTracker(Async.AdminTransactionsPage)} />
          <SentryRoute exact path={routes.AUTH_ACTIONS} component={withTracker(Async.AuthActionsPage)} />
          <SentryRoute exact path={routes.AUDIT_ORDERS_ARCHIVE} component={withTracker(Async.AuditOrdersArchivePage)} />
          <SentryRoute exact path={routes.AUDIT_ORDERS_SINGLE} component={withTracker(Async.AuditOrdersSinglePage)} />
          <SentryRoute exact path={routes.BLOCKED_ACCOUNTS} component={withTracker(Async.BlockedAccountsPage)} />
          <SentryRoute exact path={routes.BLOG_POSTS_ARCHIVE} component={withTracker(Async.BlogPage)} />
          <SentryRoute exact path={routes.BLOG_TAG_ARCHIVE} component={withTracker(Async.BlogTagPage)} />
          <SentryRoute
            exact
            path={routes.MY_COMMUNITIES_ARCHIVE}
            component={withTracker(Async.MyCommunitiesArchivePage)}
          />
          <SentryRoute
            exact
            path={routes.ESCROW_ACCOUNT_BALANCE}
            component={withTracker(Async.EscrowAccountBalancePage)}
          />
          <SentryRoute
            exact
            path={routes.ESCROW_ORDERS_ARCHIVE}
            component={withTracker(Async.EscrowOrdersArchivePage)}
          />
          <SentryRoute exact path={routes.ESCROW_ORDERS_SINGLE} component={withTracker(Async.EscrowOrdersSinglePage)} />
          <SentryRoute exact path={routes.FORGOT_PASSWORD} component={withTracker(Async.ForgotPasswordPage)} />
          <SentryRoute exact path={routes.FOR_SALE_ARCHIVE} component={withTracker(ForSaleArchivePage)} />
          <SentryRoute exact path={routes.FOR_SALE_SINGLE} component={withTracker(Async.ForSaleSinglePage)} />
          <SentryRoute exact path={routes.FROZEN} component={withTracker(FrozenPage)} />
          <SentryRoute exact path={routes.HOME} component={withTracker(HomePage)} />
          <SentryRoute exact path={routes.INVEST} component={withTracker(Async.InvestPage)} />
          <SentryRoute exact path={routes.INVEST_IN_PAPERSTAC} component={withTracker(Async.InvestInPaperstacPage)} />
          <SentryRoute exact path={routes.IMPORTS_ARCHIVE} component={withTracker(Async.MyImportsPage)} />
          <SentryRoute exact path={routes.IMPORT_SINGLE} component={withTracker(Async.ImportDetailPage)} />
          <SentryRoute exact path={routes.LISTINGS_ARCHIVE} component={withTracker(Async.MyListingsPage)} />
          <SentryRoute exact path={routes.LOGIN} component={withTracker(Async.LoginPage)} />
          <SentryRoute exact path={routes.MANAGE_ACCOUNT} component={withTracker(Async.ManageAccountPage)} />
          <SentryRoute exact path={routes.MANAGE_COMMUNITY} component={withTracker(Async.ManageCommunityPage)} />
          <SentryRoute
            exact
            path={routes.MANAGE_COMMUNITY_INVITES}
            component={withTracker(Async.ManageCommunityInvitesPage)}
          />
          <SentryRoute exact path={routes.MY_FEED} component={withTracker(Async.MyFeedPage)} />
          <SentryRoute exact path={routes.NOTES_ARCHIVE} component={withTracker(Async.MyNotesPage)} />
          <SentryRoute exact path={routes.PAYMENT_METHODS} component={withTracker(Async.PaymentMethodsPage)} />
          <SentryRoute exact path={routes.PRIVACY_POLICY} component={withTracker(Async.PrivacyPolicyPage)} />
          <SentryRoute exact path={routes.REGISTER} component={withTracker(Async.RegisterPage)} />
          <SentryRoute exact path={routes.SDIRA_INTEGRATIONS} component={withTracker(Async.SdiraIntegrationsPage)} />
          <SentryRoute exact path={routes.SELLER_ONBOARDING} component={withTracker(Async.SellerOnboardingPage)} />
          <SentryRoute
            exact
            path={routes.SERVICER_PROFILES_ARCHIVE}
            component={withTracker(Async.ServicerProfilesPage)}
          />
          <SentryRoute
            exact
            path={routes.SHIPPING_ADDRESSES_ARCHIVE}
            component={withTracker(Async.ShippingAddressesPage)}
          />
          <SentryRoute exact path={routes.SIGNATURE_PAD} component={withTracker(Async.SignaturePadPage)} />
          <SentryRoute exact path={routes.TEAMS_ARCHIVE} component={withTracker(Async.TeamsPage)} />
          <SentryRoute exact path={routes.TERMS} component={withTracker(Async.TermsPage)} />
          <SentryRoute
            exact
            path={routes.TRANSACTIONS_ARCHIVE}
            component={withTracker(Async.TransactionsArchivePage)}
          />
          <SentryRoute exact path={routes.TRANSACTIONS_SINGLE} component={withTracker(Async.TransactionsSinglePage)} />
          <SentryRoute
            exact
            path={routes.TRANSACTIONS_SINGLE_TAB}
            component={withTracker(Async.TransactionsSinglePage)}
          />
          <SentryRoute
            exact
            path={routes.TRANSACTIONS_SINGLE_NOTE}
            component={withTracker(Async.TransactionsSinglePage)}
          />
          <SentryRoute exact path={routes.USER_ACTIONS} component={withTracker(Async.UserActionsPage)} />
          <SentryRoute
            exact
            path={routes.VESTING_PROFILES_ARCHIVE}
            component={withTracker(Async.VestingProfilesPage)}
          />
          {Object.keys(blogPosts).map((blogComponentName) => (
            <SentryRoute
              key={blogPosts[blogComponentName].metadata.slug}
              exact
              path={getPath(BLOG_POSTS_SINGLE, blogPosts[blogComponentName].metadata.slug)}
              component={withTracker(
                loadable(() => import(`./components/blogPosts/${blogComponentName}/${blogComponentName}`), options)
              )}
            />
          ))}
          <SentryRoute component={withTracker(Async.NotFoundPage)} />} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
});

PaperstacRoutes.propTypes = {};

PaperstacRoutes.defaultProps = {};

PaperstacRoutes.displayName = 'PaperstacRoutes';

export default PaperstacRoutes;
