import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import useFirebaseAuth from '@paperstac/common/lib/hooks/useFirebaseAuth';
import useFullStory from '@paperstac/common/lib/hooks/useFullStory';
import * as intercomMessenger from '@paperstac/common/lib/services/intercomMessenger';
import { FIREBASE_PROJECT_ID, PAPERSTAC_SUPPORT_URL } from '@paperstac/env';
import auth from '@paperstac/firebase/lib/auth';
import { ADMIN, DOCUMENTS, HOME, MANAGE_ACCOUNT } from '@paperstac/routes/lib/webRoutes';
import Avatar from '@paperstac/ui/lib/Avatar';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import Dropdown from '@paperstac/ui/lib/Dropdown';
import DropdownContent from '@paperstac/ui/lib/DropdownContent';
import DropdownTrigger from '@paperstac/ui/lib/DropdownTrigger';
import useBreakpoints from '@paperstac/ui/lib/hooks/useBreakpoints';
import ConciergeColorIcon from '@paperstac/ui/lib/icons/ConciergeColorIcon';
import FileIcon from '@paperstac/ui/lib/icons/FileIcon';
import FingerprintIcon from '@paperstac/ui/lib/icons/FingerprintIcon';
import FullStoryIcon from '@paperstac/ui/lib/icons/FullStoryIcon';
import LogoutIcon from '@paperstac/ui/lib/icons/LogoutIcon';
import SupportIcon from '@paperstac/ui/lib/icons/SupportIcon';
import TrustedIcon from '@paperstac/ui/lib/icons/TrustedIcon';
import UsersIcon from '@paperstac/ui/lib/icons/UsersIcon';
import Image from '@paperstac/ui/lib/Image';
import ListGroupMenu from '@paperstac/ui/lib/ListGroupMenu';
import Text from '@paperstac/ui/lib/Text';
import { themeGet } from '@styled-system/theme-get';
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { useClipboard } from 'use-clipboard-copy';
import { IdentityManagerContext } from '../IdentityManager';
import ManageIdentityOverlay from '../ManageIdentityOverlay/ManageIdentityOverlay';

let _closeDropdown;

const handleCloseDropdown = () => _closeDropdown && _closeDropdown();

const AccountDropdown = memo((props) => {
  const FullStory = useFullStory();
  const { isMobile } = useBreakpoints();
  const { claims } = React.useContext(IdentityContext);
  const { currentAccount, accounts } = React.useContext(AccountsContext);
  const { showAccountChooser } = React.useContext(IdentityManagerContext);
  const { logout } = useFirebaseAuth(auth);
  const [isManageIdentityOpen, setIsManageIdentityOpen] = React.useState(false);
  const showManageIdentity = React.useCallback(() => {
    setIsManageIdentityOpen(true);
    handleCloseDropdown();
  }, []);
  const hideManageIdentity = React.useCallback(() => setIsManageIdentityOpen(false), []);
  const handleLogout = () => logout().then(() => props.history.push(HOME));
  const clipboard = useClipboard();
  const handleCopyFullStory = React.useCallback(() => {
    const fullStoryUrl = FullStory.getCurrentSessionURL(true);
    console.log(fullStoryUrl);
    clipboard.copy(fullStoryUrl);
  }, [clipboard, FullStory]);
  const items = [
    {
      label: 'Manage Identity',
      as: 'button',
      icon: <FingerprintIcon />,
      onClick: showManageIdentity,
    },
    {
      label: 'Manage Account',
      as: Link,
      icon: <Image src={currentAccount.avatar} border="none" size={16} borderRadius="circle" />,
      to: MANAGE_ACCOUNT,
    },
    {
      label: 'Documents',
      as: Link,
      icon: <FileIcon />,
      to: DOCUMENTS,
    },
  ];
  if (accounts && accounts.length > 1) {
    items.push({
      label: 'Switch Accounts',
      as: 'button',
      icon: <UsersIcon />,
      onClick: showAccountChooser,
    });
  }
  if (!!claims && claims.admin)
    items.push({
      label: 'Admin Section',
      as: Link,
      icon: <TrustedIcon />,
      to: ADMIN,
    });
  if ((!!claims && claims.admin) || (!!claims && claims.accountant) || FIREBASE_PROJECT_ID === 'paperstac-dev')
    items.push({
      label: 'Copy FullStory URL',
      as: 'button',
      icon: <FullStoryIcon />,
      onClick: () => {
        handleCopyFullStory();
        handleCloseDropdown();
      },
    });
  items.push({
    label: 'Knowledge Base',
    as: 'a',
    icon: <SupportIcon />,
    href: PAPERSTAC_SUPPORT_URL,
    target: '_blank',
    rel: 'noopener nofollow',
  });
  items.push({
    label: 'Contact Support',
    as: 'button',
    icon: <ConciergeColorIcon />,
    onClick: () => {
      intercomMessenger.showPanel();
      handleCloseDropdown();
    },
  });

  if (!currentAccount) return null;

  return (
    <>
      <Dropdown>
        <DropdownTrigger>
          {({ closeDropdown, toggleDropdown }) => {
            _closeDropdown = closeDropdown;
            return (
              <Avatar src={currentAccount.avatar} size={isMobile ? 30 : 40} display="block" onClick={toggleDropdown} />
            );
          }}
        </DropdownTrigger>
        <DropdownContent width={230} alignRight>
          <DropdownContentHeader bg="darkBlue" py={3} textAlign="center">
            <Avatar border="thickTransparentWhite" src={currentAccount.avatar} size={80} />
            <Text color="white" fontSize={1}>
              {currentAccount.displayName}
            </Text>
          </DropdownContentHeader>
          <ListGroupMenu py={2} px={3} borderRadius={0} items={items} />
          <DropdownContentFooter p={3} bg="gray.1">
            <Button variant="secondary" block onClick={handleLogout}>
              Logout <LogoutIcon ml={2} />
            </Button>
          </DropdownContentFooter>
        </DropdownContent>
      </Dropdown>
      {isManageIdentityOpen && <ManageIdentityOverlay onClose={hideManageIdentity} />}
    </>
  );
});

const DropdownContentHeader = styled(Text)`
  border-top-left-radius: ${themeGet('radii.corner')};
  border-top-right-radius: ${themeGet('radii.corner')};
  border: ${themeGet('borders.default')};
  border-bottom: ${themeGet('borders.none')};
`;

const DropdownContentFooter = styled(Box)`
  border-bottom-left-radius: ${themeGet('radii.corner')};
  border-bottom-right-radius: ${themeGet('radii.corner')};
  border: ${themeGet('borders.default')};
  border-top: ${themeGet('borders.none')};
`;

AccountDropdown.propTypes = {};

AccountDropdown.defaultProps = {};

AccountDropdown.displayName = 'AccountDropdown';

export default withRouter(connect()(AccountDropdown));
