import Heading from '@paperstac/ui/lib/Heading';
import React from 'react';

const ViewTitle = React.memo(props => <Heading textAlign="center" fontSize={20} mb={40} {...props} />);

ViewTitle.propTypes = {};

ViewTitle.defaultProps = {};

ViewTitle.displayName = 'ViewTitle';

export default ViewTitle;