import { rem } from 'polished';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rotate from './rotate';

var getSpinnerSize = function getSpinnerSize(size) {
  switch (size) {
    case 'tiny':
      return 14;

    case 'small':
      return 20;

    case 'default':
      return 40;

    case 'large':
      return 75;

    default:
      return 20;
  }
};

var getSpinnerThickness = function getSpinnerThickness(size) {
  switch (size) {
    case 'tiny':
      return 2;

    case 'small':
      return 3;

    case 'default':
      return 6;

    case 'large':
      return 10;

    default:
      return 3;
  }
};

var Spinner = styled.div.withConfig({
  displayName: "Spinner",
  componentId: "sc-2dw3d5-0"
})(["display:inline-block;width:", ";height:", ";border-width:", ";border-color:", ";border-style:solid;border-top-color:transparent;animation:", " 1s linear infinite;border-radius:50%;"], function (props) {
  return rem(getSpinnerSize(props.size));
}, function (props) {
  return rem(getSpinnerSize(props.size));
}, function (props) {
  return rem(getSpinnerThickness(props.size));
}, function (props) {
  return props.borderColor || props.theme.colors.secondary;
}, rotate);
Spinner.propTypes = {
  /** Size of button */
  size: PropTypes.oneOf(['tiny', 'small', 'default', 'large'])
};
Spinner.defaultProps = {
  size: 'default'
};
Spinner.displayName = 'Spinner';
export default Spinner;