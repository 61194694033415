function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { NOTE_TAPE_PERFORMANCE_PERFORMING } from '@paperstac/constants';
import { normalizeDate } from '@paperstac/helpers/lib/dateHelpers';
import { centsToUsdString, getOrdinal } from '@paperstac/helpers/lib/numberHelpers';
import { camelToTitle, toTitle } from '@paperstac/helpers/lib/stringHelpers';
import differenceInDays from 'date-fns/difference_in_days';
import format from 'date-fns/format';
import * as Yup from 'yup';
import { getNameFromCode as getCountry } from './countries';
import { listingCellLabel, listingLabel } from './listingsHelper';
import { getNameFromCode as getState } from './states';
export var notePropertyLabelsMap = {
  accruedLateCharges: 'Accrued Late Charges',
  bathrooms: 'Bathrooms',
  bedrooms: 'Bedrooms',
  city: 'City',
  cityStateZip: 'City/State/Zip',
  country: 'Country',
  county: 'County',
  createdDate: 'Date Created',
  escrowImpounds: 'Escrow/Impounds',
  floorSizeSqFt: 'Floor Size Sq Ft',
  interestOnlyLoan: 'Interest-Only Loan',
  interestRatePercent: 'Interest Rate',
  isHardestHitFundState: 'Hardest Hit Fund State',
  isJudicialState: 'Judicial State',
  isNonJudicialState: 'Non-Judicial State',
  lastImportDate: 'Last Import Date',
  lastPaymentReceivedDate: 'Last Payment Received Date',
  legalStatus: 'Legal Status',
  lienPosition: 'Lien Position',
  loanId: 'Loan ID',
  loanToValuePercent: 'Loan to Value',
  loanToZestimatePercent: 'Loan to Zestimate',
  lotSizeSqFt: 'Lot Size Sq Ft',
  maturityDate: 'Maturity Date',
  nextPaymentDate: 'Next Payment Date',
  noteType: 'Note Type',
  occupancyStatus: 'Occupancy Status',
  originalBalance: 'Original Balance',
  originationDate: 'Origination Date',
  paymentsRemaining: 'Payments Remaining',
  performance: 'Performance (PL/NPL)',
  principalAndInterestPayment: 'Principal And Interest Payment',
  propertyType: 'Property Type',
  rentZestimate: 'Rent Zestimate',
  rentZestimateDate: 'Rent Zestimate Date',
  sellerProvidedPropertyValue: 'Property Value (by Seller)',
  sellerProvidedPropertyValueType: 'Valuation Type',
  sellerProvidedPropertyValueDate: 'Valuation Date',
  seniorPerformance: 'Sr. Performance (PL/NPL)',
  seniorUpb: 'Sr. Unpaid Principal Balance',
  seniorLoanToValuePercent: 'Sr. Loan to Value',
  seniorInterestRatePercent: 'Sr. Interest Rate',
  seniorTotalMonthlyLoanPayment: 'Sr. Total Monthly Loan Payment',
  servicer: 'Servicer',
  state: 'State',
  streetAddress: 'Street Address',
  streetAddress1: 'Street Address',
  streetAddress2: 'Apt, Unit, etc',
  totalMonthlyLoanPayment: 'Total Monthly Loan Payment',
  totalPayoff: 'Total Payoff',
  upb: 'Unpaid Principal Balance',
  updatedDate: 'Last Update',
  yearBuilt: 'Year Built',
  zestimate: 'Zestimate',
  zestimateDate: 'Zestimate Date',
  zillowUrl: 'Zillow Url',
  zip: 'Zip'
};
export var transformNotePropertyValue = function transformNotePropertyValue(property, value) {
  var formatBoolean = function formatBoolean(boolean) {
    return typeof boolean === 'boolean' ? boolean ? 'Yes' : 'No' : '';
  };

  var formatCountry = function formatCountry(country) {
    return country ? getCountry(country) : '';
  };

  var formatDate = function formatDate(date) {
    return date ? format(normalizeDate(date), 'MMM D, YYYY') : '';
  };

  var formatMoney = function formatMoney(cents) {
    return !isNaN(cents) ? centsToUsdString(cents) : '';
  };

  var formatOrdinal = function formatOrdinal(number) {
    return number ? getOrdinal(number) : '';
  };

  var formatPercent = function formatPercent(percent) {
    return percent ? "".concat(percent, "%") : '';
  };

  var formatState = function formatState(state) {
    return state ? getState(state) : '';
  };

  var formatSqFt = function formatSqFt(percent) {
    return percent ? "".concat(percent, " sqft") : '';
  };

  var map = {
    accruedLateCharges: formatMoney,
    country: formatCountry,
    createdDate: formatDate,
    escrowImpounds: formatMoney,
    floorSizeSqFt: formatSqFt,
    interestOnlyLoan: formatBoolean,
    interestRatePercent: formatPercent,
    isHardestHitFundState: formatBoolean,
    isJudicialState: formatBoolean,
    isNonJudicialState: formatBoolean,
    lastImportDate: formatDate,
    lastPaymentReceivedDate: formatDate,
    lienPosition: formatOrdinal,
    loanToValuePercent: formatPercent,
    loanToZestimatePercent: formatPercent,
    lotSizeSqFt: formatSqFt,
    maturityDate: formatDate,
    nextPaymentDate: formatDate,
    originalBalance: formatMoney,
    originationDate: formatDate,
    principalAndInterestPayment: formatMoney,
    rentZestimate: formatMoney,
    rentZestimateDate: formatDate,
    sellerProvidedPropertyValue: formatMoney,
    sellerProvidedPropertyValueDate: formatDate,
    seniorUpb: formatMoney,
    seniorLoanToValuePercent: formatPercent,
    seniorInterestRatePercent: formatPercent,
    seniorTotalMonthlyLoanPayment: formatMoney,
    state: formatState,
    totalMonthlyLoanPayment: formatMoney,
    totalPayoff: formatMoney,
    upb: formatMoney,
    updatedDate: formatDate,
    zestimate: formatMoney,
    zestimateDate: formatDate
  };
  return map[property] ? map[property](value) : value;
};
export var getReasonsForUnlistable = function getReasonsForUnlistable(note) {
  var daysSinceUpdate = differenceInDays(new Date(), normalizeDate(note.updatedDate));
  var schema = Yup.object().shape({
    accruedLateCharges: Yup.number().required("".concat(notePropertyLabelsMap['accruedLateCharges'], " is required")),
    city: Yup.string().required("".concat(notePropertyLabelsMap['city'], " is required")),
    country: Yup.string().required("".concat(notePropertyLabelsMap['country'], " is required")),
    escrowImpounds: Yup.number().required("".concat(notePropertyLabelsMap['escrowImpounds'], " is required")),
    interestRatePercent: Yup.string().required("".concat(notePropertyLabelsMap['interestRatePercent'], " is required")),
    legalStatus: Yup.string().required("".concat(notePropertyLabelsMap['legalStatus'], " is required")),
    lienPosition: Yup.string().required("".concat(notePropertyLabelsMap['lienPosition'], " is required")),
    maturityDate: Yup.string().required("".concat(notePropertyLabelsMap['maturityDate'], " is required")),
    nextPaymentDate: Yup.string().required("".concat(notePropertyLabelsMap['nextPaymentDate'], " is required")),
    noteType: Yup.string().required("".concat(notePropertyLabelsMap['noteType'], " is required")),
    occupancyStatus: Yup.string().required("".concat(notePropertyLabelsMap['occupancyStatus'], " is required")),
    originalBalance: Yup.string().required("".concat(notePropertyLabelsMap['originalBalance'], " is required")),
    originationDate: Yup.string().required("".concat(notePropertyLabelsMap['originationDate'], " is required")),
    performance: Yup.string().required("".concat(notePropertyLabelsMap['performance'], " is required")),
    principalAndInterestPayment: Yup.string().required("".concat(notePropertyLabelsMap['principalAndInterestPayment'], " is required")),
    propertyType: Yup.string().required("".concat(notePropertyLabelsMap['propertyType'], " is required")),
    state: Yup.string().required("".concat(notePropertyLabelsMap['state'], " is required")),
    streetAddress1: Yup.string().required("".concat(notePropertyLabelsMap['streetAddress1'], " is required")),
    totalMonthlyLoanPayment: Yup.string().required("".concat(notePropertyLabelsMap['totalMonthlyLoanPayment'], " is required")),
    upb: Yup.number().positive("".concat(notePropertyLabelsMap['upb'], " must be positive")).required("".concat(notePropertyLabelsMap['upb'], " is required")),
    zip: Yup.string().required("".concat(notePropertyLabelsMap['zip'], " is required")),
    daysSinceUpdate: Yup.number().max(31, 'Note has not been updated in over a month')
  });
  return schema.validate(_objectSpread(_objectSpread({}, note), {}, {
    daysSinceUpdate: daysSinceUpdate
  }), {
    abortEarly: false
  }).then(function () {
    return [];
  }).catch(function (_ref) {
    var errors = _ref.errors;
    return errors;
  });
}; // --------------------------------------------------

export var noteLabel = function noteLabel(property) {
  if (!noteData[property]) {
    return listingLabel(property);
  }

  return noteData[property].label || camelToTitle(property);
};
export var noteCellLabel = function noteCellLabel(property) {
  if (!noteData[property]) {
    return listingCellLabel(property);
  }

  return noteData[property].cellLabel || noteLabel(property);
};
export var noteIdentifierLabel = function noteIdentifierLabel(note) {
  return "".concat(note.performance, " ").concat(centsToUsdString(note.upb), " UPB, ").concat(note.interestRatePercent, "% rate in ").concat(toTitle(note.city), ", ").concat(note.state, " ").concat(note.zip);
};
export var noteValueFormatted = function noteValueFormatted(property, value) {
  return noteData[property] && noteData[property].valueFormatter ? noteData[property].valueFormatter(value) : value;
};
export var noteCellValueFormatted = function noteCellValueFormatted(property, value) {
  return noteData[property].cellValueFormatter ? noteData[property].cellValueFormatter(value) : noteValueFormatted(property, value);
};
export var isListable = function isListable(row) {
  return !Object.keys(noteData).filter(function (prop) {
    return noteData[prop].requiredToList && !row.hasOwnProperty(prop);
  }).length;
};
export var requiredForListable = function requiredForListable(row) {
  return Object.keys(noteData).filter(function (prop) {
    return noteData[prop].requiredToList && !row.hasOwnProperty(prop);
  }).map(function (prop) {
    return noteLabel(prop);
  });
};
export var getPropertiesImportedFromTape = function getPropertiesImportedFromTape() {
  return Object.keys(noteData).filter(function (prop) {
    return noteData[prop].importedFromTape;
  });
};
export var buildManageNotesColumnSettings = function buildManageNotesColumnSettings() {
  var customSettings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Object.keys(noteData).filter(function (prop) {
    return !noteData[prop].manageNotesSpreadsheetOmit;
  }).reduce(function (settings, prop) {
    settings[prop] = customSettings[prop] ? customSettings[prop] : {
      order: noteData[prop].order,
      pinned: noteData[prop].spreadsheetViewPinned,
      enabled: noteData[prop].spreadsheetViewEnabled
    };
    return settings;
  }, {});
};
export var buildViewNotesColumnSettings = function buildViewNotesColumnSettings() {
  var customSettings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Object.keys(noteData).filter(function (prop) {
    return !noteData[prop].viewNotesSpreadsheetOmit;
  }).reduce(function (settings, prop) {
    settings[prop] = customSettings[prop] ? customSettings[prop] : {
      order: noteData[prop].order,
      pinned: noteData[prop].spreadsheetViewPinned,
      enabled: noteData[prop].spreadsheetViewEnabled
    };
    return settings;
  }, {});
};
export var getColumnsFromSettings = function getColumnsFromSettings(columnSettings) {
  return Object.keys(columnSettings).filter(function (prop) {
    return columnSettings[prop].enabled;
  }).sort(function (propOne, propTwo) {
    if (columnSettings[propOne].pinned && !columnSettings[propTwo].pinned) {
      return -1;
    }

    if (!columnSettings[propOne].pinned && columnSettings[propTwo].pinned) {
      return 1;
    }

    return columnSettings[propOne].order < columnSettings[propTwo].order ? -1 : 1;
  });
};
export var getPinnedColumnsCount = function getPinnedColumnsCount(columnSettings) {
  return Object.keys(columnSettings).filter(function (prop) {
    return columnSettings[prop].pinned && columnSettings[prop].enabled;
  }).length;
};
export var getNoteMetaProperties = function getNoteMetaProperties() {
  return Object.keys(noteData).filter(function (prop) {
    return noteData[prop].inNoteMeta;
  });
};

var dateFormatter = function dateFormatter(value) {
  return value ? format(new Date(value), 'MMM D, YYYY') : '';
};

var percentFormatter = function percentFormatter(value) {
  return value ? "".concat(value, "%") : '';
};

var booleanFormatter = function booleanFormatter(value) {
  return typeof value === 'boolean' ? value ? 'Yes' : 'No' : '';
};

export var getNoteCellWidth = function getNoteCellWidth(property) {
  var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 120;

  if (property === 'checkbox') {
    return 40;
  }

  return noteData[property].cellWidth ? noteData[property].cellWidth : defaultValue;
};

var sqFtFormatter = function sqFtFormatter(value) {
  return value ? "".concat(value, " sqft") : '';
};

var noteData = {
  accountId: {
    requiredToList: false,
    importedFromTape: false,
    label: 'Paperstac Account ID',
    cellLabel: 'Pstac Acct ID',
    cellWidth: 300,
    manageNotesSpreadsheetOmit: true,
    inNoteMeta: false,
    viewNotesSpreadsheetOmit: true
  },
  accruedLateCharges: {
    requiredToList: true,
    importedFromTape: true,
    cellLabel: 'Late Chrgs',
    valueFormatter: centsToUsdString,
    order: 14,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  bathrooms: {
    requiredToList: false,
    importedFromTape: false,
    cellLabel: 'Baths',
    cellWidth: 75,
    order: 33,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  },
  bedrooms: {
    requiredToList: false,
    importedFromTape: false,
    cellLabel: 'Beds',
    cellWidth: 75,
    order: 32,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  },
  borrowerName: {
    requiredToList: true,
    importedFromTape: true,
    cellLabel: 'Borrower',
    cellValueFormatter: toTitle,
    cellWidth: 160,
    order: 3,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  },
  city: {
    requiredToList: true,
    importedFromTape: true,
    cellValueFormatter: toTitle,
    cellWidth: 130,
    order: 20,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  },
  cityStateZip: {
    requiredToList: false,
    importedFromTape: false,
    manageNotesSpreadsheetOmit: true,
    viewNotesSpreadsheetOmit: true,
    inNoteMeta: false
  },
  country: {
    requiredToList: true,
    importedFromTape: true,
    manageNotesSpreadsheetOmit: true,
    viewNotesSpreadsheetOmit: true,
    inNoteMeta: false
  },
  county: {
    requiredToList: false,
    importedFromTape: true,
    cellValueFormatter: toTitle,
    order: 23,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  },
  createdDate: {
    requiredToList: false,
    importedFromTape: false,
    label: 'Date Added',
    cellLabel: 'Added',
    valueFormatter: dateFormatter,
    order: 48,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false,
    viewNotesSpreadsheetOmit: true
  },
  delete: {
    requiredToList: false,
    importedFromTape: false,
    cellWidth: 100,
    order: 51,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false,
    viewNotesSpreadsheetOmit: true
  },
  sellerProvidedData: {
    requiredToList: false,
    importedFromTape: false,
    cellLabel: 'Edit',
    cellWidth: 100,
    order: 50,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false,
    viewNotesSpreadsheetOmit: true
  },
  escrowImpounds: {
    requiredToList: true,
    importedFromTape: true,
    label: 'Escrow/Impounds',
    cellLabel: 'Escrw/Impnds',
    valueFormatter: centsToUsdString,
    cellWidth: 140,
    order: 42,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  filesCount: {
    requiredToList: false,
    importedFromTape: false,
    cellLabel: 'Files',
    cellWidth: 85,
    order: 6,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  },
  floorSizeSqFt: {
    requiredToList: false,
    importedFromTape: false,
    cellLabel: 'Floor Size',
    valueFormatter: sqFtFormatter,
    order: 30,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  },
  fullAddress: {
    requiredToList: false,
    importedFromTape: false,
    fullAddress: 'Property Address',
    manageNotesSpreadsheetOmit: true,
    viewNotesSpreadsheetOmit: true,
    inNoteMeta: false
  },
  hasListing: {
    requiredToList: false,
    importedFromTape: false,
    label: 'Listing',
    order: 4,
    cellWidth: 150,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false,
    viewNotesSpreadsheetOmit: true
  },
  hasPoolListing: {
    requiredToList: false,
    importedFromTape: false,
    label: 'Pool Listing',
    order: 5,
    cellWidth: 150,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    viewNotesSpreadsheetOmit: true
  },
  history: {
    requiredToList: false,
    importedFromTape: false,
    cellWidth: 85,
    order: 49,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false,
    viewNotesSpreadsheetOmit: true
  },
  id: {
    requiredToList: false,
    importedFromTape: false,
    label: 'Paperstac ID',
    cellWidth: 210,
    order: 46,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  },
  interestRatePercent: {
    requiredToList: true,
    importedFromTape: true,
    label: 'Interest Rate',
    cellLabel: 'Int Rate',
    valueFormatter: percentFormatter,
    cellWidth: 95,
    order: 11,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  isHardestHitFundState: {
    requiredToList: false,
    importedFromTape: false,
    label: 'Hardest Hit Fund State',
    cellLabel: 'Hard Hit St',
    valueFormatter: booleanFormatter,
    order: 24,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  isJudicialState: {
    requiredToList: false,
    importedFromTape: false,
    label: 'Judicial State',
    cellLabel: 'Judicial St',
    valueFormatter: booleanFormatter,
    cellWidth: 115,
    order: 25,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  isNonJudicialState: {
    requiredToList: false,
    importedFromTape: false,
    label: 'Non-Judicial State',
    cellLabel: 'Non-Jdcl St',
    valueFormatter: booleanFormatter,
    cellWidth: 125,
    order: 26,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  lastImportDate: {
    requiredToList: false,
    importedFromTape: false,
    valueFormatter: dateFormatter,
    cellLabel: 'Imported',
    order: 47,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  lastPaymentReceivedDate: {
    requiredToList: false,
    importedFromTape: true,
    cellLabel: 'Last Pmt',
    valueFormatter: dateFormatter,
    order: 39,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  },
  lat: {
    requiredToList: false,
    importedFromTape: false,
    manageNotesSpreadsheetOmit: true,
    viewNotesSpreadsheetOmit: true,
    inNoteMeta: false
  },
  legalStatus: {
    requiredToList: true,
    importedFromTape: true,
    cellWidth: 125,
    order: 9,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  lienPosition: {
    requiredToList: true,
    importedFromTape: true,
    cellLabel: 'Lien Pos',
    cellWidth: 100,
    order: 10,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  lng: {
    requiredToList: false,
    importedFromTape: false,
    manageNotesSpreadsheetOmit: true,
    viewNotesSpreadsheetOmit: true,
    inNoteMeta: false
  },
  loanId: {
    requiredToList: false,
    importedFromTape: true,
    label: 'Loan ID',
    cellWidth: 100,
    order: 45,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  },
  loanToValuePercent: {
    requiredToList: false,
    importedFromTape: false,
    label: 'Loan to Value',
    cellLabel: 'LTV',
    valueFormatter: percentFormatter,
    cellWidth: 75,
    order: 16,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  loanToZestimatePercent: {
    requiredToList: false,
    importedFromTape: false,
    label: 'Loan to Zestimate',
    cellLabel: 'Loan to Zest',
    valueFormatter: percentFormatter,
    order: 18,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  lotSizeSqFt: {
    requiredToList: false,
    importedFromTape: false,
    cellLabel: 'Lot Size',
    valueFormatter: sqFtFormatter,
    order: 31,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  },
  maturityDate: {
    requiredToList: true,
    importedFromTape: true,
    cellLabel: 'Maturity',
    valueFormatter: dateFormatter,
    order: 40,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  },
  nextPaymentDate: {
    requiredToList: true,
    importedFromTape: true,
    cellLabel: 'Next Pmt',
    valueFormatter: dateFormatter,
    order: 38,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  },
  noteType: {
    requiredToList: true,
    importedFromTape: false,
    label: 'Note Type',
    cellLabel: 'Note Type',
    cellWidth: 120,
    order: 8,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  occupancyStatus: {
    requiredToList: true,
    importedFromTape: true,
    cellLabel: 'Occupancy',
    order: 27,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  },
  originalBalance: {
    requiredToList: true,
    importedFromTape: true,
    cellLabel: 'Orig Bal',
    valueFormatter: centsToUsdString,
    order: 13,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  originationDate: {
    requiredToList: true,
    importedFromTape: true,
    cellLabel: 'Origination',
    valueFormatter: dateFormatter,
    order: 41,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  },
  paymentHistory: {
    requiredToList: false,
    importedFromTape: false,
    cellLabel: 'Pmt Hist',
    order: 5.7,
    cellWidth: 90,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  },
  paymentsRemaining: {
    requiredToList: false,
    importedFromTape: false,
    cellLabel: 'Pmt Remain',
    valueFormatter: function valueFormatter(val) {
      return isNaN(val) ? '??' : val;
    },
    order: 37,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  performance: {
    requiredToList: true,
    importedFromTape: true,
    label: 'Performance (PL/NPL)',
    cellLabel: 'PL/NPL',
    valueFormatter: function valueFormatter(value) {
      return value === NOTE_TAPE_PERFORMANCE_PERFORMING ? 'Performing' : 'Non-Performing';
    },
    cellValueFormatter: function cellValueFormatter(value) {
      return value === NOTE_TAPE_PERFORMANCE_PERFORMING ? 'PL' : 'NPL';
    },
    cellWidth: 90,
    order: 8,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  photoUrl: {
    requiredToList: false,
    importedFromTape: false,
    cellLabel: 'Photo',
    cellWidth: 40,
    order: 1,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: true,
    inNoteMeta: false
  },
  photosCount: {
    requiredToList: false,
    importedFromTape: false,
    cellLabel: 'Photos',
    cellWidth: 85,
    order: 7,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false,
    viewNotesSpreadsheetOmit: true
  },
  principalAndInterestPayment: {
    requiredToList: true,
    importedFromTape: true,
    valueFormatter: centsToUsdString,
    cellLabel: 'P&I Pmt',
    cellWidth: 100,
    order: 35,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  propertyType: {
    requiredToList: true,
    importedFromTape: true,
    cellLabel: 'Prop Type',
    order: 28,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  },
  propertyValue: {
    requiredToList: false,
    importedFromTape: false,
    cellLabel: 'Prop Val',
    valueFormatter: centsToUsdString,
    cellWidth: 130,
    order: 15,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  rentZestimate: {
    requiredToList: false,
    importedFromTape: false,
    cellLabel: 'Rent Zest',
    valueFormatter: centsToUsdString,
    order: 18,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  sellerProvidedCustomData: {
    requiredToList: false,
    importedFromTape: false,
    cellLabel: 'Custom Data',
    order: 7.5,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  },
  servicer: {
    requiredToList: false,
    importedFromTape: true,
    cellWidth: 150,
    order: 44,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  },
  state: {
    requiredToList: true,
    importedFromTape: true,
    cellLabel: 'St',
    cellWidth: 60,
    valueFormatter: function valueFormatter(val) {
      return getNameFromCode(val);
    },
    cellValueFormatter: function cellValueFormatter(val) {
      return val;
    },
    order: 21,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  streetAddress: {
    requiredToList: false,
    importedFromTape: false,
    cellValueFormatter: toTitle,
    cellWidth: 180,
    order: 2,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: true,
    inNoteMeta: false
  },
  streetAddress1: {
    requiredToList: true,
    importedFromTape: true,
    label: 'Street Address',
    manageNotesSpreadsheetOmit: true,
    viewNotesSpreadsheetOmit: true,
    inNoteMeta: false
  },
  streetAddress2: {
    requiredToList: false,
    importedFromTape: true,
    label: 'Apt, Unit, etc',
    manageNotesSpreadsheetOmit: true,
    viewNotesSpreadsheetOmit: true,
    inNoteMeta: false
  },
  totalMonthlyLoanPayment: {
    requiredToList: true,
    importedFromTape: true,
    cellLabel: 'Monthly Pmt',
    valueFormatter: centsToUsdString,
    cellWidth: 130,
    order: 36,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  totalPayoff: {
    requiredToList: false,
    importedFromTape: true,
    valueFormatter: centsToUsdString,
    cellWidth: 125,
    order: 34,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  transactions: {
    requiredToList: false,
    importedFromTape: false,
    order: 5.5,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    manageNotesSpreadsheetOmit: false,
    viewNotesSpreadsheetOmit: true,
    inNoteMeta: false
  },
  upb: {
    requiredToList: true,
    importedFromTape: true,
    label: 'Unpaid Principal Balance',
    cellLabel: 'Principal',
    valueFormatter: centsToUsdString,
    order: 12,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  trustedSource: {
    requiredToList: false,
    importedFromTape: true,
    label: 'Trusted Source',
    cellLabel: 'Trusted',
    valueFormatter: booleanFormatter,
    order: 43,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  yearBuilt: {
    requiredToList: false,
    importedFromTape: false,
    cellLabel: 'Built',
    cellWidth: 75,
    order: 29,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  },
  zestimate: {
    requiredToList: false,
    importedFromTape: false,
    cellLabel: 'Zestimate',
    valueFormatter: centsToUsdString,
    order: 17,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: true
  },
  zillowUrl: {
    requiredToList: false,
    importedFromTape: false,
    cellLabel: 'Zillow',
    cellWidth: 85,
    order: 19,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  },
  zip: {
    requiredToList: true,
    importedFromTape: true,
    cellWidth: 75,
    order: 22,
    spreadsheetViewEnabled: true,
    spreadsheetViewPinned: false,
    inNoteMeta: false
  }
};