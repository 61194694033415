export var getDomains = function () { return ({
    NOTECLOSINGS: 'noteclosings.com',
    PAPERSTAC: 'paperstac.com',
    PARTNERS: 'partners.paperstac.com',
}); };
export var getDomainFromBaseUrl = function (baseUrl) {
    var domains = getDomains();
    var hostname = new URL(baseUrl).hostname;
    if (hostname.includes('partners'))
        return domains.PARTNERS;
    if (hostname.includes('noteclosings'))
        return domains.NOTECLOSINGS;
    return domains.PAPERSTAC;
};
export var getBaseUrlFromDomain = function (domain, env) {
    var domains = getDomains();
    if (domain === domains.NOTECLOSINGS)
        return env.BASE_NOTECLOSINGS_URL;
    if (domain === domains.PARTNERS)
        return env.BASE_PARTNERS_URL;
    return env.BASE_URL;
};
