import { UPDATE_LISTING_NOTES } from '@paperstac/common/lib/serverDispatchActionTypes';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import useBoolean from '@paperstac/hooks/lib/useBoolean';
import Button from '@paperstac/ui/lib/Button';
import DescriptionGrid from '@paperstac/ui/lib/DescriptionGrid';
import DescriptionGridCell from '@paperstac/ui/lib/DescriptionGridCell';
import Flex from '@paperstac/ui/lib/Flex';
import ChevronRightIcon from '@paperstac/ui/lib/icons/ChevronRightIcon';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import React from 'react';
import NoteBulkChooser from '../NoteBulkChooser/NoteBulkChooser';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';

const SelectNotesScreen = React.memo(() => {
  const { listing, notes: allNotes, showOverview: showListing } = React.useContext(ManageListingOverlayContext);
  const notes = React.useMemo(() => {
    if (!allNotes) return [];
    return allNotes
      .filter((note) => !note.poolListingId || note.poolListingId === listing.id)
      .sort((a, b) => {
        if (a.poolListingId === listing.id && b.poolListingId !== listing.id) return -1;
        if (a.poolListingId !== listing.id && b.poolListingId === listing.id) return 1;
        return a.fullAddress < b.fullAddress ? -1 : 1;
      });
  }, [allNotes, listing.id]);
  const [noteIds, setNoteIds] = React.useState(listing.noteIds);
  const [isSubmitting, setSubmitting, unsetSubmitting] = useBoolean(false);
  const handleAdd = React.useCallback((id) => setNoteIds([...noteIds, id]), [noteIds]);
  const handleRemove = React.useCallback((id) => setNoteIds(noteIds.filter((noteId) => noteId !== id)), [noteIds]);
  const handleSubmit = React.useCallback(() => {
    if (isSubmitting) return;
    setSubmitting();
    serverDispatch({
      action: UPDATE_LISTING_NOTES,
      payload: { listingId: listing.id, noteIds },
    })
      .then(showListing)
      .catch((e) => {
        alert(e.message);
        unsetSubmitting();
      });
  }, [isSubmitting, listing.id, noteIds, setSubmitting, showListing, unsetSubmitting]);

  return (
    <DescriptionGrid
      sx={{
        bg: 'gray.1',
        border: 'none',
        borderTop: 'default',
        borderBottom: 'default',
        borderRadius: 0,
      }}
    >
      <DescriptionGridCell bg="highlight">
        <Flex alignItems="center">
          <LinkButton onClick={showListing}>Listing</LinkButton>
          <ChevronRightIcon size={12} mx={2} color="gray.6" />
          Select Notes
        </Flex>
      </DescriptionGridCell>
      <DescriptionGridCell>
        <NoteBulkChooser
          chosen={noteIds}
          label="Choose assets for listing..."
          notes={notes}
          onAdd={handleAdd}
          onRemove={handleRemove}
        />
      </DescriptionGridCell>
      <DescriptionGridCell sx={{ borderBottom: 'none' }}>
        <Button onClick={handleSubmit} variant="primary" busy={isSubmitting} mr={2}>
          Save Notes
        </Button>
        <Button onClick={showListing} variant="default">
          Cancel
        </Button>
      </DescriptionGridCell>
    </DescriptionGrid>
  );
});

SelectNotesScreen.propTypes = {};

SelectNotesScreen.defaultProps = {};

SelectNotesScreen.displayName = 'SelectNotesScreen';

export default SelectNotesScreen;
