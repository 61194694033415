import { darken, em, lighten, mix, rem, transparentize } from 'polished';
var salmon = '#ee684d';
var blue = '#047bb7';
var darkBlue = '#233844';
var gray0 = '#ffffff';
var gray1 = '#f5f8f9';
var gray2 = '#e8eef0';
var gray3 = '#d7e0e2';
var gray4 = '#c3cdd0';
var gray5 = '#acb7ba';
var gray6 = '#919da0';
var gray7 = '#747e81';
var gray8 = '#555d5f';
var gray9 = '#35393a';
var gray10 = '#141414';
var white = gray0;
var black = gray10;
var primary = salmon;
var secondary = blue;
var green = '#5cb85c';
var red = '#d9534f';
var highlight = '#fffce9';
var pink = '#f92672';
var fontSizes = [rem(12), rem(14), rem(16), rem(20), rem(24), rem(32), rem(48), rem(64)];
fontSizes.body = fontSizes[2];
fontSizes.subtitle = fontSizes[4];
fontSizes.title = fontSizes[5];
fontSizes.mobileSubtitle = fontSizes[2];
fontSizes.mobileTitle = fontSizes[3];
var breakpoints = [em(600), em(769), em(1280), em(1920)];
breakpoints.mobile = 0; // 0-599

breakpoints.tablet = breakpoints[0]; // 600-768

breakpoints.wideTablet = breakpoints[1]; // 769-1279

breakpoints.desktop = breakpoints[2]; // 1280-1919

breakpoints.wideDesktop = breakpoints[3]; // 1920+

export default {
  colors: {
    white: white,
    // https://gka.github.io/palettes/#colors=#fff,#e5eff2,#93a7ad,141414|steps=11|bez=1|coL=0
    gray: [gray0, gray1, gray2, gray3, gray4, gray5, gray6, gray7, gray8, gray9, gray10],
    black: black,
    blue: blue,
    heading: gray9,
    text: gray8,
    background: gray2,
    secondary: secondary,
    secondaryLighten: lighten(0.1, secondary),
    secondaryDarken: darken(0.1, secondary),
    darkBlue: darkBlue,
    pink: pink,
    primary: primary,
    primaryLighten: lighten(0.1, primary),
    primaryDarken: darken(0.1, primary),
    green: green,
    success: green,
    red: red,
    error: red,
    highlight: highlight
  },
  fonts: {
    body: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    heading: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    monospace: 'Menlo, monospace'
  },
  fontSizes: fontSizes,
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1.2
  },
  space: [rem(0), rem(4), rem(8), rem(16), rem(24), rem(32), rem(64), rem(128), rem(256)],
  sizes: {
    avatar: 48,
    containerMax: breakpoints.wideDesktop
  },
  radii: {
    corner: rem(3),
    round: rem(10),
    rounder: rem(20),
    circle: '50%'
  },
  shadows: {
    hover: '0 10px 30px -15px rgba(0, 0, 0, .3)',
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)'
  },
  breakpoints: breakpoints,
  borders: {
    none: "0px none ".concat(white),
    default: "1px solid ".concat(gray3),
    dotted: "1px dotted ".concat(gray8),
    error: "1px solid ".concat(red),
    link: "1px solid ".concat(secondary),
    bold: "2px solid ".concat(gray6),
    thickTransparentWhite: "7px solid rgba(255, 255, 255, .25)",
    timeline: "3px solid ".concat(gray3)
  },
  variants: {
    link: {
      color: 'secondary',
      textDecoration: 'none',
      '&:hover,&:focus': {
        color: 'secondaryDarken'
      }
    },
    nav: {
      fontSize: 1,
      fontWeight: 'bold',
      display: 'inline-block',
      p: 2,
      color: 'inherit',
      textDecoration: 'none',
      ':hover,:focus,.active': {
        color: 'primary'
      }
    },
    backdrop: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100%',
      overflowY: 'auto',
      zIndex: 1000,
      bg: transparentize(0.1, darkBlue)
    }
  },
  text: {
    code: {
      display: 'inline',
      fontFamily: 'monospace',
      color: 'pink'
    },
    bold: {
      display: 'inline',
      fontWeight: 'bold'
    },
    italic: {
      display: 'inline',
      fontStyle: 'italic'
    },
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading'
    },
    display: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      fontSize: [5, 6, 7]
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.1em'
    },
    mobilePageTitle: {
      as: 'h1',
      fontSize: 'mobileTitle',
      mb: 5,
      textAlign: 'center'
    },
    pageTitle: {
      as: 'h1',
      fontSize: 'title',
      mb: 5
    }
  },
  buttons: {
    default: {
      color: gray9,
      backgroundColor: gray4,
      '&:hover': {
        backgroundColor: gray5
      },
      '&::before': {
        borderColor: gray9,
        borderTopColor: gray5
      }
    },
    outline: {
      color: gray8,
      backgroundColor: 'transparent',
      boxShadow: "inset 0 0 0 2px ".concat(gray8),
      '&:hover': {
        color: white,
        backgroundColor: gray8
      },
      '&::before': {
        borderColor: gray9,
        borderTopColor: gray5
      }
    },
    primary: {
      color: white,
      backgroundColor: primary,
      '&:hover': {
        color: white,
        backgroundColor: darken(0.05, primary)
      },
      '&::before': {
        borderColor: white,
        borderTopColor: darken(0.05, primary)
      }
    },
    primaryOutline: {
      color: primary,
      backgroundColor: 'transparent',
      boxShadow: "inset 0 0 0 2px ".concat(primary),
      '&:hover': {
        color: white,
        backgroundColor: primary
      },
      '&::before': {
        borderColor: white,
        borderTopColor: darken(0.05, primary)
      }
    },
    secondary: {
      color: white,
      backgroundColor: secondary,
      '&:hover': {
        color: white,
        backgroundColor: darken(0.05, secondary)
      },
      '&::before': {
        borderColor: white,
        borderTopColor: darken(0.05, secondary)
      }
    },
    secondaryOutline: {
      color: secondary,
      backgroundColor: 'transparent',
      boxShadow: "inset 0 0 0 2px ".concat(secondary),
      '&:hover': {
        color: white,
        backgroundColor: secondary
      },
      '&::before': {
        borderColor: white,
        borderTopColor: darken(0.05, secondary)
      }
    },
    green: {
      color: white,
      backgroundColor: green,
      '&:hover': {
        color: white,
        backgroundColor: darken(0.05, green)
      },
      '&::before': {
        borderColor: white,
        borderTopColor: darken(0.05, green)
      }
    },
    greenOutline: {
      color: green,
      backgroundColor: 'transparent',
      boxShadow: "inset 0 0 0 2px ".concat(green),
      '&:hover': {
        color: white,
        backgroundColor: green
      },
      '&::before': {
        borderColor: white,
        borderTopColor: darken(0.05, green)
      }
    },
    red: {
      color: white,
      backgroundColor: red,
      '&:hover': {
        color: white,
        backgroundColor: darken(0.05, red)
      },
      '&::before': {
        borderColor: white,
        borderTopColor: darken(0.05, red)
      }
    },
    redOutline: {
      color: red,
      backgroundColor: 'transparent',
      boxShadow: "inset 0 0 0 2px ".concat(red),
      '&:hover': {
        color: white,
        backgroundColor: red
      },
      '&::before': {
        borderColor: white,
        borderTopColor: darken(0.05, red)
      }
    },
    white: {
      color: darkBlue,
      backgroundColor: white,
      '&:hover': {
        color: darkBlue,
        backgroundColor: darken(0.05, white)
      },
      '&::before': {
        borderColor: darkBlue,
        borderTopColor: darken(0.05, white)
      }
    },
    whiteOutline: {
      color: white,
      backgroundColor: 'transparent',
      boxShadow: "inset 0 0 0 2px ".concat(white),
      '&:hover': {
        color: darkBlue,
        backgroundColor: white
      },
      '&::before': {
        borderColor: darkBlue,
        borderTopColor: darken(0.05, white)
      }
    }
  },
  dropzone: {
    default: {
      border: "3px dashed ".concat(gray5)
    },
    active: {
      border: "3px dashed ".concat(blue),
      bg: highlight
    },
    attention: {
      border: "3px dashed ".concat(red)
    }
  },
  listingHeader: {
    active: {
      border: "1px solid ".concat(mix(0.6, white, green)),
      bg: mix(0.8, white, green)
    },
    inactive: {
      border: "1px solid ".concat(gray4),
      bg: gray3
    },
    attention: {
      border: "1px solid ".concat(mix(0.6, white, red)),
      bg: mix(0.8, white, red)
    },
    submitting: {
      border: "1px solid ".concat(mix(0.7, white, blue)),
      bg: mix(0.87, white, blue)
    }
  },
  listGroupItems: {
    active: {
      cursor: 'default',
      color: white,
      backgroundColor: secondary,
      'a&, button&': {
        '&:hover': {
          color: white
        }
      }
    },
    default: {
      cursor: 'default',
      backgroundColor: white,
      '&[disabled]': {
        cursor: 'not-allowed',
        color: gray5
      },
      'a&, button&': {
        backgroundColor: white,
        color: secondary,
        '&[disabled], &[disabled]:hover': {
          cursor: 'not-allowed',
          color: gray5,
          backgroundColor: white
        },
        '&:hover': {
          cursor: 'pointer',
          color: darken(0.1, secondary),
          backgroundColor: highlight
        }
      }
    }
  },
  zIndex: {
    DropdownContent: 1001,
    Overlay: 1000
  }
};