import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import "firebase/compat/performance";
import 'firebase/compat/storage';
import { setFirebase } from './firebase';
import { setPerformance } from './performance';
export var init = function init(env) {
  if (!firebase.apps.length) {
    var options = {
      apiKey: env.FIREBASE_API_KEY,
      authDomain: env.FIREBASE_AUTH_DOMAIN,
      databaseURL: env.FIREBASE_DATABASE_URL,
      projectId: env.FIREBASE_PROJECT_ID,
      storageBucket: env.FIREBASE_STORAGE_BUCKET,
      messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID,
      appId: env.FIREBASE_APP_ID
    };
    if (env.FIREBASE_MEASURE_ID) options.measurementId = env.FIREBASE_MEASURE_ID;
    firebase.initializeApp(options);
  }

  setFirebase(firebase); // Initialize Performance Monitoring

  if (navigator && navigator.userAgent !== 'ReactSnap') setPerformance(firebase.performance()); // Initialize Analytics

  if (env.FIREBASE_MEASURE_ID) firebase.analytics();
};
export default firebase;