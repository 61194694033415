import { notePropertyLabelsMap, transformNotePropertyValue } from '@paperstac/common/lib/services/notesHelper';
import Box from '@paperstac/ui/lib/Box';
import DescriptionGridCell from '@paperstac/ui/lib/DescriptionGridCell';
import Flex from '@paperstac/ui/lib/Flex';
import React from 'react';
import EditButton from './EditButton';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';
import { NoteScreenContext } from './NoteScreen';

const ALL_PROPS = ['seniorPerformance', 'seniorUpb', 'seniorInterestRatePercent', 'seniorTotalMonthlyLoanPayment'];

const NoteScreenViewSeniorDetails = React.memo(() => {
  const { note, stacked } = React.useContext(ManageListingOverlayContext);
  const { showSeniorDetails } = React.useContext(NoteScreenContext);

  return <DescriptionGridCell term="Senior Loan Details">
    <Flex alignItems="center" mt={3}>
      <Box flex={'1 1 0'} mr={stacked ? 3 : 5}>
        {ALL_PROPS.map(property =>
          <Flex key={property} justifyContent="space-between" mt={1} fontSize={stacked ? 1 : 2}>
            <Box>{notePropertyLabelsMap[property]}:</Box>
            <Box fontWeight="bold">{transformNotePropertyValue(property, note[property])}</Box>
          </Flex>)}
      </Box>
      <Box><EditButton onClick={showSeniorDetails} /></Box>
    </Flex>
  </DescriptionGridCell>;
});

NoteScreenViewSeniorDetails.propTypes = {};

NoteScreenViewSeniorDetails.defaultProps = {};

NoteScreenViewSeniorDetails.displayName = 'NoteScreenViewSeniorDetails';

export default NoteScreenViewSeniorDetails;
