import Box from '@paperstac/ui/lib/Box';
import Heading from '@paperstac/ui/lib/Heading';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';
import { ListingPageContext } from './ListingPageProvider';
import Section from './Section';

const SellerCommentsSection = React.memo(() => {
  const { listing, width } = React.useContext(ListingPageContext);
  const smallTitle = width < 640;

  if (!listing.title && !listing.sellerComments) return null;

  return <Section>
    <Heading as="h2" sx={{
      fontSize: smallTitle ? 3 : 'subtitle',
      textAlign: smallTitle ? 'center' : 'left',
      mb: 3
    }}>Seller Comments</Heading>
    {!!listing.title && <Box mb={!!listing.sellerComments ? 3 : 0}><Text variant="bold">{listing.title}</Text></Box>}
    {!!listing.sellerComments && <Text nl2br linkify>{listing.sellerComments}</Text>}
  </Section>;
});

SellerCommentsSection.propTypes = {};

SellerCommentsSection.defaultProps = {};

SellerCommentsSection.displayName = 'SellerCommentsSection';

export default SellerCommentsSection;
