import { firestore, TIMESTAMP } from '../services/firebaseClient';
import dateid from '../utils/dateid';
import sortItems from '../utils/sortItems';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const SET_SAVED_SEARCHES = 'SET_SAVED_SEARCHES';

const transformCollection = querySnapshot => {
  return querySnapshot.docs.map(transformDoc);
};

const transformDoc = docSnap => {
  return docSnap.exists ? transformFirestoreTimestamps(docSnap) : null;
};

export const setSavedSearches = payload => {
  return { type: SET_SAVED_SEARCHES, payload };
};

export const createSavedSearchRequest = data => {
  const payload = {
    id: dateid(),
    createdDate: TIMESTAMP,
    ...data
  };
  return dispatch => {
    return firestore.doc(`savedSearches/${payload.id}`).set(payload);
  };
};

export const updateSavedSearchRequest = payload => {
  return dispatch => {
    return firestore.doc(`savedSearches/${payload.id}`).update(payload);
  };
};

export const deleteSavedSearchRequest = payload => {
  return dispatch => {
    return firestore.doc(`savedSearches/${payload.id}`).delete();
  };
};

export const subscribeSavedSearches = accountId => {
  return dispatch => {
    return firestore
      .collection('savedSearches')
      .where('accountId', '==', accountId)
      .onSnapshot(querySnapshot => {
        dispatch(setSavedSearches(sortItems(transformCollection(querySnapshot), 'title')));
      });
  };
};
