import Box from '@paperstac/ui/lib/Box';
import Flex from '@paperstac/ui/lib/Flex';
import Heading from '@paperstac/ui/lib/Heading';
import Text from '@paperstac/ui/lib/Text';
import PropTypes from 'prop-types';
import React from 'react';

const Ratio = React.memo(({ title, option1, option2 }) => {
  return <Box mb={2} mx="auto" style={{ maxWidth: '700px' }}>
    <Heading as="h3" fontSize={[16, 24]} mb={2} color="darkBlue" textAlign="center">{title}</Heading>
    <Flex mb={2}>
      <Box bg="blue" width={option1.value} style={{
        height: '30px',
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px'
      }} />
      <Box bg="darkBlue" width={option2.value} style={{
        height: '30px',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px'
      }} />
    </Flex>
    <Flex>
      <Text textAlign="left" flex="1">
        <Heading as="h4" fontSize={[16, 20]}>{option1.label}</Heading>
        <Text fontSize={[14, 16]}><strong>{option1.value}</strong>{' '}{option1.suffix}</Text>
      </Text>
      <Text textAlign="right" flex="1">
        <Heading as="h4" fontSize={[16, 20]}>{option2.label}</Heading>
        <Text fontSize={[14, 16]}><strong>{option2.value}</strong>{' '}{option2.suffix}</Text>
      </Text>
    </Flex>
  </Box>
});

Ratio.propTypes = {
  option1: PropTypes.object.isRequired,
  option2: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

Ratio.defaultProps = {};

Ratio.displayName = 'Ratio';

export default Ratio;