import { APP_ENV, GIT_HASH } from '@paperstac/env';
import { matchPath } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import * as webRoutes from '@paperstac/routes/lib/webRoutes';

const history = createBrowserHistory();
const routes = Object.values(webRoutes).map((path) => ({ path }));

Sentry.init({
  dsn: 'https://eb8dfbca4baa4fc5b82449b923c52195@sentry.io/258282',
  environment: APP_ENV,
  release: GIT_HASH,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routes, matchPath),
    }),
  ],
  maxBreadcrumbs: 20,
  tracesSampleRate: 0.2,
});

export default Sentry;

export { history };
