import { getOrdinal } from '@paperstac/helpers/lib/numberHelpers';
import Box from '@paperstac/ui/lib/Box';
import Checkbox from '@paperstac/ui/lib/Checkbox';
import Flex from '@paperstac/ui/lib/Flex';
import LeftDownArrowIcon from '@paperstac/ui/lib/icons/LeftDownArrowIcon';
import PropTypes from 'prop-types';
import React from 'react';
import useDimensions from 'react-use-dimensions';

const WIDE_LAYOUT = 500;

const NoteBulkChooser = React.memo(({ chosen, label, notes, onAdd, onRemove, ...props }) => {
  const [ref, { width }] = useDimensions();
  const stacked = width < WIDE_LAYOUT;

  return <Box ref={ref} {...props}>
    <Flex alignItems="center" color="primary" ml={1} mb={2}>
      <LeftDownArrowIcon sx={{ mr: 2 }} />
      <Box>{label}</Box>
    </Flex>
    {notes.map((note, i) => <Flex key={note.id} mt={i > 0 ? 2 : 0} alignItems="center">
      <Box mr={2}>
        <Checkbox
          onUncheck={() => onRemove(note.id)}
          onCheck={() => onAdd(note.id)}
          checked={!!chosen.find(id => id === note.id)}
        />
      </Box>
      <Flex>
        <Flex sx={{
          bg: note.lienPosition === 1 ? 'gray.4' : 'gray.7',
          color: note.lienPosition === 1 ? 'gray.9' : 'white',
          borderRadius: 'corner',
          fontSize: 1,
          textAlign: 'center',
          width: 36,
          mr: 2,
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 'bold'
        }}>{getOrdinal(note.lienPosition)}</Flex>
        <Flex flexDirection={stacked ? 'column' : 'row'} alignItems={stacked ? 'normal' : 'center'}>
          <Box fontSize={2} mr={stacked ? 0 : 2}>{note.streetAddress}</Box>
          <Box fontSize={1} color="gray.6">{note.cityStateZip}</Box>
        </Flex>
      </Flex>
    </Flex>)}
  </Box>;
});

NoteBulkChooser.propTypes = {
  chosen: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  notes: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

NoteBulkChooser.defaultProps = {
  chosen: [],
  label: 'Select assets to add...',
  notes: [],
};

NoteBulkChooser.displayName = 'NoteBulkChooser';

export default NoteBulkChooser;
