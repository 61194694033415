import shuffle from 'lodash/shuffle';
import PropTypes from 'prop-types';
import React from 'react';
import testimonials from '../../../data/testimonials';

export const AUTH_OVERLAY_VIEWS = {
  CHOOSE_ACCOUNT: 'CHOOSE_ACCOUNT',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  CREATE_IDENTITY: 'CREATE_IDENTITY',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  LOGIN: 'LOGIN',
  PASSWORD_RESET: 'PASSWORD_RESET',
  RESET_PASSWORD: 'RESET_PASSWORD',
};

const fetchTestimonial = () => shuffle(testimonials)[0];

const AuthOverlayContext = React.createContext();
const { Provider } = AuthOverlayContext;

const AuthOverlayProvider = React.memo((props) => {
  const [view, setView] = React.useState(props.view);
  const [testimonial, setTestimonial] = React.useState(fetchTestimonial());
  const [showingTerms, setShowingTerms] = React.useState(false);
  const [showingPrivacyPolicy, setShowingPrivacyPolicy] = React.useState(false);

  React.useEffect(() => {
    setView(props.view);
  }, [props.view]);

  const providerValue = React.useMemo(
    () => ({
      view,
      testimonial,
      showingTerms,
      showingPrivacyPolicy,
      setView: (view) => setView(view),
      showTerms: () => setShowingTerms(true),
      hideTerms: () => setShowingTerms(false),
      showPrivacyPolicy: () => setShowingPrivacyPolicy(true),
      hidePrivacyPolicy: () => setShowingPrivacyPolicy(false),
      refreshTestimonial: () => setTestimonial(fetchTestimonial()),
    }),
    [view, testimonial, showingTerms, showingPrivacyPolicy]
  );

  return <Provider value={providerValue} children={props.children} />;
});

AuthOverlayProvider.propTypes = {
  children: PropTypes.element.isRequired,
  view: PropTypes.oneOf(Object.values(AUTH_OVERLAY_VIEWS)).isRequired,
};

AuthOverlayProvider.defaultProps = {};

AuthOverlayProvider.displayName = 'AuthOverlayProvider';

export default AuthOverlayProvider;

export { AuthOverlayContext };
