import classNames from 'classnames';
import React from 'react';
import './Table.css';

export default ({ children, className, bordered, condensed, ...props }) => (
  <table
    className={classNames('Table', className, {
      'Table--bordered': bordered,
      'Table--condensed': condensed
    })}
    {...props}
  >
    <tbody>{children}</tbody>
  </table>
);
