import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import useFirestoreSubscribe from '@paperstac/common/lib/hooks/useFirestoreSubscribe';
import { PAPERSTAC_SUPPORT_URL } from '@paperstac/env';
import { newestPublishedBlogPostsRef } from '@paperstac/firestore-collections/lib/blogPosts';
import getPath from '@paperstac/routes/lib/getPath';
import {
  ABOUT,
  ADMIN,
  BLOG_POSTS_SINGLE,
  FOR_SALE_ARCHIVE,
  HOME,
  LISTINGS_ARCHIVE,
  LOGIN,
  PRIVACY_POLICY,
  SELLER_ONBOARDING,
  TERMS,
  TRANSACTIONS_ARCHIVE
} from '@paperstac/routes/lib/webRoutes';
import Box from '@paperstac/ui/lib/Box';
import Container from '@paperstac/ui/lib/Container';
import Flex from '@paperstac/ui/lib/Flex';
import Heading from '@paperstac/ui/lib/Heading';
import useBreakpoints from '@paperstac/ui/lib/hooks/useBreakpoints';
import PaperstacIcon from '@paperstac/ui/lib/icons/PaperstacIcon';
import Image from '@paperstac/ui/lib/Image';
import Link from '@paperstac/ui/lib/Link';
import Text from '@paperstac/ui/lib/Text';
import format from "date-fns/format";
import React from 'react';
import styled from 'styled-components';
import { maxWidth, minWidth } from 'styled-system';

const formatDate = fsDate => format(fsDate.toDate(), 'MMMM D, YYYY, h:mma');
const year = new Date().getFullYear();

const Footer = React.memo(props => {
  const [posts, postsLastUpdated] = useFirestoreSubscribe(newestPublishedBlogPostsRef().limit(2));
  const { isDesktopOrLarger } = useBreakpoints();
  const { claims } = React.useContext(IdentityContext);
  const { currentAccount } = React.useContext(AccountsContext);
  const isLoggedIn = !!currentAccount;
  const isSeller = isLoggedIn && currentAccount.completedSellerOnboarding;

  const links = [
    { children: 'Home', to: HOME },
    { children: 'For Sale', to: FOR_SALE_ARCHIVE },
    { children: 'About Us', to: ABOUT }
  ];
  if (isLoggedIn) links.push({ children: 'My Transactions', to: TRANSACTIONS_ARCHIVE });
  if (isLoggedIn && !isSeller) links.push({ children: 'Become a Seller', to: SELLER_ONBOARDING });
  if (isSeller) links.push({ children: 'My Listings', to: LISTINGS_ARCHIVE });
  if (!isLoggedIn) links.push({ children: 'Login', to: LOGIN });
  links.push({ children: 'Knowledge Base', href: PAPERSTAC_SUPPORT_URL, target: '_blank', rel: 'noopener nofollow' });
  if (!!claims && claims.admin) links.push({ children: 'Admin', to: ADMIN });


  return <Box bg="darkBlue" py={[40]}>
    <Container>
      <Flex justifyContent="space-around" flexWrap={['wrap']}>
        <Column>
          <Heading fontSize={16} color="gray.4" mb={[2, 4]} style={{ textTransform: 'uppercase' }}>Explore</Heading>
          <Flex flexDirection="column">
            {links.map((props, i) =>
              <Box key={i} mb={1}><FooterLink {...props} /></Box>)}
          </Flex>
        </Column>
        <Column>
          <Heading fontSize={16} color="gray.4" mb={[2, 4]} style={{ textTransform: 'uppercase' }}>Follow Us</Heading>
          <Flex flexDirection="column">
            <Box mb={1}><FooterLink as="a" href="https://pstac.co/facebook" target="_blank" rel="noopener noreferrer">Facebook</FooterLink></Box>
            <Box mb={1}><FooterLink as="a" href="https://pstac.co/twitter" target="_blank" rel="noopener noreferrer">Twitter</FooterLink></Box>
            <Box mb={1}><FooterLink as="a" href="https://pstac.co/youtube" target="_blank" rel="noopener noreferrer">YouTube</FooterLink></Box>
            <Box mb={1}><FooterLink as="a" href="https://pstac.co/linkedin" target="_blank" rel="noopener noreferrer">LinkedIn</FooterLink></Box>
            <Box mb={1}><FooterLink as="a" href="https://pstac.co/instagram" target="_blank" rel="noopener noreferrer">Instagram</FooterLink></Box>
            <Box><FooterLink as="a" href="https://pstac.co/podcast" target="_blank" rel="noopener noreferrer">Podcast</FooterLink></Box>
          </Flex>
        </Column>
        <Column width={['100%', 'auto']}>
          <Heading fontSize={16} color="gray.4" mb={[2, 4]} style={{ textTransform: 'uppercase' }} textAlign={['center', 'left']}>Contact</Heading>
          <Flex flexDirection="column">
            <Box><Text fontWeight={700} fontSize={16} color="gray.4" textAlign={['center', 'left']}>Paperstac</Text></Box>
            <Box><Text fontSize={14} color="gray.4" textAlign={['center', 'left']}>300 S Orange Ave, Suite 1000</Text></Box>
            <Box mb={[1, 2]}><Text fontSize={14} color="gray.4" textAlign={['center', 'left']}>Orlando, FL 32801</Text></Box>
            <Flex mb={[1, 2]} justifyContent={['center', 'flex-start']}><FooterLink as="a" href="mailto:hello@paperstac.com" target="_blank">hello@paperstac.com</FooterLink></Flex>
            <Box><Text fontSize={16} color="gray.4" textAlign={['center', 'left']}>407-930-9749</Text></Box>
          </Flex>
        </Column>
        {isDesktopOrLarger && postsLastUpdated && <Column minWidth={400} maxWidth={500}>
          <Heading fontSize={16} color="gray.4" mb={[2, 4]} style={{ textTransform: 'uppercase' }}>Latest News</Heading>
          {posts.map((post, i) => <Post key={i} post={post} />)}
        </Column>}
      </Flex>
      <Flex justifyContent="center" mb={3}><PaperstacIcon color="gray.4" size={50} /></Flex>
      <Text textAlign="center" color="gray.4" mb={3}>Copyright &copy; {year} Paperstac Inc.<br />All rights reserved.</Text>
      <Flex justifyContent="center">
        <FooterLink to={PRIVACY_POLICY} mr={[2, 3]}>Privacy Policy</FooterLink>
        <FooterLink to={TERMS} ml={[2, 3]}>Terms and Conditions</FooterLink>
      </Flex>
    </Container>
  </Box>
});

const StyledColumn = styled(Box)`
  ${minWidth}
  ${maxWidth}
`;
const Column = props => <StyledColumn mb={[40]} {...props} />;

const FooterLink = props => <Link sx={{
  fontSize: [14, 16],
  color: 'white',
  '&:hover,&:active': {
    color: 'primary'
  }
}} {...props} />;

const Post = ({ post }) => <Flex mb={4}>
  <Box mr={3} flex="0 0 76px" pt="2px"><Image src={post.featureImageUrl} width={76} height={50} borderRadius="corner" border="default" alt={post.postTitle} /></Box>
  <Box flex="1 1 0">
    <FooterLink to={getPath(BLOG_POSTS_SINGLE, post.slug)}>{post.postTitle}</FooterLink>
    <Text fontSize={14} mt={1} color="gray.4">{formatDate(post.publishDate)}</Text>
  </Box>
</Flex>;

Footer.propTypes = {};

Footer.defaultProps = {};

Footer.displayName = 'Footer';

export default Footer;
