import { PERSISTENCE_TYPES, setPersistenceType } from '@paperstac/common/lib/services/statePersistence';
import auth from '@paperstac/firebase/lib/auth';
import { getFirebase } from '@paperstac/firebase/lib/firebase';
import { passwordResetValidator } from '@paperstac/firestore-collections/lib/identities';
import { HOME, LOGIN } from '@paperstac/routes/lib/webRoutes';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import DelayRender from '@paperstac/ui/lib/DelayRender';
import InputFormGroup from '@paperstac/ui/lib/form/InputFormGroup';
import Spinner from '@paperstac/ui/lib/Spinner';
import Text from '@paperstac/ui/lib/Text';
import { Form, Formik } from 'formik';
import qs from 'qs';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { AUTH_OVERLAY_VIEWS, AuthOverlayContext } from './AuthOverlayProvider';
import ErrorMessage from './ErrorMessage';
import LinkButton from './LinkButton';
import TitleSubtext from './TitleSubtext';
import ViewTitle from './ViewTitle';

const FIREBASE_AUTH_PERSISTENCE = getFirebase().auth.Auth.Persistence;

const PasswordResetView = React.memo(props => {
  const { continueUrl, oobCode } = qs.parse(props.location.search, { ignoreQueryPrefix: true });

  const { setView } = React.useContext(AuthOverlayContext);
  const [email, setEmail] = React.useState(null);
  const [codeErrorMessage, setCodeErrorMessage] = React.useState(null);
  const [passwordUpdated, setPasswordUpdated] = React.useState(false);

  React.useEffect(() => {
    auth.verifyPasswordResetCode(oobCode)
      .then(email => setEmail(email))
      .catch(error => {
        const errorMessages = {
          'auth/expired-action-code': 'Your password reset link has expired.',
          'auth/invalid-action-code': 'Your password reset link is invalid.',
          'auth/user-disabled': 'Your Paperstac Identity is currently disabled and unable to be modified.',
          'auth/user-not-found': 'We were unable to find a Paperstac Identity associated with your password reset link.',
        };
        setCodeErrorMessage(errorMessages[error.code] || 'We experienced an unexpected error with your password reset link.');
      });
  }, [oobCode]);

  const redirectOnceComplete = React.useCallback(() => {
    if (continueUrl) {
      window.location.replace(continueUrl);
    } else {
      props.history.push(HOME);
    }
  }, [continueUrl, props.history]);

  if (!!passwordUpdated) return <PasswordUpdatedMessage email={email} onContinue={redirectOnceComplete} />;
  if (!!codeErrorMessage) return <CodeErrorMessage codeErrorMessage={codeErrorMessage} setView={setView} />;
  if (!!email) return <PasswordResetForm email={email} oobCode={oobCode} onSuccess={() => setPasswordUpdated(true)} onRedirect={props.history.push} />;
  return <Loading />;
});

PasswordResetView.propTypes = {};

PasswordResetView.defaultProps = {};

PasswordResetView.displayName = 'PasswordResetView';

export default withRouter(PasswordResetView);

const Loading = () => <Box>
  <ViewTitle>Reset Password</ViewTitle>
  <DelayRender><Box textAlign="center"><Spinner /></Box></DelayRender>;
</Box>;

const PasswordUpdatedMessage = props => <Box>
  <ViewTitle>Password Updated</ViewTitle>
  <TitleSubtext>The password for the <Text display="inline" fontWeight="bold">{props.email}</Text> Paperstac Identity has been updated successfully.</TitleSubtext>
  <Button type="button" variant="primary" block={true} onClick={props.onContinue}>Continue</Button>
</Box>;

const CodeErrorMessage = props => <Box>
  <ViewTitle>Link Error</ViewTitle>
  <TitleSubtext>{props.codeErrorMessage}</TitleSubtext>
  <Box mt={40} textAlign="center">
    Want to start over? <LinkButton onClick={() => props.setView(AUTH_OVERLAY_VIEWS.FORGOT_PASSWORD)}>Retry</LinkButton>.
  </Box>
</Box>;

const PasswordResetForm = props => <Formik
  initialValues={{ password: '', confirmPassword: '' }}
  validationSchema={passwordResetValidator}
  onSubmit={async (payload, { setSubmitting, setStatus }) => {
    auth.confirmPasswordReset(props.oobCode, payload.password)
      .then(async () => {
        try {
          setPersistenceType(PERSISTENCE_TYPES.SESSION);
          await auth.setPersistence(FIREBASE_AUTH_PERSISTENCE.SESSION);
          await auth.signInWithEmailAndPassword(props.email, payload.password);
          props.onSuccess();
        } catch (error) {
          const errorMessages = {
            'auth/user-not-found': 'Email does not exist in our system.',
            'auth/wrong-password': 'Password is incorrect.',
          };
          setStatus({ errorMessage: errorMessages[error.code] || 'We experienced a system error.' });
        }
      })
      .catch(error => {
        setSubmitting(false);
        setStatus({ errorMessage: error.message });
      });
  }}
  render={({ isSubmitting, status }) => <Form>
    <ViewTitle>Reset Password</ViewTitle>
    <TitleSubtext>Enter a new password for your <Text display="inline" fontWeight="bold">{props.email}</Text> Paperstac Identity.</TitleSubtext>
    <InputFormGroup name="password" label="Password:" type="password" required />
    <InputFormGroup name="confirmPassword" label="Confirm Password:" type="password" required />
    {status && status.errorMessage && <ErrorMessage>{status.errorMessage}</ErrorMessage>}
    <Button type="submit" variant="primary" block={true} busy={isSubmitting}>Update Password</Button>
    <Box mt={40} textAlign="center">
      I remembered my password. <LinkButton onClick={() => props.onRedirect(LOGIN)}>Sign In</LinkButton>.
    </Box>
  </Form>}
/>;
