import Box from '@paperstac/ui/lib/Box';
import CheckmarkCircleIcon from '@paperstac/ui/lib/icons/CheckmarkCircleIcon';
import XCircleIcon from '@paperstac/ui/lib/icons/XCircleIcon';
import Image from '@paperstac/ui/lib/Image';
import PropTypes from 'prop-types';
import React from 'react';

const AccountAcceptancePill = React.memo(({ accepts, avatar, displayName, sx, ...props }) => <Box sx={{
  mt: 2,
  borderRadius: 20,
  p: 1,
  bg: accepts ? 'green' : 'gray.6',
  display: 'inline-flex',
  color: 'white', ...sx
}} {...props}>
  <Image src={avatar} alt={`${displayName}'s profile pic`} height={20} width={20} sx={{
    borderRadius: 'circle',
    mr: 2
  }} />
  {accepts && <CheckmarkCircleIcon size={20} />}
  {!accepts && <XCircleIcon size={20} />}
</Box>);

AccountAcceptancePill.propTypes = {
  accepts: PropTypes.bool,
  avatar: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
};

AccountAcceptancePill.defaultProps = {
  accepts: false
};

AccountAcceptancePill.displayName = 'AccountAcceptancePill';

export default AccountAcceptancePill;
