import PropTypes from 'prop-types';
import React from 'react';
import Content from './Content';
import ManageListingOverlayProvider from './ManageListingOverlayProvider';

const ManageListingOverlay = React.memo(({ listing, onClose }) =>
  <ManageListingOverlayProvider listing={listing} onClose={onClose}>
    <Content />
  </ManageListingOverlayProvider>);

ManageListingOverlayProvider.propTypes = {
  listing: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

ManageListingOverlay.defaultProps = {};

ManageListingOverlay.displayName = 'ManageListingOverlay';

export default ManageListingOverlay;
