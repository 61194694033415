import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { removeToast } from '../actions/toasterActions';
import Toast from './Toast';
import './Toaster.css';

class Toaster extends Component {
  constructor(props) {
    super(props);
    this.state = { inDom: false };
  }

  componentDidMount() {
    this.container = document.createElement('div');
    document.body.appendChild(this.container);
    this.setState({ inDom: true });
  }

  componentWillUnmount() {
    document.body.removeChild(this.container);
  }

  handleRemove(id) {
    return () => {
      this.props.dispatch(removeToast(id));
    };
  }

  render() {
    const Toaster = (
      <div className="Toaster">
        {this.props.toaster.map(toast => <Toast key={toast.id} toast={toast} onRemove={this.handleRemove(toast.id)} />)}
      </div>
    );
    return this.state.inDom ? ReactDOM.createPortal(Toaster, this.container) : null;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    toaster: state.toaster,
    ...ownProps
  };
}

export default connect(mapStateToProps)(Toaster);
