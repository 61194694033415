import { END_SESSION } from '../actions/authActions';
import { UNSUBSCRIBE_CURRENT_ACCOUNT } from '../actions/currentAccountActions';
import { SET_TRANSACTION_FEES } from '../actions/transactionFeeActions';

const DEFAULT_STATE = {};

export default function transactionFeesReducer(state = DEFAULT_STATE, { type, payload = [] }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case SET_TRANSACTION_FEES:
      return {
        ...state,
        [payload.transactionId]: payload.fees
      };
    default:
      return state;
  }
}

export const getTransactionFees = (state, transactionId) => {
  return state[transactionId] || [];
};
