import debounce from 'lodash/debounce';
import { firestore } from '../services/firebaseClient';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const CREATE_NOTE_FILE_REQUEST = 'CREATE_NOTE_FILE_REQUEST';
export const DELETE_NOTE_FILE_REQUEST = 'DELETE_NOTE_FILE_REQUEST';
export const SET_NOTE_FILES = 'SET_NOTE_FILES';
export const SUBSCRIBE_NOTE_FILES = 'SUBSCRIBE_NOTE_FILES';
export const UNSUBSCRIBE_NOTE_FILES = 'UNSUBSCRIBE_NOTE_FILES';
export const UPDATE_NOTE_FILE_REQUEST = 'UPDATE_NOTE_FILE_REQUEST';

let unsubscribe = null;

const transformNoteFiles = querySnapshot => {
  return querySnapshot.docs.map(transformFirestoreTimestamps);
};

export const debouncedSetNoteFiles = debounce((dispatch, noteId, items) => {
  // We debounce because this is called rapidly when updating sort order,
  // and it can cause visual ordering glitches if items aren't updated in exact order.
  dispatch({ type: SET_NOTE_FILES, payload: { id: noteId, items } });
}, 100);

export const subscribeNoteFiles = (accountId, noteId) => {
  return dispatch => {
    if (!unsubscribe) {
      dispatch({ type: SUBSCRIBE_NOTE_FILES, payload: { id: noteId } });
      unsubscribe = firestore
        .collection(`accounts/${accountId}/notes/${noteId}/files`)
        .orderBy('order')
        .onSnapshot(querySnapshot => {
          debouncedSetNoteFiles(dispatch, noteId, transformNoteFiles(querySnapshot));
        });
    }
  };
};

export const unsubscribeNoteFiles = noteId => {
  return dispatch => {
    if (unsubscribe) {
      unsubscribe();
      unsubscribe = null;
      dispatch({ type: UNSUBSCRIBE_NOTE_FILES, payload: { id: noteId } });
    }
  };
};

export const createNoteFileRequest = (accountId, noteId, payload) => {
  return dispatch => {
    dispatch({ type: CREATE_NOTE_FILE_REQUEST, payload, accountId, noteId });
    return firestore.doc(`accounts/${accountId}/notes/${noteId}/files/${payload.id}`).set(payload);
  };
};

export const updateNoteFileRequest = (accountId, noteId, id, payload) => {
  return dispatch => {
    dispatch({ type: UPDATE_NOTE_FILE_REQUEST, payload });
    return firestore.doc(`accounts/${accountId}/notes/${noteId}/files/${id}`).update(payload);
  };
};

export const deleteNoteFileRequest = (accountId, noteId, id) => {
  return dispatch => {
    dispatch({ type: DELETE_NOTE_FILE_REQUEST, payload: { noteId, id } });
    return firestore.doc(`accounts/${accountId}/notes/${noteId}/files/${id}`).delete();
  };
};
