import Overlay from '@paperstac/ui/lib/Overlay';
import PropTypes from 'prop-types';
import React from 'react';
import AuthOverlayProvider, { AUTH_OVERLAY_VIEWS } from './AuthOverlayProvider';
import Layout from './Layout';

const AuthOverlay = React.memo(({ view }) => <AuthOverlayProvider view={view}>
  <Overlay>
    <Layout />
  </Overlay>
</AuthOverlayProvider>);

AuthOverlay.propTypes = {
  view: PropTypes.oneOf(Object.values(AUTH_OVERLAY_VIEWS))
};

AuthOverlay.defaultProps = {
  view: AUTH_OVERLAY_VIEWS.LOGIN,
};

AuthOverlay.displayName = 'AuthOverlay';

export default AuthOverlay;