import { getNameFromCode, getStates } from '@paperstac/common/lib/services/states';
import AlgoliaHasListRefinement from '@paperstac/ui/lib/AlgoliaHasListRefinement';
import AlgoliaMultiSelectRefinement from '@paperstac/ui/lib/AlgoliaMultiSelectRefinement';
import React from 'react';
import CollapsibleFilterContainer from './CollapsibleFilterContainer';

const StateFilter = React.memo(props => <AlgoliaHasListRefinement attribute="noteMetaAgg.state">
  <CollapsibleFilterContainer title="State">
    <AlgoliaMultiSelectRefinement
      attribute="noteMetaAgg.state"
      limit={5}
      options={getStates().map(({ code, name }) => ({ label: name, value: code }))}
      placeholder="Select a state..."
      transformLabel={getNameFromCode}
    />
  </CollapsibleFilterContainer>
</AlgoliaHasListRefinement>);

StateFilter.propTypes = {};

StateFilter.defaultProps = {};

StateFilter.displayName = 'StateFilter';

export default StateFilter;