import React from 'react';
var errorCodeStrings = {
  'auth/expired-action-code': 'Your link has expired',
  'auth/invalid-action-code': 'Your link is invalid',
  'auth/user-disabled': 'Paperstac Identity is currently disabled',
  'auth/user-not-found': 'Paperstac Identity does not exist in our system',
  'auth/wrong-password': 'Password is incorrect'
};

var getAuthErrorMessage = function getAuthErrorMessage(error) {
  return errorCodeStrings[error.code] || error.message;
};

var useFirebaseAuth = function useFirebaseAuth(auth) {
  var actionsRef = React.useRef({
    confirmPasswordReset: function confirmPasswordReset(oobCode, password) {
      return auth.confirmPasswordReset(oobCode, password).catch(function (error) {
        throw new Error(getAuthErrorMessage(error));
      });
    },
    login: function login(email, password) {
      return auth.signInWithEmailAndPassword(email, password).catch(function (error) {
        throw new Error(getAuthErrorMessage(error));
      });
    },
    logout: function logout() {
      return auth.signOut().catch(function (error) {
        throw new Error(getAuthErrorMessage(error));
      });
    },
    resetPassword: function resetPassword(code, newPassword) {
      return auth.confirmPasswordReset(code, newPassword).catch(function (error) {
        throw new Error(getAuthErrorMessage(error));
      });
    },
    sendPasswordResetEmail: function sendPasswordResetEmail(email, continueUrl) {
      var actionCodeSettings = continueUrl ? {
        url: continueUrl
      } : null;
      return auth.sendPasswordResetEmail(email, actionCodeSettings).catch(function (error) {
        throw new Error(getAuthErrorMessage(error));
      });
    },
    verifyPasswordResetCode: function verifyPasswordResetCode(oobCode) {
      return auth.verifyPasswordResetCode(oobCode).catch(function (error) {
        throw new Error(getAuthErrorMessage(error));
      });
    }
  });
  return actionsRef.current;
};

export default useFirebaseAuth;