import debounce from 'lodash/debounce';
import React from 'react';
var CACHE_KEY = 'paperstac';
var SAVE_CACHE_DEBOUNCE_MS = 200;

var getCacheFromBrowserStorage = function getCacheFromBrowserStorage() {
  try {
    var serialized = window.localStorage.getItem(CACHE_KEY);
    return serialized ? JSON.parse(serialized) : {};
  } catch (err) {
    return {};
  }
};

var cache = getCacheFromBrowserStorage();
export var getCache = function getCache(key, defaultValue) {
  var getCacheValue = function getCacheValue() {
    return key in cache ? cache[key] : defaultValue;
  };

  var setCache = debounce(function (value) {
    cache[key] = value;

    try {
      window.localStorage.setItem(CACHE_KEY, JSON.stringify(cache));
    } catch (e) {// Do nothing
    }
  }, SAVE_CACHE_DEBOUNCE_MS);
  return [getCacheValue, setCache];
};
export var flushCache = function flushCache() {
  try {
    cache = {};
    window.localStorage.removeItem(CACHE_KEY);
  } catch (err) {}
};