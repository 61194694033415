import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import useBreakpoints from '@paperstac/ui/lib/hooks/useBreakpoints';
import { HOME } from '@paperstac/routes/lib/webRoutes';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import Container from '@paperstac/ui/lib/Container';
import Flex from '@paperstac/ui/lib/Flex';
import Link from '@paperstac/ui/lib/Link';
import React from 'react';
import useMedia from 'use-media';
import { ReactComponent as Branding } from '../../../images/paperstac.svg';
import { IdentityManagerContext } from '../IdentityManager';
import AccountDropdown from './AccountDropdown';
import Nav from './Nav';

const Header = React.memo(props => {
  const { isMobile } = useBreakpoints();
  const { currentAccount } = React.useContext(AccountsContext);
  const { showRegister } = React.useContext(IdentityManagerContext);
  const isTall = useMedia({ minHeight: 800 });

  return <Box bg="white" sx={{
    width: '100vw',
    pt: [3, 3, 5],
    pb: [50, 53, 5],
    position: isTall ? 'sticky' : 'relative',
    top: 0,
    zIndex: 3
  }}>
    <Container as={Flex} alignItems="center">
      <Box flex={1}><Link to={HOME}><Branding height={isMobile ? 24 : 30} style={{ verticalAlign: 'middle' }} /></Link></Box>
      <Flex alignItems="center" display={['block']}>
        <Nav />
        {!currentAccount && <Button onClick={showRegister} variant="primary" size="small">Register</Button>}
        {!!currentAccount && <AccountDropdown />}
      </Flex>
    </Container>
  </Box>
});

Header.propTypes = {};

Header.defaultProps = {};

Header.displayName = 'Header';

export default Header;
