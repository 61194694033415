import { SortCollectionContext } from '@paperstac/common/lib/components/SortCollectionProvider';
import CurrencyCell from '@paperstac/ui/lib/spreadsheet/CurrencyCell';
import DateCell from '@paperstac/ui/lib/spreadsheet/DateCell';
import Spreadsheet from '@paperstac/ui/lib/spreadsheet/Spreadsheet';
import SpreadsheetManager from '@paperstac/ui/lib/spreadsheet/SpreadsheetManager';
import TextCell from '@paperstac/ui/lib/spreadsheet/TextCell';
import React from 'react';

const PaymentHistorySpreadsheet = React.memo((props) => {
  const { collection, lastUpdated } = React.useContext(SortCollectionContext);

  const cellRenderer = ({ style, value, row, column: { prop } }) => {
    const map = {
      dueDate: () => <DateCell style={style} date={value} />,
      transactionDate: () => <DateCell style={style} date={value} />,
    };
    return map[prop] ? map[prop]() : typeof value === 'number'
      ? <CurrencyCell style={style} cents={value} />
      : <TextCell style={style}>{value}</TextCell>;
  };

  if (!Array.isArray(collection) || !collection.length) return null;

  return <SpreadsheetManager
    headerCells={[
      { prop: 'transactionDate', label: 'Date', width: 110 },
      { prop: 'description', label: 'Description', width: 150 },
      { prop: 'transactionAmount', label: 'Amount', width: 100 },
      { prop: 'dueDate', label: 'Due', width: 110 },
      { prop: 'principalAmount', label: 'Principal', width: 100 },
      { prop: 'principalBalance', label: 'Balance', width: 140 },
      { prop: 'arrearageForbearancePrincipal', label: 'Arrearage / Forbearance', width: 100 },
      { prop: 'interestAmount', label: 'Interest', width: 100 },
      { prop: 'unappliedAmount', label: 'Unapplied', width: 100 },
      { prop: 'escrowAmount', label: 'Escrow', width: 100 },
      { prop: 'escrowBalance', label: 'Escrow Bal', width: 100 },
      { prop: 'lateChargeAmount', label: 'Late Fee', width: 100 },
      { prop: 'lateChargeBalance', label: 'Fee Bal', width: 100 },
    ]}
    rowsLastUpdated={lastUpdated}
    rows={collection}
  >
    <Spreadsheet cellRenderer={cellRenderer} {...props} />
  </SpreadsheetManager>
});

PaymentHistorySpreadsheet.propTypes = {};

PaymentHistorySpreadsheet.defaultProps = {};

PaymentHistorySpreadsheet.displayName = 'PaymentHistorySpreadsheet';

export default PaymentHistorySpreadsheet;
