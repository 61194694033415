import firestore from '@paperstac/firebase/lib/firestore';
export var blogPostsRef = function blogPostsRef() {
  return firestore.collection('blogPosts');
};
export var publishedBlogPostsRef = function publishedBlogPostsRef() {
  return blogPostsRef().where('isPublished', '==', true);
};
export var newestPublishedBlogPostsRef = function newestPublishedBlogPostsRef() {
  return publishedBlogPostsRef().orderBy('publishDate', 'desc');
};
export var publishedBlogPostsByTagRef = function publishedBlogPostsByTagRef(tag) {
  return publishedBlogPostsRef().where('tags', 'array-contains', tag);
};
export var newestPublishedBlogPostsByTagRef = function newestPublishedBlogPostsByTagRef(tag) {
  return publishedBlogPostsByTagRef(tag).orderBy('publishDate', 'desc');
};