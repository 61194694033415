import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { REM, SPACER } from '../../../theme';
import BlogMediaBox from '../../BlogMediaBox';
import BlogPostAuthorMikeMcLin from '../../BlogPostAuthorMikeMcLin';
import BlogPostLayout from '../../BlogPostLayout';
import CloudinaryImage from '../../CloudinaryImage';

export const metadata = {
  postTitle: 'Phone Call Feature Launched at NoteExpo 2018',
  metaTitle: 'Phone Call Feature Launched at NoteExpo 2018',
  metaDescription:
    'We are back from Note Expo 2018 in Dallas, TX where we launched our new phone call communication feature.',
  slug: 'phone-call-feature-launched-at-noteexpo-2018',
  publishDate: '2018-11-20 9:00',
  featureImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1542716535/website-assets/phone-call-feature-launched-at-noteexpo-2018-feature.png',
  socialImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1542716535/website-assets/phone-call-feature-launched-at-noteexpo-2018-social.png',
  tags: ['communication', 'new-feature']
};

const TitleComponent = () => (
  <h1 style={{ marginTop: 0, marginBottom: SPACER(2) }}>
    <CloudinaryImage
      url="https://res.cloudinary.com/paperstac/image/upload/v1542716796/website-assets/phone-call-feature-launched-at-noteexpo-2018-hero.png"
      alt={metadata.postTitle}
    />
  </h1>
);

const PhoneCallFeatureLaunchedAtNoteExpo2018 = () => (
  <BlogPostLayout metadata={metadata} AuthorComponent={BlogPostAuthorMikeMcLin} TitleComponent={TitleComponent}>
    <p>
      We are back from Note Expo 2018 in Dallas, TX where we showed off the new Paperstac platform that we launched only
      a few weeks ago.
    </p>

    <BlogMediaBox>
      <iframe
        title="Video highlights from NoteExpo 2018"
        width="560"
        height="315"
        src="https://www.youtube.com/embed/PfMMpCejlYo"
        frameBorder="0"
        allowFullScreen
      />
    </BlogMediaBox>

    <p>
      We gave countless demos to investors showing how Paperstac makes performing mortgage note deals easier than ever
      with a completely online process. We even launched a new phone call communication feature...more on that in a
      second.
    </p>

    <p>
      Of course we showed off our transaction timeline which stores everything that has happened in a transaction
      including all communication, negotiations, and actions performed like document generation and execution, shipping
      updates and more. The timeline is a great feature because you can jump right into one of your transactions and see
      exactly what has been going on. The timeline also acts like a digital audit trail protecting both parties.
    </p>

    <p>
      And we can't do a demo without including the crowd-favorite feature - transaction To-Do's. Our dynamic To-Do list
      walks both the buyer and the seller through each step of the transaction. This gives both parties easy-to-follow
      actionable items and simplifies the whole process for novices and experts alike.
    </p>

    <h2 style={{ marginTop: SPACER(2) }}>New Phone Call Feature</h2>

    <BlogMediaBox>
      <VisibilitySensor partialVisibility={true}>
        {({ isVisible }) =>
          isVisible ? (
            <video style={{ width: REM(720), height: REM(405) }} autoPlay loop muted playsInline>
              <source
                src="https://res.cloudinary.com/paperstac/video/upload/q_auto:best/v1542713958/website-assets/phone-call-flow-animation.webm"
                type="video/webm"
              />
              <source
                src="https://res.cloudinary.com/paperstac/video/upload/q_auto:best/v1542713958/website-assets/phone-call-flow-animation.mp4"
                type="video/mp4"
              />
              <source
                src="https://res.cloudinary.com/paperstac/video/upload/q_auto:best/v1542713958/website-assets/phone-call-flow-animation.ogv"
                type="video/ogg"
              />
            </video>
          ) : (
            <div style={{ width: REM(720), height: REM(405) }}>&nbsp;</div>
          )
        }
      </VisibilitySensor>
    </BlogMediaBox>

    <p>
      Finally, we rolled out a completely new phone call communication feature at NoteExpo 2018. Here is how it works...
      One user can initiate a phone call conversation with the other party of a transaction. Paperstac will call the
      user and have them confirm the request. Paperstac will then call the other party's phone and let them know that a
      Paperstac user wants to communicate with them. Once they accept, we will join the calls and record the
      conversation. Once the phone call is complete, we will save the audio recording and place it on your transaction
      timeline. We will even transcribe the audio so you can see a textual representation of what was said. The best
      part is that since Paperstac initiated the phone call, you never had to disclose your phone number to the other
      party.
    </p>

    <p>
      So, as you can see we are continuing to roll out some really great features to the Paperstac online marketplace.
      Let us know what you think about the new phone call feature in the comments and stay tuned for some big upcoming
      announcements in the next couple of weeks.
    </p>
  </BlogPostLayout>
);

export default PhoneCallFeatureLaunchedAtNoteExpo2018;
