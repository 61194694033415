import LottiePlayer from '@paperstac/ui/lib/LottiePlayer';
import React from 'react';
import animationData from '../../../lottie/swipe-listings';

const SwipeListingsMedia = React.memo(props => {
  return <LottiePlayer animationData={animationData} {...props} />
});

SwipeListingsMedia.propTypes = {};

SwipeListingsMedia.defaultProps = {};

SwipeListingsMedia.displayName = 'SwipeListingsMedia';

export default SwipeListingsMedia;