import { firestore } from '../services/firebaseClient';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';
import { transformAccount } from './currentAccountActions';

export const SUBSCRIBE_CURRENT_USER = 'SUBSCRIBE_CURRENT_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_CLAIMS = 'SET_CURRENT_USER_CLAIMS';
export const UNSUBSCRIBE_CURRENT_USER = 'UNSUBSCRIBE_CURRENT_USER';

let unsubscribe = null;

export const setCurrentUser = payload => {
  return { type: SET_CURRENT_USER, payload };
};

export const setCurrentUserClaims = payload => {
  return { type: SET_CURRENT_USER_CLAIMS, payload };
};

export const subscribeCurrentUser = uid => {
  return dispatch => {
    if (!unsubscribe) {
      dispatch({ type: SUBSCRIBE_CURRENT_USER });
      unsubscribe = firestore.doc(`accounts/${uid}`).onSnapshot(docSnap => {
        dispatch(setCurrentUser(transformAccount(transformFirestoreTimestamps(docSnap))));
      });
    }
  };
};

export const unsubscribeCurrentUser = () => {
  return dispatch => {
    if (unsubscribe) {
      unsubscribe();
      unsubscribe = null;
      dispatch({ type: UNSUBSCRIBE_CURRENT_USER });
    }
  };
};

export const updateCurrentUserRequest = (uid, payload) => {
  return async dispatch => {
    return firestore.doc(`accounts/${uid}`).update(payload);
  };
};
