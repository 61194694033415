import { END_SESSION } from '../actions/authActions';
import { UNSUBSCRIBE_CURRENT_ACCOUNT } from '../actions/currentAccountActions';
import { ADD_ESCROW_EVENT, SET_ESCROW_ORDER, SET_ESCROW_ORDERS } from '../actions/escrowOrderActions';

const DEFAULT_STATE = {
  items: [],
  lastUpdated: null
};

let escrowOrderExists, items;

export default function escrowOrdersReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case SET_ESCROW_ORDERS:
      return {
        ...state,
        items: payload,
        lastUpdated: new Date().toISOString()
      };
    case SET_ESCROW_ORDER:
      escrowOrderExists = !!payload && !!getEscrowOrder(state, payload.id);
      items = escrowOrderExists ? state.items.map(item => {
        return item.id === payload.id ? payload : item;
      }) : [...state.items, payload];
      return {
        ...state,
        items,
        lastUpdated: new Date().toISOString()
      };
    case ADD_ESCROW_EVENT:
      escrowOrderExists = !!getEscrowOrder(state, payload.escrowOrderId);
      items = escrowOrderExists ? state.items.map(item => {
        return item.id === payload.escrowOrderId ? {
          ...item,
          events: { ...item.events, [payload.id]: payload }
        } : item;
      }) : [...state.items, payload];
      return {
        ...state,
        items,
        lastUpdated: new Date().toISOString()
      };
    default:
      return state;
  }
}

export const getEscrowOrders = state => {
  return state.items;
};

export const getEscrowOrder = (state, id) => {
  return state.items.find(item => item.id === id);
};

export const escrowOrdersLastUpdated = state => {
  return state.lastUpdated;
};
