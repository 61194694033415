import DelayRender from '@paperstac/ui/lib/DelayRender';
import Flex from '@paperstac/ui/lib/Flex';
import Spinner from '@paperstac/ui/lib/Spinner';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';

const LoadingMessage = React.memo(({ delay, message, ...props }) => {
  const contents = <Flex alignItems="center" fontSize={3} {...props}>
    <Spinner size="small" sx={{ display: 'block' }} />
    <Text ml={2}>{message}</Text>
  </Flex>;

  if (!delay) return contents;

  return <DelayRender ms={delay}>
    {contents}
  </DelayRender>;
});

LoadingMessage.propTypes = {};

LoadingMessage.defaultProps = {
  message: 'Getting latest data...',
  delay: 1000
};

LoadingMessage.displayName = 'LoadingMessage';

export default LoadingMessage;
