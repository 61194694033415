import Box from '@paperstac/ui/lib/Box';
import React from 'react';

const ErrorMessage = React.memo(props => <Box {...props} />);

ErrorMessage.propTypes = {};

ErrorMessage.defaultProps = {
  sx: {
    fontSize: 1,
    mb: 3,
    py: 2,
    px: 3,
    border: 'default',
    borderColor: 'red',
    borderRadius: 'corner',
    color: 'red',
  }
};

ErrorMessage.displayName = 'ErrorMessage';

export default ErrorMessage;