function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import { dateToTime } from '@paperstac/helpers/lib/dateHelpers';
import differenceInSeconds from "date-fns/difference_in_seconds";
import distanceInWordsToNow from "date-fns/distance_in_words_to_now";
import React from 'react';
var FIVE_SECS_IN_MS = 5000;
var ONE_MINUTE_IN_SECS = 60;
var ONE_MINUTE_IN_MS = ONE_MINUTE_IN_SECS * 1000;
var ONE_HOUR_IN_SECS = ONE_MINUTE_IN_SECS * 60;
var ONE_HOUR_IN_MS = ONE_HOUR_IN_SECS * 1000;
var ONE_DAY_IN_SECS = ONE_HOUR_IN_SECS * 24;

var timeToRelativeDate = function timeToRelativeDate(time) {
  return distanceInWordsToNow(new Date(time), {
    includeSeconds: true,
    addSuffix: true
  });
};

export default (function (dateOrFirestoreTimestamp) {
  var time = dateToTime(dateOrFirestoreTimestamp);

  var _React$useState = React.useState(timeToRelativeDate(time)),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      relativeDate = _React$useState2[0],
      setRelativeDate = _React$useState2[1];

  var _React$useState3 = React.useState(null),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      relativeDateRefreshed = _React$useState4[0],
      setRelativeDateRefreshed = _React$useState4[1];

  var refreshRelativeDate = React.useCallback(function () {
    setRelativeDate(timeToRelativeDate(time));
    setRelativeDateRefreshed(new Date().getTime());
  }, [time]);
  React.useEffect(function () {
    var diffInSecs = differenceInSeconds(new Date(), new Date(time));

    if (diffInSecs < ONE_MINUTE_IN_SECS) {
      var to = setTimeout(refreshRelativeDate, FIVE_SECS_IN_MS);
      return function () {
        return clearTimeout(to);
      };
    } else if (diffInSecs < ONE_HOUR_IN_SECS) {
      var _to = setTimeout(refreshRelativeDate, ONE_MINUTE_IN_MS);

      return function () {
        return clearTimeout(_to);
      };
    } else if (diffInSecs < ONE_DAY_IN_SECS) {
      var _to2 = setTimeout(refreshRelativeDate, ONE_HOUR_IN_MS);

      return function () {
        return clearTimeout(_to2);
      };
    }
  }, [time, relativeDateRefreshed]);
  return relativeDate;
});