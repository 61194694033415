import React from 'react';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';
import NoteScreenFileItem from './NoteScreenFileItem';

const NoteScreenFileList = React.memo(() => {
  const { note } = React.useContext(ManageListingOverlayContext);
  const { files } = note;

  if (!files || !files.length) return null;

  return files.map((file, i) => <NoteScreenFileItem key={file.id} file={file} sx={{
    py: 2,
    borderTop: i === 0 ? 'none' : 'default'
  }} />);
});

NoteScreenFileList.propTypes = {};

NoteScreenFileList.defaultProps = {};

NoteScreenFileList.displayName = 'NoteScreenFileList';

export default NoteScreenFileList;
