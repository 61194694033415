const isDocumentSnapshot = obj => {
  return typeof obj.data === 'function' && typeof obj.get === 'function' && typeof obj.isEqual === 'function';
};

const isFirestoreTimestamp = obj => {
  return typeof obj.toDate === 'function' && typeof obj.toMillis === 'function';
};

const isDateObject = obj => {
  return typeof obj.getDate === 'function' && typeof obj.getDay === 'function' && typeof obj.toISOString === 'function';
};

const transformFirestoreTimestamps = data => {
  if (!data || typeof data !== 'object') {
    return data;
  }
  if (isDocumentSnapshot(data)) {
    return transformFirestoreTimestamps(data.data());
  }
  if (isFirestoreTimestamp(data)) {
    return data.toDate();
  }
  if (isDateObject(data)) {
    return data;
  }
  if (Array.isArray(data)) {
    return data.map(d => transformFirestoreTimestamps(d));
  }
  Object.keys(data).forEach(propName => {
    data[propName] = transformFirestoreTimestamps(data[propName]);
  });
  return data;
};

export default transformFirestoreTimestamps;
