import { END_SESSION } from '../actions/authActions';
import { SET_EVENT_MODERATION_QUEUE } from '../actions/eventModerationQueueActions';

const DEFAULT_STATE = [];

export default function eventModerationQueueReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
      return DEFAULT_STATE;
    case SET_EVENT_MODERATION_QUEUE:
      return payload;
    default:
      return state;
  }
}

export const getEventModerationQueue = state => {
  return state;
};