import { firestore } from '../services/firebaseClient';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const SET_FLAGGED_EVENTS = 'SET_FLAGGED_EVENTS';

const transformCollection = querySnapshot => {
  return querySnapshot.docs.map(transformDoc);
};

const transformDoc = docSnap => {
  return docSnap.exists ? transformFirestoreTimestamps(docSnap) : null;
};

export const setFlaggedEvents = payload => {
  return { type: SET_FLAGGED_EVENTS, payload };
};

export const createFlaggedEvent = event => {
  return dispatch => {
    return firestore.doc(`flaggedEvents/${event.id}`).set(event);
  };
};

export const updateFlaggedEvent = event => {
  return dispatch => {
    return firestore.doc(`flaggedEvents/${event.id}`).update(event);
  };
};

export const deleteFlaggedEvent = eventId => {
  return dispatch => {
    return firestore.doc(`flaggedEvents/${eventId}`).delete();
  };
};

export const subscribeFlaggedEvents = () => {
  return dispatch => {
    return firestore
      .collection('flaggedEvents')
      .orderBy('flaggedDate', 'desc')
      .limit(50)
      .onSnapshot(querySnapshot => {
        dispatch(setFlaggedEvents(transformCollection(querySnapshot)));
      });
  };
};
