import AlgoliaHasRangeRefinement from '@paperstac/ui/lib/AlgoliaHasRangeRefinement';
import AlgoliaRangeInput from '@paperstac/ui/lib/AlgoliaRangeInput';
import React from 'react';
import CollapsibleFilterContainer from './CollapsibleFilterContainer';

const InvestmentToValueFilter = React.memo(props => <AlgoliaHasRangeRefinement attribute="investmentMetaAgg.investmentToValuePercent">
  <CollapsibleFilterContainer title="Investment to Value (ITV)">
    <AlgoliaRangeInput attribute="investmentMetaAgg.investmentToValuePercent" suffix="%"/>
  </CollapsibleFilterContainer>
</AlgoliaHasRangeRefinement>);

InvestmentToValueFilter.propTypes = {};

InvestmentToValueFilter.defaultProps = {};

InvestmentToValueFilter.displayName = 'InvestmentToValueFilter';

export default InvestmentToValueFilter;