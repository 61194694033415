import React from 'react';
import { Helmet } from 'react-helmet';
import { BASE_URL } from '@paperstac/env';
import { BLOG_POSTS_SINGLE, getPath } from '../routes';
import './BlogPostLayout.css';
import DefaultLayout from './DefaultLayout';
import FacebookShareButton from './FacebookShareButton';
import PageTitle from './PageTitle';

const getUrl = slug => {
  return `${BASE_URL}${getPath(BLOG_POSTS_SINGLE, slug)}`;
};

export default ({ metadata, AuthorComponent, TitleComponent, children }) => (
  <DefaultLayout title={metadata.metaTitle}>
    <Helmet>
      <meta name="Description" content={metadata.metaDescription} />

      {/* Facebook Meta Tags */}
      <meta property="og:title" content={metadata.postTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={getUrl(metadata.slug)} />
      <meta property="og:image" content={metadata.socialImageUrl} />
      <meta property="og:description" content={metadata.metaDescription} />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metadata.postTitle} />
      <meta name="twitter:description" content={metadata.metaDescription} />
      <meta name="twitter:image" content={metadata.socialImageUrl} />
    </Helmet>
    {TitleComponent ? <TitleComponent /> : <PageTitle title={metadata.postTitle} />}
    <div className="BlogPostLayout__layout">
      <div className="BlogPostLayout__layout-main">
        <div className="BlogPostLayout__meta">
          <div className="BlogPostLayout__meta-author">
            <AuthorComponent publishDate={metadata.publishDate} />
          </div>
          <div className="BlogPostLayout__meta-social">
            <FacebookShareButton url={getUrl(metadata.slug)} />
          </div>
        </div>
        <div className="BlogPostLayout__post">{children}</div>
        <div className="BlogPostLayout__comments">
          <div className="fb-comments" data-href={getUrl(metadata.slug)} data-width="800" data-numposts="10" />
        </div>
      </div>
    </div>
  </DefaultLayout>
);
