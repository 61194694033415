import * as intercomMessenger from '@paperstac/common/lib/services/intercomMessenger';
import Box from '@paperstac/ui/lib/Box';
import React from 'react';
import { AuthOverlayContext } from './AuthOverlayProvider';
import LinkButton from './LinkButton';

const Footer = React.memo(() => {
  const { showTerms, showPrivacyPolicy } = React.useContext(AuthOverlayContext);

  return <Box textAlign="center">
    <LinkButton mr={4} onClick={showTerms}>Terms</LinkButton>
    <LinkButton mr={4} onClick={showPrivacyPolicy}>Privacy Policy</LinkButton>
    <LinkButton onClick={intercomMessenger.showPanel}>Contact</LinkButton>
  </Box>
});

Footer.propTypes = {};

Footer.defaultProps = {};

Footer.displayName = 'Footer';

export default Footer;