import { noteLabel, noteValueFormatted } from '@paperstac/common/lib/services/notesHelper';
import { firestore } from '../services/firebaseClient';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const SET_NOTE_IMPORTS = 'SET_NOTE_IMPORTS';
export const SUBSCRIBE_NOTE_IMPORTS = 'SUBSCRIBE_NOTE_IMPORTS';
export const UNSUBSCRIBE_NOTE_IMPORTS = 'UNSUBSCRIBE_NOTE_IMPORTS';
export const FETCH_NOTE_IMPORT_REQUEST = 'FETCH_NOTE_IMPORT_REQUEST';
export const FETCH_NOTE_IMPORT_SUCCESS = 'FETCH_NOTE_IMPORT_SUCCESS';
export const FETCH_NOTE_IMPORT_FAILURE = 'FETCH_NOTE_IMPORT_FAILURE';
export const SET_NOTE_IMPORT = 'SET_NOTE_IMPORT';

const unsubscribes = {};

const transformNoteImports = querySnapshot => {
  return querySnapshot.docs.map(docSnap => {
    const row = transformFirestoreTimestamps(docSnap);
    delete row.rawTape;
    delete row.diff;
    return row;
  });
};

const transformNoteImport = doc => {
  const row = { ...doc.data() };
  const noteChanges = [];
  const rawTapeChanges = [];
  row.rawTape = Object.keys(row.rawTape).map(label => ({ label, value: row.rawTape[label] }));
  row.diff.forEach(diff => {
    switch (diff.kind) {
      case 'N':
        if (diff.path[0].startsWith('rawTape.')) {
          rawTapeChanges.push({ label: diff.path[0].substring(8), addition: diff.rhs, deletion: null });
        } else {
          noteChanges.push({
            label: noteLabel(diff.path[0]),
            addition: noteValueFormatted(diff.path[0], diff.rhs),
            deletion: null
          });
        }
        break;
      case 'E':
        if (diff.path[0].startsWith('rawTape.')) {
          rawTapeChanges.push({ label: diff.path[0].substring(8), addition: diff.rhs, deletion: diff.lhs });
        } else {
          noteChanges.push({
            label: noteLabel(diff.path[0]),
            addition: noteValueFormatted(diff.path[0], diff.rhs),
            deletion: noteValueFormatted(diff.path[0], diff.lhs)
          });
        }
        break;
      case 'D':
        if (diff.path[0].startsWith('rawTape.')) {
          rawTapeChanges.push({ label: diff.path[0].substring(8), addition: null, deletion: diff.lhs });
        } else {
          noteChanges.push({
            label: noteLabel(diff.path[0]),
            addition: null,
            deletion: noteValueFormatted(diff.path[0], diff.lhs)
          });
        }
        break;
      default:
      // Do nothing
    }
  });
  delete row.diff;
  return { ...row, noteChanges, rawTapeChanges };
};

export const setNoteImports = (noteId, items) => {
  return { type: SET_NOTE_IMPORTS, payload: { noteId, items } };
};

export const setNoteImport = (noteId, importId, data) => {
  return { type: SET_NOTE_IMPORT, payload: { noteId, importId, data } };
};

export const fetchNoteImportRequest = (accountId, noteId, importId) => {
  return dispatch => {
    dispatch({ type: FETCH_NOTE_IMPORT_REQUEST });
    return firestore
      .doc(`accounts/${accountId}/notes/${noteId}/imports/${importId}`)
      .get()
      .then(docRef => {
        dispatch({ type: FETCH_NOTE_IMPORT_SUCCESS });
        dispatch(setNoteImport(noteId, importId, transformNoteImport(docRef)));
      })
      .catch(error => {
        dispatch({ type: FETCH_NOTE_IMPORT_FAILURE, message: error.message });
      });
  };
};

export const subscribeNoteImports = (accountId, noteId) => {
  return dispatch => {
    if (!unsubscribes[`noteImport${noteId}`]) {
      dispatch({ type: SUBSCRIBE_NOTE_IMPORTS, noteId });
      unsubscribes[`noteImport${noteId}`] = firestore
        .collection(`accounts/${accountId}/notes/${noteId}/imports`)
        .orderBy('id', 'desc')
        .onSnapshot(querySnapshot => {
          dispatch(setNoteImports(noteId, transformNoteImports(querySnapshot)));
        });
    }
  };
};

export const unsubscribeNoteImports = noteId => {
  return dispatch => {
    if (unsubscribes[`noteImport${noteId}`]) {
      unsubscribes[`noteImport${noteId}`]();
      unsubscribes[`noteImport${noteId}`] = null;
      dispatch({ type: UNSUBSCRIBE_NOTE_IMPORTS });
    }
  };
};
