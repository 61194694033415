import { AlgoliaHasListRefinementContext } from '@paperstac/ui/lib/AlgoliaHasListRefinement';
import { AlgoliaHasRangeRefinementContext } from '@paperstac/ui/lib/AlgoliaHasRangeRefinement';
import Box from '@paperstac/ui/lib/Box';
import ChevronDownIcon from '@paperstac/ui/lib/icons/ChevronDownIcon';
import ChevronUpIcon from '@paperstac/ui/lib/icons/ChevronUpIcon';
import UnstyledButton from '@paperstac/ui/lib/UnstyledButton';
import React from 'react';
import BaseFilterContainer from './BaseFilterContainer';
import FilterTitle from './FilterTitle';

const CollapsibleFilterContainer = React.memo(({ children, title, ...props }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleToggle = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);
  const hasListRefinement = React.useContext(AlgoliaHasListRefinementContext);
  const hasRangeRefinement = React.useContext(AlgoliaHasRangeRefinementContext);
  const forceOpen = hasListRefinement || hasRangeRefinement;
  if (forceOpen && !isOpen) {
    setIsOpen(true);
  }

  return <BaseFilterContainer py={0} {...props}>
    {!forceOpen && <UnstyledButton
      onClick={handleToggle}
      sx={{
        display: 'flex',
        width: '100%',
        py: 3,
        cursor: 'pointer',
        '&:hover': {
          color: 'black'
        }
      }}
    >
      <FilterTitle mb={0} flex={1}>{title}</FilterTitle>
      {isOpen && <ChevronUpIcon sx={{ color: 'gray.5' }} />}
      {!isOpen && <ChevronDownIcon sx={{ color: 'gray.5' }} />}
    </UnstyledButton>}
    {forceOpen && <FilterTitle mb={0} py={3}>{title}</FilterTitle>}
    <Box pb={3} display={(isOpen || forceOpen) ? 'block' : 'none'}>{children}</Box>
  </BaseFilterContainer>;
});

CollapsibleFilterContainer.propTypes = {};

CollapsibleFilterContainer.defaultProps = {};

CollapsibleFilterContainer.displayName = 'CollapsibleFilterContainer';

export default CollapsibleFilterContainer;
