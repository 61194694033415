export var normalizeCampaign = function normalizeCampaign() {
  var campaign = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var sourceTransform = function sourceTransform(value) {
    var transformations = {
      'Adwords': 'adwords'
    };
    return transformations[value] || value;
  };

  var mediumTransform = function mediumTransform(value) {
    var transformations = {
      'Search': 'ppc',
      'cpc': 'ppc',
      'CPC': 'ppc',
      'ppch': 'ppc'
    };
    return transformations[value] || value;
  };

  var normalized = {};
  normalized.campaign = campaign.campaign && campaign.campaign !== 'unknown' ? campaign.campaign.trim() : '';
  normalized.source = campaign.source && campaign.source !== 'unknown' ? sourceTransform(campaign.source.trim()) : '';
  normalized.medium = campaign.medium && campaign.medium !== 'unknown' ? mediumTransform(campaign.medium.trim()) : '';
  normalized.term = campaign.term && campaign.term !== 'unknown' ? campaign.term.trim() : '';
  normalized.content = campaign.content && campaign.content !== 'unknown' ? campaign.content.trim() : '';
  return normalized;
};