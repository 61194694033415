import Heading from '@paperstac/ui/lib/Heading';
import React from 'react';

const ViewTitle = React.memo(props => <Heading as="h1" fontSize={3} mb={3} {...props} />);

ViewTitle.propTypes = {};

ViewTitle.defaultProps = {};

ViewTitle.displayName = 'ViewTitle';

export default ViewTitle;
