import auth from '@paperstac/firebase/lib/auth';
import { getFirebase } from '@paperstac/firebase/lib/firebase';
import Button from '@paperstac/ui/lib/Button';
import ErrorText from '@paperstac/ui/lib/ErrorText';
import InputFormGroup from '@paperstac/ui/lib/form/InputFormGroup';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

const firebase = getFirebase();

const validationSchema = () => Yup.object().shape({
  currentPassword: Yup.string().required(),
  newPassword: Yup.string().min(8).required().matches(/^(?=.*?[A-Za-z])(?=.*?[0-9])/, 'Password must contain a letter and number'),
  confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword')], 'Passwords must match').required()
});

const UpdatePasswordForm = React.memo(({ onCancel }) => {
  return <Formik
    initialValues={{
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    }}
    validationSchema={validationSchema}
    onSubmit={(payload, { setSubmitting, setStatus }) => {
      const authUser = auth.currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(authUser.email, payload.currentPassword);
      authUser.reauthenticateWithCredential(credential)
        .then(() => authUser.updatePassword(payload.newPassword))
        .then(() => {
          setSubmitting(false);
          onCancel && onCancel();
        })
        .catch(error => {
          setSubmitting(false);
          setStatus({
            errorMessage: error.code === 'auth/wrong-password'
              ? 'Current password you entered is incorrect'
              : error.message
          });
        });
    }}>
    {({ isSubmitting, status }) =>
      <Form>
        <InputFormGroup name="currentPassword" label="Current Password:" type="password" description="* Please provide for security purposes" required />
        <InputFormGroup name="newPassword" label="New Password:" type="password" required />
        <InputFormGroup name="confirmNewPassword" label="Confirm New Password:" type="password" required />
        {status && status.errorMessage && <ErrorText mb={3}>{status.errorMessage}</ErrorText>}
        <Button type="submit" busy={isSubmitting} variant="primary" mr={3}>Update Password</Button>
        <Button type="button" variant="default" onClick={onCancel}>Cancel</Button>
      </Form>}
  </Formik>
});

UpdatePasswordForm.propTypes = {};

UpdatePasswordForm.defaultProps = {};

UpdatePasswordForm.displayName = 'UpdatePasswordForm';

export default UpdatePasswordForm;
