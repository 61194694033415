import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import useRelativeDate from '@paperstac/common/lib/hooks/useRelativeDate';
import { LISTING_NEGOTIATION_TYPE } from '@paperstac/firestore-collections/lib/listings';
import { normalizeDate } from '@paperstac/helpers/lib/dateHelpers';
import { centsToUsdString } from '@paperstac/helpers/lib/numberHelpers';
import getPath from '@paperstac/routes/lib/getPath';
import { FOR_SALE_SINGLE, MY_FEED_ITEM } from '@paperstac/routes/lib/webRoutes';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import Flex from '@paperstac/ui/lib/Flex';
import ExpandIcon from '@paperstac/ui/lib/icons/ExpandIcon';
import ExternalLinkIcon from '@paperstac/ui/lib/icons/ExternalLinkIcon';
import Image from '@paperstac/ui/lib/Image';
import Link from '@paperstac/ui/lib/Link';
import Text from '@paperstac/ui/lib/Text';
import UnstyledButton from '@paperstac/ui/lib/UnstyledButton';
import format from 'date-fns/format';
import get from 'lodash/get';
import React from 'react';
import { IdentityManagerContext } from '../../common/IdentityManager';
import { ListingPageContext } from './ListingPageProvider';
import Section from './Section';

const Aside = React.memo(React.forwardRef((props, ref) => {
  const { asideWidth, isSeller, listing, showGallery, showMessage, showOffer, transactionId } = React.useContext(ListingPageContext);
  const { currentAccountId } = React.useContext(AccountsContext);
  const { showLogin } = React.useContext(IdentityManagerContext);

  const stacked = asideWidth < 720;
  const sellerName = get(listing, 'seller.displayName', 'Paperstac Seller');
  const sellerAvatar = get(listing, 'seller.avatar');
  const listPrice = React.useMemo(() => centsToUsdString(listing.listPrice), [listing.listPrice]);
  const listedDateFormatted = React.useMemo(() => listing.isActive ? format(normalizeDate(listing.listedDate), 'MMM D, YYYY') : 'Not Currently Listed', [listing.isActive, listing.listedDate]);
  const listedDateRelative = useRelativeDate(listing.listedDate);
  const listingUrl = React.useMemo(() => getPath(FOR_SALE_SINGLE, listing.id), [listing.id]);
  const feedItemUrl = React.useMemo(() => transactionId ? getPath(MY_FEED_ITEM, transactionId) : '', [transactionId]);
  const showLoginButton = React.useMemo(() => !currentAccountId, [currentAccountId]);
  const showCreateEventButtons = React.useMemo(() => !!currentAccountId && !feedItemUrl, [currentAccountId, feedItemUrl]);
  const showViewTransactionButton = React.useMemo(() => !!feedItemUrl, [feedItemUrl]);

  return <Box ref={ref} {...props}>
    <Section>
      <Flex sx={{ flexDirection: stacked ? 'column' : 'row', boxShadow: '0 20px 50px -15px rgba(0,0,0,.3)' }}>
        <Box sx={{ bg: 'gray.1' }}>
          <UnstyledButton sx={{ display: 'block', cursor: 'pointer', position: 'relative' }} onClick={showGallery}>
            <Image src={listing.photoUrl} width={stacked ? asideWidth || 360 : 360} height={240} sx={{ display: 'block' }} />
            <Box sx={{
              position: 'absolute',
              top: 3,
              right: 3,
              color: 'white',
              bg: 'blue',
              borderRadius: 'corner',
              p: 2
            }}><ExpandIcon size={24} /></Box>
          </UnstyledButton>
          {!!currentAccountId && <Box sx={{ py: 3, px: 4, bg: 'darkBlue', color: 'gray.3', position: 'relative' }}>
            <Text sx={{ fontSize: 1 }}>Listed By</Text>
            <Text sx={{ fontSize: 3, fontWeight: 'bold', color: 'white' }}>{sellerName}</Text>
            <Image src={sellerAvatar} width={70} height={70} sx={{
              borderRadius: 'circle',
              position: 'absolute',
              top: '-30px',
              right: 4,
              border: '3px solid white',
              bg: 'white',
            }} />
          </Box>}
        </Box>
        <Flex sx={{
          flex: stacked ? null : '1 1 0',
          flexDirection: 'column',
        }}>
          <Box sx={{ bg: 'white', py: 3, px: 4, flex: stacked ? null : '1 1 0' }}>
            <Text sx={{ fontSize: 1, color: 'gray.7' }}>List Price</Text>
            <Text sx={{
              fontSize: 5,
              fontWeight: 'bold',
              mb: 2
            }}>{listing.negotiationType === LISTING_NEGOTIATION_TYPE.MAKE_OFFER ? 'Make an Offer' : listPrice}</Text>
            <Text sx={{ fontSize: 1, color: 'gray.7' }}>Date Listed</Text>
            <Box>
              <Text sx={{ fontSize: 3, fontWeight: 'bold', display: 'inline' }}>{listedDateFormatted}</Text>
              {listing.isActive && <Text sx={{
                fontSize: 2,
                ml: 2,
                fontStyle: 'italic',
                display: 'inline',
                color: 'gray.7'
              }}>({listedDateRelative})</Text>}
            </Box>
          </Box>
          <Box sx={{
            bg: 'gray.1',
            py: 3,
            px: 4,
            flex: stacked ? null : '1 1 0',
            borderTop: 'default'
          }}>
            {showLoginButton &&
            <Button onClick={showLogin} variant="primary" block={stacked}>Login to Contact Seller</Button>}
            {!isSeller && showCreateEventButtons && <>
              <Button onClick={showMessage} variant="primary" block={stacked} mb={2} mr={stacked ? 0 : 2}>Message Seller</Button>
              <Button onClick={showOffer} variant="primary" block={stacked}>Make an Offer</Button>
            </>}
            {!isSeller && showViewTransactionButton &&
            <Button as={Link} to={feedItemUrl} variant="primary" sx={{ display: stacked ? 'block' : 'inline-block' }}>View Transaction</Button>}
            <Box sx={{ textAlign: stacked ? 'center' : 'left', mt: isSeller ? 2 : 3, mb: 2 }}>
              <Link to={listingUrl} target="_blank">Direct link to listing <ExternalLinkIcon /></Link>
            </Box>
          </Box>
        </Flex>
      </Flex>
    </Section>
  </Box>;
}));

Aside.propTypes = {};

Aside.defaultProps = {};

Aside.displayName = 'Aside';

export default Aside;
