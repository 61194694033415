import { createProfileForAccount } from '../services/account';
import { firestore } from '../services/firebaseClient';
import dateid from '../utils/dateid';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const SET_MY_BLACKLIST = 'SET_MY_BLACKLIST';
export const SET_BLACKLISTED_BY = 'SET_BLACKLISTED_BY';

const transformCollection = querySnapshot => {
  return querySnapshot.docs.map(transformDoc);
};

const transformDoc = docSnap => {
  return docSnap.exists ? transformFirestoreTimestamps(docSnap) : null;
};

export const setMyBlacklist = payload => {
  return { type: SET_MY_BLACKLIST, payload };
};

export const setBlacklistedBy = payload => {
  return { type: SET_BLACKLISTED_BY, payload };
};

export const addToBlacklistRequest = (blacklisterId, blacklistedAccount) => {
  const payload = {
    id: dateid(),
    createdDate: new Date().toISOString(),
    blacklisterId,
    blacklisteeId: blacklistedAccount.id,
    blacklistee: createProfileForAccount(blacklistedAccount)
  };
  return dispatch => {
    return firestore.doc(`blacklists/${payload.id}`).set(payload);
  };
};

export const removeFromBlacklistRequest = blacklistId => {
  return dispatch => {
    return firestore.doc(`blacklists/${blacklistId}`).delete();
  };
};

export const subscribeMyBlacklist = accountId => {
  return dispatch => {
    return firestore
      .collection('blacklists')
      .where('blacklisterId', '==', accountId)
      .onSnapshot(querySnapshot => {
        dispatch(setMyBlacklist(transformCollection(querySnapshot)));
      });
  };
};

export const subscribeBlacklistedBy = accountId => {
  return dispatch => {
    return firestore
      .collection('blacklists')
      .where('blacklisteeId', '==', accountId)
      .onSnapshot(querySnapshot => {
        dispatch(setBlacklistedBy(transformCollection(querySnapshot)));
      });
  };
};
