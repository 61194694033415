import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import useFullStory from '@paperstac/common/lib/hooks/useFullStory';
import { VALIDATE_IDENTITY } from '@paperstac/common/lib/serverDispatchActionTypes';
import { getCanadianProvinces } from '@paperstac/common/lib/services/canadianProvinces';
import { getCountries } from '@paperstac/common/lib/services/countries';
import * as intercomMessenger from '@paperstac/common/lib/services/intercomMessenger';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import { getStates } from '@paperstac/common/lib/services/states';
import { validateIdentityDispatchValidator } from '@paperstac/firestore-collections/lib/identities';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import Flex from '@paperstac/ui/lib/Flex';
import CheckboxFormGroup from '@paperstac/ui/lib/form/CheckboxFormGroup';
import DateSelectFormGroup from '@paperstac/ui/lib/form/DateSelectFormGroup';
import InputFormGroup from '@paperstac/ui/lib/form/InputFormGroup';
import PrefixedInputFormGroup from '@paperstac/ui/lib/form/PrefixedInputFormGroup';
import SelectFormGroup from '@paperstac/ui/lib/form/SelectFormGroup';
import Text from '@paperstac/ui/lib/Text';
import { Form, Formik } from 'formik';
import React from 'react';
import { MergeIdentitiesContext } from '../MergeIdentities/MergeIdentitiesProvider';
import ErrorMessage from '../AuthOverlay/ErrorMessage';
import InfoMessage from '../AuthOverlay/InfoMessage';
import LinkButton from '../AuthOverlay/LinkButton';

const handleSupportRequest = () => intercomMessenger.showPanel();
const minYear = new Date().getFullYear() - 100;
const maxYear = new Date().getFullYear() - 17;

const ValidateIdentityBlockscore = React.memo((props) => {
  const FullStory = useFullStory();
  const { showMergeIdentities } = React.useContext(MergeIdentitiesContext);
  const { uid } = React.useContext(IdentityContext);

  const fullStoryEvent = React.useCallback(
    (eventName, eventProperties = {}) => {
      FullStory.event(eventName, { uid_str: uid, ...eventProperties });
    },
    [FullStory, uid]
  );

  React.useEffect(() => {
    fullStoryEvent('Identity Validation View Loaded');
  }, [fullStoryEvent]);

  return (
    <Formik
      initialValues={{
        nameFirst: '',
        nameMiddle: '',
        nameLast: '',
        birthDate: '',
        primaryAddressCountry: 'US',
        primaryAddressStreetAddress1: '',
        primaryAddressStreetAddress2: '',
        primaryAddressCity: '',
        primaryAddressState: '',
        primaryAddressZip: '',
        usCitizen: true,
        verificationType: 'ssn',
        verificationValue: '',
      }}
      validationSchema={validateIdentityDispatchValidator}
      onSubmit={(payload, { setSubmitting, setStatus }) => {
        fullStoryEvent('Submitted Identity for Validation');
        serverDispatch({ action: VALIDATE_IDENTITY, payload })
          .then(() => {
            setSubmitting(false);
            fullStoryEvent('Identity Validated');
          })
          .catch((error) => {
            setSubmitting(false);
            setStatus({ errorMessage: error.message });
            error.code === 'already-exists'
              ? setStatus({ matchingIdentityError: true })
              : setStatus({ errorMessage: error.message });
            fullStoryEvent('Identity Validation Failed', { code_str: error.code, message_str: error.message });
          });
      }}
      render={({ isSubmitting, status, values, setFieldValue }) => (
        <Form>
          <Box maxWidth={500} mx="auto">
            <Flex flexDirection={['column', 'row']}>
              <Box flex="1" mr={[0, 2]}>
                <InputFormGroup name="nameFirst" label="First Name:" required />
              </Box>
              <Box flex="1" mr={[0, 2]}>
                <InputFormGroup name="nameMiddle" label="Middle Name:" />
              </Box>
              <Box flex="1">
                <InputFormGroup name="nameLast" label="Last Name:" required />
              </Box>
            </Flex>
            <Box maxWidth={280}>
              <DateSelectFormGroup
                name="birthDate"
                label="Date of Birth:"
                minYear={minYear}
                maxYear={maxYear}
                required
              />
            </Box>
            <SelectFormGroup
              name="primaryAddressCountry"
              label="Primary Home Address:"
              onChange={(e) => {
                setFieldValue('primaryAddressCountry', e.target.value);
                setFieldValue('primaryAddressState', '');
              }}
            >
              {getCountries().map(({ code, name }) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </SelectFormGroup>
            <Flex flexDirection={['column', 'row']}>
              <Box flex="3" mr={[0, 2]}>
                <InputFormGroup name="primaryAddressStreetAddress1" label="Street Address 1:" required />
              </Box>
              <Box flex="1.3">
                <InputFormGroup
                  name="primaryAddressStreetAddress2"
                  label="Street Address 2:"
                  placeholder="Apt, Unit, etc"
                />
              </Box>
            </Flex>
            <Flex flexDirection={['column', 'row']}>
              <Box flex="2.8" mr={[0, 2]}>
                <InputFormGroup name="primaryAddressCity" label="City:" required />
              </Box>
              <Box flex="2.2" mr={[0, 2]}>
                {values.primaryAddressCountry === 'US' && (
                  <SelectFormGroup name="primaryAddressState" label="State:">
                    <option value="" />
                    {getStates().map(({ code, name }) => (
                      <option key={code} value={code}>
                        {name}
                      </option>
                    ))}
                  </SelectFormGroup>
                )}
                {values.primaryAddressCountry === 'CA' && (
                  <SelectFormGroup name="primaryAddressState" label="Province:">
                    <option value="" />
                    {getCanadianProvinces().map(({ code, name }) => (
                      <option key={code} value={code}>
                        {name}
                      </option>
                    ))}
                  </SelectFormGroup>
                )}
                {!['US', 'CA'].includes(values.primaryAddressCountry) && (
                  <InputFormGroup name="primaryAddressState" label="State/Province:" />
                )}
              </Box>
              <Box flex={values.primaryAddressCountry === 'US' ? 1 : 1.4}>
                <InputFormGroup
                  name="primaryAddressZip"
                  label={values.primaryAddressCountry === 'US' ? 'Zip Code:' : 'Postal Code:'}
                />
              </Box>
            </Flex>
            <CheckboxFormGroup
              name="usCitizen"
              checked={values['usCitizen']}
              onChange={(e) => {
                setFieldValue('usCitizen', e.target.checked);
                setFieldValue('verificationType', e.target.checked ? 'ssn' : 'passport');
              }}
              label={
                <Text fontWeight="normal" fontSize={1} mr={3}>
                  I am a US Citizen
                </Text>
              }
            />
            <Box maxWidth={200}>
              {!!values['usCitizen'] && (
                <PrefixedInputFormGroup
                  name="verificationValue"
                  label="Last 4 of Social:"
                  prefix="XXX - XX -"
                  required
                />
              )}
              {!values['usCitizen'] && <InputFormGroup name="verificationValue" label="Passport #:" required />}
            </Box>
            {status && status.errorMessage && <ErrorMessage>{status.errorMessage}</ErrorMessage>}
            {status && status.matchingIdentityError && (
              <Box>
                <ErrorMessage>
                  We have found an identity that matches the details you provided. A person is only able to have a
                  single identity on Paperstac.
                </ErrorMessage>
                <InfoMessage>
                  If you have multiple Paperstac Identities (logins), you can combine them using our{' '}
                  <LinkButton onClick={showMergeIdentities} fontSize={1} fontWeight="bold">
                    Merge Identity Tool
                  </LinkButton>
                  . If you do not have multiple Paperstac Identities, and feel you've received this error by mistake,
                  please{' '}
                  <LinkButton onClick={handleSupportRequest} fontSize={1} fontWeight="bold">
                    Contact Paperstac Support
                  </LinkButton>
                  .
                </InfoMessage>
              </Box>
            )}
            <Button variant="primary" block={true} busy={isSubmitting}>
              Submit Identity Details
            </Button>
          </Box>
        </Form>
      )}
    />
  );
});

ValidateIdentityBlockscore.propTypes = {};

ValidateIdentityBlockscore.defaultProps = {};

ValidateIdentityBlockscore.displayName = 'ValidateIdentityBlockscore';

export default ValidateIdentityBlockscore;
