import { centsToUsdString, getOrdinal } from '@paperstac/helpers/lib/numberHelpers';
import Box from '@paperstac/ui/lib/Box';
import Flex from '@paperstac/ui/lib/Flex';
import Text from '@paperstac/ui/lib/Text';
import PropTypes from 'prop-types';
import React from 'react';
import EditButton from './EditButton';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';

const PriceScreenMultiSection = React.memo(({ currentPrices, initialPrices, notes, onEdit }) => {
  const { stacked } = React.useContext(ManageListingOverlayContext);

  const getPriceDiffComponent = React.useCallback(noteId => {
    if (!currentPrices || !currentPrices[noteId] || !initialPrices || !initialPrices[noteId]) return null;
    const diffCents = currentPrices[noteId] - initialPrices[noteId];
    if (diffCents === 0) return null;
    return <Text sx={{
      display: 'inline',
      ml: 2,
      fontSize: 0,
      color: diffCents > 0 ? 'green' : 'red'
    }}>({centsToUsdString(diffCents)})</Text>;

  }, [initialPrices, currentPrices]);

  return <>
    {notes.map(note => <Flex key={note.id} alignItems="center" mt={3}>
      <Box flex={'1 1 0'}>
        <Flex alignItems={stacked ? 'flex-start' : 'center'} mb={1}>
          <Flex sx={{
            bg: note.lienPosition === 1 ? 'gray.4' : 'gray.7',
            color: note.lienPosition === 1 ? 'gray.9' : 'white',
            borderRadius: 'corner',
            fontSize: 1,
            textAlign: 'center',
            width: 36,
            py: 1,
            mr: 2,
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold'
          }}>{getOrdinal(note.lienPosition)}</Flex>
          <Flex flexDirection={stacked ? 'column' : 'row'} alignItems={stacked ? 'normal' : 'center'}>
            <Box fontSize={2} mr={stacked ? 0 : 2}>{note.streetAddress}</Box>
            <Box fontSize={1} color="gray.6">{note.cityStateZip}</Box>
          </Flex>
        </Flex>
        {currentPrices[note.id]
          ? <Text variant="bold" color="darkBlue">{centsToUsdString(currentPrices[note.id])}</Text>
          : <Text variant="bold" color="red">Price not set</Text>}
        {getPriceDiffComponent(note.id)}
      </Box>
      <Box><EditButton onClick={() => onEdit(note.id)} /></Box>
    </Flex>)}
  </>
});


PriceScreenMultiSection.propTypes = {
  notes: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
};

PriceScreenMultiSection.defaultProps = {};

PriceScreenMultiSection.displayName = 'PriceScreenMultiSection';

export default PriceScreenMultiSection;
