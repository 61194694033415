import { MERGE_IDENTITIES } from '@paperstac/common/lib/serverDispatchActionTypes';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import { getPersistenceType, PERSISTENCE_TYPES } from '@paperstac/common/lib/services/statePersistence';
import auth from '@paperstac/firebase/lib/auth';
import { getFirebase } from '@paperstac/firebase/lib/firebase';
import { loginValidator } from '@paperstac/firestore-collections/lib/identities';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import InputFormGroup from '@paperstac/ui/lib/form/InputFormGroup';
import Text from '@paperstac/ui/lib/Text';
import { Form, Formik } from 'formik';
import React from 'react';
import ErrorMessage from '../AuthOverlay/ErrorMessage';
import ViewContainer from './ViewContainer';
import ViewTitle from './ViewTitle';

const FIREBASE_AUTH_PERSISTENCE = getFirebase().auth.Auth.Persistence;

const MergeView = React.memo(({ onContinue, onCancel, token }) => <ViewContainer>
  <ViewTitle>Identity to Keep</ViewTitle>

  <Box mb={5}>Please provide the login for the Paperstac Identity that you wish to keep. we will import and merge the data from your other identity into this one. <Text sx={{
    display: 'inline',
    fontWeight: 'bold'
  }}>This will be your only Paperstac Identity moving forward</Text>.</Box>

  <Formik
    initialValues={{ email: '', password: '', stayLoggedIn: getPersistenceType() === PERSISTENCE_TYPES.LOCAL }}
    validationSchema={loginValidator}
    onSubmit={async (payload, { setSubmitting, setStatus }) => {
      try {
        await auth.setPersistence(payload.stayLoggedIn ? FIREBASE_AUTH_PERSISTENCE.LOCAL : FIREBASE_AUTH_PERSISTENCE.SESSION);
        await auth.signInWithEmailAndPassword(payload.email, payload.password);
        await serverDispatch({ action: MERGE_IDENTITIES, payload: { token } });
        onContinue(payload.email);
      } catch (error) {
        const errorMessages = {
          'auth/user-not-found': 'Email does not exist in our system.',
          'auth/wrong-password': 'Password is incorrect.',
        };
        setStatus({ errorMessage: errorMessages[error.code] || error.message });
        setSubmitting(false);
      }
    }}
    render={({ isSubmitting, status }) => <Form>
      <InputFormGroup name="email" label="Email Address:" type="email" required />
      <InputFormGroup name="password" label="Password:" type="password" required />
      {status && status.errorMessage && <ErrorMessage>{status.errorMessage}</ErrorMessage>}
      <Box mb={3}><Button type="submit" variant="primary" block busy={isSubmitting}>Continue</Button></Box>
      <Box><Button onClick={onCancel} variant="default" block>Cancel</Button></Box>
    </Form>}
  />

</ViewContainer>);

MergeView.propTypes = {};

MergeView.defaultProps = {};

MergeView.displayName = 'MergeView';

export default MergeView;
