function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

import get from 'lodash/get';
import { normalizeDate } from './dateHelpers';
export var sortCollection = function sortCollection(items, sortBy) {
  var sortDirection = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'ASC';
  return items.sort(function (itemA, itemB) {
    var direction = sortDirection.toUpperCase() === 'ASC' ? 1 : -1;
    var valueA = Array.isArray(get(itemA, sortBy)) ? get(itemA, sortBy).length : get(itemA, sortBy);
    var valueB = Array.isArray(get(itemB, sortBy)) ? get(itemB, sortBy).length : get(itemB, sortBy);

    if (valueA === valueB) {
      return 0;
    }

    if (typeof valueA === 'undefined') return -1 * direction;
    if (typeof valueB === 'undefined') return direction;
    if (typeof valueA === 'string' && typeof valueB === 'string') return valueA.toLowerCase() < valueB.toLowerCase() ? -1 * direction : direction;
    if (valueA && _typeof(valueA) === 'object' && (valueA.nanoseconds || valueA._nanoseconds) && valueB && _typeof(valueB) === 'object' && (valueA.nanoseconds || valueA._nanoseconds)) return normalizeDate(valueA) < normalizeDate(valueB) ? -1 * direction : direction;
    return valueA < valueB ? -1 * direction : direction;
  });
};