import { ADD_TOAST, REMOVE_TOAST } from '../actions/toasterActions';

const DEFAULT_STATE = [];

export default function toasterReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case ADD_TOAST:
      return [...state, payload];
    case REMOVE_TOAST:
      return state.filter(toast => toast.id !== payload.id);
    default:
      return state;
  }
}
