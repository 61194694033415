import { auditAccountsRef } from '@paperstac/firestore-collections/lib/auditAccounts';
import { firestore } from '../services/firebaseClient';
import dateid from '../utils/dateid';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const ADD_AUDIT_EVENT = 'ADD_AUDIT_EVENT';
export const SET_AUDIT_ORDER = 'SET_AUDIT_ORDER';
export const SET_AUDIT_ORDERS = 'SET_AUDIT_ORDERS';
export const UNSUBSCRIBE_AUDIT_ORDER = 'UNSUBSCRIBE_AUDIT_ORDER';
export const UNSUBSCRIBE_AUDIT_ORDERS = 'UNSUBSCRIBE_AUDIT_ORDERS';

const unsubscribes = {};

const transformCollection = querySnapshot => {
  return querySnapshot.docs.map(transformDoc);
};

const transformDoc = docSnap => {
  return docSnap.exists
    ? transformFirestoreTimestamps(docSnap)
    : null;
};

export const setAuditOrders = payload => {
  return { type: SET_AUDIT_ORDERS, payload };
};

export const setAuditOrder = payload => {
  return { type: SET_AUDIT_ORDER, payload };
};

export const subscribeAuditOrders = (accountId) => {
  return async dispatch => {
    const auditAccountRef = await auditAccountsRef().where(`members.${accountId}.id`, '==', accountId).get().then(qs => qs.docs[0]);
    if (!auditAccountRef) return;
    unsubscribes.auditOrders = firestore
      .collection('auditOrders')
      .where('auditAccountId', '==', auditAccountRef.data().id)
      .onSnapshot(querySnapshot => {
        dispatch(setAuditOrders(transformCollection(querySnapshot)));
      });
  };
};

export const unsubscribeAuditOrders = () => {
  return dispatch => {
    if (unsubscribes.auditOrders) {
      unsubscribes.auditOrders();
      dispatch({ type: UNSUBSCRIBE_AUDIT_ORDERS });
      unsubscribes.auditOrders = null;
    }
  };
};

export const subscribeAuditOrder = (auditOrderId) => {
  return dispatch => {
    unsubscribes[auditOrderId] = firestore
      .doc(`auditOrders/${auditOrderId}`)
      .onSnapshot(docSnap => {
        dispatch(setAuditOrder(transformDoc(docSnap)));
      });
  };
};

export const unsubscribeAuditOrder = (auditOrderId) => {
  return dispatch => {
    if (unsubscribes[auditOrderId]) {
      unsubscribes[auditOrderId]();
      dispatch({ type: UNSUBSCRIBE_AUDIT_ORDER });
      unsubscribes[auditOrderId] = null;
    }
  };
};

export const createAuditOrderEventRequest = (auditOrderId, data) => {
  return dispatch => {
    const payload = { ...data, id: dateid() };
    return firestore
      .doc(`auditOrders/${auditOrderId}/events/${payload.id}`)
      .set(payload)
      .then(() => dispatch({ type: ADD_AUDIT_EVENT, payload }))
      .catch(error => console.error(error));
  };
};
