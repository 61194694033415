import { END_SESSION } from '../actions/authActions';
import { SET_TEAM_INVITES, SET_TEAM_THAT_INVITED } from '../actions/teamInviteActions';
import { SWITCH_CURRENT_ACCOUNT } from '../actions/currentAccountActions';

const DEFAULT_STATE = {
  invites: [],
  teamThatInvited: null
};

export default function teamInvitesReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
    case SWITCH_CURRENT_ACCOUNT:
      return { ...state, invites: [] };
    case SET_TEAM_INVITES:
      return payload ? { ...state, invites: payload } : { ...state, invites: [] };
    case SET_TEAM_THAT_INVITED:
      return payload ? { ...state, teamThatInvited: payload } : { ...state, teamThatInvited: null };
    default:
      return state;
  }
}

export const getTeamInvites = state => {
  return state.invites;
};

export const getTeamThatInvited = state => {
  return state.teamThatInvited;
};
