import React from 'react';
import Button from './Button';
import Input from './Input';
import './SubmitField.css';

export default ({
  input,
  buttonText = 'Submit',
  label,
  type,
  placeholder,
  description,
  disabled,
  required,
  width = '100%',
  busy,
  meta: { touched, error }
}) => (
  <div className="SubmitField">
    {label && (
      <label className={required && type !== 'checkbox' ? 'SubmitField__label--required' : ''}>
        {label}
        {type === 'checkbox' ? ' *' : ':'}
      </label>
    )}
    <div className="SubmitFiled__group" style={{ width }}>
      <Input
        className="SubmitFiled__input"
        hasError={touched && error}
        input={input}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        squareRight
      />
      <Button type="submit" busy={busy} primary small squareLeft>
        {buttonText}
      </Button>
    </div>
    {description && <div className="SubmitFiled__description">{description}</div>}
    {touched && error && <div className="SubmitFiled__error">{error}</div>}
  </div>
);
