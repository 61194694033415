import Flex from '@paperstac/ui/lib/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import Stat from './Stat';

const StatGrid = React.memo(({ stat1, stat2, stat3, stat4 }) => {
  return <Flex flexDirection={['column', 'column', 'column', 'row']}>
    <Flex justifyContent="space-around" mb={[40, 64, 64, 0]} flex="1">
      <Stat {...stat1} />
      <Stat {...stat2} />
    </Flex>
    <Flex justifyContent="space-around" flex="1">
      <Stat {...stat3} />
      <Stat {...stat4} />
    </Flex>
  </Flex>
});

StatGrid.propTypes = {
  stat1: PropTypes.object.isRequired,
  stat2: PropTypes.object.isRequired,
  stat3: PropTypes.object.isRequired,
  stat4: PropTypes.object.isRequired,
};

StatGrid.defaultProps = {};

StatGrid.displayName = 'StatGrid';

export default StatGrid;