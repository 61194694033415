import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import Box from '@paperstac/ui/lib/Box';
import Heading from '@paperstac/ui/lib/Heading';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';
import CreateAccountOverlay from '../CreateAccountOverlay';
import Section from './Section';

const AccountsSection = React.memo(props => {
  const { accounts } = React.useContext(AccountsContext);
  const [visible, setVisible] = React.useState(false);
  const show = React.useCallback(() => setVisible(true), []);
  const hide = React.useCallback(() => setVisible(false), []);

  return <Section>
    <Heading fontSize={4} mb={3}>My Accounts</Heading>
    <Box mb={3}><LinkButton onClick={show}><Text variant="bold">+</Text> Create Another Account</LinkButton></Box>
    <Text as="ul" sx={{ p: 0, m: 0, listStylePosition: 'inside' }}>
      {accounts.map(account => <Text key={account.id} as="li">{account.displayName}</Text>)}
    </Text>
    {visible && <CreateAccountOverlay onClose={hide} />}
  </Section>;
});

AccountsSection.propTypes = {};

AccountsSection.defaultProps = {};

AccountsSection.displayName = 'AccountsSection';

export default AccountsSection;
