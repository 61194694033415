import React from 'react';
import { LIGHT_GRAY, REM, SPACER } from '../theme';
import Button from './Button';
import Overlay from './Overlay';

export default props => (
  <Overlay className="RefreshWebsiteOverlay">
    <div style={{ backgroundColor: LIGHT_GRAY, padding: SPACER(3), textAlign: 'center', width: REM(560) }}>
      <h1 style={{ textAlign: 'center', marginBottom: SPACER(2) }}>Website Update Available</h1>
      <p style={{ fontSize: REM(18), marginBottom: SPACER(2) }}>
        We have just updated the Paperstac website. Please refresh the page to load the newest version.
      </p>
      <Button accent onClick={() => window.location.reload(true)}>Refresh Page</Button>
    </div>
  </Overlay>
);
