import { CREATE_NOTE_ADJUSTMENT, UPDATE_NOTE_ADJUSTMENT } from '@paperstac/common/lib/serverDispatchActionTypes';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import { createNoteAdjustmentValidator } from '@paperstac/firestore-collections/lib/noteAdjustments';
import Button from '@paperstac/ui/lib/Button';
import DescriptionGrid from '@paperstac/ui/lib/DescriptionGrid';
import DescriptionGridCell from '@paperstac/ui/lib/DescriptionGridCell';
import ErrorText from '@paperstac/ui/lib/ErrorText';
import Flex from '@paperstac/ui/lib/Flex';
import DateSelect from '@paperstac/ui/lib/form/DateSelect';
import Description from '@paperstac/ui/lib/form/Description';
import Input from '@paperstac/ui/lib/form/Input';
import UsdInput from '@paperstac/ui/lib/form/UsdInput';
import ChevronRightIcon from '@paperstac/ui/lib/icons/ChevronRightIcon';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import get from 'lodash/get';
import React from 'react';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';
import { NoteScreenContext } from './NoteScreen';

const NoteScreenManageAdjustment = React.memo(() => {
  const { note, showOverview: showListing, stacked } = React.useContext(ManageListingOverlayContext);
  const { adjustment, showOverview } = React.useContext(NoteScreenContext);
  const [detailsVisible, setDetailsVisible] = React.useState(false);
  const showDetails = React.useCallback(() => setDetailsVisible(true), []);
  const hideDetails = React.useCallback(() => setDetailsVisible(false), []);

  return (
    <DescriptionGrid
      sx={{
        bg: 'gray.1',
        border: 'none',
        borderTop: 'default',
        borderBottom: 'default',
        borderRadius: 0,
      }}
    >
      <DescriptionGridCell bg="highlight">
        <Flex alignItems="center">
          <LinkButton onClick={showListing}>Listing</LinkButton>
          <ChevronRightIcon size={12} mx={2} color="gray.6" />
          <LinkButton onClick={showOverview}>Note</LinkButton>
          <ChevronRightIcon size={12} mx={2} color="gray.6" />
          {adjustment ? 'Edit' : 'Add'} Adjustment
        </Flex>
      </DescriptionGridCell>
      <Formik
        initialValues={
          adjustment
            ? adjustment
            : {
                accountId: note.accountId,
                noteId: note.id,
                transactionDate: '',
                description: '',
                transactionAmount: '',
                dueDate: '',
                principalAmount: '',
                principalBalance: '',
                arrearageForbearancePrincipal: '',
                interestAmount: '',
                unappliedAmount: '',
                escrowAmount: '',
                escrowBalance: '',
                lateChargeAmount: '',
                lateChargeBalance: '',
              }
        }
        validationSchema={createNoteAdjustmentValidator()}
        onSubmit={async (payload, { setSubmitting, setStatus }) => {
          setSubmitting(true);
          serverDispatch({ action: payload.id ? UPDATE_NOTE_ADJUSTMENT : CREATE_NOTE_ADJUSTMENT, payload })
            .then(showOverview)
            .catch((error) => {
              setStatus({ errorMessage: error.message });
              setSubmitting(false);
            });
        }}
        children={(formik) => (
          <Form>
            <DescriptionGridCell term="Adjustment Description" as="label">
              <Field name="description" component={Input} placeholder="e.g. Regular Payment" />
              <ErrorMessage name="description" component={ErrorText} />
            </DescriptionGridCell>
            <Flex flexDirection={stacked ? 'column' : 'row'}>
              <DescriptionGridCell
                term="Transaction Date"
                as="label"
                sx={{ borderRight: stacked ? 'none' : 'default' }}
              >
                <Field name="transactionDate" component={DateSelect} />
                <ErrorMessage name="transactionDate" component={ErrorText} />
              </DescriptionGridCell>
              <DescriptionGridCell term="Amount" as="label" sx={{ flex: stacked ? '1 1 0' : '0 0 40%' }}>
                <Field name="transactionAmount" component={UsdInput} />
                <ErrorMessage name="transactionAmount" component={ErrorText} />
              </DescriptionGridCell>
            </Flex>
            <DescriptionGridCell>
              {detailsVisible && <LinkButton onClick={hideDetails}>Hide Optional Details</LinkButton>}
              {!detailsVisible && <LinkButton onClick={showDetails}>Show Optional Details</LinkButton>}
            </DescriptionGridCell>
            {detailsVisible && (
              <>
                <DescriptionGridCell term="Due Date" as="label">
                  <Field name="dueDate" component={DateSelect} />
                  <Description>Date payment/adjustment was due</Description>
                  <ErrorMessage name="dueDate" component={ErrorText} />
                </DescriptionGridCell>
                <Flex flexDirection={stacked ? 'column' : 'row'}>
                  <DescriptionGridCell
                    term="Principal Amount"
                    as="label"
                    sx={{ borderRight: stacked ? 'none' : 'default' }}
                  >
                    <Field name="principalAmount" component={UsdInput} />
                    <Description>Amount applied towards principal</Description>
                    <ErrorMessage name="principalAmount" component={ErrorText} />
                  </DescriptionGridCell>
                  <DescriptionGridCell term="Principal Balance" as="label">
                    <Field name="principalBalance" component={UsdInput} />
                    <Description>Principal balance after adjustment</Description>
                    <ErrorMessage name="principalBalance" component={ErrorText} />
                  </DescriptionGridCell>
                </Flex>
                <Flex flexDirection={stacked ? 'column' : 'row'}>
                  <DescriptionGridCell
                    term="Arrearage/Forbearance"
                    as="label"
                    sx={{ borderRight: stacked ? 'none' : 'default' }}
                  >
                    <Field name="arrearageForbearancePrincipal" component={UsdInput} />
                    <Description>Arrearage/Forbearance principal balance</Description>
                    <ErrorMessage name="arrearageForbearancePrincipal" component={ErrorText} />
                  </DescriptionGridCell>
                  <DescriptionGridCell
                    term="Interest Amount"
                    as="label"
                    sx={{ borderRight: stacked ? 'none' : 'default' }}
                  >
                    <Field name="interestAmount" component={UsdInput} />
                    <Description>Amount applied towards interest</Description>
                    <ErrorMessage name="interestAmount" component={ErrorText} />
                  </DescriptionGridCell>
                  <DescriptionGridCell term="Unapplied Amount" as="label">
                    <Field name="unappliedAmount" component={UsdInput} />
                    <Description>Amount that has not been applied to any balance</Description>
                    <ErrorMessage name="unappliedAmount" component={ErrorText} />
                  </DescriptionGridCell>
                </Flex>
                <Flex flexDirection={stacked ? 'column' : 'row'}>
                  <DescriptionGridCell
                    term="Escrow Amount"
                    as="label"
                    sx={{ borderRight: stacked ? 'none' : 'default' }}
                  >
                    <Field name="escrowAmount" component={UsdInput} />
                    <Description>Amount applied towards escrow</Description>
                    <ErrorMessage name="escrowAmount" component={ErrorText} />
                  </DescriptionGridCell>
                  <DescriptionGridCell term="Escrow Balance" as="label">
                    <Field name="escrowBalance" component={UsdInput} />
                    <Description>Escrow balance after adjustment</Description>
                    <ErrorMessage name="escrowBalance" component={ErrorText} />
                  </DescriptionGridCell>
                </Flex>
                <Flex flexDirection={stacked ? 'column' : 'row'}>
                  <DescriptionGridCell
                    term="Late Charge Amount"
                    as="label"
                    sx={{ borderRight: stacked ? 'none' : 'default' }}
                  >
                    <Field name="lateChargeAmount" component={UsdInput} />
                    <Description>Amount applied towards late fees</Description>
                    <ErrorMessage name="lateChargeAmount" component={ErrorText} />
                  </DescriptionGridCell>
                  <DescriptionGridCell term="Late Charge Balance" as="label">
                    <Field name="lateChargeBalance" component={UsdInput} />
                    <Description>Late charge balance after adjustment</Description>
                    <ErrorMessage name="lateChargeBalance" component={ErrorText} />
                  </DescriptionGridCell>
                </Flex>
              </>
            )}
            <DescriptionGridCell>
              {get(formik, 'status.errorMessage') && <ErrorText mb={3}>{formik.status.errorMessage}</ErrorText>}
              <Button type="submit" variant="primary" busy={formik.isSubmitting} mr={2}>
                Save Adjustment
              </Button>
              <Button variant="default" onClick={showOverview}>
                Cancel
              </Button>
            </DescriptionGridCell>
          </Form>
        )}
      />
    </DescriptionGrid>
  );
});

NoteScreenManageAdjustment.propTypes = {};

NoteScreenManageAdjustment.defaultProps = {};

NoteScreenManageAdjustment.displayName = 'NoteScreenManageAdjustment';

export default NoteScreenManageAdjustment;
