import Cookies from 'js-cookie';
import qs from 'qs';

const DEFAULT_CAMPAIGN = {
  campaign: '',
  source: 'paperstac',
  medium: 'direct',
  term: '',
  content: ''
};

class Campaign {
  constructor() {
    if (Campaign.isSupported()) {
      this.COOKIE_KEY = 'ps-campaign';
      this.COOKIE_SETTINGS = { expires: 1 }; // day(s)
    }
  }

  static isSupported() {
    return typeof window !== 'undefined';
  }

  static getCampaignFromUTM() {
    return qs.parse(window.location.search, { ignoreQueryPrefix: true }).utm_campaign
      ? {
        campaign: qs.parse(window.location.search, { ignoreQueryPrefix: true }).utm_campaign,
        source: qs.parse(window.location.search, { ignoreQueryPrefix: true }).utm_source || '',
        medium: qs.parse(window.location.search, { ignoreQueryPrefix: true }).utm_medium || '',
        term: qs.parse(window.location.search, { ignoreQueryPrefix: true }).utm_term || '',
        content: qs.parse(window.location.search, { ignoreQueryPrefix: true }).utm_content || '',
      }
      : null;
  }

  static getCampaignFromReferrer() {
    if (window.document.referrer !== '') {
      const parser = window.document.createElement('a');
      parser.href = window.document.referrer;
      let pathname = parser.pathname;
      if (pathname.charAt(0) !== '/') {
        // IE omits leading slash.  Let's add it back if necessary.
        pathname = `/${pathname}`;
      }
      return {
        campaign: pathname,
        source: parser.hostname,
        medium: 'referral',
        term: '',
        content: ''
      };
    }
    return null;
  }

  init() {
    if (Campaign.isSupported() && (Campaign.getCampaignFromUTM() || !this.exists())) {
      this.set();
    }
  }

  exists() {
    if (!Campaign.isSupported()) {
      return false;
    }
    return !!this.get();
  }

  set() {
    if (!Campaign.isSupported()) {
      return;
    }
    let campaign = Campaign.getCampaignFromUTM();
    if (!campaign) {
      campaign = Campaign.getCampaignFromReferrer();
    }
    if (!campaign) {
      campaign = DEFAULT_CAMPAIGN;
    }
    Cookies.set(this.COOKIE_KEY, campaign, this.COOKIE_SETTINGS);
  }

  get() {
    if (!Campaign.isSupported()) {
      return {};
    }
    return Cookies.getJSON(this.COOKIE_KEY);
  }
}

export default new Campaign();
