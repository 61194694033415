import { BASE_URL } from '@paperstac/env';
import getPath from '@paperstac/routes/lib/getPath';
import { BLOG_POSTS_SINGLE } from '@paperstac/routes/lib/webRoutes';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import Container from '@paperstac/ui/lib/Container';
import Heading from '@paperstac/ui/lib/Heading';
import Image from '@paperstac/ui/lib/Image';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';
import FacebookComments from '../../common/FacebookComments';
import FacebookShareButton from '../../FacebookShareButton';
import BlogPostLayout from '../../layouts/BlogPostLayout';

const slug = 'mortgage-note-investing-in-2020';
export const metadata = {
  postTitle: "Mortgage Note Investing Market Numbers During COVID-19",
  metaTitle: "Mortgage Notes and the Effect of COVID-19",
  metaDescription: 'Paperstac and Notes Direct combine efforts to bring their data they\'ve seen prior to COVID-19 and post.',
  slug,
  url: `${BASE_URL}${getPath(BLOG_POSTS_SINGLE, slug)}`,
  publishDate: '2020-08-25 08:00',
  featureImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1598299543/Papestac%20Blog/Investing%20in%20COVID-19/bobAndRick_COVID-19.png',
  socialImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1598299609/Papestac%20Blog/Investing%20in%20COVID-19/bobAndRick12001.png',
  tags: ['note investing', '2020', 'market report'],
};

const MortgageNoteInvestingMarketNumbersDuringCovid19 = React.memo(() => {
  return (
    <BlogPostLayout metadata={metadata}>
      <Heading as="h1" sx={{ mt: 0, mb: 5, textAlign: 'center' }}>
        <Image src={metadata.featureImageUrl} alt={metadata.postTitle} />
      </Heading>
      <Container maxWidth={800}>
        <Box sx={{ textAlign: 'right', mb: 4 }}>
          <FacebookShareButton url={metadata.url} />
        </Box>

        <Text as="p" mb={4}>
          The market for investing in mortgage notes changes from year to year. Still, usually there is a formula of predictability that acts as a crystal ball allowing investors to gauge how the coming months will play out. <Text as="span" variant="bold">That is the case usually...enter 2020.</Text>
        </Text>
        <Text as="p" mb={4}>
          We're past the halfway point for 2020 and all formulas for predicting the future can be tossed out the window. We had a worldwide pandemic, the ultimate black swan event, come through like a wrecking ball and turn the world upside down. Businesses shut down, employees furloughed or laid off, foreclosure and eviction moratoriums, cats and dogs living together, total anarchy. Did I mention that we are in an election year.
        </Text>
        <Text as="p" mb={4}>
          There are many questions about how the market reacted to Coronavirus and what is the state of the industry, with the most common question surrounding what's to come.
        </Text>
        <Text as="p" mb={4}>
          Investors want to know how to make sense of the current market, and what the coming months hold for mortgage note investors. Conventional wisdom says to look at the history books and see what the market did during a similar time period; you know history repeats itself. Well, I looked and can’t find the guide to how this one will play out. Fortunately, we can still look back in history and get an idea of how the market reacted to COVID-19 and how it will play out.
        </Text>
        <Text as="p" mb={4}>
          The two leading mortgage note trading platforms, Paperstac and NotesDirect, teamed up to deliver a report on the trading activity in the first part of 2020. Directed by Bob Repass from NotesDirect and Rick Allen from Paperstac, the two platforms combined their data into a White Paper and Infographic providing mortgage note investors with insights on what took place during the first six months of trading activity.
        </Text>
        <Text as="p" mb={6}>
          While this mid-year report is excellent for understanding how the market reacted immediately to the pandemic, it also sets a benchmark that will be used for gauging the future. The findings in this report will be compared with the trading activity for the next 6 months, and will be analyzed in the end of the year report for 2020, which is due out in late January 2021.
        </Text>
        <Box sx={{ mb: 6, textAlign: 'center' }}>
          <Button as="a" variant="primary" href="https://res.cloudinary.com/paperstac/image/upload/v1597345458/Papestac%20Blog/An_Inside_Look_2020.pdf" target="_blank" rel="noopener nofollow">Download White Paper</Button>
        </Box>
      </Container>

      <Box sx={{ textAlign: 'center', mb: 6 }}>
        <Image src="https://res.cloudinary.com/paperstac/image/upload/v1598300227/Papestac%20Blog/Investing%20in%20COVID-19/mid_year_infographic.png" alt="Mortgage Note Investing Market Numbers During COVID-19 Infographic" />
      </Box>

      <Container maxWidth={800}>
        <Heading mb={3}>Leave us a comment and let us know what you think.</Heading>
        <FacebookComments url={metadata.url} />
      </Container>
    </BlogPostLayout>
  );
});

MortgageNoteInvestingMarketNumbersDuringCovid19.propTypes = {};

MortgageNoteInvestingMarketNumbersDuringCovid19.defaultProps = {};

MortgageNoteInvestingMarketNumbersDuringCovid19.displayName = 'MortgageNoteInvestingMarketNumbersDuringCovid19';

export default MortgageNoteInvestingMarketNumbersDuringCovid19;
