import get from 'lodash/get';
import uniq from 'lodash/uniq';
import { centsToShortUsdString, getOrdinal } from '@paperstac/helpers/lib/numberHelpers';
export default (function (listing) {
  var upbRate = "".concat(centsToShortUsdString(get(listing, 'noteMetaAgg.upb')), " UPB @ ").concat(get(listing, 'noteMetaAgg.interestRatePercent'), "%");
  var value = get(listing, 'noteMetaAgg.propertyValue') ? "".concat(centsToShortUsdString(get(listing, 'noteMetaAgg.propertyValue')), " property value") : '';

  if (listing.isPool) {
    var perf = uniq(get(listing, 'noteMetaAgg.performance')).length === 1 ? get(listing, 'noteMetaAgg.performance')[0] : 'PL/NPL';
    var lienPosition = uniq(get(listing, 'noteMetaAgg.lienPosition')).length === 1 ? "".concat(getOrdinal(get(listing, 'noteMetaAgg.lienPosition')[0]), "'s") : '';
    var noteCount = listing.notes.length;
    var senior = uniq(get(listing, 'noteMetaAgg.lienPosition')).length === 1 && get(listing, 'noteMetaAgg.lienPosition')[0] > 1 && uniq(get(listing, 'noteMetaAgg.seniorPerformance')).length === 1 && !!get(listing, 'noteMetaAgg.seniorPerformance')[0] ? "".concat(get(listing, 'noteMetaAgg.seniorPerformance')[0], " Seniors") : '';
    var valueOrSenior = senior || value;
    var location = uniq(get(listing, 'noteMetaAgg.state')).sort().join(', ');
    return "".concat(perf, " Pool of (").concat(noteCount, ") ").concat(lienPosition, " - ").concat(upbRate, " - ").concat(valueOrSenior, " in ").concat(location);
  } else {
    var note = listing.notes[0];
    var perfLien = "".concat(note.performance, " ").concat(getOrdinal(note.lienPosition));

    var _senior = note.lienPosition > 1 && note.seniorPerformance ? "".concat(note.seniorPerformance, " Senior") : '';

    var _valueOrSenior = _senior || value;

    var _location = "".concat(note.city, ", ").concat(note.state);

    return "".concat(perfLien, " - ").concat(upbRate, " - ").concat(_valueOrSenior, " in ").concat(_location);
  }
});