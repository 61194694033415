import classNames from 'classnames';
import React from 'react';
import './Unbutton.css';

export default props => (
  <button
    onClick={props.onClick}
    title={props.title}
    type="button"
    style={props.style}
    className={classNames('Unbutton', props.className, {
      'Unbutton--link': props.link,
      'Unbutton--editable': props.editable
    })}
  >
    {props.children}
  </button>
);
