export * from './authRoutes';
export var ABOUT = '/about';
export var ACCEPT_TEAM_INVITE = '/accept-team-invite';
export var ACCOUNT_STATEMENTS = '/documents/account-statements';
export var ACTION = '/action';
export var ADMIN = '/admin';
export var ADMIN_ACCOUNTS = '/admin/accounts';
export var ADMIN_CAMPAIGNS = '/admin/campaigns';
export var ADMIN_DATABASE = '/admin/database';
export var ADMIN_ESCROW_ORDERS = '/admin/escrow-orders';
export var ADMIN_IDENTITIES = '/admin/identities';
export var ADMIN_JOBS = '/admin/jobs';
export var ADMIN_MODERATE_EVENTS = '/admin/moderate-events';
export var ADMIN_REPORTS = '/admin/reports';
export var ADMIN_RESTORE_POINTS = '/admin/restore-points';
export var ADMIN_SENSITIVITY_REVIEW = '/admin/sensitivity-review';
export var ADMIN_SETTINGS = '/admin/settings';
export var ADMIN_SHORT_URLS = '/admin/short-urls';
export var ADMIN_TASKS = '/admin/tasks';
export var ADMIN_TRANSACTIONS = '/admin/transactions';
export var AUDIT_ORDERS_ARCHIVE = '/audit-orders';
export var AUDIT_ORDERS_SINGLE = '/audit-orders/:id';
export var BANK_ACCOUNTS_ARCHIVE = '/payment-methods';
export var BLOCKED_ACCOUNTS = '/blocked-accounts';
export var BLOG_POSTS_ARCHIVE = '/blog';
export var BLOG_POSTS_SINGLE = '/blog/:id/';
export var BLOG_TAG_ARCHIVE = '/blog/tag/:id';
export var DASHBOARD = '/dashboard';
export var DOCUMENTS = ACCOUNT_STATEMENTS;
export var ESCROW_ACCOUNT_BALANCE = '/escrow-account-balance';
export var ESCROW_ORDERS_ARCHIVE = '/escrow-orders';
export var ESCROW_ORDERS_SINGLE = '/escrow-orders/:id';
export var FOR_SALE_ARCHIVE = '/for-sale';
export var FOR_SALE_SINGLE = '/for-sale/:id';
export var FROZEN = '/frozen';
export var HOME = '/';
export var INVEST = '/invest';
export var INVEST_IN_PAPERSTAC = '/invest-in-paperstac';
export var IMPORT_SINGLE = '/my-imports/:id';
export var IMPORTS_ARCHIVE = '/my-imports';
export var LISTINGS_ARCHIVE = '/my-listings';
export var MANAGE_ACCOUNT = '/manage-account';
export var MANAGE_COMMUNITY = '/manage-community/:id';
export var MANAGE_COMMUNITY_INVITES = '/manage-community/:id/invites';
export var MY_COMMUNITIES_ARCHIVE = '/my-communities';
export var MY_FEED = '/my-feed';
export var MY_FEED_ITEM = '/my-feed?itemId=:id';
export var NOTE_FILES = '/my-notes/:id/files';
export var NOTE_HISTORY = '/my-notes/:id/history';
export var NOTE_PHOTOS = '/my-notes/:id/photos';
export var NOTE_SINGLE = '/my-notes/:id';
export var NOTES_ARCHIVE = '/my-notes';
export var PAYMENT_METHODS = '/payment-methods';
export var PRICING = '/pricing';
export var PRIVACY_POLICY = '/privacy-policy';
export var SDIRA_INTEGRATIONS = '/sdira-integrations';
export var SELLER_ONBOARDING = '/seller-onboarding';
export var SERVICER_PROFILES_ARCHIVE = '/servicer-profiles';
export var SHIPPING_ADDRESSES_ARCHIVE = '/shipping-addresses';
export var SIGNATURE_PAD = '/signature-pad/:id';
export var TEAMS_ARCHIVE = '/teams';
export var TERMS = '/terms';
export var TRANSACTIONS_ARCHIVE = '/my-transactions';
export var TRANSACTIONS_SINGLE = '/my-transactions/:id';
export var TRANSACTIONS_SINGLE_TAB = '/my-transactions/:id/:tab';
export var TRANSACTIONS_SINGLE_NOTE = '/my-transactions/:id/notes/:noteId';
export var USER_ACTIONS = '/user-actions';
export var VESTING_PROFILES_ARCHIVE = '/vesting-profiles';
