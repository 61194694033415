/* eslint no-undef: 0 no-unused-vars: 0 */

import { GET_INTERCOM_USER_HASH } from '@paperstac/common/lib/serverDispatchActionTypes';
import Sentry from './sentry';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import { APP_ENV, INTERCOM_APP_ID } from '@paperstac/env';

let booted = false;

const DELAY_INITIAL_LOAD_MS = 5000;

const isSupported = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement &&
  APP_ENV !== 'testing'
);

const init = () => {
  if (!isSupported) {
    return;
  }

  if (!window.Intercom) {
    (function (w, d, id, s, x) {
      function i() {
        i.c(arguments);
      }

      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      s = d.createElement('script');
      s.async = 1;
      s.src = 'https://widget.intercom.io/widget/' + id;
      d.head.appendChild(s);
    })(window, document, INTERCOM_APP_ID);
  }

  if (!booted && window.Intercom) {
    window.Intercom('boot', { app_id: INTERCOM_APP_ID });
    booted = true;
  }
};

export const login = async (currentUserId) => {
  Sentry.addBreadcrumb({
    category: 'intercomClient.login',
    message: `Beginning Intercom login attempt for ${currentUserId}`,
    level: 'info',
  });
  if (isSupported && !window.Intercom) {
    window.setTimeout(() => {
      init();
      return login(currentUserId);
    }, DELAY_INITIAL_LOAD_MS);
  } else {
    try {
      const response = await serverDispatch({ action: GET_INTERCOM_USER_HASH });
      const userHash = response.data;
      if (userHash) {
        window.Intercom('update', { app_id: INTERCOM_APP_ID, user_id: currentUserId, user_hash: userHash });
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }
};

export const logout = () => {
  if (isSupported && !window.Intercom) {
    window.setTimeout(() => {
      init();
      logout();
    }, DELAY_INITIAL_LOAD_MS);
  } else {
    window.Intercom('update', { app_id: INTERCOM_APP_ID });
  }
};

export const showPanel = () => {
  if (isSupported && !window.Intercom) {
    init();
  }
  window.Intercom('show');
};
