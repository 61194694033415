import React from 'react';
import { withRouter } from 'react-router-dom';

const ServiceWorkerRefresh = React.memo(props => {

  React.useEffect(() => {
    if (window.swUpdate) window.location.reload();
  }, [props.location]);

  return null;
});

ServiceWorkerRefresh.propTypes = {};

ServiceWorkerRefresh.defaultProps = {};

ServiceWorkerRefresh.displayName = 'ServiceWorkerRefresh';

export default withRouter(ServiceWorkerRefresh);
