import { END_SESSION } from '../actions/authActions';
import { UNSUBSCRIBE_CURRENT_ACCOUNT } from '../actions/currentAccountActions';
import { SET_ESCROW_ACCOUNT, SET_ESCROW_ACCOUNT_TRANSACTIONS } from '../actions/escrowAccountActions';

const DEFAULT_STATE = {
  account: null,
  transactions: null
};

export default function escrowAccountsReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case SET_ESCROW_ACCOUNT:
      return {
        ...state,
        account: payload
      };
    case SET_ESCROW_ACCOUNT_TRANSACTIONS:
      return {
        ...state,
        transactions: payload
      };
    default:
      return state;
  }
}

export const getEscrowAccount = state => {
  return state.account;
};

export const getEscrowAccountTransactions = state => {
  return state.transactions;
};
