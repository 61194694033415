import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import PropTypes from 'prop-types';
import React from 'react';

const BetaContext = React.createContext();
const { Provider } = BetaContext;

const BetaProvider = React.memo(({ children }) => {
  const { identity } = React.useContext(IdentityContext);
  const isBeta = React.useMemo(() => !!identity && !!identity.isBeta, [identity]);
  const state = React.useMemo(() => ({ isBeta }), [isBeta]);

  React.useEffect(() => {
    console.log(`Is Beta?`, state.isBeta);
  }, [state.isBeta]);

  return <Provider value={state}>{children}</Provider>;
});

BetaProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default BetaProvider;

export { BetaContext };
