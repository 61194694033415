import Button from '@paperstac/ui/lib/Button';
import FileInputButton from '@paperstac/ui/lib/form/FileInputButton';
import PencilIcon from '@paperstac/ui/lib/icons/PencilIcon';
import React from 'react';

const EditButton = React.memo(({ fileInput, sx, children, ...props }) => fileInput
  ? <FileInputButton variant="secondaryOutline" size="small" sx={{
    bg: 'white',
    borderRadius: 'circle',
    width: 36,
    height: 36,
    lineHeight: '36px',
    p: 0,
    textAlign: 'center',
    ...sx
  }} {...props}>
    {!!children ? children : <PencilIcon size={13} />}
  </FileInputButton>
  : <Button variant="secondaryOutline" size="small" sx={{
    bg: 'white',
    borderRadius: 'circle',
    width: 36,
    height: 36,
    lineHeight: '36px',
    p: 0,
    textAlign: 'center',
    ...sx
  }} {...props}>
    {!!children ? children : <PencilIcon size={13} />}
  </Button>);

EditButton.propTypes = {};

EditButton.defaultProps = {
  fileInput: false
};

EditButton.displayName = 'EditButton';

export default EditButton;
