import React from 'react';
import { price } from './report';
import Section from './Section';
import StatGrid from './StatGrid';

const Price = React.memo(props => {
  return <Section title="Sale Prices" subtitle="(per individual asset)" bg="white">
    <StatGrid stat1={price[0]} stat2={price[1]} stat3={price[2]} stat4={price[3]} currency={true} />
  </Section>
});

Price.propTypes = {};

Price.defaultProps = {};

Price.displayName = 'AveragePrice';

export default Price;
