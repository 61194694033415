import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import { centsToShortUsdString } from '@paperstac/helpers/lib/numberHelpers';
import { LISTING_NEGOTIATION_TYPE } from '@paperstac/firestore-collections/lib/listings';
import Box from '@paperstac/ui/lib/Box';
import Flex from '@paperstac/ui/lib/Flex';
import Image from '@paperstac/ui/lib/Image';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import StarButton from '@paperstac/ui/lib/StarButton';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';
import { ReactComponent as SalePending } from '../../../images/sale-pending.svg';
import { ListingDetailsContext } from './ListingDetailsProvider';
import { ListingsSearchContext } from './ListingsSearchProvider';

const ListingItem = React.memo(({ listing, ...props }) => {
  const [busy, setBusy] = React.useState(false);
  const { uid } = React.useContext(IdentityContext);
  const { getStarredListingByListingId, starListing, unstarListing } = React.useContext(ListingsSearchContext);
  const { showListing } = React.useContext(ListingDetailsContext)
  const starredListing = getStarredListingByListingId(listing.id);
  const handleShowListing = React.useCallback(() => showListing(listing.id), [listing.id, showListing]);
  const handleStarClick = React.useCallback(() => {
    setBusy(true);
    !starredListing
      ? starListing(listing).finally(() => setBusy(false))
      : unstarListing(starredListing).finally(() => setBusy(false))
  }, [starredListing, listing, starListing, unstarListing]);
  const showSeller = !!uid;
  return <Box {...props}>
    <Flex sx={{
      bg: 'white',
      border: 'default',
      borderRadius: 'corner',
      p: [2, 2, 3]
    }}>
      <Box sx={{
        height: [95, 95, 106, 106, 124],
        width: [142, 142, 159, 159, 186],
        mr: [2, 2, 3],
        position: 'relative'
      }}>
        <LinkButton onClick={handleShowListing} sx={{
          height: [95, 95, 106, 106, 124],
          width: [142, 142, 159, 159, 186],
          borderRadius: 'corner',
          overflow: 'hidden',
          display: 'block',
          position: 'relative'
        }}>
          <Image src={listing.photoUrl} height={[95, 95, 106, 106, 124]} width={[142, 142, 159, 159, 186]} />
          {listing.pendingSale &&
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: [95, 95, 106, 106, 124],
            width: [142, 142, 159, 159, 186]
          }}><SalePending width="100%" /></Box>}
        </LinkButton>
        {!!uid && <Box sx={{
          position: 'absolute',
          top: 1,
          left: 1,
          width: [24, 24, 32, 32, 40],
          height: [24, 24, 32, 32, 40],
        }}>
          <StarButton
            busy={busy}
            active={!!starredListing}
            onClick={handleStarClick}
            size="100%"
          />
        </Box>}
      </Box>
      <Box flex={'1 1 0'}>
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          <LinkButton onClick={handleShowListing} sx={{
            fontSize: [1, 1, 2, 2, 20],
            textDecoration: 'none',
          }}>{listing.title}</LinkButton>
        </Box>

        <Flex fontSize={[1, 1, 1, 1, 2]}>
          <Metric label="Yield" value={listing.yieldPercent ? `${listing.yieldPercent}%` : 'N/A'} mr={3} />
          <Metric label="ITB" value={listing.itb ? `${listing.itb}%` : 'N/A'} mr={3} />
          <Metric label="ITV" value={listing.itv ? `${listing.itv}%` : 'N/A'} mr={3} />
          <Metric label="LTV" value={listing.ltv ? `${listing.ltv}%` : 'N/A'} />
        </Flex>
        <Text fontSize={[1, 1, 1, 1, 2]} py={[1, 1, 2]}>
          <ListPrice negotiationType={listing.negotiationType} listPrice={listing.listPrice} />{' '}
          {showSeller &&
          <Text display="inline">by <Text fontWeight="bold" display="inline" color="gray.9">{listing.seller}</Text></Text>}
        </Text>

        <Text fontSize={[1, 1, 1, 1, 2]}>
          <Box sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}>
            <Text fontWeight="bold" display="inline" color="gray.9">{listing.noteTypes.join(', ')}</Text>
            {!!listing.sellerComments && ` - ${listing.sellerComments}`}
          </Box>
        </Text>
      </Box>
    </Flex>
  </Box>
});

const ListPrice = ({ listPrice, negotiationType }) => {
  if (negotiationType === LISTING_NEGOTIATION_TYPE.FIRM) {
    return <>
      <Text fontWeight="bold" display="inline" fontSize={[2, 2, 2, 2, 20]} color="primary">{centsToShortUsdString(listPrice)}</Text>{' '}
      Firm
    </>;
  }
  if (negotiationType === LISTING_NEGOTIATION_TYPE.MAKE_OFFER) {
    return <Text fontWeight="bold" display="inline" fontSize={[2, 2, 2, 2, 20]} color="primary">Make an Offer</Text>;
  }
  return <>
    <Text fontWeight="bold" display="inline" fontSize={[2, 2, 2, 2, 20]} color="primary">{centsToShortUsdString(listPrice)}</Text>{' '}
    or Best Offer
  </>;
};

const Metric = ({ label, value, ...props }) => <Box {...props}>
  <Text display="inline" fontWeight="bold" color="gray.9">{label}</Text>
  <Text display="inline">: {value}</Text>
</Box>;


ListingItem.propTypes = {};

ListingItem.defaultProps = {};

ListingItem.displayName = 'ListingItem';

export default ListingItem;
