import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import listingTitleGenerator from '@paperstac/common/lib/services/listingTitleGenerator';
import { TRANSACTION_EVENT_TYPE } from '@paperstac/firestore-collections/lib/transactionEvents';
import getPath from '@paperstac/routes/lib/getPath';
import { MY_FEED_ITEM } from '@paperstac/routes/lib/webRoutes';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import Drawer from '@paperstac/ui/lib/Drawer';
import DrawerHeader from '@paperstac/ui/lib/DrawerHeader';
import TextareaFormGroup from '@paperstac/ui/lib/form/TextareaFormGroup';
import TextFormGroup from '@paperstac/ui/lib/form/TextFormGroup';
import Heading from '@paperstac/ui/lib/Heading';
import Link from '@paperstac/ui/lib/Link';
import Overlay from '@paperstac/ui/lib/Overlay';
import Text from '@paperstac/ui/lib/Text';
import { Form, Formik } from 'formik';
import get from 'lodash/get';
import React from 'react';
import { object, string } from 'yup';
import ErrorMessage from '../../common/AuthOverlay/ErrorMessage';
import { ListingPageContext } from './ListingPageProvider';
import useFirestoreSubscribe from '@paperstac/hooks/lib/useFirestoreSubscribe';
import { settingsRef } from '@paperstac/firestore-collections/lib/settings';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import VerifyIdentityOverlay from '../../common/VerifyIdentityOverlay/VerifyIdentityOverlay';

export const validator = () =>
  object().shape({
    message: string().required(),
  });

const MessageSellerOverlay = React.memo(() => {
  const [settings] = useFirestoreSubscribe(settingsRef());
  const { identity } = React.useContext(IdentityContext);
  const { createTransaction, hideMessage, listing, transactionId } = React.useContext(ListingPageContext);
  const { currentAccount } = React.useContext(AccountsContext);
  const sellerName = get(listing, 'seller.displayName', 'Paperstac Seller');

  const feedItemUrl = React.useMemo(() => (transactionId ? getPath(MY_FEED_ITEM, transactionId) : ''), [transactionId]);

  const handleSubmit = React.useCallback(
    ({ message }) =>
      createTransaction({
        type: TRANSACTION_EVENT_TYPE.MESSAGE,
        message,
      }),
    [createTransaction]
  );

  if (!settings) return null;

  if (settings.requireVerificationToCreateTransaction && !identity.isVerified)
    return <VerifyIdentityOverlay onClose={hideMessage} />;

  return (
    <>
      <Overlay onClose={hideMessage}>
        <Drawer width={600}>
          <DrawerHeader text="Message Seller" onClose={hideMessage} />
          <Box sx={{ p: 4, overflowY: 'auto', flex: '1 1 auto' }}>
            {!feedItemUrl && (
              <Formik
                initialValues={{ message: '' }}
                validationSchema={validator}
                onSubmit={async (payload, { setSubmitting, setStatus }) => {
                  setSubmitting(true);
                  handleSubmit(payload).catch((error) => {
                    setStatus({ errorMessage: error.message });
                    setSubmitting(false);
                  });
                }}
                render={({ isSubmitting, status }) => (
                  <Form>
                    <TextFormGroup label="To:" value={sellerName} />
                    <TextFormGroup label="From:" value={currentAccount.displayName} />
                    <TextFormGroup
                      label="Listing:"
                      value={<Text fontSize={1}>{listingTitleGenerator(listing)}</Text>}
                    />
                    <TextareaFormGroup name="message" label="Message:" required />
                    {status && status.errorMessage && <ErrorMessage>{status.errorMessage}</ErrorMessage>}
                    <Button type="submit" variant="primary" busy={isSubmitting} mr={2}>
                      Send Message
                    </Button>
                    <Button variant="default" onClick={hideMessage}>
                      Cancel
                    </Button>
                  </Form>
                )}
              />
            )}
            {!!feedItemUrl && (
              <Box>
                <Heading mb={4}>Message Sent</Heading>
                <Text mb={4} fontSize={1}>
                  Paperstac has successfully delivered your message. We have also created a{' '}
                  <Link to={feedItemUrl}>transaction</Link> between you and <Text variant="bold">{sellerName}</Text>. In
                  that transaction, you can further discuss this listing, negotiate pricing and even make a purchase
                  with our online note closings.
                </Text>
                <Button as={Link} to={feedItemUrl} variant="primary" mr={2}>
                  View Transaction
                </Button>
                <Button variant="default" onClick={hideMessage}>
                  Close
                </Button>
              </Box>
            )}
          </Box>
        </Drawer>
      </Overlay>
    </>
  );
});

MessageSellerOverlay.propTypes = {};

MessageSellerOverlay.defaultProps = {};

MessageSellerOverlay.displayName = 'MessageSellerOverlay';

export default MessageSellerOverlay;
