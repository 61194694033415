import Box from '@paperstac/ui/lib/Box';
import React from 'react';

const BaseFilterContainer = React.memo(({ sx, ...props }) =>
  <Box sx={{ py: 3, borderBottom: 'default', ...sx }} {...props} />);

BaseFilterContainer.propTypes = {};

BaseFilterContainer.defaultProps = {
  sx: {}
};

BaseFilterContainer.displayName = 'BaseFilterContainer';

export default BaseFilterContainer;