import { END_SESSION } from '../actions/authActions';
import { UNSUBSCRIBE_CURRENT_ACCOUNT } from '../actions/currentAccountActions';
import {
  REMOVE_TRANSACTION_NOTE,
  SET_TRANSACTION,
  SET_TRANSACTION_OTHERS,
  SET_TRANSACTION_PAGE_ACTION,
  SET_TRANSACTIONS,
  SET_TRANSACTIONS_IN_CLOSING
} from '../actions/transactionActions';
import { TRANSACTION_STATUS_CLOSING, TRANSACTION_STATUS_NEGOTIATIONS } from '@paperstac/constants';

const DEFAULT_STATE = {
  entities: {},
  lastUpdated: null,
  actionName: null,
  actionProps: {},
  mySellerTransactionIds: [],
  myBuyerTransactionIds: [],
  closingTransactionIds: [],
  closingTransactionsLastUpdated: null,
  otherTransactionIds: {}
};

export default function transactionsReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case SET_TRANSACTIONS:
      return {
        ...state,
        mySellerTransactionIds: payload.role === 'seller' ? payload.items.map(t => t.id) : state.mySellerTransactionIds || [],
        myBuyerTransactionIds: payload.role === 'buyer' ? payload.items.map(t => t.id) : state.myBuyerTransactionIds || [],
        lastUpdated: new Date().toISOString(),
        entities: payload.items.reduce((entities, transaction) => {
          entities[transaction.id] = transaction;
          return entities;
        }, { ...state.entities }),
      };
    case SET_TRANSACTIONS_IN_CLOSING:
      return {
        ...state,
        closingTransactionIds: payload.map(t => t.id),
        closingTransactionsLastUpdated: new Date().toISOString(),
        entities: payload.reduce((entities, transaction) => {
          entities[transaction.id] = transaction;
          return entities;
        }, { ...state.entities }),
      };
    case SET_TRANSACTION:
      return {
        ...state,
        lastUpdated: new Date().toISOString(),
        entities: { ...state.entities, [payload.id]: payload.data },
        [payload.id]: { ...state[payload.id], lastFetched: new Date().toISOString() }
      };
    case SET_TRANSACTION_OTHERS:
      const otherTransactionIds = state.otherTransactionIds || {};
      return {
        ...state,
        entities: payload.items.reduce((entities, transaction) => {
          entities[transaction.id] = transaction;
          return entities;
        }, { ...state.entities }),
        otherTransactionIds: { ...otherTransactionIds, [payload.id]: payload.items }
      };
    case REMOVE_TRANSACTION_NOTE:
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.transactionId]: {
            ...state.entities[payload.transactionId],
            notes: state.entities[payload.transactionId].notes.filter(n => n.id !== payload.noteId),
            noteIds: state.entities[payload.transactionId].noteIds.filter(id => id !== payload.noteId),
            noteCount: state.entities[payload.transactionId].noteCount - 1
          }
        }
      };
    case SET_TRANSACTION_PAGE_ACTION:
      return {
        ...state,
        actionName: payload.actionName || DEFAULT_STATE.actionName,
        actionProps: payload.actionProps || DEFAULT_STATE.actionProps
      };
    default:
      return state;
  }
}

export const getTransaction = (state, transactionId) => {
  const meta = state[transactionId] || {};
  return { ...meta, data: state.entities[transactionId] };
};

export const getTransactionOthers = (state, transactionId) => {
  const otherTransactionIds = state.otherTransactionIds || {};
  return otherTransactionIds[transactionId] || [];
};

export const getTransactions = (state) => {
  const sellerIds = state.mySellerTransactionIds || [];
  const buyerIds = state.myBuyerTransactionIds || [];
  return [...sellerIds, ...buyerIds]
    .filter(id => state.entities[id])
    .map(id => state.entities[id]);
};

export const getActiveSellerTransactions = (state) => {
  const sellerIds = state.mySellerTransactionIds || [];
  return sellerIds
    .filter(id => state.entities[id])
    .map(id => state.entities[id])
    .filter(t => [TRANSACTION_STATUS_NEGOTIATIONS, TRANSACTION_STATUS_CLOSING].includes(t.status));
};

export const transactionsLastUpdated = state => {
  return state.lastUpdated;
};

export const getClosingTransactions = (state) => {
  const ids = state.closingTransactionIds || [];
  return ids
    .filter(id => state.entities[id])
    .map(id => state.entities[id]);
};

export const closingTransactionsLastUpdated = state => {
  return state.closingTransactionsLastUpdated;
};

export const getTransactionPageAction = state => {
  const { actionName, actionProps } = state;
  return { actionName, actionProps };
};

export const getBuyerTransactionForListing = (state, buyerId, listingId) => {
  return Object.keys(state.entities)
    .map(id => state.entities[id])
    .find(transaction => {
      return transaction.buyerId === buyerId && transaction.listingId === listingId;
    });
};
