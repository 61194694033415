import { END_SESSION } from '../actions/authActions';
import { SET_COMMUNITY_INVITES } from '../actions/communityInvitesActions';
import { UNSUBSCRIBE_CURRENT_ACCOUNT } from '../actions/currentAccountActions';

const DEFAULT_STATE = {};

export default function communityInvitesReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case SET_COMMUNITY_INVITES:
      return {
        ...state,
        [payload.communityId]: payload.invites,
        lastUpdated: new Date().toISOString()
      };
    default:
      return state;
  }
}

export const getCommunityInvites = (state, communityId) => {
  return state[communityId] || [];
};

export const getCommunityInvitesLastUpdated = state => {
  return state.lastUpdated;
};
