import AlgoliaRadioRefinement from '@paperstac/ui/lib/AlgoliaRadioRefinement';
import React from 'react';
import FilterContainer from './FilterContainer';

const ListingTypeFilter = React.memo(props => <FilterContainer title="Listing Type">
  <AlgoliaRadioRefinement
    attribute="isPool"
    itemsOverride={[
      { label: 'Single Asset', value: 'false' },
      { label: 'Asset Pool', value: 'true' },
    ]}
  />
</FilterContainer>);

ListingTypeFilter.propTypes = {};

ListingTypeFilter.defaultProps = {};

ListingTypeFilter.displayName = 'ListingTypeFilter';

export default ListingTypeFilter;