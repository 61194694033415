import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import { centsToShortUsdString } from '@paperstac/helpers/lib/numberHelpers';
import { LISTING_NEGOTIATION_TYPE } from '@paperstac/firestore-collections/lib/listings';
import Box from '@paperstac/ui/lib/Box';
import Flex from '@paperstac/ui/lib/Flex';
import Image from '@paperstac/ui/lib/Image';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import StarButton from '@paperstac/ui/lib/StarButton';
import Text from '@paperstac/ui/lib/Text';
import get from 'lodash/get';
import React from 'react';
import LinesEllipsisLoose from 'react-lines-ellipsis/lib/loose';
import { ReactComponent as SalePending } from '../../../images/sale-pending.svg';
import { ListingDetailsContext } from './ListingDetailsProvider';
import { ListingsSearchContext } from './ListingsSearchProvider';

const getDescription = (listing) => {
  const parts = [];
  if (Array.isArray(listing.noteTypes)) parts.push(listing.noteTypes.join(', '));
  if (listing.sellerComments) parts.push(listing.sellerComments);
  return parts.join(' - ');
};

const CarouselListingItem = ({ listing, onRefreshCarousel }) => {
  const [busy, setBusy] = React.useState(false);
  const { uid } = React.useContext(IdentityContext);
  const { getStarredListingByListingId, starListing, unstarListing } = React.useContext(ListingsSearchContext);
  const { showListing } = React.useContext(ListingDetailsContext);
  const starredListing = getStarredListingByListingId(listing.id);
  const handleShowListing = React.useCallback(() => showListing(listing.id), [listing.id, showListing]);
  const handleStarClick = React.useCallback(() => {
    const handleCompletion = () => {
      setBusy(false);
      onRefreshCarousel();
    };
    setBusy(true);
    !starredListing
      ? starListing(listing).finally(handleCompletion)
      : unstarListing(starredListing).finally(handleCompletion);
  }, [starredListing, listing, onRefreshCarousel, starListing, unstarListing]);
  const itb = get(listing, 'investmentMetaAgg.investmentToBalancePercent');
  const itv = get(listing, 'investmentMetaAgg.investmentToValuePercent');
  const ltv = get(listing, 'noteMetaAgg.loanToValuePercent');
  const yieldPercent = get(listing, 'investmentMetaAgg.yieldPercent');
  const showSeller = !!uid;

  return <Box>
    <Box sx={{
      height: 100,
      width: 150,
      display: 'block',
      mx: 'auto',
      mb: 1,
      position: 'relative'
    }}>
      <LinkButton onClick={handleShowListing} sx={{
        height: 100,
        width: 150,
        borderRadius: 'corner',
        overflow: 'hidden',
        display: 'block',
        position: 'relative'
      }}>
        <Image src={listing.photoUrl} height={100} width={150} />
        {listing.pendingSale &&
        <Box sx={{ position: 'absolute', top: 0, left: 0 }}><SalePending height={100} width={150} /></Box>}
      </LinkButton>
      {!!uid && <Box sx={{
        position: 'absolute',
        top: 1,
        left: 1,
        width: 32,
        height: 32,
      }}>
        <StarButton
          busy={busy}
          active={!!starredListing}
          onClick={handleStarClick}
          size="100%"
        />
      </Box>}
    </Box>
    <LinkButton onClick={handleShowListing} sx={{
      fontSize: 1,
      textDecoration: 'none',
      textAlign: 'center'
    }}>{listing.title}</LinkButton>
    <Flex justifyContent="space-between" fontSize={1} mt={1}>
      <Metric label="ITB" value={itb ? `${itb}%` : 'N/A'} />
      <Metric label="ITV" value={itv ? `${itv}%` : 'N/A'} />
      <Metric label="LTV" value={ltv ? `${ltv}%` : 'N/A'} />
      <Metric label="Yield" value={yieldPercent ? `${yieldPercent}%` : 'N/A'} />
    </Flex>
    <Box my={4}>
      <ListPrice negotiationType={listing.negotiationType} listPrice={listing.listPrice} />
      {showSeller &&
      <Text fontSize={1}>Sold by <Text fontWeight="bold" display="inline" color="gray.8">{get(listing, 'seller')}</Text></Text>}
    </Box>
    <Text fontSize={1} mb={5}>
      <LinesEllipsisLoose text={getDescription(listing)} maxLine={3} lineHeight={14 * 1.15} />
    </Text>
  </Box>
};

const ListPrice = ({ listPrice, negotiationType }) => {
  if (negotiationType === LISTING_NEGOTIATION_TYPE.FIRM) {
    return <Text fontSize={1}><Text fontWeight="bold" display="inline" fontSize={2} color="primary">{centsToShortUsdString(listPrice)}</Text> Firm</Text>;
  }
  if (negotiationType === LISTING_NEGOTIATION_TYPE.MAKE_OFFER) {
    return <Text fontSize={1}><Text fontWeight="bold" display="inline" fontSize={2} color="primary">Make an Offer</Text></Text>;
  }
  return <Text fontSize={1}><Text fontWeight="bold" display="inline" fontSize={2} color="primary">{centsToShortUsdString(listPrice)}</Text> or Best Offer</Text>;
};

const Metric = ({ label, value, ...props }) => <Box {...props}>
  <Text display="inline" fontWeight="bold" color="gray.9">{label}</Text>
  <Text display="inline">: {value}</Text>
</Box>;


CarouselListingItem.propTypes = {};

CarouselListingItem.defaultProps = {};

CarouselListingItem.displayName = 'CarouselListingItem';

export default CarouselListingItem;
