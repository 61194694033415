import Box from '@paperstac/ui/lib/Box';
import Flex from '@paperstac/ui/lib/Flex';
import Image from '@paperstac/ui/lib/Image';
import Text from '@paperstac/ui/lib/Text';
import PropTypes from 'prop-types';
import React from 'react';


const Quote = React.memo(({ author, avatar, comment, company }) => <Box mx="auto" style={{ maxWidth: '600px' }}>
  <Text fontFamily="serif" mb={[2, 3]} fontSize={[14, 18]}>
    &ldquo;{comment}&rdquo;
  </Text>
  <Flex alignItems="center">
    <Image src={avatar} alt={author} width={50} height={50} borderRadius="corner" mr={10} />
    <Box>
      <Text fontSize={20} fontWeight="bold" color="gray.9">{author}</Text>
      <Text>{company}</Text>
    </Box>
  </Flex>
</Box>);

Quote.propTypes = {
  author: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

Quote.defaultProps = {};

Quote.displayName = 'Quote';

export default Quote;
