import { SortCollectionContext } from '@paperstac/common/lib/components/SortCollectionProvider';
import Box from '@paperstac/ui/lib/Box';
import Drawer from '@paperstac/ui/lib/Drawer';
import DrawerHeader from '@paperstac/ui/lib/DrawerHeader';
import ListGroupSortByChooser from '@paperstac/ui/lib/ListGroupSortByChooser';
import Overlay from '@paperstac/ui/lib/Overlay';
import React from 'react';

const SortByDrawer = React.memo(({ onClose }) => {
  const { setSort, sortBy, sortDirection } = React.useContext(SortCollectionContext);
  const items = [
    { label: 'Date Listed', value: 'listedDate', type: 'numeric' },
    { label: 'Interest Rate', value: 'interestRate', type: 'numeric' },
    { label: 'Investment to Balance', value: 'itb', type: 'numeric' },
    { label: 'Investment to Value', value: 'itv', type: 'numeric' },
    { label: 'List Price', value: 'listPrice', type: 'numeric' },
    { label: 'Loan to Value', value: 'ltv', type: 'numeric' },
    { label: 'Payments Remaining', value: 'paymentsRemaining', type: 'numeric' },
    { label: 'Popularity Rank', value: '', type: 'numeric' },
    { label: 'Property Value', value: 'propertyValue', type: 'numeric' },
    { label: 'Unpaid Principal Balance', value: 'upb', type: 'numeric' },
    { label: 'Yield', value: 'yieldPercent', type: 'numeric' },
  ];

  return <Overlay onClose={onClose}>
    <Drawer width={360}>
      <DrawerHeader text="Sort Results" onClose={onClose} px={3} />
      <Box sx={{ overflowY: 'auto', flex: '1 1 auto', p: 3 }}>
        <ListGroupSortByChooser sortBy={sortBy} sortDirection={sortDirection} onChoice={setSort} py={2} px={3} fontSize={1} items={items} />
      </Box>
    </Drawer>
  </Overlay>
});

SortByDrawer.propTypes = {};

SortByDrawer.defaultProps = {};

SortByDrawer.displayName = 'SortByDrawer';

export default SortByDrawer;
