import AlgoliaHasRangeRefinement from '@paperstac/ui/lib/AlgoliaHasRangeRefinement';
import AlgoliaRangeInput from '@paperstac/ui/lib/AlgoliaRangeInput';
import React from 'react';
import CollapsibleFilterContainer from './CollapsibleFilterContainer';

const PropertyValueFilter = React.memo(props => <AlgoliaHasRangeRefinement attribute="noteMetaAgg.propertyValue">
  <CollapsibleFilterContainer title="Property Value">
    <AlgoliaRangeInput attribute="noteMetaAgg.propertyValue" format="thousandUsd"/>
  </CollapsibleFilterContainer>
</AlgoliaHasRangeRefinement>);

PropertyValueFilter.propTypes = {};

PropertyValueFilter.defaultProps = {};

PropertyValueFilter.displayName = 'PropertyValueFilter';

export default PropertyValueFilter;