import { SortCollectionContext } from '@paperstac/common/lib/components/SortCollectionProvider';
import useAlgoliaActiveFilterCount from '@paperstac/common/lib/hooks/useAlgoliaActiveFilterCount';
import Box from '@paperstac/ui/lib/Box';
import Carousel from '@paperstac/ui/lib/Carousel';
import Container from '@paperstac/ui/lib/Container';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';
import CarouselListingItem from './CarouselListingItem';
import { ListingsSearchContext } from './ListingsSearchProvider';
import SwipeListingsMedia from './SwipeListingsMedia';

const CarouselSerp = React.memo(({ onFilters, onSort }) => {
  const { resultCount, starredFilterEnabled } = React.useContext(ListingsSearchContext);
  const { collection, sortBy, sortLabel, sortDirection } = React.useContext(SortCollectionContext);
  const reactSlickRef = React.useRef(null);
  const refreshCarousel = React.useCallback(() => {
    reactSlickRef.current.forceUpdate();
  }, []);
  const [searchState, setSearchState] = React.useState('');
  const algoliaActiveFilterCount = useAlgoliaActiveFilterCount();
  const activeFilterCount = starredFilterEnabled ? algoliaActiveFilterCount + 1 : algoliaActiveFilterCount;
  const currentSearchState = `${resultCount}:${activeFilterCount}:${sortBy}:${sortDirection}`;
  if (searchState !== currentSearchState) {
    reactSlickRef.current && reactSlickRef.current.slickGoTo(0);
    setSearchState(currentSearchState);
  }

  return <Box textAlign="center">
    <Text lineHeight={2} fontSize={2} mb={40}>
      <Strong>{resultCount}</Strong> {resultCount === 1 ? 'listing' : 'listings'} found and sorted by{' '}
      <LinkButton onClick={onSort}>{sortLabel} {sortDirection === 'ASC' ? '↓' : '↑'}</LinkButton> after applying{' '}
      <LinkButton onClick={onFilters}>{activeFilterCount}{' '}{activeFilterCount === 1 ? 'Filter' : 'Filters'}</LinkButton>.
    </Text>
    {!!resultCount && <Box mx={-3}>
      <Carousel onRef={c => reactSlickRef.current = c} arrows={false} adaptiveHeight={true} lazyLoad={true}>
        <Box minHeight={350}>
          <Text fontSize={1} mb={4}>Swipe to browse listings</Text>
          <SwipeListingsMedia height={150} />
        </Box>
        {collection.map((result, i) => <Container key={result.id}>
          <Text fontSize={1} mb={4}>Viewing listing <Strong>{i + 1}</Strong> of <Strong>{resultCount}</Strong></Text>
          <CarouselListingItem listing={result} onRefreshCarousel={refreshCarousel} />
        </Container>)}
      </Carousel>
    </Box>}
  </Box>
});

const Strong = props => <Text fontWeight="bold" display="inline" {...props} />;

CarouselSerp.propTypes = {};

CarouselSerp.defaultProps = {};

CarouselSerp.displayName = 'CarouselSerp';

export default CarouselSerp;