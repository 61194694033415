import { lastCharacter } from './stringHelpers';
export var getOrdinal = function getOrdinal(i) {
  var j = i % 10,
      k = i % 100;

  if (j === 1 && k !== 11) {
    return i + 'st';
  }

  if (j === 2 && k !== 12) {
    return i + 'nd';
  }

  if (j === 3 && k !== 13) {
    return i + 'rd';
  }

  return i + 'th';
};
export var stripNonNumeric = function stripNonNumeric(value) {
  return value.replace(/[^\d.]/g, '');
};
export var onlyDigits = function onlyDigits(value) {
  return value && "".concat(value).replace(/[^\d]/g, '');
};
export var usdToCents = function usdToCents(usd) {
  return Math.round(100 * parseFloat(typeof usd === 'string' ? usd.replace(/[$,]/g, '') : usd));
};
export var centsToUsdString = function centsToUsdString(cents) {
  var showCents = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var onlyNumbers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (typeof cents === 'undefined') return '';
  var formattedUsd = cents < 0 ? "-$".concat((cents / 100 * -1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')) : "$".concat((cents / 100).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'));
  return [formattedUsd].map(function (usd) {
    return showCents ? usd : usd.split('.')[0];
  }).map(function (usd) {
    return onlyNumbers ? stripNonNumeric(usd) : usd;
  })[0];
};
export var thousandUsdToCentsString = function thousandUsdToCentsString(value) {
  if (!value && value !== 0 && value !== '0') return '';
  var suffix = lastCharacter(value) === '.' ? '.' : '';
  var cents = value.replace(/[^\d.]/g, '') * 100000;
  return "".concat(cents).concat(suffix);
};
export var centsToThousandUsdString = function centsToThousandUsdString(value) {
  var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var onlyNumbers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (!value && value !== 0 && value !== '0') return '';
  var suffix = lastCharacter(value) === '.' && precision ? '.' : '';
  var thousandUsd = +(value / 100000).toFixed(precision);
  return onlyNumbers ? "".concat(thousandUsd).concat(suffix) : "$".concat(thousandUsd).concat(suffix, "K");
};
export var centsToMillionUsdString = function centsToMillionUsdString(value) {
  var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var onlyNumbers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (!value && value !== 0 && value !== '0') return '';
  var suffix = lastCharacter(value) === '.' && precision ? '.' : '';
  var millionUsd = +(value / 100000000).toFixed(precision);
  return onlyNumbers ? "".concat(millionUsd).concat(suffix) : "$".concat(millionUsd).concat(suffix, "M");
};
export var centsToShortUsdString = function centsToShortUsdString(value) {
  var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var onlyNumbers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return value > 99999999 ? centsToMillionUsdString(value, precision, onlyNumbers) : centsToThousandUsdString(value, precision, onlyNumbers);
};
export var round = function round(number, precision) {
  var shift = function shift(number, precision, reverseShift) {
    if (reverseShift) {
      precision = -precision;
    }

    var numArray = ('' + number).split('e');
    return +(numArray[0] + 'e' + (numArray[1] ? +numArray[1] + precision : precision));
  };

  return shift(Math.round(shift(number, precision, false)), precision, true);
};