import React from 'react';

const PrivacyPolicyContent = React.memo(props => <>
  <div>
    <div>
      This privacy policy has been compiled to better serve those who are concerned with how their 'Personally
      Identifiable Information' (PII) is being used online. PII, as described in US privacy law and information
      security, is information that can be used on its own or with other information to identify, contact, or locate a
      single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear
      understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in
      accordance with our website.
      <br />
    </div>
    <br />
    <div>
      <strong>What personal information do we collect from the people that visit our blog, website or app?</strong>
    </div>
    <br />
    <div>
      When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address,
      mailing address, phone number, credit card information or other details to help you with your experience.
    </div>
    <br />
    <div>
      <strong>When do we collect information?</strong>
    </div>
    <br />
    <div>
      We collect information from you when you register on our site, fill out a form, Use Live Chat, Open a Support
      Ticket or enter information on our site.
    </div>
    <br />
    <div>
      <strong>How do we use your information? </strong>
    </div>
    <br />
    <div>
      {' '}
      We may use the information we collect from you when you register, make a purchase, sign up for our newsletter,
      respond to a survey or marketing communication, surf the website, or use certain other site features in the
      following ways:
      <br />
      <br />
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> To personalize your experience and to allow us to deliver the type of content and product
      offerings in which you are most interested.
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> To improve our website in order to better serve you.
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> To allow us to better service you in responding to your customer service requests.
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> To quickly process your transactions.
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> To send periodic emails regarding your order or other products and services.
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> To follow up with them after correspondence (live chat, email or phone inquiries)
    </div>
    <br />
    <div>
      <strong>How do we protect your information?</strong>
    </div>
    <br />
    <div>We do not use vulnerability scanning and/or scanning to PCI standards.</div>
    <div>An external PCI compliant payment gateway handles all CC transactions.</div>
    <div>
      We do not use Malware Scanning.
      <br />
      <br />
    </div>
    <div>
      Your personal information is contained behind secured networks and is only accessible by a limited number of
      persons who have special access rights to such systems, and are required to keep the information confidential.
      In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.
    </div>
    <br />
    <div>
      We implement a variety of security measures when a user places an order enters, submits, or accesses their
      information to maintain the safety of your personal information.
    </div>
    <br />
    <div>
      All transactions are processed through a gateway provider and are not stored or processed on our servers.
    </div>
    <br />
    <div>
      <strong>Do we use 'cookies'?</strong>
    </div>
    <br />
    <div>
      Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through
      your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser
      and capture and remember certain information. For instance, we use cookies to help us remember and process the
      items in your shopping cart. They are also used to help us understand your preferences based on previous or
      current site activity, which enables us to provide you with improved services. We also use cookies to help us
      compile aggregate data about site traffic and site interaction so that we can offer better site experiences and
      tools in the future.
    </div>
    <div>
      <br />
      <strong>We use cookies to:</strong>
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> Keep track of advertisements.
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> Compile aggregate data about site traffic and site interactions in order to offer better site
      experiences and tools in the future. We may also use trusted third-party services that track this information on
      our behalf.
    </div>
    <div>
      <br />
      You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off
      all cookies. You do this through your browser settings. Since browser is a little different, look at your
      browser's Help Menu to learn the correct way to modify your cookies.
      <br />
    </div>
    <br />
    <div>
      If you turn cookies off, Some of the features that make your site experience more efficient may not function
      properly.It won't affect the user's experience that make your site experience more efficient and may not
      function properly.
    </div>
    <br />
    <div>
      <strong>Third-party disclosure</strong>
    </div>
    <br />
    <div>
      <strong>Do we disclose the information we collect to Third-Parties?</strong>
    </div>
    <div>
      We sell,trade, or otherwise transfer to outside parties your name, screen name or user names, phone number
      <div>
        <br />
        <strong>We engage in this practice because,:</strong>
      </div>
      <div>User data is transferred to our support system to provide a better user experience.</div>
      Personally Identifiable Information.
    </div>
    <br />
    <div>
      <strong>Third-party links</strong>
    </div>
    <br />
    <div>
      Occasionally, at our discretion, we may include or offer third-party products or services on our website. These
      third-party sites have separate and independent privacy policies. We therefore have no responsibility or
      liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of
      our site and welcome any feedback about these sites.
    </div>
    <br />
    <div className="blueText">
      <strong>Google</strong>
    </div>
    <br />
    <div>
      Google's advertising requirements can be summed up by Google's Advertising Principles. They are put in place to
      provide a positive experience for users. https://support.google.com/adwordspolicy/answer/1316548?hl=en <br />
      <br />
    </div>
    <div>We use Google AdSense Advertising on our website.</div>
    <div>
      <br />
      Google, as a third-party vendor, uses cookies to serve ads on our site. Google's use of the DART cookie enables
      it to serve ads to our users based on previous visits to our site and other sites on the Internet. Users may
      opt-out of the use of the DART cookie by visiting the Google Ad and Content Network privacy policy.
      <br />
    </div>
    <div>
      <br />
      <strong>We have implemented the following:</strong>
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> Remarketing with Google AdSense
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> Google Display Network Impression Reporting
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> Demographics and Interests Reporting
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> DoubleClick Platform Integration
      <br />
    </div>
    <br />
    <div>
      We, along with third-party vendors such as Google use first-party cookies (such as the Google Analytics cookies)
      and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to compile
      data regarding user interactions with ad impressions and other ad service functions as they relate to our
      website.
    </div>
    <div>
      <br />
      <strong>Opting out:</strong>
      <br />
      Users can set preferences for how Google advertises to you using the Google Ad Settings page. Alternatively, you
      can opt out by visiting the Network Advertising Initiative Opt Out page or by using the Google Analytics Opt Out
      Browser add on.
    </div>
    <br />
    <div className="blueText">
      <strong>California Online Privacy Protection Act</strong>
    </div>
    <br />
    <div>
      CalOPPA is the first state law in the nation to require commercial websites and online services to post a
      privacy policy. The law's reach stretches well beyond California to require any person or company in the United
      States (and conceivably the world) that operates websites collecting Personally Identifiable Information from
      California consumers to post a conspicuous privacy policy on its website stating exactly the information being
      collected and those individuals or companies with whom it is being shared. - See more at:
      http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
      <br />
    </div>
    <div>
      <br />
      <strong>According to CalOPPA, we agree to the following:</strong>
      <br />
    </div>
    <div>Users can visit our site anonymously.</div>
    <div>
      Once this privacy policy is created, we will add a link to it on our home page or as a minimum, on the first
      significant page after entering our website.
      <br />
    </div>
    <div>
      Our Privacy Policy link includes the word 'Privacy' and can easily be found on the page specified above.
    </div>
    <div>
      <br />
      You will be notified of any Privacy Policy changes:
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> On our Privacy Policy Page
      <br />
    </div>
    <div>Can change your personal information:</div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> By logging in to your account
    </div>
    <div>
      <br />
      <strong>How does our site handle Do Not Track signals?</strong>
      <br />
    </div>
    <div>
      We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track (DNT)
      browser mechanism is in place.
    </div>
    <div>
      <br />
      <strong>Does our site allow third-party behavioral tracking?</strong>
      <br />
    </div>
    <div>It's also important to note that we allow third-party behavioral tracking</div>
    <br />
    <div className="blueText">
      <strong>COPPA (Children Online Privacy Protection Act)</strong>
    </div>
    <br />
    <div>
      When it comes to the collection of personal information from children under the age of 13 years old, the
      Children's Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, United
      States' consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and
      online services must do to protect children's privacy and safety online.
      <br />
      <br />
    </div>
    <div>We do not specifically market to children under the age of 13 years old.</div>
    <div>Do we let third-parties, including ad networks or plug-ins collect PII from children under 13?</div>
    <br />
    <div className="blueText">
      <strong>Fair Information Practices</strong>
    </div>
    <br />
    <div>
      The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts
      they include have played a significant role in the development of data protection laws around the globe.
      Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply
      with the various privacy laws that protect personal information.
      <br />
      <br />
    </div>
    <div>
      <strong>
        In order to be in line with Fair Information Practices we will take the following responsive action, should a
        data breach occur:
      </strong>
    </div>
    <div>We will notify you via email</div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> Within 7 business days
    </div>
    <div>We will notify the users via in-site notification</div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> Within 7 business days
    </div>
    <div>
      <br />
      We also agree to the Individual Redress Principle which requires that individuals have the right to legally
      pursue enforceable rights against data collectors and processors who fail to adhere to the law. This principle
      requires not only that individuals have enforceable rights against data users, but also that individuals have
      recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.
    </div>
    <br />
    <div className="blueText">
      <strong>CAN SPAM Act</strong>
    </div>
    <br />
    <div>
      The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial
      messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough
      penalties for violations.
      <br />
      <br />
    </div>
    <div>
      <strong>We collect your email address in order to:</strong>
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> Send information, respond to inquiries, and/or other requests or questions
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> Process orders and to send information and updates pertaining to orders.
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> Send you additional information related to your product and/or service
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> Market to our mailing list or continue to send emails to our clients after the original
      transaction has occurred.
    </div>
    <div>
      <br />
      <strong>To be in accordance with CANSPAM, we agree to the following:</strong>
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> Not use false or misleading subjects or email addresses.
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> Identify the message as an advertisement in some reasonable way.
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> Include the physical address of our business or site headquarters.
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> Monitor third-party email marketing services for compliance, if one is used.
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> Honor opt-out/unsubscribe requests quickly.
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> Allow users to unsubscribe by using the link at the bottom of each email.
    </div>
    <div>
      <strong>
        <br />
        If at any time you would like to unsubscribe from receiving future emails, you can email us at
      </strong>
    </div>
    <div>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <strong>•</strong> Follow the instructions at the bottom of each email.
    </div>
    and we will promptly remove you from <strong>ALL</strong> correspondence.
  </div>
  <br />
  <div className="blueText">
    <strong>Contacting Us</strong>
  </div>
  <br />
  <div>
    If there are any questions regarding this privacy policy, you may contact us using the information below.
    <br />
    <br />
  </div>
  <div>https://www.paperstac.com</div>
  <div>300 S Orange Ave Suite 1000</div>
  Orlando, FL 32801
  <div>United States</div>
  <div>support@paperstac.com</div>
  <div>407-930-9749</div>
  <div>
    <br />
    Last Edited on 2018-05-20
  </div>
</>);

PrivacyPolicyContent.propTypes = {};

PrivacyPolicyContent.defaultProps = {};

PrivacyPolicyContent.displayName = 'PrivacyPolicyContent';

export default PrivacyPolicyContent;