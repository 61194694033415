import Recaptcha from '@paperstac/common/lib/components/Recaptcha';
import useFullStory from '@paperstac/common/lib/hooks/useFullStory';
import { CREATE_IDENTITY } from '@paperstac/common/lib/serverDispatchActionTypes';
import { normalizeCampaign } from '@paperstac/common/lib/services/campaigns';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import { RECAPTCHA_SITE_KEY } from '@paperstac/env';
import auth from '@paperstac/firebase/lib/auth';
import { getFirebase } from '@paperstac/firebase/lib/firebase';
import { createIdentityDispatchValidator } from '@paperstac/firestore-collections/lib/identities';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import CheckboxFormGroup from '@paperstac/ui/lib/form/CheckboxFormGroup';
import InputFormGroup from '@paperstac/ui/lib/form/InputFormGroup';
import Text from '@paperstac/ui/lib/Text';
import { Form, Formik } from 'formik';
import React from 'react';
import { fireRegistrationConversion as fireAdwordsConversion } from '../../../services/adwords';
import { fireRegisteredEvent as fireAnalyticsConversion } from '../../../services/analytics';
import { fireRegisterEvent as fireBingConversion } from '../../../services/bing';
import campaign from '../../../services/campaign';
import { fireRegisterEvent as fireLinkedInConversion } from '../../../services/linkedin';
import { fireRegisterEvent as fireTwitterConversion } from '../../../services/twitter';
import { AUTH_OVERLAY_VIEWS, AuthOverlayContext } from './AuthOverlayProvider';
import ErrorMessage from './ErrorMessage';
import LinkButton from './LinkButton';
import ViewTitle from './ViewTitle';
import { getDomains } from '@paperstac/helpers/lib/domain';

const CreateIdentityView = React.memo((props) => {
  const FullStory = useFullStory();
  const { setView, showTerms, showPrivacyPolicy } = React.useContext(AuthOverlayContext);
  const reCaptchaRef = React.useRef(null);

  React.useEffect(() => FullStory.event('Create Identity View Loaded'), [FullStory]);

  return (
    <Formik
      initialValues={{ email: '', password: '', agreedToTerms: false, domain: getDomains().PAPERSTAC }}
      validationSchema={createIdentityDispatchValidator}
      onSubmit={async (payload, { setSubmitting, setStatus }) => {
        FullStory.event('Submitted Identity for Creation');
        const token = await reCaptchaRef.current.getToken();
        const campaignData = campaign.get() || {
          campaign: 'cookies-disabled',
          source: '',
          medium: '',
          term: '',
          content: '',
        };
        serverDispatch({ action: CREATE_IDENTITY, payload: { ...payload, ...normalizeCampaign(campaignData), token } })
          .then(async () => {
            try {
              await Promise.all([
                fireAdwordsConversion(),
                fireAnalyticsConversion(),
                fireBingConversion(),
                fireLinkedInConversion(),
                fireTwitterConversion(),
              ]);
              FullStory.event('Identity Created Successfully', {
                campaign_str: campaignData.campaign,
                source_str: campaignData.source,
                medium_str: campaignData.medium,
                term_str: campaignData.term,
                content_str: campaignData.content,
              });
            } catch (err) {
              // Swallow error
            }
            await auth.setPersistence(getFirebase().auth.Auth.Persistence.SESSION);
            await auth.signInWithEmailAndPassword(payload.email, payload.password);
          })
          .catch((error) => {
            setSubmitting(false);
            setStatus({ errorMessage: error.message });
            FullStory.event('Identity Creation Failed', { code_str: error.code, message_str: error.message });
          });
      }}
      render={({ isSubmitting, status }) => (
        <Form>
          <ViewTitle>Get Started</ViewTitle>
          <InputFormGroup name="email" label="Email Address:" type="email" required />
          <InputFormGroup name="password" label="Password:" type="password" required />
          <CheckboxFormGroup
            name="agreedToTerms"
            label={<CheckboxLabel showTerms={showTerms} showPrivacyPolicy={showPrivacyPolicy} />}
            required
          />
          <Recaptcha ref={reCaptchaRef} sitekey={RECAPTCHA_SITE_KEY} />
          {status && status.errorMessage && <ErrorMessage>{status.errorMessage}</ErrorMessage>}
          <Button variant="primary" block={true} busy={isSubmitting}>
            Register
          </Button>
          <Box mt={40} textAlign="center">
            Already have an account? <LinkButton onClick={() => setView(AUTH_OVERLAY_VIEWS.LOGIN)}>Sign In</LinkButton>.
          </Box>
        </Form>
      )}
    />
  );
});

CreateIdentityView.propTypes = {};

CreateIdentityView.defaultProps = {};

CreateIdentityView.displayName = 'CreateIdentityView';

export default CreateIdentityView;

const CheckboxLabel = ({ showTerms, showPrivacyPolicy }) => (
  <Text fontWeight="normal" fontSize={1}>
    I agree to{' '}
    <LinkButton fontSize={1} onClick={showTerms}>
      terms
    </LinkButton>{' '}
    and{' '}
    <LinkButton fontSize={1} onClick={showPrivacyPolicy}>
      privacy policy
    </LinkButton>
  </Text>
);
