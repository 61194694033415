import AlgoliaHasRangeRefinement from '@paperstac/ui/lib/AlgoliaHasRangeRefinement';
import AlgoliaRangeInput from '@paperstac/ui/lib/AlgoliaRangeInput';
import React from 'react';
import CollapsibleFilterContainer from './CollapsibleFilterContainer';

const PaymentsRemainingFilter = React.memo(props =>
  <AlgoliaHasRangeRefinement attribute="noteMetaAgg.paymentsRemaining">
    <CollapsibleFilterContainer title="Payments Remaining">
      <AlgoliaRangeInput attribute="noteMetaAgg.paymentsRemaining" />
    </CollapsibleFilterContainer>
  </AlgoliaHasRangeRefinement>);

PaymentsRemainingFilter.propTypes = {};

PaymentsRemainingFilter.defaultProps = {};

PaymentsRemainingFilter.displayName = 'PaymentsRemainingFilter';

export default PaymentsRemainingFilter;