import { AlgoliaSearchStateContext } from '@paperstac/common/lib/components/AlgoliaSearchStateProvider';
import { getNameFromCode, getStates } from '@paperstac/common/lib/services/states';
import {
  NOTE_TAPE_LEGAL_STATUS_BANKRUPTCY,
  NOTE_TAPE_LEGAL_STATUS_FORECLOSURE,
  NOTE_TAPE_LEGAL_STATUS_NONE,
  NOTE_TAPE_PERFORMANCE_NON_PERFORMING,
  NOTE_TAPE_PERFORMANCE_PERFORMING,
  NOTE_TAPE_PROPERTY_TYPE_CONDO,
  NOTE_TAPE_PROPERTY_TYPE_LAND,
  NOTE_TAPE_PROPERTY_TYPE_MULTI_FAMILY,
  NOTE_TAPE_PROPERTY_TYPE_OTHER,
  NOTE_TAPE_PROPERTY_TYPE_PUD,
  NOTE_TAPE_PROPERTY_TYPE_SINGLE_FAMILY
} from '@paperstac/constants';
import { NOTE_TYPE } from '@paperstac/firestore-collections/lib/accountNotes';
import { centsToThousandUsdString, thousandUsdToCentsString } from '@paperstac/helpers/lib/numberHelpers';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import ErrorText from '@paperstac/ui/lib/ErrorText';
import Flex from '@paperstac/ui/lib/Flex';
import Checkbox from '@paperstac/ui/lib/form/Checkbox';
import Checklist from '@paperstac/ui/lib/form/Checklist';
import Input from '@paperstac/ui/lib/form/Input';
import InputFormGroup from '@paperstac/ui/lib/form/InputFormGroup';
import Label from '@paperstac/ui/lib/form/Label';
import Prefix from '@paperstac/ui/lib/form/Prefix';
import RadioGroup from '@paperstac/ui/lib/form/RadioGroup';
import Select from '@paperstac/ui/lib/form/Select';
import Suffix from '@paperstac/ui/lib/form/Suffix';
import Heading from '@paperstac/ui/lib/Heading';
import XIcon from '@paperstac/ui/lib/icons/XIcon';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import Text from '@paperstac/ui/lib/Text';
import { Field, Form } from 'formik';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import React from 'react';

const SavedSearchForm = React.memo(({ isSubmitting, onCancel, setFieldValue, values, status }) => {
  const { allSearchResults } = React.useContext(AlgoliaSearchStateContext);
  const uniqueSellerNames = React.useMemo(() => {
    const uniqueSellerNames = [];
    if (!allSearchResults || !allSearchResults.hits) return uniqueSellerNames;
    allSearchResults.hits.forEach(({ uniqueSellerName }) => uniqueSellerNames.push(uniqueSellerName));
    return uniq(uniqueSellerNames);
  }, [allSearchResults]);
  const getSellerDisplayName = React.useCallback(uniqueSellerName => uniqueSellerName.split('::')[1], []);
  const [showFilter, setShowFilter] = React.useReducer((oldState, newState) => ({ ...oldState, ...newState }), {
    interestRatePercent: !!get(values, 'filters.interestRatePercent.min') || !!get(values, 'filters.interestRatePercent.max'),
    investmentToBalancePercent: !!get(values, 'filters.investmentToBalancePercent.min') || !!get(values, 'filters.investmentToBalancePercent.max'),
    investmentToValuePercent: !!get(values, 'filters.investmentToValuePercent.min') || !!get(values, 'filters.investmentToValuePercent.max'),
    legalStatus: !!Object.values(get(values, 'filters.legalStatus')).filter(enabled => enabled).length,
    listingType: !!get(values, 'filters.listingType'),
    lienPosition: !!get(values, 'filters.lienPosition'),
    listPrice: !!get(values, 'filters.listPrice.min') || !!get(values, 'filters.listPrice.max'),
    loanToValuePercent: !!get(values, 'filters.loanToValuePercent.min') || !!get(values, 'filters.loanToValuePercent.max'),
    noteTypes: !!get(values, 'filters.noteTypes').length,
    paymentsRemaining: !!get(values, 'filters.paymentsRemaining.min') || !!get(values, 'filters.paymentsRemaining.max'),
    performance: !!get(values, 'filters.performance'),
    propertyTypes: !!get(values, 'filters.propertyTypes').length,
    propertyValue: !!get(values, 'filters.propertyValue.min') || !!get(values, 'filters.propertyValue.max'),
    state: !!get(values, 'filters.state').length,
    stateClassifications: !!Object.values(get(values, 'filters.stateClassifications')).filter(enabled => enabled).length,
    uniqueSellerName: !!get(values, 'filters.uniqueSellerName', []).length,
    upb: !!get(values, 'filters.upb.min') || !!get(values, 'filters.upb.max'),
  });
  const handleAdd = ({ target: { value } }) => {
    setShowFilter({ [value]: true });
  };
  const handleRemove = (filterName) => {
    setShowFilter({ [filterName]: false });
    setFieldValue(`filters.${filterName}`, '');
  };
  const handleRemoveNoteTypes = (filterName) => {
    setShowFilter({ [filterName]: false });
    setFieldValue(`filters.${filterName}`, []);
  };
  const handleRemovePropertyTypes = (filterName) => {
    setShowFilter({ [filterName]: false });
    setFieldValue(`filters.${filterName}`, []);
  };
  const handleRemoveSeller = (filterName) => {
    setShowFilter({ [filterName]: false });
    setFieldValue(`filters.${filterName}`, []);
  };
  const handleRemoveState = (filterName) => {
    setShowFilter({ [filterName]: false });
    setFieldValue(`filters.${filterName}`, []);
  };
  const handleRemoveLegalStatus = (filterName) => {
    setShowFilter({ [filterName]: false });
    setFieldValue(`filters.${filterName}.bankruptcy`, false);
    setFieldValue(`filters.${filterName}.foreclosure`, false);
    setFieldValue(`filters.${filterName}.none`, false);
  };
  const handleRemoveStateClassifications = (filterName) => {
    setShowFilter({ [filterName]: false });
    setFieldValue(`filters.${filterName}.isHardestHitFundState`, false);
    setFieldValue(`filters.${filterName}.isJudicialState`, false);
    setFieldValue(`filters.${filterName}.isNonJudicialState`, false);
  };
  const handleRemoveRange = (filterName) => {
    setShowFilter({ [filterName]: false });
    setFieldValue(`filters.${filterName}.min`, '');
    setFieldValue(`filters.${filterName}.max`, '');
  };
  const handleAddSellerItem = (value) => {
    setFieldValue('filters.uniqueSellerName', [...get(values, 'filters.uniqueSellerName', []), value]);
  };
  const handleRemoveSellerItem = (value) => {
    setFieldValue('filters.uniqueSellerName', get(values, 'filters.uniqueSellerName', []).filter(v => v !== value));
  };
  const handleAddStateItem = (value) => {
    setFieldValue('filters.state', [...get(values, 'filters.state'), value]);
  };
  const handleRemoveStateItem = (value) => {
    setFieldValue('filters.state', get(values, 'filters.state').filter(v => v !== value));
  };

  return <Form>
    <InputFormGroup name="title" label="Title:" />

    <Box sx={{ py: 3, mb: 3, borderTop: 'default', borderBottom: 'default' }}>
      <Heading as="h3" sx={{ fontSize: 1, mb: 2, color: 'secondary', textTransform: 'uppercase' }}>Filters</Heading>
      <Select value="" onChange={handleAdd} mb={3}>
        <option value="">Add a filter...</option>
        {!showFilter.interestRatePercent && <option value="interestRatePercent">Interest Rate</option>}
        {!showFilter.investmentToBalancePercent &&
        <option value="investmentToBalancePercent">Investment to Balance (ITB)</option>}
        {!showFilter.investmentToValuePercent &&
        <option value="investmentToValuePercent">Investment to Value (ITV)</option>}
        {!showFilter.legalStatus && <option value="legalStatus">Legal Status</option>}
        {!showFilter.lienPosition && <option value="lienPosition">Lien Position</option>}
        {!showFilter.noteTypes && <option value="noteTypes">Note Type</option>}
        {!showFilter.listPrice && <option value="listPrice">List Price</option>}
        {!showFilter.listingType && <option value="listingType">Listing Type</option>}
        {!showFilter.loanToValuePercent && <option value="loanToValuePercent">Loan to Value (LTV)</option>}
        {!showFilter.paymentsRemaining && <option value="paymentsRemaining">Payments Remaining</option>}
        {!showFilter.performance && <option value="performance">Performance (PL/NPL)</option>}
        {!showFilter.propertyValue && <option value="propertyValue">Property Value</option>}
        {!showFilter.propertyTypes && <option value="propertyTypes">Property Type</option>}
        {!showFilter.uniqueSellerName && <option value="uniqueSellerName">Seller</option>}
        {!showFilter.state && <option value="state">State</option>}
        {!showFilter.stateClassifications && <option value="stateClassifications">State Classifications</option>}
        {!showFilter.upb && <option value="upb">Unpaid Principal Balance (UPB)</option>}
      </Select>

      {showFilter.listingType && <FilterContainer filterName="listingType" label="Listing Type" onRemove={handleRemove}>
        <RadioGroup
          name="filters.listingType"
          value={get(values, 'filters.listingType')}
          items={[
            { label: 'All', value: '' },
            { label: 'Single Asset', value: 'false' },
            { label: 'Asset Pool', value: 'true' }
          ]}
        />
      </FilterContainer>}

      {showFilter.lienPosition &&
      <FilterContainer filterName="lienPosition" label="Lien Position" onRemove={handleRemove}>
        <RadioGroup
          name="filters.lienPosition"
          value={get(values, 'filters.lienPosition')}
          items={[
            { label: 'All', value: '' },
            { label: '1st', value: '1' },
            { label: '2nd', value: '2' }
          ]}
        />
      </FilterContainer>}

      {showFilter.performance &&
      <FilterContainer filterName="performance" label="Performance" onRemove={handleRemove}>
        <RadioGroup
          name="filters.performance"
          value={get(values, 'filters.performance')}
          items={[
            { label: 'All', value: '' },
            { label: 'Performing', value: NOTE_TAPE_PERFORMANCE_PERFORMING },
            { label: 'Non-Performing', value: NOTE_TAPE_PERFORMANCE_NON_PERFORMING }
          ]}
        />
      </FilterContainer>}

      {showFilter.noteTypes &&
      <FilterContainer filterName="noteTypes" label="Note Type" onRemove={handleRemoveNoteTypes}>
        <Checklist
          name="filters.noteTypes"
          value={get(values, 'filters.noteTypes')}
          items={[
            { label: NOTE_TYPE.MORTGAGE, value: NOTE_TYPE.MORTGAGE },
            { label: NOTE_TYPE.DEED_OF_TRUST, value: NOTE_TYPE.DEED_OF_TRUST },
            { label: NOTE_TYPE.CFD, value: NOTE_TYPE.CFD },
          ]}
        />
      </FilterContainer>}

      {showFilter.propertyTypes &&
      <FilterContainer filterName="propertyTypes" label="Property Type" onRemove={handleRemovePropertyTypes}>
        <Checklist
          name="filters.propertyTypes"
          value={get(values, 'filters.propertyTypes')}
          items={[
            { label: NOTE_TAPE_PROPERTY_TYPE_SINGLE_FAMILY, value: NOTE_TAPE_PROPERTY_TYPE_SINGLE_FAMILY },
            { label: NOTE_TAPE_PROPERTY_TYPE_MULTI_FAMILY, value: NOTE_TAPE_PROPERTY_TYPE_MULTI_FAMILY },
            { label: NOTE_TAPE_PROPERTY_TYPE_CONDO, value: NOTE_TAPE_PROPERTY_TYPE_CONDO },
            { label: NOTE_TAPE_PROPERTY_TYPE_PUD, value: NOTE_TAPE_PROPERTY_TYPE_PUD },
            { label: NOTE_TAPE_PROPERTY_TYPE_LAND, value: NOTE_TAPE_PROPERTY_TYPE_LAND },
            { label: NOTE_TAPE_PROPERTY_TYPE_OTHER, value: NOTE_TAPE_PROPERTY_TYPE_OTHER },
          ]}
        />
      </FilterContainer>}

      {showFilter.uniqueSellerName &&
      <FilterContainer filterName="uniqueSellerName" label="Seller" onRemove={handleRemoveSeller}>
        {get(values, 'filters.uniqueSellerName', []).map(value => <Flex key={value} alignContent="center" mb={2}>
          <LinkButton onClick={() => handleRemoveSellerItem(value)} sx={{
            color: 'red',
            opacity: .75,
            fontSize: 1,
            mr: 2,
            ':hover,:focus': { color: 'red', opacity: 1 }
          }}><XIcon size={12} /></LinkButton>
          <Text fontSize={1}>{getSellerDisplayName(value)}</Text>
        </Flex>)}

        {get(values, 'filters.uniqueSellerName', []).length < 5 &&
        <Select value="" onChange={({ target: { value } }) => handleAddSellerItem(value)}>
          <option value="">Choose a seller...</option>
          {uniqueSellerNames.filter((value) => !get(values, 'filters.uniqueSellerName', []).includes(value)).map((value) =>
            <option key={value} value={value}>{getSellerDisplayName(value)}</option>)}
        </Select>}
      </FilterContainer>}

      {showFilter.state &&
      <FilterContainer filterName="state" label="State" onRemove={handleRemoveState}>
        {get(values, 'filters.state').map(value => <Flex key={value} alignContent="center" mb={2}>
          <LinkButton onClick={() => handleRemoveStateItem(value)} sx={{
            color: 'red',
            opacity: .75,
            fontSize: 1,
            mr: 2,
            ':hover,:focus': { color: 'red', opacity: 1 }
          }}><XIcon size={12} /></LinkButton>
          <Text fontSize={1}>{getNameFromCode(value)}</Text>
        </Flex>)}

        {get(values, 'filters.state').length < 5 &&
        <Select value="" onChange={({ target: { value } }) => handleAddStateItem(value)}>
          <option value="">Choose a state...</option>
          {getStates().filter(({ code }) => !get(values, 'filters.state').includes(code)).map(({ name, code }) =>
            <option key={code} value={code}>{name}</option>)}
        </Select>}
      </FilterContainer>}

      {showFilter.listPrice && <RangeFilter
        filterName="listPrice"
        label="List Price"
        prefix="$"
        suffix="K"
        value={{
          min: centsToThousandUsdString(get(values, 'filters.listPrice.min'), 1, true),
          max: centsToThousandUsdString(get(values, 'filters.listPrice.max'), 1, true)
        }}
        onChange={(attr, value) => setFieldValue(`filters.listPrice.${attr}`, thousandUsdToCentsString(value))}
        onRemove={handleRemoveRange}
      />}

      {showFilter.propertyValue && <RangeFilter
        filterName="propertyValue"
        label="Property Value"
        prefix="$"
        suffix="K"
        value={{
          min: centsToThousandUsdString(get(values, 'filters.propertyValue.min'), 1, true),
          max: centsToThousandUsdString(get(values, 'filters.propertyValue.max'), 1, true)
        }}
        onChange={(attr, value) => setFieldValue(`filters.propertyValue.${attr}`, thousandUsdToCentsString(value))}
        onRemove={handleRemoveRange}
      />}

      {showFilter.upb && <RangeFilter
        filterName="upb"
        label="Unpaid Principal Balance (UPB)"
        prefix="$"
        suffix="K"
        value={{
          min: centsToThousandUsdString(get(values, 'filters.upb.min'), 1, true),
          max: centsToThousandUsdString(get(values, 'filters.upb.max'), 1, true)
        }}
        onChange={(attr, value) => setFieldValue(`filters.upb.${attr}`, thousandUsdToCentsString(value))}
        onRemove={handleRemoveRange}
      />}

      {showFilter.interestRatePercent && <RangeFilter
        filterName="interestRatePercent"
        label="Interest Rate"
        suffix="%"
        value={{
          min: get(values, 'filters.interestRatePercent.min'),
          max: get(values, 'filters.interestRatePercent.max')
        }}
        onChange={(attr, value) => setFieldValue(`filters.interestRatePercent.${attr}`, value)}
        onRemove={handleRemoveRange}
      />}

      {showFilter.investmentToBalancePercent && <RangeFilter
        filterName="investmentToBalancePercent"
        label="Investment to Balance (ITB)"
        suffix="%"
        value={{
          min: get(values, 'filters.investmentToBalancePercent.min'),
          max: get(values, 'filters.investmentToBalancePercent.max')
        }}
        onChange={(attr, value) => setFieldValue(`filters.investmentToBalancePercent.${attr}`, value)}
        onRemove={handleRemoveRange}
      />}

      {showFilter.investmentToValuePercent && <RangeFilter
        filterName="investmentToValuePercent"
        label="Investment to Value (ITV)"
        suffix="%"
        value={{
          min: get(values, 'filters.investmentToValuePercent.min'),
          max: get(values, 'filters.investmentToValuePercent.max')
        }}
        onChange={(attr, value) => setFieldValue(`filters.investmentToValuePercent.${attr}`, value)}
        onRemove={handleRemoveRange}
      />}

      {showFilter.paymentsRemaining && <RangeFilter
        filterName="paymentsRemaining"
        label="Payments Remaining"
        value={{
          min: get(values, 'filters.paymentsRemaining.min'),
          max: get(values, 'filters.paymentsRemaining.max')
        }}
        onChange={(attr, value) => setFieldValue(`filters.paymentsRemaining.${attr}`, value)}
        onRemove={handleRemoveRange}
      />}

      {showFilter.loanToValuePercent && <RangeFilter
        filterName="loanToValuePercent"
        label="Loan to Value (LTV)"
        suffix="%"
        value={{
          min: get(values, 'filters.loanToValuePercent.min'),
          max: get(values, 'filters.loanToValuePercent.max')
        }}
        onChange={(attr, value) => setFieldValue(`filters.loanToValuePercent.${attr}`, value)}
        onRemove={handleRemoveRange}
      />}

      {showFilter.legalStatus &&
      <FilterContainer filterName="legalStatus" label="Legal Status" onRemove={handleRemoveLegalStatus}>
        <Flex as="label" alignItems="center">
          <Field name="filters.legalStatus.bankruptcy" value={NOTE_TAPE_LEGAL_STATUS_BANKRUPTCY} checked={get(values, 'filters.legalStatus.bankruptcy')} component={Checkbox} />
          <Text fontSize={1}>Bankruptcy</Text>
        </Flex>
        <Flex as="label" alignItems="center">
          <Field name="filters.legalStatus.foreclosure" value={NOTE_TAPE_LEGAL_STATUS_FORECLOSURE} checked={get(values, 'filters.legalStatus.foreclosure')} component={Checkbox} />
          <Text fontSize={1}>Foreclosure</Text>
        </Flex>
        <Flex as="label" alignItems="center">
          <Field name="filters.legalStatus.none" value={NOTE_TAPE_LEGAL_STATUS_NONE} checked={get(values, 'filters.legalStatus.none')} component={Checkbox} />
          <Text fontSize={1}>None</Text>
        </Flex>
      </FilterContainer>}

      {showFilter.stateClassifications &&
      <FilterContainer filterName="stateClassifications" label="State Classifications" onRemove={handleRemoveStateClassifications}>
        <Flex as="label" alignItems="center">
          <Field name="filters.stateClassifications.isHardestHitFundState" value={true} checked={get(values, 'filters.stateClassifications.isHardestHitFundState')} component={Checkbox} />
          <Text fontSize={1}>Hardest Hit Fund</Text>
        </Flex>
        <Flex as="label" alignItems="center">
          <Field name="filters.stateClassifications.isJudicialState" value={true} checked={get(values, 'filters.stateClassifications.isJudicialState')} component={Checkbox} />
          <Text fontSize={1}>Judicial State</Text>
        </Flex>
        <Flex as="label" alignItems="center">
          <Field name="filters.stateClassifications.isNonJudicialState" value={true} checked={get(values, 'filters.stateClassifications.isNonJudicialState')} component={Checkbox} />
          <Text fontSize={1}>Non-Judicial State</Text>
        </Flex>
      </FilterContainer>}

    </Box>

    <Flex as="label" alignItems="center" mb={3}>
      <Field name="notify" checked={values.notify} component={Checkbox} />
      <Text fontSize={1}>Notify me when new listings match this search</Text>
    </Flex>
    <Flex as="label" alignItems="center" mb={3}>
      <Field name="star" checked={values.star} component={Checkbox} />
      <Text fontSize={1}>Star new listings that match this search</Text>
    </Flex>

    {status && status.errorMessage && <ErrorText mb={3}>{status.errorMessage}</ErrorText>}
    <Box mt={3}>
      <Button type="submit" busy={isSubmitting} variant="primary" size="small" mr={3}>
        Save Search
      </Button>
      {onCancel && <Button type="button" variant="default" size="small" onClick={onCancel}>Cancel</Button>}
    </Box>
  </Form>
});

const FilterContainer = ({ filterName, onRemove, label, children }) => <Box mb={3}>
  <Flex justifyContent="space-between" alignItems="center">
    <Label>{label}:</Label>
    <LinkButton onClick={() => onRemove(filterName)} sx={{
      color: 'red',
      opacity: .75,
      fontSize: 1,
      ':hover,:focus': { color: 'red', opacity: 1 }
    }}><XIcon size={10} mr={'6px'} style={{ position: 'relative', top: '-1px' }} />Remove</LinkButton>
  </Flex>
  {children}
</Box>;

const RangeFilter = ({ filterName, prefix, label, onChange, onRemove, suffix, value }) =>
  <FilterContainer filterName={filterName} label={label} onRemove={onRemove}>
    <Flex alignItems="center" fontSize={1}>
      <Flex flex={1}>
        {prefix && <Prefix px={3}>{prefix}</Prefix>}
        <Input
          name={`filters.${filterName}.min`}
          type="number"
          value={value.min}
          onChange={({ target: { value } }) => onChange('min', value)}
          px={2}
          flex={1}
          placeholder="min"
          hasPrefix={!!prefix}
          hasSuffix={!!suffix}
        />
        {suffix && <Suffix px={3}>{suffix}</Suffix>}
      </Flex>
      <Text mx={2}>to</Text>
      <Flex flex={1}>
        {prefix && <Prefix px={3}>{prefix}</Prefix>}
        <Input
          name={`filters.${filterName}.max`}
          type="number"
          value={value.max}
          onChange={({ target: { value } }) => onChange('max', value)}
          px={2}
          flex={1}
          placeholder="max"
          hasPrefix={!!prefix}
          hasSuffix={!!suffix}
        />
        {suffix && <Suffix px={3}>{suffix}</Suffix>}
      </Flex>
    </Flex>
  </FilterContainer>

SavedSearchForm.propTypes = {
  onCancel: PropTypes.func,
};

SavedSearchForm.defaultProps = {};

SavedSearchForm.displayName = 'SavedSearchForm';

export default SavedSearchForm;
