import { escrowAccountsRef } from '@paperstac/firestore-collections/lib/escrowAccounts';
import { get } from '../services/api';
import { firestore } from '../services/firebaseClient';
import dateid from '../utils/dateid';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const ADD_ESCROW_EVENT = 'ADD_ESCROW_EVENT';
export const SET_ESCROW_ORDER = 'SET_ESCROW_ORDER';
export const SET_ESCROW_ORDERS = 'SET_ESCROW_ORDERS';
export const UNSUBSCRIBE_ESCROW_ORDER = 'UNSUBSCRIBE_ESCROW_ORDER';
export const UNSUBSCRIBE_ESCROW_ORDERS = 'UNSUBSCRIBE_ESCROW_ORDERS';

const unsubscribes = {};

const transformCollection = querySnapshot => {
  return querySnapshot.docs.map(transformDoc);
};

const transformDoc = docSnap => {
  return docSnap.exists
    ? transformFirestoreTimestamps(docSnap)
    : null;
};

export const setEscrowOrders = payload => {
  return { type: SET_ESCROW_ORDERS, payload };
};

export const setEscrowOrder = payload => {
  return { type: SET_ESCROW_ORDER, payload };
};

export const subscribeEscrowOrders = (accountId) => {
  return async dispatch => {
    const escrowAccountRef = await escrowAccountsRef().where(`members.${accountId}.id`, '==', accountId).get().then(qs => qs.docs[0]);
    if (!escrowAccountRef) return;
    unsubscribes.escrowOrders = firestore
      .collection('escrowOrders')
      .where('escrowAccountId', '==', escrowAccountRef.data().id)
      .onSnapshot(querySnapshot => {
        dispatch(setEscrowOrders(transformCollection(querySnapshot)));
      });
  };
};

export const unsubscribeEscrowOrders = () => {
  return dispatch => {
    if (unsubscribes.escrowOrders) {
      unsubscribes.escrowOrders();
      dispatch({ type: UNSUBSCRIBE_ESCROW_ORDERS });
      unsubscribes.escrowOrders = null;
    }
  };
};

export const subscribeEscrowOrder = (escrowOrderId) => {
  return dispatch => {
    unsubscribes[escrowOrderId] = firestore
      .doc(`escrowOrders/${escrowOrderId}`)
      .onSnapshot(docSnap => {
        dispatch(setEscrowOrder(transformDoc(docSnap)));
      });
  };
};

export const unsubscribeEscrowOrder = (escrowOrderId) => {
  return dispatch => {
    if (unsubscribes[escrowOrderId]) {
      unsubscribes[escrowOrderId]();
      dispatch({ type: UNSUBSCRIBE_ESCROW_ORDER });
      unsubscribes[escrowOrderId] = null;
    }
  };
};

export const createEscrowOrderEventRequest = (escrowOrderId, data) => {
  return dispatch => {
    const payload = { ...data, id: dateid() };
    return firestore
      .doc(`escrowOrders/${escrowOrderId}/events/${payload.id}`)
      .set(payload)
      .then(() => dispatch({ type: ADD_ESCROW_EVENT, payload }))
      .catch(error => console.error(error));
  };
};

export const fetchBankAccountNumberRequest = (escrowOrderId) => {
  return dispatch => {
    return get(`escrow-orders/${escrowOrderId}/bank-account-number`);
  };
};
