import Box from '@paperstac/ui/lib/Box';
import React from 'react';

const ViewContainer = React.memo(props => <Box sx={{
  my: [0, 5],
  mx: 'auto',
  py: 5,
  px: [3, 5],
  maxWidth: ['100%', 400],
  bg: 'gray.1',
  border: ['none', 'default'],
  borderRadius: ['none', 'center']
}} {...props} />);

ViewContainer.propTypes = {};

ViewContainer.defaultProps = {};

ViewContainer.displayName = 'ViewContainer';

export default ViewContainer;
