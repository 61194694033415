import {
  investmentToBalancePercent,
  investmentToValuePercent,
  itbToPurchasePrice,
  itvToPurchasePrice,
  round
} from '@paperstac/helpers/lib/math';
import { centsToShortUsdString, centsToUsdString, getOrdinal } from '@paperstac/helpers/lib/numberHelpers';
import { NOTE_TAPE_PERFORMANCE_NON_PERFORMING, NOTE_TAPE_PERFORMANCE_PERFORMING } from '@paperstac/constants';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import ErrorText from '@paperstac/ui/lib/ErrorText';
import Description from '@paperstac/ui/lib/form/Description';
import FormGroup from '@paperstac/ui/lib/form/FormGroup';
import Label from '@paperstac/ui/lib/form/Label';
import PercentInput from '@paperstac/ui/lib/form/PercentInput';
import UsdInput from '@paperstac/ui/lib/form/UsdInput';
import Heading from '@paperstac/ui/lib/Heading';
import Pill from '@paperstac/ui/lib/Pill';
import Text from '@paperstac/ui/lib/Text';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

const NegotiateNotePriceSingleView = React.memo(({ differentPriceActionText, note, notePrice, onCancel, onSubmit, samePriceActionText, updatedNotePrice }) =>
  <Formik
    initialValues={{
      price: updatedNotePrice || '',
      itb: investmentToBalancePercent(updatedNotePrice, note.upb, 1) || '',
      itv: investmentToValuePercent(updatedNotePrice, note.propertyValue, 1) || '',
    }}
    validate={({ price }) => {
      const errors = {};
      if (!price || isNaN(price) || !(+price > 0)) {
        errors.price = 'Positive price is required';
      }
      return errors;
    }}
    onSubmit={async (payload, { setSubmitting, setStatus }) => {
      setSubmitting(true);
      onSubmit({ [note.id]: payload.price }).catch(error => {
        setStatus({ errorMessage: error.message });
        setSubmitting(false);
      });
    }}
    children={context => <FormContents
      {...context}
      differentPriceActionText={differentPriceActionText}
      note={note}
      notePrice={notePrice}
      onCancel={onCancel}
      samePriceActionText={samePriceActionText}
    />} />);

const FormContents = React.memo(({ differentPriceActionText, isSubmitting, note, notePrice, onCancel, samePriceActionText, setValues, status, values }) => {

  const isSamePrice = React.useMemo(() => !isNaN(notePrice) && !isNaN(values.price) && +notePrice === +values.price, [notePrice, values.price]);

  const priceDescription = React.useMemo(() => {
    const oldPrice = isNaN(notePrice) ? 0 : +notePrice;
    const newPrice = isNaN(values.price) ? 0 : +values.price;
    const diffCents = newPrice - oldPrice;
    const absoluteDiffCents = diffCents < 0 ? diffCents * -1 : diffCents;
    const percentChange = oldPrice && newPrice ? round(absoluteDiffCents / oldPrice * 100, 2) : 0;
    const percentChangeString = percentChange ? `(${percentChange}%)` : '';
    if (diffCents === 0) return null;
    return <Text sx={{ color: 'blue' }}>
      {diffCents > 0 ? 'Raising' : 'Dropping'} price by {centsToUsdString(absoluteDiffCents)} {percentChangeString}
    </Text>;
  }, [notePrice, values.price]);

  const handlePriceChange = React.useCallback(({ target: { value } }) => {
    const price = value;
    setValues({
      price,
      itb: investmentToBalancePercent(price, note.upb, 4) || '',
      itv: investmentToValuePercent(price, note.propertyValue, 4) || ''
    });
  }, [setValues, note]);

  const handleItbChange = React.useCallback(({ target: { value } }) => {
    const price = itbToPurchasePrice(value, note.upb);
    setValues({
      price,
      itb: value,
      itv: investmentToValuePercent(price, note.propertyValue, 4) || ''
    });
  }, [setValues, note]);

  const handleItvChange = React.useCallback(({ target: { value } }) => {
    const price = itvToPurchasePrice(value, note.propertyValue);
    setValues({
      price,
      itb: investmentToBalancePercent(price, note.upb, 4) || '',
      itv: value
    });
  }, [setValues, note]);

  return <Form>
    <Box mb={5}>
      <Heading fontSize={3}>{note.streetAddress}</Heading>
      <Text sx={{ fontSize: 1, textTransform: 'uppercase', mb: 1 }}>{note.cityStateZip}</Text>
      <Pill mr={1}>{getOrdinal(note.lienPosition)}</Pill>
      {note.performance === NOTE_TAPE_PERFORMANCE_PERFORMING && <Pill mr={1}>PL</Pill>}
      {note.performance === NOTE_TAPE_PERFORMANCE_NON_PERFORMING && <Pill mr={1}>NPL</Pill>}
      <Pill mr={1}>{centsToShortUsdString(note.upb)} UPB</Pill>
      {!!note.propertyValue && <Pill mr={1}>{centsToShortUsdString(note.propertyValue)} Property</Pill>}
    </Box>
    <Box mb={4}>
      <FormGroup>
        <Label>Price:</Label>
        <Box maxWidth={180}><Field name="price" component={UsdInput} onChange={handlePriceChange} /></Box>
        {!!priceDescription && <Description>{priceDescription}</Description>}
        <ErrorMessage name="price" component={ErrorText} />
      </FormGroup>
      <Box sx={{ display: 'inline-block', mr: 5 }}>
        <FormGroup>
          <Label>Investment to Balance (ITB):</Label>
          <Box maxWidth={150}><Field name="itb" component={PercentInput} onChange={handleItbChange} /></Box>
          <ErrorMessage name="itb" component={ErrorText} />
        </FormGroup>
      </Box>
      {!!note.propertyValue && <Box sx={{ display: 'inline-block' }}>
        <FormGroup>
          <Label>Investment to Value (ITV):</Label>
          <Box maxWidth={150}><Field name="itv" component={PercentInput} onChange={handleItvChange} /></Box>
          <ErrorMessage name="itv" component={ErrorText} />
        </FormGroup>
      </Box>}
    </Box>
    {status && status.errorMessage && <ErrorText mb={3}>{status.errorMessage}</ErrorText>}
    <Button type="submit" variant="primary" busy={isSubmitting} mr={2}>{isSamePrice ? samePriceActionText : differentPriceActionText}</Button>
    <Button onClick={onCancel} variant="default">Cancel</Button>
  </Form>;
});

NegotiateNotePriceSingleView.propTypes = {
  differentPriceActionText: PropTypes.string,
  note: PropTypes.object.isRequired,
  notePrice: PropTypes.any.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  samePriceActionText: PropTypes.string,
  updatedNotePrice: PropTypes.any.isRequired,
};

NegotiateNotePriceSingleView.defaultProps = {
  differentPriceActionText: 'Make Counter Offer',
  samePriceActionText: 'Accept Current Price',
};

NegotiateNotePriceSingleView.displayName = 'NegotiateNotePriceSingleView';

export default NegotiateNotePriceSingleView;
