import React from 'react';
import { itv } from './report';
import Section from './Section';
import StatGrid from './StatGrid';

const Itv = React.memo(props => {
  return <Section title="Investment to Value" subtitle="(sale price / property value)" bg="gray.1">
    <StatGrid stat1={itv[0]} stat2={itv[1]} stat3={itv[2]} stat4={itv[3]} />
  </Section>
});

Itv.propTypes = {};

Itv.defaultProps = {};

Itv.displayName = 'AverageITV';

export default Itv;
