import { firestore } from '../services/firebaseClient';
import dateid from '../utils/dateid';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const SET_STARRED_LISTINGS = 'SET_STARRED_LISTINGS';

const transformCollection = querySnapshot => {
  return querySnapshot.docs.map(transformDoc);
};

const transformDoc = docSnap => {
  return docSnap.exists ? transformFirestoreTimestamps(docSnap) : null;
};

export const setStarredListings = payload => {
  return { type: SET_STARRED_LISTINGS, payload };
};

export const createStarredListingRequest = (accountId, listing) => {
  const payload = {
    id: dateid(),
    listing,
    listingId: listing.id,
    accountId
  };
  return dispatch => {
    return firestore.doc(`starredListings/${payload.id}`).set(payload);
  };
};

export const deleteStarredListingRequest = starredListingId => {
  return dispatch => {
    return firestore.doc(`starredListings/${starredListingId}`).delete();
  };
};

export const subscribeStarredListings = accountId => {
  return dispatch => {
    return firestore
      .collection('starredListings')
      .where('accountId', '==', accountId)
      .onSnapshot(querySnapshot => {
        dispatch(setStarredListings(transformCollection(querySnapshot)));
      });
  };
};
