import Box from '@paperstac/ui/lib/Box';
import Flex from '@paperstac/ui/lib/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import Ratio from './Ratio';

const RatioGrid = React.memo(({ ratio1, ratio2, ratio3, ratio4, ratio5, ratio6 }) => {
  return <>
    <Flex flexDirection={['column', 'column', 'column', 'row']}>
      <Box flex={1} mb={[40, 40, 64]} mr={[0, 0, 0, 32, 0]}><Ratio {...ratio1} /></Box>
      <Box flex={1} mb={[40, 40, 64]} ml={[0, 0, 0, 32, 0]}><Ratio {...ratio2} /></Box>
    </Flex>
    <Flex flexDirection={['column', 'column', 'column', 'row']}>
      <Box flex={1} mb={[40, 40, 64]} mr={[0, 0, 0, 32, 0]}><Ratio {...ratio3} /></Box>
      <Box flex={1} mb={[40, 40, 64]} ml={[0, 0, 0, 32, 0]}><Ratio {...ratio4} /></Box>
    </Flex>
    <Flex flexDirection={['column', 'column', 'column', 'row']}>
      <Box flex={1} mb={[40, 40, 64, 0]} mr={[0, 0, 0, 32, 0]}><Ratio {...ratio5} /></Box>
      <Box flex={1} ml={[0, 0, 0, 32, 0]}><Ratio {...ratio6} /></Box>
    </Flex>
  </>
});

RatioGrid.propTypes = {
  ratio1: PropTypes.object.isRequired,
  ratio2: PropTypes.object.isRequired,
  ratio3: PropTypes.object.isRequired,
  ratio4: PropTypes.object.isRequired,
  ratio5: PropTypes.object.isRequired,
  ratio6: PropTypes.object.isRequired,
};

RatioGrid.defaultProps = {};

RatioGrid.displayName = 'RatioGrid';

export default RatioGrid;