import React from 'react';
import { Link } from 'react-router-dom';
import { FOR_SALE_ARCHIVE } from '../../../routes';
import { SPACER } from '../../../theme';
import BlogMediaBox from '../../BlogMediaBox';
import BlogPostAuthorBrettBurky from '../../BlogPostAuthorBrettBurky';
import BlogPostLayout from '../../BlogPostLayout';
import CloudinaryImage from '../../CloudinaryImage';

export const metadata = {
  postTitle: 'Save Searches for the Perfect Mortgage Note for Sale',
  metaTitle: 'Never Miss a Deal With Saved Searches',
  metaDescription:
    'Don\'t miss your opportunity to purchase mortgage notes that match your buying criteria. With saved searches, you\'ll never miss your chance at the perfect deal.',
  slug: 'never-miss-a-deal-with-saved-searches',
  publishDate: '2018-12-11 9:00',
  featureImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1543931661/website-assets/never-miss-a-deal-with-saved-searches-featured.png',
  socialImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1543931661/website-assets/never-miss-a-deal-with-saved-searches-social.png',
  tags: ['communities', 'how-to']
};

const TitleComponent = () => (
  <h1 style={{ marginTop: 0, marginBottom: SPACER(2) }}>
    <CloudinaryImage
      url="https://res.cloudinary.com/paperstac/image/upload/v1543931661/website-assets/never-miss-a-deal-with-saved-searches-social.png"
      alt={metadata.postTitle}
    />
  </h1>
);

const NeverMissADealWithSavedSearches = () => (
  <BlogPostLayout metadata={metadata} AuthorComponent={BlogPostAuthorBrettBurky} TitleComponent={TitleComponent}>
    <BlogMediaBox>
      <iframe
        title="Never Miss A Deal With The Saved Search Feature"
        width="560"
        height="315"
        src="https://www.youtube.com/embed/h5zgxAd-pwE"
        frameBorder="0"
        allowFullScreen
      />
    </BlogMediaBox>

    <p>
      Mortgage notes are being listed and sold on the Paperstac platform everyday. We know that you as a buyer don't want to miss your opportunity to get a great deal on a mortgage note that meets your buying criteria.
    </p>

    <p>
      That is why we've created a new feature that solves that.
    </p>

    <h2 style={{ marginTop: SPACER(2) }}>Introducing Saved Searches</h2>

    <p>
      With saved searches, you're now able to use our search filters to create your perfect buying criteria for mortgage notes listed on Paperstac.
    </p>

    <p>
      When a mortgage note that matches your buying criteria is listed on Paperstac, you'll receive an email notification altering you of the fact.
    </p>

    <p>
      Now you'll be among the first to know about the listing and will have the opportunity to jump into a transaction with the seller before it is too late.
    </p>

    <p>
      Now all you need to do is view the <Link to={FOR_SALE_ARCHIVE}>mortgage notes for sale</Link> listed on Paperstac, create your saved search, and wait for the emails to arrive.
    </p>
  </BlogPostLayout>
);

export default NeverMissADealWithSavedSearches;
