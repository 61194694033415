import { SubmissionError } from 'redux-form';
import { firestore } from '../services/firebaseClient';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const SUBSCRIBE_SELLER_ONBOARDING = 'SUBSCRIBE_SELLER_ONBOARDING';
export const SET_SELLER_ONBOARDING = 'SET_SELLER_ONBOARDING';
export const UNSUBSCRIBE_SELLER_ONBOARDING = 'UNSUBSCRIBE_SELLER_ONBOARDING';
export const CREATE_SELLER_ONBOARDING_REQUEST = 'CREATE_SELLER_ONBOARDING_REQUEST';
export const CREATE_SELLER_ONBOARDING_SUCCESS = 'CREATE_SELLER_ONBOARDING_SUCCESS';
export const CREATE_SELLER_ONBOARDING_FAILURE = 'CREATE_SELLER_ONBOARDING_FAILURE';

let unsubscribe = null;

const transformDoc = docSnap => {
  return docSnap.exists ? transformFirestoreTimestamps(docSnap) : null;
};

export const setSellerOnboarding = payload => {
  return { type: SET_SELLER_ONBOARDING, payload };
};

export const createSellerOnboardingRequest = payload => {
  return dispatch => {
    dispatch({ type: CREATE_SELLER_ONBOARDING_REQUEST });
    return firestore
      .doc(`sellerOnboarding/${payload.accountId}`)
      .set(payload)
      .then(() => dispatch({ type: CREATE_SELLER_ONBOARDING_SUCCESS }))
      .catch(error => {
        dispatch({ type: CREATE_SELLER_ONBOARDING_FAILURE, message: error.message });
        throw new SubmissionError({ _error: error.message });
      });
  };
};

export const subscribeSellerOnboarding = accountId => {
  return dispatch => {
    if (!unsubscribe) {
      dispatch({ type: SUBSCRIBE_SELLER_ONBOARDING });
      unsubscribe = firestore.doc(`sellerOnboarding/${accountId}`).onSnapshot(docRef => {
        dispatch(setSellerOnboarding(transformDoc(docRef)));
      });
    }
  };
};

export const unsubscribeSellerOnboarding = () => {
  return dispatch => {
    if (unsubscribe) {
      unsubscribe();
      unsubscribe = null;
      dispatch({ type: UNSUBSCRIBE_SELLER_ONBOARDING });
    }
  };
};
