import React from 'react';

export default () => (
  <>
    <p>
      This Software Services Agreement, including Sections 1 through 27 (and the definitions set forth in Section 26),
      is entered at and as of the date (the "Effective Date") when Subscriber accepts this Agreement or begins utilizing
      the Service. This Agreement is by and between Paperstac Inc, a Florida limited liability company ("Paperstac"),
      and Subscriber.
    </p>

    <p>
      Paperstac Software as a Service. Paperstac grants Subscriber a non-exclusive, non-transferable, limited right to
      access and use the Service within the United States during the Term of the Agreement solely for Subscriber's
      internal business purpose of buying or selling notes and other negotiable instruments and related security
      agreements and instruments (e.g., mortgages) (collectively being the "Mortgages"). As of the date of this
      Agreement, the Service is a marketplace permitting:
    </p>

    <ol>
      <li>
        Subscribers seeking to sell one or more Mortgages ("Seller Subscribers") to upload information concerning each
        such Mortgage, as well as information about the related property ("Mortgage Related Documentation") to the
        Service for advertisement to third party subscribers; and
      </li>
      <li>
        Subscribers seeking to acquire Mortgages ("Buyer Subscribers") to view Mortgage Related Documentation and other
        Content provided by Seller Subscribers; and
      </li>
      <li>
        Buyer Subscribers and Seller Subscribers to:
        <ol>
          <li>
            communicate via the Service to negotiate price and terms and, if determined by them, to tentatively agree to
            the purchase price and sale of a Mortgage; and
          </li>
          <li>
            conduct a due diligence review of Mortgage Related Documentation to determine whether or not to purchase on
            the terms of the tentative agreement such Mortgage; and
          </li>
          <li>
            arrange for, and consummate the purchase and sale of Mortgages through third party escrow agents, primarily
            being licensed attorneys in the State of Florida, selected by Paperstac (each being an "Escrow Agent").
          </li>
        </ol>
      </li>
    </ol>

    <p>
      In the event that Subscriber and a third party subscriber agree to the purchase and sale of a Mortgage through the
      Service, the purchase and sale shall be consummated on terms agreed to by them, and the proceeds and Mortgage
      disbursed via an Escrow Agent in accordance with the terms of the Escrow Agreement attached hereto as Exhibit A.
      The Escrow Agent shall first inspect the Mortgage Related Documentation to ascertain whether or not it is the
      original documentation. Paperstac shall select an alternative Escrow Agent if Subscriber reasonably objects to the
      original selected Escrow Agent. Subscriber authorize and directs the Escrow Agent to the Fees due Paperstac from
      Subscriber as and when same become due from the amounts held by Escrow Agent for Subscriber. The arrangement
      between Escrow Agent and Subscriber shall be based upon the terms and conditions of the Escrow Agreement between
      them and Paperstac shall have no liability or obligation, and Subscriber shall not look to Paperstac for or
      concerning, the actions and undertakings of the Escrow Agent or of the other subscriber party to the Mortgage
      purchase and sale transaction. Paperstac may, from time to time, for the convenience of Subscriber provide a form
      written purchase and sale agreement that Subscriber may elect, in Subscriber's sole and absolute discretion, to
      use to consummate a purchase and sale; provided that, Subscriber acknowledges and agrees that Paperstac is not
      providing legal, financial or other professional advice and shall have no liability or obligation whatsoever
      concerning any such purchase and sale agreement. Paperstac strongly suggests that Subscriber secure appropriate
      third party professional advice concerning any purchase or sale of a Mortgage.
    </p>

    <p>
      Username. After the provision of all required information, including, without limitation, an e-mail address,
      Paperstac shall provide Subscriber with a unique identifier to use to access and use the Service (the "Username").
      Only Subscriber shall be entitled to use such Username and the Username may not be transferred to any other person
      or entity. Subscriber shall not permit any other person to use the Username or to have access the Service as, by
      or through Subscriber. From the time the Username is issued until Paperstac acknowledges that it has been deleted
      or changed, all acts, communications, agreements and transactions undertaken under the Username shall be the acts,
      communications, agreements and transactions of Subscriber.
    </p>

    <p>
      Content. ALL CONTENT IS PROVIDED AS-IS, WHERE-IS WITH NO REPRESENTATIONS OR WARRANTIES WHATSOEVER. Paperstac does
      not screen or otherwise analyze or review Content at or prior to or after the time it is uploaded to the Service
      and, except for the license granted it below, makes no claim to any Content. Paperstac's sole obligation
      concerning the Content shall be to display it. Subscriber provides Paperstac with a fully paid non-terminable
      license to: (A) utilize the Content provided by Subscriber to the Service from time to time, in connection with
      the Service; to undertake Paperstac' other obligations under this Agreement; and to generate, publish and
      otherwise utilize aggregate anonymized information about the Service; and (B) make copies of the Content.
      Paperstac may, in its sole and absolute discretion and without notice to Subscriber, remove, revise, amend or
      otherwise correct any Content that Paperstac believes infringes the intellectual property rights of any person or
      entity, or is libelous, scandalous, false, fraudulent or provided for a purpose other than that for which the
      Service is permitted to be utilized. Paperstac has not obligation whatsoever to store or retain any Content and
      Subscriber agrees to retain copies of all Content he, she or it deems necessary.
    </p>

    <p>Limitations on Services. Subscriber shall not:</p>
    <ol>
      <li>
        access or use the Service via any automated means, including, without limitation, using any scripted, robotic or
        software means, except for such scripts or processes as may be provided by Paperstac from time to time;
      </li>
      <li>
        reproduce, duplicate, copy, sell, resell or exploit access to the Service, use of the Service, or any portion of
        the Service, including, but not limited to, the software coding or visual design elements;
      </li>
      <li>
        modify, reverse engineer, adapt or otherwise tamper with the Service or modify another website so as to falsely
        imply that it is associated with the Service, Paperstac, or any other software or service provided by Paperstac;
      </li>
      <li>
        knowingly use the Service in any manner that: (1) infringes or may infringe any third party intellectual
        property or moral rights; or (2) is unlawful, offensive, threatening, libelous, defamatory, pornographic,
        obscene or in violation of the terms of this Agreement;
      </li>
      <li>remove any copyright or other intellectual property notices of Paperstac; and</li>
      <li>
        knowingly use the Service to upload, post, host, or transmit any unsolicited bulk e-mail (e.g., spam), viruses,
        destructive, malicious or self-replicating computer software.
      </li>
    </ol>

    <p>
      Non-Circumvention. In exchange for the ability to access and use the Service and access to the Confidential
      Information, Subscriber agrees that he, she or it will not: (A) communicate with any other subscriber concerning
      any Mortgage other than via the Service; (B) suggest, imply or otherwise cause any other subscriber to deal with
      Subscriber except through the Service; (C) close or consummate and purchase any Mortgage, except in accordance
      with the Service and through an Escrow Agent. Subscriber acknowledges that, without the restrictions set forth in
      this Section 5, Paperstac would not grant Subscriber access to and the right to use the Service or access to the
      Confidential Information. Paperstac shall be entitled to recover liquidated damages in the amount of $6,000 for
      each violation of the provisions of this Section 5, it being the understanding of the parties that the actual
      damages of Paperstac for each such violation will be difficult to determine and the foregoing amount reflects
      their best estimate of such damages as of the date of this Agreement.
    </p>

    <p>
      Availability. Paperstac shall have the right at any time, and from time to time, to modify or discontinue the
      Service, temporarily or permanently, with or without notice, in whole or in part; and to temporarily suspend
      Subscriber's access to the Service for operational or other purposes, including, but not limited to, maintenance,
      repairs or installation of upgrades and emergencies, but will endeavor to provide notice prior to any
      non-emergency suspension.
    </p>

    <p>
      Ownership of Services. The Service, the software utilized to provide the Service, and all intellectual property
      and moral rights therein shall be and remain the property solely of Paperstac and, except for access to the
      Service on the terms set forth herein, Subscriber shall not have or receive any rights or interests in any of the
      foregoing.
    </p>

    <p>Obligations Concerning Confidential Information. Subscriber shall:</p>
    <ul>
      <li>not disclose, or allow any person or entity access to Confidential Information;</li>
      <li>not make any use, commercial or otherwise, of the Confidential Information;</li>
      <li>
        exercise reasonable diligence to maintain the confidential, secret or proprietary nature of all Confidential
        Information and to prevent its use, utilizing such security measures as may reasonable under the circumstances,
        but not less than that utilized by the Receiving Party for its own Confidential Information.
      </li>
    </ul>

    <p>
      Notwithstanding the foregoing, Subscriber may: (AA) disclose Confidential Information: (1) to your professional
      advisers who are under an obligation of confidentiality to Subscriber and who are providing professional services
      to Subscriber in connection with Subscriber's purchase or sale to which the Confidential Information applies; or
      (2) if and only if: (a) compelled by, and solely to the minimum extent necessary to comply with, bona fide
      unaffiliated third party initiated legal process (including, but not limited to, deposition, interrogatory,
      request for documents, subpoena, civil investigative demand or similar legal process); and (b) reasonable advance
      written notice is provided to the Disclosing Party (to the extent not prohibited by applicable law) including the
      relevant details of the legal process so as to enable the Disclosing Party to protect its rights in and to the
      subject Confidential Information; and (BB) use Confidential Information in connection with a due diligence review
      of a Mortgage for purchase, with making a Mortgage available for sale via the Service, and with consummating a
      purchase or sale through the Service and via an Escrow Agent. Upon Subscriber's acquisition of a Mortgage, the
      restrictions of this Section 8 shall not apply to Confidential Information of or pertaining to such Mortgage.
    </p>

    <p>
      Fees. Subscriber shall pay Paperstac a fee (the "Fee") of one percent of the purchase price of each Mortgage that
      is either purchased or sold by Subscriber. The Fee shall be due and payable at the time the purchase price is
      either paid by Subscriber or received by Subscriber, and Subscriber directs and authorizes the Escrow Agent to
      deduct the Fees from the purchase price at the time of the closing and consummation of each purchase or sale, and
      to pay such Fees to Paperstac. Payments received by Paperstac more than ten (10) days from when they are due shall
      be subject to a late fee of the greater of 5% of the amount due or $50.00, to offset Paperstac's costs incurred in
      connection with late payments, and all amounts due and unpaid shall bear interest from the date due until paid at
      the lesser of 1.5% per month or the highest rate permitted by applicable law. "To find out current rates and fees,
      please go to: https://support.paperstac.com/general-usage-questions/what-does-your-paperstac-fee-include. Should
      there be any difference between the fees contained herein and the fees found at the web page in the preceding
      sentence, the web page will control." If Subscriber does not complete the sale, Paperstac retains the right to
      collect any fees owed and retain any fees paid if Subscriber was negligent in completing the closing based on
      Paperstac's discretion.
    </p>

    <p>
      Term and Termination. The term (the "Term") of this Agreement shall commence on the Effective Date and continue
      until terminated in accordance herewith. Either party may terminate this Agreement on written notice to the other
      party; provided that, if termination is by Subscriber and the purchase or sale of a Mortgage is pending,
      termination shall not occur until the consummation or termination of such purchase or sale. Upon a termination of
      this Agreement for any reason whatsoever, Paperstac may terminate Subscriber's access to the Service and
      Subscriber shall immediately cease using the Service.
    </p>

    <p>
      WARRANTIES. EXCEPT FOR THE EXPRESS WARRANTIES SPECIFICALLY SET FORTH HEREINBELOW, PAPERSTAC MAKES NO WARRANTIES OR
      REPRESENTATIONS, EXPRESS OR IMPLIED CONCERNING OR RELATING TO ANY OF THE SERVICE OR THE ESCROW AGENT, INCLUDING,
      BUT NOT LIMITED TO: (A) NO WARRANTIES OF MERCHANTABILITY; (B) NO WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE; AND
      (C) NO WARRANTY CONCERNING INTELLECTUAL PROPERTY RIGHTS. PAPERSTAC ONLY WARRANTS THAT IT CAN PROVIDE SUBSCRIBER
      THE ACCESS TO THE SERVICE.
    </p>

    <p>
      Improvements and Suggestions by any Customer. All right, title and interest in and to, and the right to pursue
      protection for, improvements, enhancements and modifications the Service or its use or applicability that are
      suggested or made by Subscriber (being "Improvements") shall vest solely with Paperstac, and Subscriber does
      hereby assign all such Improvements to Paperstac. No license is granted to Subscriber in, to or under any
      Improvements or other intellectual property or moral right owned or otherwise assertable by Paperstac by express
      or implied grant, estoppel or otherwise, except solely when and if incorporated into a future revision of the
      Service. All benefits from the use of any such Improvements shall inure solely to Paperstac.
    </p>

    <p>
      LIMITATION OF LIABILITY. PAPERSTAC SHALL NOT BE LIABLE TO SUBSCRIBER FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL,
      SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOST PROFITS, LOSS OF BUSINESS
      OR OTHER ECONOMIC DAMAGE, OR INJURY TO PROPERTY. IN ADDITION, IN NO EVENT SHALL THE DAMAGES PAYABLE BY PAPERSTAC
      TO SUBSCRIBER OR ANY PERSON OR ENTITY CLAIMING THROUGH SUBSCRIBER EXCEED THE GREATER OF $1,000.00 OR THE FEES PAID
      BY SUBSCRIBER TO PAPERSTAC IN THE PARTICULAR TRANSACTION AT ISSUE. EACH PARTY ACKNOWLEDGES THAT: THIS SECTION
      REFLECTS AN INFORMED, VOLUNTARY ALLOCATION OF THE RISKS (KNOWN AND UNKNOWN) THAT MAY EXIST IN CONNECTION WITH THE
      ARRANGEMENTS SET FORTH IN THIS AGREEMENT, INCLUDING, WITHOUT LIMITATION, THE USE OF THE SERVICE, STORAGE AND
      DISSEMINATION OF THE CONTENT AND THE EXERCISE OF THE LICENSED RIGHTS; AND SUCH VOLUNTARY RISK ALLOCATION WAS A
      MATERIAL PART OF THE BARGAIN BETWEEN THE PARTIES; AND THE ECONOMIC AND OTHER TERMS OF THIS AGREEMENT WERE
      NEGOTIATED AND AGREED TO BY THE PARTIES IN RELIANCE ON SUCH VOLUNTARY RISK ALLOCATION.
    </p>

    <p>
      Indemnification. Paperstac shall hold Subscriber harmless from and against any claims, obligations, losses,
      damages, liabilities, fines, costs and expenses (including, without limitation, reasonable attorney's fees
      pretrial, trial and appellate) (collectively "Losses") arising out of or incurred as a result of or in connection
      with Paperstac' material breach of this Agreement; excluding therefrom all Losses arising from Subscriber's breach
      of this Agreement. Subscriber shall hold Paperstac and its members, managers and employees, collectively and
      individually, harmless from and against any Losses arising out of or incurred as a result of or in connection
      with: (A) Subscriber's material breach of this Agreement; or (B) a third party claim based on Subscriber's: (1)
      use of the Services; or (2) Content; excluding therefrom all Losses arising from Paperstac' breach of this
      Agreement.
    </p>

    <p>
      Entire Agreement, Waiver and Modification. This Agreement sets forth the entire understanding of the parties
      concerning the subject matter of this Agreement and incorporates all prior negotiations and understandings. There
      are no covenants, promises, agreements, conditions or understandings, either oral or written, between them
      relating to the subject matter of this Agreement other than those set forth herein. No purported waiver by any
      party of any default by another party of any term or provision contained herein shall be deemed to be a waiver of
      such term or provision unless the waiver is in writing and signed by the waiving party. No such waiver shall in
      any event be deemed a waiver of any subsequent default under the same or any other term or provision contained
      herein. Except as set forth in Section 16, no alteration, amendment, change or addition to this Agreement shall be
      binding upon any party unless in writing and signed by the party to be charged.
    </p>

    <p>
      Paperstac Amendment by Notice. Paperstac may at any time and from time to time amend this Agreement by providing
      not less than fifteen (15) days advance notice (the "Amendment Notice") to Subscriber setting forth the substance
      of such amendment. If Subscriber does not terminate this Agreement within the foregoing period, the amendment
      shall be effective as of the date set forth in the Amendment Notice.
    </p>

    <p>
      Successors and Assignment. All of the provisions of this Agreement shall be binding upon and inure to the benefit
      of the parties hereto and, except as otherwise specifically provided in this Agreement, their respective permitted
      successors and assigns. No Customer may assign or delegate its, his or her, rights or obligations under this
      Agreement. Paperstac may assign and delegate its rights and obligations under this Agreement without notice to any
      Customer at any time and from time to time.
    </p>

    <p>
      Notices. Any consent, waiver, notice, demand, request or other instrument required or permitted to be given and
      effective under this Agreement shall be in writing and deemed to have been properly given upon: (A) actual
      delivery if hand delivered; (B) the next business day after: (1) transmission by overnight express courier service
      (e.g., Federal Express), freight prepaid to the address for such party set forth herein; or (2) in the case of a
      notice provided by Paperstac, being sent to the e-mail address for the Subscriber associated with the Username;
      (C) the next business day after transmission by facsimile (with receipt showing successful transmission) to the
      facsimile number for such party set forth in the records for the Service; or (D) three (3) business days after
      being sent by certified United States mail, return receipt requested, postage prepaid, to the address for such
      party set forth in the records for the Service. Either party may change its address or facsimile number for
      notices by changing it on the records for the Service.
    </p>

    <p>
      Captions. The captions and paragraph letters appearing in this Agreement are inserted only as a matter of
      convenience. They do not define, limit, construe or describe the scope or intent of the provisions of this
      Agreement.
    </p>

    <p>
      Partial Invalidity. If any term or provisions of this Agreement, or the application thereof to any person or
      circumstance, shall be invalid or unenforceable, the remainder of this Agreement, or the application of such term
      or provision to persons or circumstances, other than those as to which it is held invalid, shall both be
      unaffected thereby and each term or provision of this Agreement shall be valid and be enforced to the fullest
      extent permitted by law.
    </p>

    <p>
      Applicable Law and Venue and Forum. The provisions of this Section are a material part of the agreement of the
      parties. But for the provisions of the Section, Paperstac would not enter this Agreement, grant the Licensed
      Rights, or provide any Customer with access to the Service. THIS AGREEMENT SHALL BE CONSTRUED AND GOVERNED UNDER
      AND BY THE LAWS OF THE STATE OF FLORIDA FOR CONTRACTS EXECUTED AND TO BE PERFORMED IN FLORIDA. EXCLUSIVE VENUE FOR
      ANY LEGAL ACTION AUTHORIZED HEREUNDER OR RELATING HERETO SHALL BE IN ORANGE COUNTY, FLORIDA.
    </p>

    <p>
      Third Party Beneficiaries. There are no intended, express or implied third party beneficiaries to this Agreement.
    </p>

    <p>
      WAIVER OF JURY TRIAL. THE PARTIES HEREBY KNOWINGLY AND VOLUNTARILY WAIVE ALL OF THEIR INDIVIDUAL AND COLLECTIVE
      RIGHTS TO A TRIAL BY JURY ON ANY AND ALL ISSUES PERTAINING TO OR ARISING OUT OF THIS AGREEMENT AND THE PRODUCTS.
    </p>

    <p>
      Attorneys' Fees. In the event any litigation, mediation, arbitration, or controversy between the parties hereto
      arises out of or relates to this Agreement or the Service, the Prevailing Party in such litigation, mediation,
      arbitration or controversy shall be entitled to recover from the other party all reasonable attorneys' fees,
      expenses and suit costs, including any associated with any appellate proceedings and any post-judgment collection
      proceedings.
    </p>

    <p>Definitions.</p>
    <p>
      Agreement means this entire Software Services Agreement (Sections 1 through 27), as may be amended from time to
      time in accordance herewith. Confidential Information means:
    </p>
    <ol>
      <li>
        all confidential or proprietary information of, about, or relating to:
        <ol>
          <li>Paperstac; or</li>
          <li>other subscribers that is received or accessed via the Service; or</li>
          <li>
            Mortgages, Mortgage Related Documentation, and property that is the subject of a mortgage that is received
            or accessed via the Service, as well as offers and transactions concerning or relating to Mortgages; or
          </li>
        </ol>
      </li>
      <li>
        all information provided by any subscriber of Paperstac other than Subscriber; provided, that, "Confidential
        Information" shall not, in any event, include any information that becomes generally known or publicly available
        upon reasonable inspection other than information that became generally known or publicly available as a result
        of a breach of an obligation of confidentiality to any of Seller or the Company.
      </li>
    </ol>

    <p>
      Content means any information uploaded or posted to the Service by Subscriber, including, without limitation,
      information about Subscriber or any Mortgage offered for sale by Subscriber.
    </p>

    <p>
      Prevailing Party means, generally, the party in any litigation, mediation, arbitration or other controversy (each
      being a "Dispute") that prevails on substantially the majority of the issues in the Dispute; provided that, if one
      party provides an offer of judgment or settlement in any Dispute and the outcome of the Dispute is not material
      different that such offer, the party making the offer shall be the Prevailing Party in such Dispute. For purposes
      of this definition, materially different shall include, in the case of money damages, the outcome of the Dispute
      provides for damages in excess of 115% of such offer.
    </p>

    <p>
      Service means the services provided from time to time by Paperstac through its Paperstac Software-as-a-Service
      offering.
    </p>

    <p>
      Survival. Sections 3, 5, and 8 through 27 shall survive the termination, cancellation or expiration of this
      Agreement by whatever means for whatever reason.
    </p>
  </>
);
