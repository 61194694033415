import { firestore } from '../services/firebaseClient';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';
import { addToast } from './toasterActions';

export const CREATE_SERVICER_PROFILE_REQUEST = 'CREATE_SERVICER_PROFILE_REQUEST';
export const CREATE_SERVICER_PROFILE_SUCCESS = 'CREATE_SERVICER_PROFILE_SUCCESS';
export const CREATE_SERVICER_PROFILE_FAILURE = 'CREATE_SERVICER_PROFILE_FAILURE';
export const DELETE_SERVICER_PROFILE_REQUEST = 'DELETE_SERVICER_PROFILE_REQUEST';
export const DELETE_SERVICER_PROFILE_SUCCESS = 'DELETE_SERVICER_PROFILE_SUCCESS';
export const DELETE_SERVICER_PROFILE_FAILURE = 'DELETE_SERVICER_PROFILE_FAILURE';
export const UPDATE_SERVICER_PROFILE_REQUEST = 'UPDATE_SERVICER_PROFILE_REQUEST';
export const UPDATE_SERVICER_PROFILE_SUCCESS = 'UPDATE_SERVICER_PROFILE_SUCCESS';
export const UPDATE_SERVICER_PROFILE_FAILURE = 'UPDATE_SERVICER_PROFILE_FAILURE';
export const FETCH_SERVICER_PROFILE_REQUEST = 'FETCH_SERVICER_PROFILE_REQUEST';
export const FETCH_SERVICER_PROFILE_SUCCESS = 'FETCH_SERVICER_PROFILE_SUCCESS';
export const FETCH_SERVICER_PROFILE_FAILURE = 'FETCH_SERVICER_PROFILE_FAILURE';
export const SET_SERVICER_PROFILES = 'SET_SERVICER_PROFILES';

const transformQuerySnapshot = querySnapshot => {
  return querySnapshot.docs.map(docSnap => transformFirestoreTimestamps(docSnap));
};

export const setServicerProfiles = payload => {
  return { type: SET_SERVICER_PROFILES, payload };
};

export const fetchServicerProfilesRequest = accountId => {
  return dispatch => {
    dispatch({ type: FETCH_SERVICER_PROFILE_REQUEST });
    return firestore
      .collection(`accounts/${accountId}/servicerProfiles`)
      .get()
      .then(querySnapshot => {
        dispatch({ type: FETCH_SERVICER_PROFILE_SUCCESS });
        dispatch(setServicerProfiles(transformQuerySnapshot(querySnapshot)));
      })
      .catch(error => {
        dispatch({ type: FETCH_SERVICER_PROFILE_FAILURE, message: error.message });
      });
  };
};

export const subscribeServicerProfiles = accountId => {
  return dispatch => {
    return firestore.collection(`accounts/${accountId}/servicerProfiles`).onSnapshot(querySnapshot => {
      dispatch(setServicerProfiles(transformQuerySnapshot(querySnapshot)));
    });
  };
};

export const createServicerProfileRequest = (accountId, payload) => {
  return dispatch => {
    dispatch({ type: CREATE_SERVICER_PROFILE_REQUEST, payload });
    return firestore
      .doc(`accounts/${accountId}/servicerProfiles/${payload.id}`)
      .set(payload);
  };
};

export const updateServicerProfileRequest = (accountId, payload) => {
  return dispatch => {
    dispatch({ type: UPDATE_SERVICER_PROFILE_REQUEST, payload });
    return firestore
      .doc(`accounts/${accountId}/servicerProfiles/${payload.id}`)
      .set(payload);
  };
};

export const deleteServicerProfileRequest = (accountId, id) => {
  return dispatch => {
    dispatch({ type: DELETE_SERVICER_PROFILE_REQUEST, id });
    return firestore
      .doc(`accounts/${accountId}/servicerProfiles/${id}`)
      .delete()
      .then(() => dispatch({ type: DELETE_SERVICER_PROFILE_SUCCESS }))
      .catch(error => {
        dispatch({ type: DELETE_SERVICER_PROFILE_FAILURE, message: error.message });
        dispatch(addToast(error.message, null, 'error'));
      });
  };
};
