import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { isEmail } from '../utils/validation';
import ServerErrorMessage from './ServerErrorMessage';
import SubmitField from './SubmitField';
import { REM } from '../theme';

export const TeamInviteFormName = 'TeamInviteForm';

const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!isEmail(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

const TeamInviteForm = props => {
  const { handleSubmit, submitting, error } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Field
        placeholder="Email Address"
        buttonText="Send Invite"
        name="email"
        type="email"
        component={SubmitField}
        width={REM(320)}
        busy={submitting}
        required
      />
      {error && <ServerErrorMessage>{error}</ServerErrorMessage>}
    </form>
  );
};

export default reduxForm({ form: TeamInviteFormName, validate })(TeamInviteForm);
