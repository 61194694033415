import Text from '@paperstac/ui/lib/Text';
import React from 'react';

const FilterTitle = React.memo(props => <Text fontWeight="bold" lineHeight={1} mb={3} {...props} />);

FilterTitle.propTypes = {};

FilterTitle.defaultProps = {};

FilterTitle.displayName = 'FilterTitle';

export default FilterTitle;