import { SortCollectionContext } from '@paperstac/common/lib/components/SortCollectionProvider';
import useAlgoliaActiveFilterCount from '@paperstac/common/lib/hooks/useAlgoliaActiveFilterCount';
import { getCache } from '@paperstac/common/lib/services/cache';
import AlgoliaCurrentRefinements from '@paperstac/ui/lib/AlgoliaCurrentRefinements';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import Flex from '@paperstac/ui/lib/Flex';
import MediaListIcon from '@paperstac/ui/lib/icons/MediaListIcon';
import SpreadsheetIcon from '@paperstac/ui/lib/icons/SpreadsheetIcon';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import Text from '@paperstac/ui/lib/Text';
import UnstyledButton from '@paperstac/ui/lib/UnstyledButton';
import React from 'react';
import ExportToCsv from './ExportToCsv';
import { ListingsSearchContext } from './ListingsSearchProvider';
import SerpList from './SerpList';
import SerpSpreadsheet from './SerpSpreadsheet';

const LIST_VIEW = 'LIST_VIEW';
const TABLE_VIEW = 'TABLE_VIEW';

const [getCachedView, setViewCache] = getCache('DefaultSerpView', LIST_VIEW);

const DefaultSerp = React.memo(({ onFilters, onSort }) => {
  const [view, setView] = React.useState(getCachedView());
  const handleViewChange = React.useCallback((view) => {
    setView(view);
    setViewCache(view);
  }, []);
  const { resultCount, starredFilterEnabled, disableStarredFilter } = React.useContext(ListingsSearchContext);
  const { sortLabel, sortDirection } = React.useContext(SortCollectionContext);
  const activeFilterCount = useAlgoliaActiveFilterCount();

  return <Box>
    <Flex mb={[40, 40, 40, 64]}>
      <Flex flexDirection={['column', 'column', 'row']} flex={'1 1 0'}>
        <Box mb={[3, 3, 0]} mr={[0, 0, 4]} display={['block', 'block', 'block', 'none']}>
          <Button onClick={onFilters} variant="secondaryOutline" size="small">
            Filters {activeFilterCount !== 0 && <CountPill>{activeFilterCount}</CountPill>}
          </Button>
        </Box>
        <Text>
          <Strong>{resultCount}</Strong> {resultCount === 1 ? ' listing ' : ' listings '} found.
          <ExportToCsv ml={2} />
        </Text>
      </Flex>
      <Flex flexDirection={['column', 'column', 'row']} alignItems={['flex-end', 'flex-end', 'center']} justifyContent={['space-between']}>
        <Text><Strong>Sort By</Strong>: <LinkButton onClick={onSort}>{sortLabel} {sortDirection === 'ASC' ? '↓' : '↑'}</LinkButton></Text>
        <Box order={[0, 0, -1]} mr={[0, 0, 4]}>
          <UnstyledButton title="List View" onClick={() => handleViewChange(LIST_VIEW)} sx={{
            cursor: 'pointer',
            color: view === LIST_VIEW ? 'secondary' : 'gray.6',
            mr: '12px',
            '&:hover,&:active': {
              color: view === LIST_VIEW ? 'secondary' : 'gray.8',
            }
          }}><MediaListIcon size={24} /></UnstyledButton>
          <UnstyledButton title="Table View" onClick={() => handleViewChange(TABLE_VIEW)} sx={{
            cursor: 'pointer',
            color: view === TABLE_VIEW ? 'secondary' : 'gray.6',
            '&:hover,&:active': {
              color: view === TABLE_VIEW ? 'secondary' : 'gray.8',
            }
          }}><SpreadsheetIcon size={24} /></UnstyledButton>
        </Box>
      </Flex>
    </Flex>
    <AlgoliaCurrentRefinements prependRefinements={starredFilterEnabled ? [{
      label: 'My Starred Listings',
      handleClear: disableStarredFilter
    }] : []} />
    {view === LIST_VIEW && <SerpList />}
    {view === TABLE_VIEW && <SerpSpreadsheet />}
  </Box>
});

const Strong = props => <Text fontWeight="bold" display="inline" {...props} />;

const CountPill = (props) => <Text {...props} sx={{
  borderRadius: 'corner',
  display: 'inline-block',
  px: 1,
  bg: 'primary',
  color: 'white',
  fontSize: 0,
  lineHeight: '1.3',
  fontWeight: 'normal'
}} />;

DefaultSerp.propTypes = {};

DefaultSerp.defaultProps = {};

DefaultSerp.displayName = 'DefaultSerp';

export default DefaultSerp;