import { END_SESSION } from '../actions/authActions';
import { UNSUBSCRIBE_CURRENT_ACCOUNT } from '../actions/currentAccountActions';
import { SET_TRANSACTION_EVENTS } from '../actions/transactionEventActions';

const DEFAULT_STATE = {};

export default function transactionEventsReducer(state = DEFAULT_STATE, { type, payload = [] }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case SET_TRANSACTION_EVENTS:
      return {
        ...state,
        [payload.transactionId]: payload.events
      };
    default:
      return state;
  }
}

export const getTransactionEvents = (state, transactionId) => {
  return state[transactionId] || [];
};
