import { firestore } from '../services/firebaseClient';
import dateid from '../utils/dateid';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';
import { addToast } from './toasterActions';

export const DELETE_CREDIT_CARD_REQUEST = 'DELETE_CREDIT_CARD_REQUEST';
export const DELETE_CREDIT_CARD_SUCCESS = 'DELETE_CREDIT_CARD_SUCCESS';
export const DELETE_CREDIT_CARD_FAILURE = 'DELETE_CREDIT_CARD_FAILURE';
export const SET_CREDIT_CARDS = 'SET_CREDIT_CARDS';
export const SUBSCRIBE_CREDIT_CARDS = 'SUBSCRIBE_CREDIT_CARDS';

const transformQuerySnapshot = querySnapshot => {
  return querySnapshot.docs.map(transformFirestoreTimestamps);
};

export const setCreditCards = payload => {
  return { type: SET_CREDIT_CARDS, payload };
};

export const subscribeCreditCards = accountId => {
  return dispatch => {
    dispatch({ type: SUBSCRIBE_CREDIT_CARDS, accountId });
    return firestore
      .collection(`accounts/${accountId}/creditCards`)
      .where('_processed', '==', true)
      .onSnapshot(querySnapshot => {
        dispatch(setCreditCards(transformQuerySnapshot(querySnapshot)));
      });
  };
};

export const createCreditCard = (accountId, data) => {
  const payload = { id: dateid(), accountId, ...data };
  return dispatch => {
    return firestore.doc(`accounts/${accountId}/creditCards/${payload.id}`).set(payload);
  };
};

export const deleteCreditCardRequest = (accountId, id) => {
  return dispatch => {
    dispatch({ type: DELETE_CREDIT_CARD_REQUEST, id });
    return firestore
      .doc(`accounts/${accountId}/creditCards/${id}`)
      .delete()
      .then(() => dispatch({ type: DELETE_CREDIT_CARD_SUCCESS }))
      .catch(error => {
        dispatch({ type: DELETE_CREDIT_CARD_FAILURE, message: error.message });
        dispatch(addToast(error.message, null, 'error'));
      });
  };
};
