import React from 'react';
import { averageITB } from './report';
import Section from './Section';
import StatGrid from './StatGrid';

const AverageITB = React.memo(props => {
  return <Section title="Average Investment to Balance" subtitle="(sale price / unpaid principal balance)" bg="white">
    <StatGrid stat1={averageITB[0]} stat2={averageITB[1]} stat3={averageITB[2]} stat4={averageITB[3]} />
  </Section>
});

AverageITB.propTypes = {};

AverageITB.defaultProps = {};

AverageITB.displayName = 'AverageITB';

export default AverageITB;