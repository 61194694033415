import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import { relativeDate } from '@paperstac/helpers/lib/dateHelpers';
import { loanToValuePercent } from '@paperstac/helpers/lib/math';
import { centsToUsdString } from '@paperstac/helpers/lib/numberHelpers';
import { uppercaseFirstLetter } from '@paperstac/helpers/lib/stringHelpers';
import Box from '@paperstac/ui/lib/Box';
import Heading from '@paperstac/ui/lib/Heading';
import ExternalLinkIcon from '@paperstac/ui/lib/icons/ExternalLinkIcon';
import Link from '@paperstac/ui/lib/Link';
import ListGrid from '@paperstac/ui/lib/ListGrid';
import ProvidedByZillowCard from '@paperstac/ui/lib/ProvidedByZillowCard';
import Text from '@paperstac/ui/lib/Text';
import PropTypes from 'prop-types';
import React from 'react';

const PropertyValuationsSection = React.memo(({ note, parentWidth, ...props }) => {
  const { uid } = React.useContext(IdentityContext);
  const hasZillowData = !!note.zestimate || !!note.rentZestimate;
  const showPriceRange = parentWidth > 800 && (note.zestimateHigh || note.rentZestimateHigh);

  const items = React.useMemo(() => {
    const items = [];
    if (note.sellerProvidedPropertyValue) {
      let item = { 'Value': centsToUsdString(note.sellerProvidedPropertyValue) };
      if (showPriceRange) item = { ...item, 'Range': 'N/A' };
      item = {
        ...item,
        'LTV': `${loanToValuePercent(note.upb, note.sellerProvidedPropertyValue)}%`,
        'Date': note.sellerProvidedPropertyValueDate ? `${uppercaseFirstLetter(relativeDate(note.sellerProvidedPropertyValueDate))}` : 'N/A',
        'Type': note.sellerProvidedPropertyValueUrl ?
          <Link href={note.sellerProvidedPropertyValueUrl} target="_blank" rel="noreferrer noopener">{note.sellerProvidedPropertyValueType}{' '}
            <ExternalLinkIcon size={14} /></Link> : note.sellerProvidedPropertyValueType,
        'Provided By': 'Seller',
      };
      items.push(item);
    }
    if (note.zestimate) {
      let item = { 'Value': centsToUsdString(note.zestimate) };
      if (showPriceRange) item = {
        ...item,
        'Range': note.zestimateHigh ? `${centsToUsdString(note.zestimateLow)} - ${centsToUsdString(note.zestimateHigh)}` : 'N/A'
      };
      item = {
        ...item,
        'Value': centsToUsdString(note.zestimate),
        'LTV': note.loanToZestimatePercent ? `${note.loanToZestimatePercent}%` : 'N/A',
        'Date': note.zestimateDate ? `${uppercaseFirstLetter(relativeDate(note.zestimateDate))}` : 'N/A',
        'Type': uid && note.zillowUrl ?
          <Link href={note.zillowUrl} target="_blank" rel="noreferrer noopener">Zestimate <ExternalLinkIcon size={14} /></Link> : 'Zestimate',
        'Provided By': 'Zillow',
      };
      items.push(item);
    }
    if (note.rentZestimate) {
      let item = { 'Value': centsToUsdString(note.rentZestimate) };
      if (showPriceRange) item = {
        ...item,
        'Range': note.rentZestimateHigh ? `${centsToUsdString(note.rentZestimateLow)} - ${centsToUsdString(note.rentZestimateHigh)}` : 'N/A'
      };
      item = {
        ...item,
        'Value': centsToUsdString(note.rentZestimate),
        'LTV': 'N/A',
        'Date': note.rentZestimateDate ? `${uppercaseFirstLetter(relativeDate(note.rentZestimateDate))}` : 'N/A',
        'Type': uid && note.zillowUrl ?
          <Link href={note.zillowUrl} target="_blank" rel="noreferrer noopener">Rent Zestimate <ExternalLinkIcon size={14} /></Link> : 'Rent Zestimate',
        'Provided By': 'Zillow',
      };
      items.push(item);
    }
    return items;
  }, [showPriceRange, note, uid]);

  return <Box {...props}>
    <Heading sx={{
      mb: 2,
      fontSize: 2,
      textTransform: 'uppercase',
    }}>Property Valuations</Heading>

    {!!items.length && <Box sx={{ border: 'default', borderRadius: 'corner', p: 3, bg: 'white' }}>
      <ListGrid items={items} sx={{
        border: 'none',
        p: 0,
        fontSize: 1
      }} stacked={parentWidth < 600} />
      {hasZillowData && <ProvidedByZillowCard sx={{ mt: 2, p: 0, border: 'none' }} />}
    </Box>}

    {!items.length && <Text variant="italic">No valuations found...</Text>}

  </Box>;
});

PropertyValuationsSection.propTypes = {
  note: PropTypes.object.isRequired,
};

PropertyValuationsSection.defaultProps = {};

PropertyValuationsSection.displayName = 'PropertyValuationsSection';

export default PropertyValuationsSection;
