import { notePropertyLabelsMap, transformNotePropertyValue } from '@paperstac/common/lib/services/notesHelper';
import Box from '@paperstac/ui/lib/Box';
import DescriptionGridCell from '@paperstac/ui/lib/DescriptionGridCell';
import Flex from '@paperstac/ui/lib/Flex';
import Link from '@paperstac/ui/lib/Link';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import React from 'react';
import EditButton from './EditButton';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';
import { NoteScreenContext } from './NoteScreen';

const ALL_PROPS = ['sellerProvidedPropertyValueType', 'sellerProvidedPropertyValueDate', 'zestimate', 'zestimateDate', 'rentZestimate', 'rentZestimateDate'];

const ALWAYS_SHOW_PROPS = ['sellerProvidedPropertyValueType', 'sellerProvidedPropertyValueDate', 'zestimate'];

const NoteScreenViewPropertyValue = React.memo(() => {
  const { note, stacked } = React.useContext(ManageListingOverlayContext);
  const { showPropertyValue } = React.useContext(NoteScreenContext);
  const [isShowingAll, setShowingAll] = React.useState(false);
  const showAll = React.useCallback(() => setShowingAll(true), []);

  return <DescriptionGridCell term="Property Value">
    <Flex alignItems="center" mt={3}>
      <Box flex={'1 1 0'} mr={stacked ? 3 : 5}>

        <Flex justifyContent="space-between" mt={1} fontSize={stacked ? 1 : 2}>
          <Box>{notePropertyLabelsMap['sellerProvidedPropertyValue']}:</Box>
          <Box fontWeight="bold">
            <Link href={note['sellerProvidedPropertyValueUrl']} target="_blank">
              {transformNotePropertyValue('sellerProvidedPropertyValue', note['sellerProvidedPropertyValue'])}
            </Link>
          </Box>
        </Flex>

        {!isShowingAll && ALWAYS_SHOW_PROPS.map(property =>
          <Flex key={property} justifyContent="space-between" mt={1} fontSize={stacked ? 1 : 2}>
            <Box>{notePropertyLabelsMap[property]}:</Box>
            <Box fontWeight="bold">{transformNotePropertyValue(property, note[property])}</Box>
          </Flex>)}
        {!isShowingAll && <Box mt={2}><LinkButton onClick={showAll} fontSize={1}>Show All Details</LinkButton></Box>}

        {isShowingAll && ALL_PROPS.map(property =>
          <Flex key={property} justifyContent="space-between" mt={1} fontSize={stacked ? 1 : 2}>
            <Box>{notePropertyLabelsMap[property]}:</Box>
            <Box fontWeight="bold">{transformNotePropertyValue(property, note[property])}</Box>
          </Flex>)}

      </Box>
      <Box><EditButton onClick={showPropertyValue} /></Box>
    </Flex>
  </DescriptionGridCell>;
});

NoteScreenViewPropertyValue.propTypes = {};

NoteScreenViewPropertyValue.defaultProps = {};

NoteScreenViewPropertyValue.displayName = 'NoteScreenViewPropertyValue';

export default NoteScreenViewPropertyValue;
