import get from 'lodash/get';
import { END_SESSION } from '../actions/authActions';
import { SET_CURRENT_USER, SET_CURRENT_USER_CLAIMS, UNSUBSCRIBE_CURRENT_USER } from '../actions/currentUserActions';

const DEFAULT_STATE = {
  user: null,
  claims: null
};

export default function currentUserReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_USER:
      return DEFAULT_STATE;
    case SET_CURRENT_USER:
      return { ...state, user: payload || null };
    case SET_CURRENT_USER_CLAIMS:
      return { ...state, claims: payload || null };
    default:
      return state;
  }
}

export const getCurrentUser = state => {
  return state && state.user ? state.user : null;
};

export const isUserAdmin = state => {
  return !!get(state, 'claims.admin');
};
