import { END_SESSION } from '../actions/authActions';
import { UNSUBSCRIBE_CURRENT_ACCOUNT } from '../actions/currentAccountActions';
import { FETCH_ACTIVE_LISTING_FAILURE, SET_ACTIVE_LISTING } from '../actions/activeListingActions';

const DEFAULT_STATE = {};

export default function activeListingsReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case FETCH_ACTIVE_LISTING_FAILURE:
      return {
        ...state,
        [payload.id]: {
          ...getActiveListing(state, payload.id),
          errorMessage: payload.message,
          data: null
        }
      };
    case SET_ACTIVE_LISTING:
      return {
        ...state,
        [payload.id]: {
          ...getActiveListing(state, payload.id),
          errorMessage: null,
          data: payload
        }
      };
    default:
      return state;
  }
}

export const getActiveListing = (state, listingId) => {
  return state[listingId] || { data: null, errorMessage: null };
};
