import { firestore } from '../services/firebaseClient';
import dateid from '../utils/dateid';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const CREATE_LISTING_SUCCESS = 'CREATE_LISTING_SUCCESS';
export const DELETE_LISTING_SUCCESS = 'DELETE_LISTING_SUCCESS';
export const SET_LISTING = 'SET_LISTING';
export const SET_LISTINGS = 'SET_LISTINGS';
export const SET_LISTINGS_SORT = 'SET_LISTINGS_SORT';
export const UNSUBSCRIBE_LISTINGS = 'UNSUBSCRIBE_LISTINGS';

const unsubscribes = {};

const transformCollection = querySnapshot => {
  return querySnapshot.docs.map(transformDoc);
};

const transformDoc = transformFirestoreTimestamps;

export const setListing = payload => {
  return { type: SET_LISTING, payload };
};

export const setListings = payload => {
  return { type: SET_LISTINGS, payload };
};

export const setListingsSort = (sortBy, sortDirection) => {
  return { type: SET_LISTINGS_SORT, payload: { sortBy, sortDirection } };
};

export const subscribeListings = accountId => {
  return dispatch => {
    if (unsubscribes.listings) {
      unsubscribes.listings();
    }
    unsubscribes.listings = firestore
      .collection('listings')
      .where('accountId', '==', accountId)
      .onSnapshot(querySnapshot => {
        dispatch(setListings(transformCollection(querySnapshot)));
      });
  };
};

export const unsubscribeListings = () => {
  return dispatch => {
    if (unsubscribes.listings) {
      unsubscribes.listings();
      dispatch({ type: UNSUBSCRIBE_LISTINGS });
      unsubscribes.listings = null;
    }
  };
};

export const subscribeListing = listingId => {
  return dispatch => {
    if (unsubscribes[listingId]) {
      unsubscribes[listingId]();
    }
    unsubscribes[listingId] = firestore.doc(`listings/${listingId}`).onSnapshot(docRef => {
      dispatch(setListing(transformDoc(docRef)));
    });
  };
};

export const fetchListingRequest = listingId => {
  return dispatch => {
    return firestore
      .doc(`listings/${listingId}`)
      .get()
      .then(docRef => {
        const listing = transformDoc(docRef);
        dispatch(setListing(listing));
        return listing;
      });
  };
};

export const unsubscribeListing = listingId => {
  return dispatch => {
    if (unsubscribes[listingId]) {
      unsubscribes[listingId]();
      unsubscribes[listingId] = null;
    }
  };
};

export const saveListingRequest = payload => {
  return payload.id ? updateListingRequest(payload) : createListingRequest({ id: dateid(), ...payload });
};

export const createListingRequest = payload => {
  return dispatch => {
    return firestore
      .doc(`listings/${payload.id}`)
      .set(payload)
      .then(() => {
        dispatch({ type: CREATE_LISTING_SUCCESS, payload });
      });
  };
};

export const updateListingRequest = payload => {
  return dispatch => {
    return firestore.doc(`listings/${payload.id}`).update(payload);
  };
};

export const deleteListingRequest = payload => {
  return dispatch => {
    return firestore
      .doc(`listings/${payload.id}`)
      .delete()
      .then(() => {
        dispatch({ type: DELETE_LISTING_SUCCESS, payload });
      });
  };
};
