import { END_SESSION } from '../actions/authActions';
import {
  SET_CURRENT_ACCOUNT,
  SET_CURRENT_ACCOUNT_ID,
  UNSUBSCRIBE_CURRENT_ACCOUNT
} from '../actions/currentAccountActions';

const DEFAULT_STATE = null;

export default function currentAccountIdReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case SET_CURRENT_ACCOUNT:
      return payload ? payload.id : DEFAULT_STATE;
    case SET_CURRENT_ACCOUNT_ID:
      return payload || DEFAULT_STATE;
    default:
      return state;
  }
}
