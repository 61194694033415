import AlgoliaHasRangeRefinement from '@paperstac/ui/lib/AlgoliaHasRangeRefinement';
import AlgoliaRangeInput from '@paperstac/ui/lib/AlgoliaRangeInput';
import React from 'react';
import CollapsibleFilterContainer from './CollapsibleFilterContainer';

const LoanToValueFilter = React.memo(props => <AlgoliaHasRangeRefinement attribute="noteMetaAgg.loanToValuePercent">
  <CollapsibleFilterContainer title="Loan to Value (LTV)">
    <AlgoliaRangeInput attribute="noteMetaAgg.loanToValuePercent" suffix="%"/>
  </CollapsibleFilterContainer>
</AlgoliaHasRangeRefinement>);

LoanToValueFilter.propTypes = {};

LoanToValueFilter.defaultProps = {};

LoanToValueFilter.displayName = 'LoanToValueFilter';

export default LoanToValueFilter;