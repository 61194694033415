var firebase = null;
export var setFirebase = function setFirebase(client) {
  firebase = client;
};
export var getFirebase = function getFirebase() {
  if (!firebase) {
    throw Error('Firebase client is not installed');
  }

  return firebase;
};