import { firestore } from '../services/firebaseClient';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const SET_ESCROW_ACCOUNT = 'SET_ESCROW_ACCOUNT';
export const SET_ESCROW_ACCOUNT_TRANSACTIONS = 'SET_ESCROW_ACCOUNT_TRANSACTIONS';
export const UNSUBSCRIBE_ESCROW_ESCROW_ACCOUNT_TRANSACTIONS = 'UNSUBSCRIBE_ESCROW_ESCROW_ACCOUNT_TRANSACTIONS';

const unsubscribes = {};

const transformCollection = querySnapshot => {
  return querySnapshot.docs.map(transformDoc);
};

const transformDoc = docSnap => {
  return docSnap.exists
    ? transformFirestoreTimestamps(docSnap)
    : null;
};

export const setEscrowAccountTransactions = payload => {
  return { type: SET_ESCROW_ACCOUNT_TRANSACTIONS, payload };
};

export const fetchEscrowAccountRequest = (ownerId) => {
  return dispatch => {
    return firestore.collection('escrowAccounts').where('ownerId', '==', ownerId).get()
      .then(querySnapshot => {
        const escrowAccount = transformCollection(querySnapshot)[0];
        dispatch({ type: SET_ESCROW_ACCOUNT, payload: escrowAccount });
        return escrowAccount;
      });
  };
};

export const subscribeEscrowAccountTransactions = (escrowAccountId) => {
  return dispatch => {
    unsubscribes.escrowAccountTransactions = firestore
      .collection(`escrowAccounts/${escrowAccountId}/transactions`)
      .orderBy('createdDate', 'desc')
      .onSnapshot(querySnapshot => {
        dispatch(setEscrowAccountTransactions(transformCollection(querySnapshot)));
      });
  };
};

export const unsubscribeEscrowAccountTransactions = () => {
  return dispatch => {
    if (unsubscribes.escrowAccountTransactions) {
      unsubscribes.escrowAccountTransactions();
      dispatch({ type: UNSUBSCRIBE_ESCROW_ESCROW_ACCOUNT_TRANSACTIONS });
      unsubscribes.escrowAccountTransactions = null;
    }
  };
};