import UiLinkButton from '@paperstac/ui/lib/LinkButton';
import React from 'react';

const LinkButton = React.memo(({ sx, ...props }) => <UiLinkButton sx={{
  fontWeight: 'bold',
  color: 'gray.9',
  textDecoration: 'underline',
  ':hover,:focus': { color: 'primary', textDecoration: 'none' },
  ...props.sx
}} {...props} />);

LinkButton.propTypes = {};

LinkButton.defaultProps = { sx: {} };

LinkButton.displayName = 'LinkButton';

export default LinkButton;