import LinkButton from '@paperstac/ui/lib/LinkButton';
import React from 'react';

const NavLinkButton = ({fontSize, mr, ...props}) => <LinkButton sx={{
  fontSize,
  mr,
  textTransform: 'uppercase',
  cursor: 'pointer'
}} {...props} />;

NavLinkButton.defaultProps = {
  fontSize: [0, 1],
  mr: [0, 0, 4]
};

NavLinkButton.displayName = 'NavLinkButton';

export default NavLinkButton;
