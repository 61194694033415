import { sortCollection } from '@paperstac/helpers/lib/arrayHelpers';
import AlgoliaChecklistRefinement from '@paperstac/ui/lib/AlgoliaChecklistRefinement';
import AlgoliaHasListRefinement from '@paperstac/ui/lib/AlgoliaHasListRefinement';
import React from 'react';
import CollapsibleFilterContainer from './CollapsibleFilterContainer';

const SellerFilter = React.memo(props => <AlgoliaHasListRefinement attribute="uniqueSellerName">
  <CollapsibleFilterContainer title="Seller" {...props}>
    <AlgoliaChecklistRefinement
      attribute="uniqueSellerName"
      limit={100}
      selectAll={false}
      noResultsMessage="No matching sellers..."
      transformItems={items => sortCollection(items, 'count', 'DESC')}
    />
  </CollapsibleFilterContainer>
</AlgoliaHasListRefinement>);

SellerFilter.propTypes = {};

SellerFilter.defaultProps = {};

SellerFilter.displayName = 'SellerFilter';

export default SellerFilter;
