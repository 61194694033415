import React from 'react';

const FacebookShareButton = ({ url }) => (
  <div
    className="fb-share-button"
    data-href={url}
    data-layout="button_count"
    data-size="large"
    data-mobile-iframe="true"
  >
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&amp;src=sdkpreparse`}
      className="fb-xfbml-parse-ignore"
    >Share</a>
  </div>
);

export default FacebookShareButton;