import Text from '@paperstac/ui/lib/Text';
import React from 'react';

const TitleSubtext = React.memo(props => <Text textAlign="center" fontSize={1} mb={40} {...props} />);

TitleSubtext.propTypes = {};

TitleSubtext.defaultProps = {};

TitleSubtext.displayName = 'TitleSubtext';

export default TitleSubtext;