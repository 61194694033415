import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import {
  ABOUT,
  BLOG_POSTS_ARCHIVE,
  FOR_SALE_ARCHIVE,
  LISTINGS_ARCHIVE,
  MY_FEED,
  SELLER_ONBOARDING
} from '@paperstac/routes/lib/webRoutes';
import Flex from '@paperstac/ui/lib/Flex';
import Link from '@paperstac/ui/lib/Link';
import React from 'react';
import styled from 'styled-components';
import { borderTop, bottom, height, left, position, right } from 'styled-system';
import { IdentityManagerContext } from '../IdentityManager';
import NavLinkButton from './NavLinkButton';

const StyledNav = styled(Flex)`
  ${position}
  ${bottom}
  ${left}
  ${right}
  ${borderTop}
  ${height}
`;

const Nav = React.memo(props => {
  const { currentAccount } = React.useContext(AccountsContext);
  const { showLogin } = React.useContext(IdentityManagerContext);
  const isLoggedIn = !!currentAccount;
  const isSeller = isLoggedIn && currentAccount.completedSellerOnboarding;

  const links = [{ label: 'For Sale', path: FOR_SALE_ARCHIVE }];
  if (!isLoggedIn) links.push({ label: 'About Us', path: ABOUT });
  if (!isLoggedIn) links.push({ label: 'Blog', path: BLOG_POSTS_ARCHIVE });
  if (isLoggedIn) links.push({ label: 'My Feed', path: MY_FEED });
  if (isLoggedIn && !isSeller) links.push({ label: 'Become a Seller', path: SELLER_ONBOARDING });
  if (isSeller) links.push({ label: 'My Listings', path: LISTINGS_ARCHIVE });
  if (!isLoggedIn) links.push({ label: 'Login', onClick: showLogin });

  return <StyledNav {...props}>
    {links.map(({ label, path, onClick }, i) => {
      return !!path
        ? <Link key={i} to={path} sx={{
          fontSize: [0, 1],
          mr: [0, 0, 4],
          textTransform: 'uppercase',
          textDecoration: 'none'
        }}>{label}</Link>
        : <NavLinkButton key={i} onClick={onClick}>{label}</NavLinkButton>;
    })}
  </StyledNav>
});

Nav.propTypes = {};

Nav.defaultProps = {
  position: ['absolute', 'absolute', 'static'],
  bottom: 0,
  left: 0,
  right: 0,
  bg: ['gray.1', 'gray.1', 'transparent'],
  height: [34, 37, 'auto'],
  borderTop: ['default', 'default', 'none'],
  alignItems: 'center',
  justifyContent: ['space-around', 'space-around', 'start'],
  pl: [3, 4, 0],
  pr: [3, 4, 0],
};

Nav.displayName = 'Nav';

export default Nav;
