import Button from '@paperstac/ui/lib/Button';
import ErrorText from '@paperstac/ui/lib/ErrorText';
import PropTypes from 'prop-types';
import React from 'react';
import NegotiateNotePricingTable from '../../pages/ListingPage/NegotiateNotePricingTable';

const NegotiateNotePriceMultiView = React.memo(({ notes, notePrices, onCancel, onEdit, onSubmit, updatedNotePrices }) => {
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const isSamePrice = React.useMemo(() => {
    return Object.keys(notePrices).reduce((isSamePrice, noteId) => {
      return isSamePrice
        && !isNaN(notePrices[noteId])
        && !isNaN(updatedNotePrices[noteId])
        && +notePrices[noteId] === +updatedNotePrices[noteId];
    }, true);
  }, [notePrices, updatedNotePrices]);

  const handleSubmit = React.useCallback(() => {
    if (isSubmitting) return;
    setSubmitting(true);
    onSubmit(updatedNotePrices)
      .catch(err => setErrorMessage(err.message))
      .finally(() => setSubmitting(false));
  }, [isSubmitting, onSubmit, updatedNotePrices]);

  return <>
    <NegotiateNotePricingTable
      notes={notes}
      notePrices={notePrices}
      updatedNotePrices={updatedNotePrices}
      onEdit={onEdit}
      mb={4}
    />
    {!!errorMessage && <ErrorText mb={3}>{errorMessage}</ErrorText>}
    <Button onClick={handleSubmit} variant="primary" busy={isSubmitting} mr={2}>{isSamePrice ? 'Accept Current Price' : 'Make Counter Offer'}</Button>
    <Button onClick={onCancel} variant="default">Cancel</Button>
  </>
});

NegotiateNotePriceMultiView.propTypes = {
  notes: PropTypes.array.isRequired,
  notePrices: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  updatedNotePrices: PropTypes.object.isRequired,
};

NegotiateNotePriceMultiView.defaultProps = {};

NegotiateNotePriceMultiView.displayName = 'NegotiateNotePriceMultiView';

export default NegotiateNotePriceMultiView;
