import { SubmissionError } from 'redux-form';
import { firestore } from '../services/firebaseClient';
import { getAddressMeta } from '../utils/address';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';
import { addToast } from './toasterActions';

export const CREATE_VESTING_PROFILE_REQUEST = 'CREATE_VESTING_PROFILE_REQUEST';
export const CREATE_VESTING_PROFILE_SUCCESS = 'CREATE_VESTING_PROFILE_SUCCESS';
export const CREATE_VESTING_PROFILE_FAILURE = 'CREATE_VESTING_PROFILE_FAILURE';
export const DELETE_VESTING_PROFILE_REQUEST = 'DELETE_VESTING_PROFILE_REQUEST';
export const DELETE_VESTING_PROFILE_SUCCESS = 'DELETE_VESTING_PROFILE_SUCCESS';
export const DELETE_VESTING_PROFILE_FAILURE = 'DELETE_VESTING_PROFILE_FAILURE';
export const UPDATE_VESTING_PROFILE_REQUEST = 'UPDATE_VESTING_PROFILE_REQUEST';
export const UPDATE_VESTING_PROFILE_SUCCESS = 'UPDATE_VESTING_PROFILE_SUCCESS';
export const UPDATE_VESTING_PROFILE_FAILURE = 'UPDATE_VESTING_PROFILE_FAILURE';
export const FETCH_VESTING_PROFILE_REQUEST = 'FETCH_VESTING_PROFILE_REQUEST';
export const FETCH_VESTING_PROFILE_SUCCESS = 'FETCH_VESTING_PROFILE_SUCCESS';
export const FETCH_VESTING_PROFILE_FAILURE = 'FETCH_VESTING_PROFILE_FAILURE';
export const SET_VESTING_PROFILES = 'SET_VESTING_PROFILES';
export const SUBSCRIBE_VESTING_PROFILES = 'SUBSCRIBE_VESTING_PROFILES';
export const UNSUBSCRIBE_VESTING_PROFILES = 'UNSUBSCRIBE_VESTING_PROFILES';

let unsubscribe = null;

const transformQuerySnapshot = querySnapshot => {
  return querySnapshot.docs.map(docSnap => {
    const data = transformFirestoreTimestamps(docSnap);
    return { ...data, ...getAddressMeta(data) };
  });
};

export const setVestingProfiles = payload => {
  return { type: SET_VESTING_PROFILES, payload };
};

export const fetchVestingProfilesRequest = accountId => {
  return dispatch => {
    dispatch({ type: FETCH_VESTING_PROFILE_REQUEST });
    return firestore
      .collection(`accounts/${accountId}/vestingProfiles`)
      .get()
      .then(querySnapshot => {
        dispatch({ type: FETCH_VESTING_PROFILE_SUCCESS });
        dispatch(setVestingProfiles(transformQuerySnapshot(querySnapshot)));
      })
      .catch(error => {
        dispatch({ type: FETCH_VESTING_PROFILE_FAILURE, message: error.message });
      });
  };
};

export const subscribeVestingProfiles = accountId => {
  return dispatch => {
    if (!unsubscribe) {
      dispatch({ type: SUBSCRIBE_VESTING_PROFILES, accountId });
      unsubscribe = firestore.collection(`accounts/${accountId}/vestingProfiles`).onSnapshot(querySnapshot => {
        dispatch(setVestingProfiles(transformQuerySnapshot(querySnapshot)));
      });
    }
  };
};

export const unsubscribeVestingProfiles = () => {
  return dispatch => {
    if (unsubscribe) {
      unsubscribe();
      unsubscribe = null;
      dispatch({ type: UNSUBSCRIBE_VESTING_PROFILES });
    }
  };
};

export const createVestingProfileRequest = (accountId, payload) => {
  return dispatch => {
    dispatch({ type: CREATE_VESTING_PROFILE_REQUEST, payload });
    return firestore
      .doc(`accounts/${accountId}/vestingProfiles/${payload.id}`)
      .set(payload)
      .then(() => dispatch({ type: CREATE_VESTING_PROFILE_SUCCESS }))
      .catch(error => {
        dispatch({ type: CREATE_VESTING_PROFILE_FAILURE, message: error.message });
        throw new SubmissionError({ _error: error.message });
      });
  };
};

export const updateVestingProfileRequest = (accountId, payload) => {
  return dispatch => {
    dispatch({ type: UPDATE_VESTING_PROFILE_REQUEST, payload });
    return firestore
      .doc(`accounts/${accountId}/vestingProfiles/${payload.id}`)
      .update(payload)
      .then(() => dispatch({ type: UPDATE_VESTING_PROFILE_SUCCESS }))
      .catch(error => {
        dispatch({ type: UPDATE_VESTING_PROFILE_FAILURE, message: error.message });
        throw new SubmissionError({ _error: error.message });
      });
  };
};

export const deleteVestingProfileRequest = (accountId, id) => {
  return dispatch => {
    dispatch({ type: DELETE_VESTING_PROFILE_REQUEST, id });
    return firestore
      .doc(`accounts/${accountId}/vestingProfiles/${id}`)
      .delete()
      .then(() => dispatch({ type: DELETE_VESTING_PROFILE_SUCCESS }))
      .catch(error => {
        dispatch({ type: DELETE_VESTING_PROFILE_FAILURE, message: error.message });
        dispatch(addToast(error.message, null, 'error'));
      });
  };
};
