import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import { getNameFromCode } from '@paperstac/common/lib/services/states';
import { normalizeDate } from '@paperstac/helpers/lib/dateHelpers';
import { centsToUsdString, getOrdinal } from '@paperstac/helpers/lib/numberHelpers';
import Box from '@paperstac/ui/lib/Box';
import Flex from '@paperstac/ui/lib/Flex';
import InfoCircleIcon from '@paperstac/ui/lib/icons/InfoCircleIcon';
import RelativeDate from '@paperstac/ui/lib/RelativeDate';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React from 'react';
import NoteFilesSection from './NoteFilesSection';
import NoteMetaGroup from './NoteMetaGroup';
import PaymentHistorySection from './PaymentHistorySection';
import PropertyValuationsSection from './PropertyValuationsSection';

const formatBoolean = boolean => typeof boolean === 'boolean' ? boolean ? 'Yes' : 'No' : '';
const formatDate = date => date ? format(normalizeDate(date), 'MMM D, YYYY') : '';
const formatMoney = cents => !isNaN(cents) ? centsToUsdString(cents) : '';
const formatOrdinal = number => number ? getOrdinal(number) : '';
const formatPercent = percent => percent ? `${percent}%` : '';
const formatState = state => state ? getNameFromCode(state) : '';
const formatSqFt = percent => percent ? `${percent} sqft` : '';

const NoteMeta = React.memo(({ note, parentWidth, showFiles }) => {
  const { uid } = React.useContext(IdentityContext);
  const stackMeta = parentWidth < 640;

  const seniorTerms = React.useMemo(() => ([
    { label: 'Sr. Performance (PL/NPL)', value: note.seniorPerformance },
    { label: 'Sr. Unpaid Principal Balance', value: formatMoney(note.seniorUpb) },
    { label: 'Sr. Loan to Value', value: formatPercent(note.seniorLoanToValuePercent) },
    { label: 'Sr. Interest Rate', value: formatPercent(note.seniorInterestRatePercent) },
    { label: 'Sr. Total Monthly Loan Payment', value: formatMoney(note.seniorTotalMonthlyLoanPayment) },
  ]), [note]);

  const noteTerms = React.useMemo(() => ([
    { label: 'Lien Position', value: formatOrdinal(note.lienPosition) },
    { label: 'Performance (PL/NPL)', value: note.performance },
    { label: 'Note Type', value: note.noteType },
    { label: 'Legal Status', value: note.legalStatus },
    { label: 'Unpaid Principal Balance', value: formatMoney(note.upb) },
    { label: 'Loan to Value', value: formatPercent(note.loanToValuePercent) },
    { label: 'Origination Date', value: formatDate(note.originationDate) },
    { label: 'Original Balance', value: formatMoney(note.originalBalance) },
    { label: 'Total Payoff', value: formatMoney(note.totalPayoff) },
    { label: 'Payments Remaining', value: note.paymentsRemaining },
    { label: 'Interest Rate', value: formatPercent(note.interestRatePercent) },
    { label: 'Interest-Only Loan', value: formatBoolean(note.interestOnlyLoan) },
    { label: 'Principal and Interest Payment', value: formatMoney(note.principalAndInterestPayment) },
    { label: 'Escrow/Impounds', value: formatMoney(note.escrowImpounds) },
    { label: 'Total Monthly Loan Payment', value: formatMoney(note.totalMonthlyLoanPayment) },
    { label: 'Last Payment Received Date', value: formatDate(note.lastPaymentReceivedDate) },
    { label: 'Next Payment Date', value: formatDate(note.nextPaymentDate) },
    { label: 'Maturity Date', value: formatDate(note.maturityDate) },
    { label: 'Accrued Late Charges', value: formatMoney(note.accruedLateCharges) },
    { label: 'Hardest Hit Fund State', value: formatBoolean(note.isHardestHitFundState) },
    { label: 'Judicial State', value: formatBoolean(note.isJudicialState) },
    { label: 'Non-Judicial State', value: formatBoolean(note.isNonJudicialState) },
  ]), [note]);

  const propertyDetails = React.useMemo(() => ([
    { label: 'Property Type', value: note.propertyType },
    { label: 'Street Address', value: uid ? note.streetAddress : '[Login to View]' },
    { label: 'City', value: note.city },
    { label: 'State', value: formatState(note.state) },
    { label: 'Zip', value: note.zip },
    { label: 'County', value: note.county },
    { label: 'Year Built', value: note.yearBuilt },
    { label: 'Floor Size Sq Ft', value: formatSqFt(note.floorSizeSqFt) },
    { label: 'Lot Size Sq Ft', value: formatSqFt(note.lotSizeSqFt) },
    { label: 'Bedrooms', value: note.bedrooms },
    { label: 'Bathrooms', value: note.bathrooms },
    { label: 'Occupancy Status', value: note.occupancyStatus },
  ]), [uid, note]);

  return <Box>
    <Box sx={{
      mb: 4,
      bg: 'darkBlue',
      color: 'white',
      borderRadius: 'corner',
      py: 2,
      px: 3,
      textAlign: 'center'
    }}>
      <InfoCircleIcon mr={2} style={{ position: 'relative', top: '-2px' }} />
      Note updated <RelativeDate date={note.updatedDate} />
    </Box>

    <Flex mb={stackMeta ? 0 : 4} flexDirection={stackMeta ? 'column' : 'row'}>

      <Box sx={stackMeta ? {} : { mr: 3, flex: '1 1 0' }}>
        {note.lienPosition > 1 && <NoteMetaGroup
          title="Senior Note Terms"
          subtitle={`Senior note terms are provided by the seller to help analyze this ${getOrdinal(note.lienPosition)} position note.`}
          items={seniorTerms}
          sx={{ mb: 4 }}
        />}
        <NoteMetaGroup
          title="Note Terms"
          items={noteTerms}
          sx={{ mb: 4 }}
        />
      </Box>
      <NoteMetaGroup
        title="Property Details"
        items={propertyDetails}
        sx={stackMeta ? { mb: 4 } : { ml: 3, flex: '1 1 0' }}
      />
    </Flex>

    <PropertyValuationsSection note={note} mb={4} parentWidth={parentWidth} />
    <PaymentHistorySection
      noteAccountId={note.accountId}
      noteId={note.id}
      paymentHistoryFileDate={note.paymentHistoryFileDate}
      paymentHistoryFileUrl={note.paymentHistoryFileUrl}
      mb={4}
    />
    {showFiles && <NoteFilesSection note={note} mb={4} parentWidth={parentWidth} />}
  </Box>;
});

NoteMeta.propTypes = {
  note: PropTypes.object.isRequired
};

NoteMeta.defaultProps = {};

NoteMeta.displayName = 'NoteMeta';

export default NoteMeta;

