import { firestore } from '../services/firebaseClient';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';
import { addToast } from './toasterActions';

export const CREATE_BANK_ACCOUNT_REQUEST = 'CREATE_BANK_ACCOUNT_REQUEST';
export const CREATE_BANK_ACCOUNT_SUCCESS = 'CREATE_BANK_ACCOUNT_SUCCESS';
export const CREATE_BANK_ACCOUNT_FAILURE = 'CREATE_BANK_ACCOUNT_FAILURE';
export const DELETE_BANK_ACCOUNT_REQUEST = 'DELETE_BANK_ACCOUNT_REQUEST';
export const DELETE_BANK_ACCOUNT_SUCCESS = 'DELETE_BANK_ACCOUNT_SUCCESS';
export const DELETE_BANK_ACCOUNT_FAILURE = 'DELETE_BANK_ACCOUNT_FAILURE';
export const FETCH_BANK_ACCOUNTS_REQUEST = 'FETCH_BANK_ACCOUNTS_REQUEST';
export const FETCH_BANK_ACCOUNTS_SUCCESS = 'FETCH_BANK_ACCOUNTS_SUCCESS';
export const FETCH_BANK_ACCOUNTS_FAILURE = 'FETCH_BANK_ACCOUNTS_FAILURE';
export const SET_BANK_ACCOUNTS = 'SET_BANK_ACCOUNTS';
export const SUBSCRIBE_BANK_ACCOUNTS = 'SUBSCRIBE_BANK_ACCOUNTS';
export const UNSUBSCRIBE_BANK_ACCOUNTS = 'UNSUBSCRIBE_BANK_ACCOUNTS';
export const UPDATE_BANK_ACCOUNT_REQUEST = 'UPDATE_BANK_ACCOUNT_REQUEST';
export const UPDATE_BANK_ACCOUNT_SUCCESS = 'UPDATE_BANK_ACCOUNT_SUCCESS';
export const UPDATE_BANK_ACCOUNT_FAILURE = 'UPDATE_BANK_ACCOUNT_FAILURE';

let unsubscribe = null;

const transformQuerySnapshot = querySnapshot => {
  return querySnapshot.docs.map(transformFirestoreTimestamps);
};

export const setBankAccounts = payload => {
  return { type: SET_BANK_ACCOUNTS, payload };
};

export const fetchBankAccountsRequest = accountId => {
  return dispatch => {
    dispatch({ type: FETCH_BANK_ACCOUNTS_REQUEST });
    return firestore
      .collection(`accounts/${accountId}/bankAccounts`)
      .get()
      .then(querySnapshot => {
        dispatch({ type: FETCH_BANK_ACCOUNTS_SUCCESS });
        dispatch(setBankAccounts(transformQuerySnapshot(querySnapshot)));
      })
      .catch(error => {
        dispatch({ type: FETCH_BANK_ACCOUNTS_FAILURE, message: error.message });
      });
  };
};

export const subscribeBankAccounts = accountId => {
  return dispatch => {
    if (!unsubscribe) {
      dispatch({ type: SUBSCRIBE_BANK_ACCOUNTS, accountId });
      unsubscribe = firestore.collection(`accounts/${accountId}/bankAccounts`).onSnapshot(querySnapshot => {
        dispatch(setBankAccounts(transformQuerySnapshot(querySnapshot)));
      });
    }
  };
};

export const unsubscribeBankAccounts = () => {
  return dispatch => {
    if (unsubscribe) {
      unsubscribe();
      unsubscribe = null;
      dispatch({ type: UNSUBSCRIBE_BANK_ACCOUNTS });
    }
  };
};

export const createBankAccount = (accountId, payload) => {
  return dispatch => {
    return firestore.doc(`accounts/${accountId}/bankAccounts/${payload.id}`).set(payload);
  }
};

export const updateBankAccount = (accountId, payload) => {
  return dispatch => {
    return firestore.doc(`accounts/${accountId}/bankAccounts/${payload.id}`).update(payload);
  }
};

export const deleteBankAccountRequest = (accountId, id) => {
  return dispatch => {
    dispatch({ type: DELETE_BANK_ACCOUNT_REQUEST, id });
    return firestore
      .doc(`accounts/${accountId}/bankAccounts/${id}`)
      .delete()
      .then(() => dispatch({ type: DELETE_BANK_ACCOUNT_SUCCESS }))
      .catch(error => {
        dispatch({ type: DELETE_BANK_ACCOUNT_FAILURE, message: error.message });
        dispatch(addToast(error.message, null, 'error'));
      });
  };
};
