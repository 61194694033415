import Box from '@paperstac/ui/lib/Box';
import React from 'react';

const InfoMessage = React.memo(props => <Box {...props} />);

InfoMessage.propTypes = {};

InfoMessage.defaultProps = {
  sx: {
    fontSize: 1,
    mb: 3,
    py: 2,
    px: 3,
    border: 'default',
    borderColor: 'blue',
    borderRadius: 'corner',
    bg: 'gray.1',
  }
};

InfoMessage.displayName = 'InfoMessage';

export default InfoMessage;
