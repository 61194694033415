import AlgoliaHasRangeRefinement from '@paperstac/ui/lib/AlgoliaHasRangeRefinement';
import AlgoliaRangeInput from '@paperstac/ui/lib/AlgoliaRangeInput';
import React from 'react';
import CollapsibleFilterContainer from './CollapsibleFilterContainer';

const ListPriceFilter = React.memo(props => <AlgoliaHasRangeRefinement attribute="listPrice">
  <CollapsibleFilterContainer title="List Price">
    <AlgoliaRangeInput attribute="listPrice" format="thousandUsd"/>
  </CollapsibleFilterContainer>
</AlgoliaHasRangeRefinement>);

ListPriceFilter.propTypes = {};

ListPriceFilter.defaultProps = {};

ListPriceFilter.displayName = 'ListPriceFilter';

export default ListPriceFilter;