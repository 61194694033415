import 'indexeddb-getall-shim';
import './services/setupFirebaseClient';
import '@paperstac/validation/lib/initYupWebDictionary';
import 'core-js/stable';
import { getState, loadComponents } from 'loadable-components';
import React from 'react';
import 'react-app-polyfill/ie11';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import 'regenerator-runtime/runtime';
import App from './App';
import campaign from './services/campaign';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';

const rootElement = document.getElementById('root');
const store = configureStore();

campaign.init();
window.snapSaveState = () => getState();
delete window.__PRELOADED_STATE__;

if (rootElement.hasChildNodes()) {
  loadComponents().then(() => {
    hydrate(
      <Provider store={store}>
        <App />
      </Provider>,
      rootElement
    );
  });
} else {
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  );
}

serviceWorker.register();
